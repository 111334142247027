import { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import Annotation from 'types/Annotation';
import { useAppDispatch, useAppSelector } from 'store';
import * as datasetsSlice from 'redux/datasets';
import * as annotationsSlice from 'redux/annotations';
import * as settingsSlice from 'redux/settings';
import ApiErrors from 'services/ApiErrors';
import { ANNOTATION_CREATESTATE, DEFAULT_ANNOTATION_FILTER, EDITSTATE } from 'services/Constants';
import ToggleSwitch from 'components/ToggleSwitch';
import {
    fetchSSDMTypes,
    checkForUsernames,
    startAnnotationCreation,
    deleteAnnotation as deleteAnnotationAction,
} from '../../../redux/actions';
import AnnotationExport from './AnnotationExport';
import AnnotationFilters from './AnnotationFilters';
import AnnotationItem from './AnnotationItem';
import ErrorBoundary from '../../ErrorBoundary';

const AnnotationList = (props: { annotations: Annotation[]; deleteCallback: (a: Annotation) => void }) => {
    const { annotations, deleteCallback } = props;
    const dispatch = useAppDispatch();

    if (annotations.length === 0) {
        return (
            <span className="text-center mt-5">There are currently no annotations or they are hidden by filters.</span>
        );
    }

    return (
        <ul className="list">
            {annotations.map((annotation) => (
                <ErrorBoundary
                    key={annotation.id}
                    dispatch={dispatch}
                    fallback={
                        <li>
                            <div>
                                <span className="error-fallback-message">
                                    <i className="fal fa-exclamation-triangle icon-red" />
                                    An error occured in this AnnotationItem component.
                                    <i className="fal fa-exclamation-triangle icon-red" />
                                </span>
                            </div>
                        </li>
                    }
                >
                    <AnnotationItem annotation={annotation} deleteCallback={deleteCallback} />
                </ErrorBoundary>
            ))}
        </ul>
    );
};

const AnnotationMenu = () => {
    const dispatch = useAppDispatch();

    const project = useAppSelector(datasetsSlice.currentProject);
    const filters = useAppSelector(annotationsSlice.filter);

    const createState = useAppSelector(annotationsSlice.createState);
    const editState = useAppSelector(annotationsSlice.editState);

    const viewFilter = useAppSelector(settingsSlice.getFilterAnnotationByVisibility);
    const showAnnotations = useAppSelector(settingsSlice.getShowAnnotationsInViewport);
    const datasets = useAppSelector(datasetsSlice.getProjectDatasets);

    const annotations = useAppSelector(annotationsSlice.filtered(viewFilter));
    const filteredAnnotations = sortByDate(annotations);
    const annotationAuthors = [...new Set(annotations.map((annotation) => annotation.created_by_id))];

    useEffect(() => {
        if (annotationAuthors.length > 0) {
            dispatch(checkForUsernames(annotationAuthors));
        }
    }, [annotationAuthors]);

    useEffect(() => {
        dispatch(fetchSSDMTypes());
    }, []);

    const DELETE = {
        PROMPT: 'prompt',
        DELETING: 'deleting',
        CONFIRMATION: 'confirm',
        ERROR: 'error',
    };

    const [deleteIsOpen, setDeleteIsOpen] = useState(false);
    const [deleteState, setDeleteState] = useState(DELETE.PROMPT);
    const [errorText, setErrorText] = useState(null);
    const [annotationToDelete, setAnnotationToDelete] = useState(null);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filtersCount, setFiltersCount] = useState(0);

    useEffect(() => {
        let newCount = 0;

        Object.keys(DEFAULT_ANNOTATION_FILTER).forEach((x) => {
            if (Array.isArray(filters[x])) {
                // Symetric difference
                newCount += DEFAULT_ANNOTATION_FILTER[x]
                    .filter((element) => !filters[x].includes(element))
                    .concat(filters[x].filter((element) => !DEFAULT_ANNOTATION_FILTER[x].includes(element))).length;
            } else if (filters[x] !== DEFAULT_ANNOTATION_FILTER[x]) newCount += 1;
        });
        setFiltersCount(newCount);
    }, [filters]);

    const openDeleteModal = (annotation: Annotation) => {
        setDeleteState(DELETE.PROMPT);
        setDeleteIsOpen(true);
        setAnnotationToDelete(annotation);
    };

    const cancelDelete = () => {
        setDeleteIsOpen(false);
    };

    const deleteAnnotation = () => {
        setDeleteState(DELETE.DELETING);
        dispatch(deleteAnnotationAction(annotationToDelete))
            .then(async () => {
                setDeleteState(DELETE.CONFIRMATION);
                setTimeout(cancelDelete, 2000);
            })
            .catch((err) => {
                setErrorText(ApiErrors.getErrorMessage(err));
                setDeleteState(DELETE.ERROR);
            });
    };

    const deleteModalContent = () => {
        switch (deleteState) {
            case DELETE.PROMPT:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                            <span className="big-modal-text">Are you sure?</span>
                            <button type="button" className="pane-button large highlight" onClick={deleteAnnotation}>
                                Yes, Delete this Annotation
                            </button>
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" className="pane-button large" onClick={cancelDelete}>
                                Cancel
                            </button>
                        </ModalFooter>
                    </>
                );
            case DELETE.DELETING:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-warn fal fa-timer no-hover" />
                            <span className="big-modal-text">Deleting annotation...</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case DELETE.CONFIRMATION:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                            <span className="big-modal-text">Annotation deleted</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case DELETE.ERROR:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                            <span className="big-modal-text">An error occured</span>
                            <span className="small-modal-text">{errorText}</span>
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" className="pane-button large highlight" onClick={cancelDelete}>
                                OK
                            </button>
                        </ModalFooter>
                    </>
                );
            default:
                return null;
        }
    };

    const beginCreation = () => {
        dispatch(startAnnotationCreation());
    };

    function sortByDate(list: Annotation[]): Annotation[] {
        const copy = [...list];
        copy.sort((a, b) => new Date(b.created_at_utc).getTime() - new Date(a.created_at_utc).getTime());

        return copy;
    }

    return (
        <div className="tabContent">
            <div className="input-row">
                <AnnotationExport project={project} annotations={annotations} />
                <button
                    type="button"
                    className={`pane-button ${filtersCount === 0 ? '' : 'alternate-highlight'}`}
                    onClick={() => setFiltersOpen(true)}
                >
                    <i className="fas fa-bars-filter" />
                </button>
                <div className="grow" />
                <button
                    id="add-annotation"
                    type="button"
                    title="New annotation..."
                    className="pane-button highlight"
                    onClick={beginCreation}
                    disabled={
                        !project?.user_permissions.interact ||
                        editState !== EDITSTATE.NONE ||
                        createState !== ANNOTATION_CREATESTATE.NONE
                    }
                >
                    <i className="fas fa-plus" />
                </button>
                {!project?.user_permissions.interact ? (
                    <UncontrolledTooltip target="add-annotation">
                        You do not have the permissions to add annotations.
                    </UncontrolledTooltip>
                ) : null}
                {editState !== EDITSTATE.NONE || createState !== ANNOTATION_CREATESTATE.NONE ? (
                    <UncontrolledTooltip target="add-annotation">
                        There is already an annotation action ongoing.
                    </UncontrolledTooltip>
                ) : null}
            </div>
            <ul className="list">
                <div className="input-row">
                    <ToggleSwitch
                        id="showAnnotations"
                        checked={showAnnotations}
                        onChange={(v) => dispatch(settingsSlice.showAnnotationsInViewport(v.target.checked))}
                    />
                    Show annotations in viewport
                </div>
                <div className="input-row">
                    <ToggleSwitch
                        id="visibleAnnotations"
                        checked={viewFilter}
                        onChange={(v) => {
                            dispatch(settingsSlice.filterAnnotations(v.target.checked));
                            if (!v) dispatch(annotationsSlice.resetVisibilities());
                        }}
                    />
                    List visible annotations
                </div>
            </ul>
            <AnnotationList annotations={filteredAnnotations} deleteCallback={(a) => openDeleteModal(a)} />
            <Modal id="annotation" isOpen={deleteIsOpen} centered className="modal-confirm">
                <ModalHeader toggle={cancelDelete} />
                {deleteModalContent()}
            </Modal>
            <Modal isOpen={filtersOpen} toggle={() => setFiltersOpen(false)}>
                <ErrorBoundary
                    dispatch={dispatch}
                    fallback={
                        <>
                            <span className="error-fallback-message">
                                <i className="fal fa-exclamation-triangle icon-red" />
                                An error occured in the AnotationFilters component.
                                <i className="fal fa-exclamation-triangle icon-red" />
                            </span>
                            <button type="button" className="pane-button" onClick={() => setFiltersOpen(false)}>
                                Back
                            </button>
                        </>
                    }
                >
                    <AnnotationFilters
                        authors={annotationAuthors}
                        datasets={datasets}
                        close={() => setFiltersOpen(false)}
                    />
                </ErrorBoundary>
            </Modal>
        </div>
    );
};

export default AnnotationMenu;

import { useAppDispatch, useAppSelector } from 'store';
import * as annotationsSlice from 'redux/annotations';
import * as datasetsSlice from 'redux/datasets';
import { Button } from 'reactstrap';
import { selectAnnotationForEdit, startAnnotationEdit, startAnnotationView } from 'redux/actions';
import InlineDropMenu from 'components/InlineDropMenu';
import { getSSDMTypes, getUsernames } from 'redux/selectors';
import { AnnotationId } from 'types/Annotation';
import { gotoAnnotation } from 'redux/annotationActions';
import Comments from './Comments';
import Status from './Status';
import CommentForm from './CommentForm';

type Props = {
    annotationId: AnnotationId;
    pinned?: boolean;
};

const AnnotationViewer = (props: Props) => {
    const dispatch = useAppDispatch();

    const annotation = useAppSelector(annotationsSlice.get(props.annotationId));
    const ssdm = useAppSelector(getSSDMTypes);
    const project = useAppSelector(datasetsSlice.currentProject);
    const users = useAppSelector(getUsernames);
    const author = users && annotation ? users.find((x) => x.id === annotation.created_by_id) : null;

    const triggerEdit = () => {
        dispatch(selectAnnotationForEdit(annotation));
    };
    const triggerEditGeometry = () => {
        dispatch(startAnnotationEdit(annotation));
    };
    const triggerViewGeometry = () => {
        dispatch(startAnnotationView(annotation));
    };
    const triggerGotoGeometry = () => {
        dispatch(gotoAnnotation(annotation));
    };

    const classification = () => {
        if (annotation.ssdm_type) {
            if (ssdm === null) return 'Loading...';
            const ssdmDetails = ssdm.find((elem) => elem.name === annotation.ssdm_type);
            return `${ssdmDetails?.name} ${ssdmDetails?.display_name}`;
        }
        return null;
    };

    const formatTimestamp = (timestamp) => {
        timestamp = new Date(timestamp);
        const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
        const result = `${timestamp.getDate()} ${months[timestamp.getMonth()]} ${timestamp.getFullYear()}`;
        return result;
    };

    const classificationString = classification();

    return (
        <div className="tabContent">
            <div className="annotation-title-row">
                {annotation.name}
                <div className="grow" />
                <Status annotation={annotation} corner />
                <InlineDropMenu id={`annotation-actions-${annotation.id}`}>
                    {annotation.user_permissions.manage ? (
                        <>
                            <Button
                                className="borderless light-blue"
                                title="Edit annotation geometry"
                                onClick={triggerEditGeometry}
                            >
                                <i className="fas fa-draw-polygon" />
                            </Button>
                            <Button className="borderless green" title="Edit annotation" onClick={triggerEdit}>
                                <i className="fas fa-pen" />
                            </Button>
                            <Button
                                className="borderless light-blue"
                                title="Goto Annotation"
                                onClick={triggerGotoGeometry}
                            >
                                <i className="fas fa-location-arrow" />
                            </Button>
                        </>
                    ) : (
                        <Button
                            className="borderless light-blue"
                            id={`annotation-view-geom-${annotation.id}`}
                            title="View Geometry"
                            onClick={triggerViewGeometry}
                        >
                            <i className="fas fa-eye" />
                        </Button>
                    )}
                </InlineDropMenu>
            </div>
            <div className="annotation-info">
                {classificationString ? (
                    <>
                        {classificationString}
                        <br />
                    </>
                ) : null}
                {author ? `${author.given_name} ${author.family_name}` : 'Loading User'}
                {' | '}
                {formatTimestamp(annotation.created_at_utc)}
            </div>
            <div className="annotation-description">{annotation.description}</div>
            <hr />
            <Comments annotation={annotation} pinned={props.pinned} />
            {project.user_permissions.interact ? <CommentForm annotation={annotation} pinned={props.pinned} /> : null}
        </div>
    );
};

export default AnnotationViewer;

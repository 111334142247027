export default `
#include <giro3d_precision_qualifiers>
#include <giro3d_common>

uniform sampler2D signalTexture;
uniform vec3 vLut[ LUT_SIZE ];

varying vec2 vUv;
uniform float intensityFilter;
uniform bool filterTransparency;
uniform bool isEnvelope;
uniform float opacity;
uniform vec4 offsetScale;
uniform bool customBoundsMode;
uniform float dataMin;
uniform float dataMax;
uniform float customMin;
uniform float customMax;
uniform float brightness;

#include <clipping_planes_pars_fragment>

float filterTexture(float texture_value, float bounds){
    // isEnvelope -> only positive values, minimum at intensity 0.
    if (isEnvelope){
        if (texture_value < bounds){
            if (filterTransparency){
                discard;
            }
            return 0.0;
        }
    }

    // !isEnvelope -> full waveform, positive and negative values, minimum at intensity 0.5 (pixel value 127).
    if ((texture_value < 0.5 + 0.5*bounds) && (texture_value > 0.5 - 0.5*bounds)){
        if (filterTransparency){
            discard;
        }
        return 0.5;
    }
    return texture_value;
}

float rescaleToBounds(float texture_value){
    // if (customBoundsMode == False){
    //     return texture_value;
    // }
    // [0, 1] -> [dataMin, dataMax]
    float realValue = texture_value * (dataMax - dataMin) + dataMin;

    // [dataMin, dataMax] -> [customMin, customMax]
    float clampedValue = clamp(realValue, customMin, customMax);

    // [customMin, customMax] -> [0, 1]
    float rescaledTextureValue = (clampedValue - customMin) / (customMax - customMin);

    return rescaledTextureValue;
}

void main() {
    #include <clipping_planes_fragment>

    vec2 uv = computeUv(vUv, offsetScale.xy, offsetScale.zw);
    vec3 textureColor = texture2D(signalTexture, uv).rgb;

    float filteredTexture = filterTexture(textureColor.x, intensityFilter);
    float rescaledValue = rescaleToBounds(filteredTexture);
    highp int index = clamp(int(rescaledValue * float(LUT_SIZE)), 0, int(LUT_SIZE) - 1);
    vec3 vColor = vLut[index];
    gl_FragColor = vec4(vColor, opacity);

    gl_FragColor.rgb = mix(gl_FragColor.rgb, vec3(1, 1, 1), brightness);

#if defined(ENABLE_OUTLINES)
    const float OUTLINE_WIDTH = 0.01;
    if (vUv.x < OUTLINE_WIDTH || vUv.x > 1.0 - OUTLINE_WIDTH || vUv.y < OUTLINE_WIDTH || vUv.y > 1.0 - OUTLINE_WIDTH) {
        gl_FragColor.r = 1.0 - gl_FragColor.r;
        gl_FragColor.g = 1.0 - gl_FragColor.g;
        gl_FragColor.b = 1.0 - gl_FragColor.b;
    }
#endif

    #include <colorspace_fragment>
}
`;

import { nanoid } from '@reduxjs/toolkit';
import { InputHTMLAttributes } from 'react';

function SettingInput(props: InputHTMLAttributes<HTMLInputElement> & { unit?: string }) {
    const id = nanoid();
    return (
        <li>
            <div>
                <span>{props.title}</span>
                <div className="settings-input">
                    <input title="floorLevel" id={id} type="number" value={props.value} {...props} />
                    <span>{props.unit}</span>
                </div>
            </div>
        </li>
    );
}

export default SettingInput;

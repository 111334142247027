import { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'store';
import Annotation, {
    AnnotationComment,
    AnnotationCommentReply,
    CommentId,
    isAnnotationCommentReply,
} from 'types/Annotation';
import { Timestamp, UUID } from 'types/common';
import { User } from 'types/User';
import { useEventBus } from 'EventBus';
import { PANE } from 'services/Constants';
import { useMountEffect } from 'components/utils';
import { updateComment, deleteComment, deleteReply } from '../../../redux/actions';
import { getProjectUsers, getUsernames } from '../../../redux/selectors';
import * as datasetsSlice from '../../../redux/datasets';
import * as annotationsSlice from '../../../redux/annotations';
import CommentEditForm from './CommentEditForm';
import formatMentions from '../../../services/MentionFormatting';
import CommentForm from './CommentForm';
import InlineDropMenu from '../../InlineDropMenu';
import ApiErrors from '../../../services/ApiErrors';

const Comments = (props: { annotation: Annotation; pinned: boolean }) => {
    const dispatch = useAppDispatch();
    const eventBus = useEventBus();

    const project = useAppSelector(datasetsSlice.currentProject);
    const comments: Readonly<AnnotationComment[]> = useAppSelector(annotationsSlice.comments(props.annotation.id));
    const users: User[] = useAppSelector(getProjectUsers);
    const usernames = useAppSelector(getUsernames);

    const [repliesVisible, setRepliesVisible] = useState([]);

    enum MODAL {
        PROMPT = 'prompt',
        PROCESSING = 'processing',
        CONFIRMATION = 'confirmation',
        ERROR = 'error',
    }

    const [deleteModalState, setModal] = useState<MODAL>(null);
    const [deletingComment, setDeletingComment] = useState(null);
    const [deletingReply, setDeletingReply] = useState(null);
    const [errorText, setErrorText] = useState();
    const [editingComment, setEditingComment] = useState(null);
    const [replying, setReplying] = useState<CommentId>(null);

    const pinAction = async (action: string, commentId?: string) => {
        if (!props.pinned) {
            await eventBus.dispatch('create-annotation-pane', {
                annotationId: props.annotation.id,
                paneType: PANE.ANNOTATION,
                showExisting: true,
            });
            eventBus.dispatch('select-annotation-form', {
                annotationId: props.annotation.id,
                action,
                commentId,
            });
        }
    };

    const startAction = (message) => {
        if (message.annotationId === props.annotation.id) {
            if (message.action === 'comment') {
                setReplying(message.commentId);
                document.getElementById(`comment-field-${message.commentId}`).focus();
            }
            if (message.action === 'edit') {
                const comment = comments.find((c) => c.id === message.commentId);
                if (comment) {
                    setEditingComment(comment);
                    document.getElementById(`edit-field-${message.commentId}`)?.focus();
                } else {
                    const parent = comments.find((c) => c.replies.map((r) => r.id === message.commentId).length !== 0);
                    const reply = parent.replies.find((r) => r.id === message.commentId);
                    toggleReplies(parent.id, true);
                    setEditingComment(reply);
                    document.getElementById(`edit-field-${message.commentId}`)?.focus();
                }
            }
        }
    };

    if (props.pinned) {
        useMountEffect(
            () => eventBus.subscribe('select-annotation-form', startAction),
            () => eventBus.unsubscribe('select-annotation-form', startAction)
        );
    }

    const createDateReadout = (input: Timestamp) => {
        // The provided timestamp is UTC but is assumed to be local so we must convert
        const date = new Date(input);
        const tick = new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds()
            )
        ).valueOf();

        const difference = Date.now() - tick;

        const years = Math.floor(difference / (365 * 24 * 60 * 60 * 1000));
        if (years > 0) return `${years} year${years === 1 ? '' : 's'}`;
        const months = Math.floor(difference / (30 * 24 * 60 * 60 * 1000));
        if (months > 0) return `${months} month${months === 1 ? '' : 's'}`;
        const weeks = Math.floor(difference / (7 * 24 * 60 * 60 * 1000));
        if (weeks > 0) return `${weeks} week${weeks === 1 ? '' : 's'}`;
        const days = Math.floor(difference / (24 * 60 * 60 * 1000));
        if (days > 0) return `${days} day${days === 1 ? '' : 's'}`;
        const hours = Math.floor(difference / (60 * 60 * 1000));
        if (hours > 0) return `${hours} hour${hours === 1 ? '' : 's'}`;
        const minutes = Math.floor(difference / (60 * 1000));
        if (minutes > 0) return `${minutes} minute${minutes === 1 ? '' : 's'}`;
        return 'less than a minute';
    };

    const getUsername = (id: UUID) => {
        if (users) {
            const match = users.filter((user) => user.id === id);
            if (match.length === 1) return `${match[0].given_name} ${match[0].family_name}`;
        }
        return 'Unknown User';
    };

    const openDeletePrompt = (comment: AnnotationComment | AnnotationCommentReply) => {
        setModal(MODAL.PROMPT);

        if (isAnnotationCommentReply(comment)) {
            setDeletingReply(comment);
        } else {
            setDeletingComment(comment);
        }
    };

    const modalContent = () => {
        switch (deleteModalState) {
            case MODAL.PROMPT:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                            <span className="big-modal-text">Are you sure?</span>
                            {deletingComment ? (
                                <span className="small-modal-text">
                                    This will also delete all replies to the comment.
                                </span>
                            ) : null}
                            <button
                                type="button"
                                className="pane-button large highlight"
                                onClick={() => {
                                    setModal(MODAL.PROCESSING);
                                    dispatch(
                                        deletingComment
                                            ? deleteComment(props.annotation, deletingComment)
                                            : deleteReply(props.annotation, deletingReply)
                                    )
                                        .then(() => {
                                            setModal(MODAL.CONFIRMATION);
                                            setTimeout(() => {
                                                setModal(null);
                                                setDeletingComment(null);
                                                setDeletingReply(null);
                                            }, 2000);
                                        })
                                        .catch((err) => {
                                            setErrorText(ApiErrors.getErrorMessage(err));
                                            setModal(MODAL.ERROR);
                                        });
                                }}
                            >
                                Yes, Delete this {deletingComment ? 'Comment' : 'Reply'}
                            </button>
                        </ModalBody>
                        <ModalFooter>
                            <button
                                type="button"
                                className="pane-button large"
                                onClick={() => {
                                    setDeletingComment(null);
                                    setDeletingReply(null);
                                    setModal(null);
                                }}
                            >
                                Cancel
                            </button>
                        </ModalFooter>
                    </>
                );
            case MODAL.PROCESSING:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-warn fal fa-timer no-hover" />
                            <span className="big-modal-text">Deleting {deletingComment ? 'comment' : 'reply'}...</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case MODAL.CONFIRMATION:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                            <span className="big-modal-text">{deletingComment ? 'Comment' : 'Reply'} deleted</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case MODAL.ERROR:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                            <span className="big-modal-text">An error occured</span>
                            <span className="small-modal-text">{errorText}</span>
                        </ModalBody>
                        <ModalFooter>
                            <button
                                type="button"
                                className="pane-button large highlight"
                                onClick={() => {
                                    setDeletingComment(null);
                                    setModal(null);
                                }}
                            >
                                OK
                            </button>
                        </ModalFooter>
                    </>
                );
            default:
                return null;
        }
    };

    const renderedListItems = [];

    const renderComment = (comment: AnnotationComment) =>
        editingComment !== comment ? (
            <div className="comment" key={comment.id} id={comment.id}>
                <div className={`comment-author-line ${comment.resolved ? 'resolved' : ''}`}>
                    <i className="fal fa-user-circle" />
                    <span className="comment-author">
                        {' '}
                        {getUsername(comment.author_id)} -
                        <span className="comment-time"> commented {createDateReadout(comment.timestamp)} ago</span>
                    </span>
                    {comment.user_permissions.manage ? (
                        <InlineDropMenu id="comment-actions">
                            <Button
                                className="borderless light-blue"
                                id="comment-resolved"
                                title={comment.resolved ? 'Resolved' : 'Unresolved'}
                                onClick={() => {
                                    dispatch(
                                        updateComment(props.annotation, comment.id, {
                                            content: comment.content,
                                            resolved: !comment.resolved,
                                            replies: comment.replies,
                                        })
                                    );
                                }}
                            >
                                <i className={`fal fa-square${comment.resolved ? '-check' : ''}`} />
                            </Button>
                            <Button
                                className="borderless green"
                                id="comment-edit"
                                title="Edit Comment"
                                onClick={() =>
                                    props.pinned ? setEditingComment(comment) : pinAction('edit', comment.id)
                                }
                            >
                                <i className="fas fa-pen" />
                            </Button>
                            <Button
                                className="borderless red"
                                id="comment-delete"
                                title="Delete Comment"
                                onClick={() => openDeletePrompt(comment)}
                            >
                                <i className="fas fa-trash-can" />
                            </Button>
                        </InlineDropMenu>
                    ) : null}
                </div>
                <div className="comment-content">{formatMentions(comment.content, dispatch, usernames)}</div>
            </div>
        ) : (
            <CommentEditForm
                annotation={props.annotation}
                comment={comment}
                close={() => setEditingComment(null)}
                key={comment.id}
            />
        );

    const renderReply = (reply: AnnotationCommentReply, resolved: boolean) =>
        editingComment !== reply ? (
            <div className="comment indent" key={reply.id} id={reply.id}>
                <div className={`comment-author-line ${resolved ? 'resolved' : ''}`}>
                    <i className="fal fa-user-circle" />
                    <span className="comment-author">
                        {' '}
                        {getUsername(reply.author_id)} -
                        <span className="comment-time"> replied {createDateReadout(reply.timestamp)} ago</span>
                    </span>
                    {reply.user_permissions.manage ? (
                        <InlineDropMenu id="reply-actions">
                            <Button
                                className="borderless green"
                                id="comment-edit"
                                title="Edit Comment"
                                onClick={() => (props.pinned ? setEditingComment(reply) : pinAction('edit', reply.id))}
                            >
                                <i className="fas fa-pen" />
                            </Button>
                            <Button
                                className="borderless red"
                                id="comment-delete"
                                title="Delete Comment"
                                onClick={() => openDeletePrompt(reply)}
                            >
                                <i className="fas fa-trash-can" />
                            </Button>
                        </InlineDropMenu>
                    ) : null}
                </div>
                <div className="comment-content">{formatMentions(reply.content, dispatch, usernames)}</div>
            </div>
        ) : (
            <CommentEditForm
                annotation={props.annotation}
                comment={reply}
                parent_id={reply.comment_id}
                close={() => setEditingComment(null)}
                key={reply.id}
            />
        );

    const renderReplies = (replies: Readonly<AnnotationCommentReply[]>, resolved: boolean) => {
        const copy = [...replies];
        copy.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
        return replies.map((reply) => renderReply(reply, resolved));
    };

    function toggleReplies(commentId: CommentId, onlyOpen?: boolean) {
        if (repliesVisible.includes(commentId) && !onlyOpen)
            setRepliesVisible(repliesVisible.filter((element) => element !== commentId));
        else setRepliesVisible([...repliesVisible, commentId]);
    }

    const renderComments = (allComments: AnnotationComment[]) => {
        allComments.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
        allComments.forEach((comment) => {
            const replies = comment.replies.length !== 0;
            const openReplies = replies && repliesVisible.includes(comment.id);
            renderedListItems.push(
                <div key={comment.id}>
                    {renderComment(comment)}
                    {openReplies ? renderReplies(comment.replies, comment.resolved) : null}
                    <div className="comment-replies-line">
                        {replies ? (
                            <Button className="borderless tan" onClick={() => toggleReplies(comment.id)}>
                                <i className={`fas fa-angle-${openReplies ? 'up' : 'down'}`} />
                                {openReplies ? 'Hide' : 'Show'}
                                {` ${comment.replies.length} repl${comment.replies.length === 1 ? 'y' : 'ies'}`}
                            </Button>
                        ) : null}
                        {project.user_permissions.interact ? (
                            <Button
                                className="borderless green"
                                onClick={() =>
                                    props.pinned ? setReplying(comment.id) : pinAction('comment', comment.id)
                                }
                            >
                                <i className="fas fa-reply" />
                                Reply
                            </Button>
                        ) : null}
                    </div>
                    {replying === comment.id && project.user_permissions.interact ? (
                        <CommentForm
                            annotation={props.annotation}
                            parent_id={comment.id}
                            onSubmit={() => setReplying(null)}
                            pinned={props.pinned}
                        />
                    ) : null}
                </div>
            );
        });
    };

    if (comments) renderComments([...comments]);

    return (
        <>
            <div className="comment-list">{renderedListItems}</div>
            <Modal isOpen={deleteModalState != null} centered className="modal-confirm">
                {modalContent()}
            </Modal>
        </>
    );
};

export default Comments;

import * as THREE from 'three';
import CameraControls from 'camera-controls';
import { ZoomFactor } from 'types/common';
import * as layoutSlice from 'redux/layout';
import store, { RootState } from 'store';
import StateObserver from 'giro3d_extensions/layers/StateObserver';
import { Rect } from 'flexlayout-react';
import type Instance from '@giro3d/giro3d/core/Instance';
import GetPointAtCallback from './GetPointAtCallback';

const tmpvec2 = new THREE.Vector2();
const tmpvec3 = new THREE.Vector3();
const keys = { LEFT: 'ArrowLeft', UP: 'ArrowUp', RIGHT: 'ArrowRight', BOTTOM: 'ArrowDown' };

const axisVectors = {
    X_pos: new THREE.Vector3(1, 0, 0),
    X_neg: new THREE.Vector3(-1, 0, 0),
    Y_pos: new THREE.Vector3(0, 1, 0),
    Y_neg: new THREE.Vector3(0, -1, 0),
    // Note: These are offset to prevent a prevent the camera from looking stright down
    // Dolly in camera-controls seems to react poorly to an azimuth angle of 0
    Z_pos: new THREE.Vector3(0, -0.0001, 1),
    Z_neg: new THREE.Vector3(0, 0.0001, -1),
    isometric_camera: new THREE.Vector3(1, -1, 1).normalize(),
    isometric_horizontal: new THREE.Vector3(-1, -1, 0).normalize(),
    isometric_vertical: new THREE.Vector3(1, 1, 1).normalize(),
};

const DEG90 = Math.PI / 2;
const TAN15 = Math.tan(Math.PI / 12);

CameraControls.install({ THREE });

/**
 * Mode for controls
 */
export enum CONTROLS_MODE {
    DISABLED = 'disabled',
    ORBIT = 'orbit',
    PAN = 'pan',
    DOLLY = 'dolly',
    FOLLOW = 'follow',
    RELEASED_FOLLOW = 'released_follow',
}

export type GetBoundingBoxCallback = () => THREE.Box3 | null;

/**
 * Object to handle camera in the view
 */
class Controls {
    cameraControls: CameraControls;

    instance: Instance;

    getPointAt: GetPointAtCallback;

    getBoundingBox: GetBoundingBoxCallback;

    clock: THREE.Clock;

    interactionPoint: THREE.Vector3;

    _dragStartPosition: THREE.Vector2;

    _lastDragPosition: THREE.Vector2;

    _isDraggingForward: boolean;

    _documentEventsHandlers: Record<string, (e: Event) => void>;

    _viewportEventsHandlers: Record<string, (e: Event) => void>;

    _eventhandlers: { before_camera_update: () => void; update: () => void };

    _stateObserver: StateObserver<RootState>;

    mode: CONTROLS_MODE;

    protected _execEndTimeout: NodeJS.Timeout;

    get enabled() {
        return this.cameraControls.enabled;
    }

    set enabled(v: boolean) {
        this.cameraControls.enabled = v;
    }

    /**
     * Constructor
     * @param instance Giro3D Instance object
     * @param getPointAt Callback to get the point coordinates from a mouse event
     * @param getBoundingBox Callback to get the bounding box to look at
     */
    constructor(instance: Instance, getPointAt: GetPointAtCallback, getBoundingBox: GetBoundingBoxCallback) {
        // Create our camera controls
        this.cameraControls = new CameraControls(instance.view.camera, instance.viewport);

        // Giro3D integration
        this.instance = instance;
        this.getPointAt = getPointAt;
        this.getBoundingBox = getBoundingBox;
        this.clock = new THREE.Clock();

        // Distinguish between the target/pivot point and the point we clicked as they are different when zooming
        this.interactionPoint = new THREE.Vector3();

        // State for dragging forward, as it's not handled natively by camera-controls
        this._dragStartPosition = new THREE.Vector2();
        this._lastDragPosition = new THREE.Vector2();
        this._isDraggingForward = false;

        // Event handlers
        this._documentEventsHandlers = {};
        this._viewportEventsHandlers = {};
        this._eventhandlers = {
            'before_camera_update': () => {
                // Called from giro3d
                const delta = this.clock.getDelta();
                const hasControlsUpdated = this.cameraControls.update(delta);
                if (hasControlsUpdated) {
                    this.instance.notifyChange(this.instance.view.camera);
                }
            },
            'update': () => {
                // Called from the control, if things have changed (from user or API)
                this.instance.notifyChange(this.instance.view.camera);
            },
        };

        this.instance.addEventListener('before-camera-update', this._eventhandlers.before_camera_update);
        this.cameraControls.addEventListener('update', this._eventhandlers.update);
        this.cameraControls.addEventListener('control', this._eventhandlers.update);

        this._stateObserver = new StateObserver(store.getState, store.subscribe);

        this.setMode(CONTROLS_MODE.PAN);
    }

    /**
     * Disposes of the controls and unregisters the event listeners
     */
    dispose() {
        this._unregisterEventListeners();
        this.instance.removeEventListener('before-camera-update', this._eventhandlers.before_camera_update);
        this.cameraControls.removeEventListener('update', this._eventhandlers.update);
        this.cameraControls.removeEventListener('control', this._eventhandlers.update);
        this.cameraControls.dispose();
    }

    /**
     * Sets mode for the controls
     * @param mode New mode
     */
    setMode(mode: CONTROLS_MODE) {
        // Do some clean-up
        if (this.mode === CONTROLS_MODE.DOLLY && this._isDraggingForward) this._endDraggingForward();
        this._unregisterEventListeners();

        this.mode = mode;

        // Register new interactions
        // We try to use native camera-controls interactions, but we have to re-implement some
        // (keyboard, monkey-patching for mousewheel, etc.)
        switch (this.mode) {
            case CONTROLS_MODE.ORBIT:
                this.cameraControls.dollyToCursor = false;
                this.cameraControls.verticalDragToForward = true;
                this.cameraControls.smoothTime = 0.125;
                this.cameraControls.draggingSmoothTime = 0;

                this.cameraControls.mouseButtons.left = CameraControls.ACTION.ROTATE;
                this.cameraControls.mouseButtons.right = CameraControls.ACTION.ROTATE;
                this.cameraControls.mouseButtons.wheel = CameraControls.ACTION.DOLLY;
                this.cameraControls.mouseButtons.middle = CameraControls.ACTION.DOLLY;

                this.cameraControls.zoomTo(1);
                this.cameraControls.azimuthRotateSpeed = 1;
                this.cameraControls.polarRotateSpeed = 1;

                this._viewportEventsHandlers.keydown = this.onKeyDownOrbit.bind(this);
                break;

            case CONTROLS_MODE.PAN:
            case CONTROLS_MODE.RELEASED_FOLLOW:
                this.cameraControls.dollyToCursor = true;
                this.cameraControls.verticalDragToForward = true;
                this.cameraControls.smoothTime = 0.125;
                this.cameraControls.draggingSmoothTime = 0;

                this.cameraControls.mouseButtons.left = CameraControls.ACTION.TRUCK;
                this.cameraControls.mouseButtons.right = CameraControls.ACTION.ROTATE;
                this.cameraControls.mouseButtons.wheel = CameraControls.ACTION.DOLLY;
                this.cameraControls.mouseButtons.middle = CameraControls.ACTION.DOLLY;

                this.cameraControls.zoomTo(1);
                this.cameraControls.azimuthRotateSpeed = 1;
                this.cameraControls.polarRotateSpeed = 1;

                this._viewportEventsHandlers.mousedown = this.onMouseDownSetPivot.bind(this);
                this._viewportEventsHandlers.wheel = this.onWheel.bind(this);
                this._viewportEventsHandlers.keydown = this.onKeyDownPan.bind(this);
                break;

            case CONTROLS_MODE.DOLLY:
                this.cameraControls.dollyToCursor = true;
                this.cameraControls.verticalDragToForward = true;
                this.cameraControls.smoothTime = 0.125;
                this.cameraControls.draggingSmoothTime = 0;

                this.cameraControls.mouseButtons.left = CameraControls.ACTION.NONE; // Custom
                this.cameraControls.mouseButtons.right = CameraControls.ACTION.ROTATE;
                this.cameraControls.mouseButtons.wheel = CameraControls.ACTION.DOLLY;
                this.cameraControls.mouseButtons.middle = CameraControls.ACTION.DOLLY;

                this.cameraControls.zoomTo(1);
                this.cameraControls.azimuthRotateSpeed = 1;
                this.cameraControls.polarRotateSpeed = 1;

                this._viewportEventsHandlers.mousedown = (e: MouseEvent) => {
                    this.onMouseDownSetPivot(e);
                    this.onMouseDownStartDraggingForward(e);
                };
                this._viewportEventsHandlers.wheel = this.onWheel.bind(this);
                this._viewportEventsHandlers.keydown = this.onKeyDownPan.bind(this);
                break;

            case CONTROLS_MODE.FOLLOW:
                this.cameraControls.dollyToCursor = false;
                this.cameraControls.verticalDragToForward = false;

                this.cameraControls.mouseButtons.left = CameraControls.ACTION.ROTATE;
                this.cameraControls.mouseButtons.right = CameraControls.ACTION.ROTATE;
                this.cameraControls.mouseButtons.wheel = CameraControls.ACTION.ZOOM;
                this.cameraControls.mouseButtons.middle = CameraControls.ACTION.ZOOM;

                this.cameraControls.maxZoom = 5;
                this.cameraControls.minZoom = 0.1;

                this.cameraControls.azimuthRotateSpeed = -0.3; // negative value to invert rotation direction
                this.cameraControls.polarRotateSpeed = -0.3; // negative value to invert rotation direction
                break;

            case CONTROLS_MODE.DISABLED:
                /* do nothing */
                break;

            default:
                throw new Error(`Mode ${mode} is not supported`);
        }

        this._registerEventListeners();
    }

    // eslint-disable-next-line class-methods-use-this
    protected _endDraggingForward() {
        throw new Error('Method not implemented.');
    }

    /**
     * Event handler to handle Orbitting on key stroke
     * @param e Event
     */
    onKeyDownOrbit(e: KeyboardEvent) {
        let azimuthAngle = 0;
        let polarAngle = 0;

        switch (e.code) {
            case keys.UP:
                azimuthAngle = 0;
                polarAngle = -0.5 * THREE.MathUtils.DEG2RAD;
                break;
            case keys.BOTTOM:
                azimuthAngle = 0;
                polarAngle = 0.5 * THREE.MathUtils.DEG2RAD;
                break;
            case keys.LEFT:
                azimuthAngle = -1 * THREE.MathUtils.DEG2RAD;
                polarAngle = 0;
                break;
            case keys.RIGHT:
                azimuthAngle = 1 * THREE.MathUtils.DEG2RAD;
                polarAngle = 0;
                break;
            default:
            // do nothing
        }
        this._exec(() => this.cameraControls.rotate(azimuthAngle, polarAngle, true));
    }

    /**
     * Event handler to handle Panning on key stroke
     * @param e Event
     */
    onKeyDownPan(e: KeyboardEvent) {
        const factor = this.cameraControls.truckSpeed * (this.cameraControls.distance / 300);

        if (e.ctrlKey || e.metaKey || e.shiftKey) {
            let truckDirectionY = 0;
            switch (e.code) {
                case keys.UP:
                    truckDirectionY = -1;
                    break;

                case keys.BOTTOM:
                    truckDirectionY = 1;
                    break;

                default:
                // do nothing
            }
            this._exec(() => this.cameraControls.truck(0, truckDirectionY * factor, true));
        } else {
            let forwardDirection = 0;
            let truckDirectionX = 0;
            switch (e.code) {
                case keys.UP:
                    forwardDirection = 1;
                    break;

                case keys.BOTTOM:
                    forwardDirection = -1;
                    break;

                case keys.LEFT:
                    truckDirectionX = -1;
                    break;

                case keys.RIGHT:
                    truckDirectionX = 1;
                    break;

                default:
                // do nothing
            }
            if (forwardDirection) this._exec(() => this.cameraControls.forward(forwardDirection * factor, true));
            if (truckDirectionX) this._exec(() => this.cameraControls.truck(truckDirectionX * factor, 0, true));
        }
    }

    /**
     * Event handler to set pivot point on click.
     * - sets interaction point for giro3dservice
     * - sets pivot point for rotating around that point
     * - binds event for resetting pivot point on mouseup
     * @param e Event
     */
    onMouseDownSetPivot(e: MouseEvent) {
        const picked = this.getPointAt(e);
        if (picked) {
            // Sets pivot for orbiting around that point
            this.setPivot(picked.point);
            // Show the user where we clicked
            this.setInteractionPoint(picked.point);

            this.instance.viewport.ownerDocument.addEventListener('mouseup', this.resetPivot.bind(this), {
                once: true,
            });
        }
    }

    /**
     * Event handler to start dragging in Dolly mode.
     * - Binds events for dragging on mousemove&mouseup
     * @param e Event
     */
    onMouseDownStartDraggingForward(e: MouseEvent) {
        if (e.button === 0) {
            if (this._isDraggingForward) {
                console.warn('We are already dragging, something is wrong');
                return;
            }
            const pointer = {
                pointerId: 0,
                clientX: e.clientX,
                clientY: e.clientY,
                deltaX: 0,
                deltaY: 0,
            };
            tmpvec2.set(pointer.clientX, pointer.clientY);

            this._dragStartPosition.copy(tmpvec2);
            this._lastDragPosition.copy(tmpvec2);

            this._isDraggingForward = true;
            this.cameraControls.dispatchEvent({ type: 'controlstart' });

            this._documentEventsHandlers.mousemove = this.onMouseMoveDraggingForward.bind(this);
            this.instance.viewport.ownerDocument.addEventListener('mousemove', this._documentEventsHandlers.mousemove);

            // Bind mouseup on document so we end dragging even if we're outside of the viewport
            this._documentEventsHandlers.mouseup = this.onMouseUpEndDraggingForward.bind(this);
            this.instance.viewport.ownerDocument.addEventListener('mouseup', this._documentEventsHandlers.mouseup);
            // drawTools capture mouseup on document when adding a point
            // not sure why native camera-controls are not affected, but here's a workaround
            this._viewportEventsHandlers.mouseup = this.onMouseUpEndDraggingForward.bind(this);
            this.instance.viewport.addEventListener('mouseup', this._viewportEventsHandlers.mouseup);
        }
    }

    /**
     * Event handler to handle dragging in Dolly mode
     * @param e Event
     */
    onMouseMoveDraggingForward(e: MouseEvent) {
        if (!this._isDraggingForward) return;

        const factor = this.cameraControls.truckSpeed * (this.cameraControls.distance / 1000);
        const pointer = {
            pointerId: 0,
            clientX: e.clientX,
            clientY: e.clientY,
            deltaX: e.movementX,
            deltaY: e.movementY,
        };

        tmpvec2.set(pointer.clientX, pointer.clientY);
        const deltaY = this._lastDragPosition.y - tmpvec2.y;
        const forward = -deltaY * factor;

        this._lastDragPosition.copy(tmpvec2);

        this._do(() => this.cameraControls.forward(forward));
    }

    /**
     * Event handler to end dragging in Dolly mode
     */
    onMouseUpEndDraggingForward() {
        if (!this._isDraggingForward) return;
        this._isDraggingForward = false;
        this.cameraControls.dispatchEvent({ type: 'controlend' });
    }

    /**
     * Event handler to handle zooming
     * - sets interaction point for giro3dservice
     * @param e Event
     */
    onWheel(e: MouseEvent) {
        const picked = this.getPointAt(e);
        if (picked) {
            this.setInteractionPoint(picked.point);
        }
        // Hack for displaying interaction point while zooming
        // As camera-controls doesn't dispatch controlstart/controlend events, we need
        // to take care of them so zooming appears as an interaction in Giro3dService
        this.cameraControls.dispatchEvent({ type: 'controlstart' });
        setTimeout(() => this.cameraControls.dispatchEvent({ type: 'controlend' }), 0);
    }

    /**
     * Registers stored event listeners
     */
    _registerEventListeners() {
        for (const [key, value] of Object.entries(this._documentEventsHandlers)) {
            this.instance.viewport.ownerDocument.addEventListener(key, value);
        }
        for (const [key, value] of Object.entries(this._viewportEventsHandlers)) {
            this.instance.viewport.addEventListener(key, value);
        }
    }

    /**
     * Unregisters all stored event listeners
     */
    _unregisterEventListeners() {
        for (const [key, value] of Object.entries(this._documentEventsHandlers)) {
            this.instance.viewport.ownerDocument.removeEventListener(key, value);
        }
        for (const [key, value] of Object.entries(this._viewportEventsHandlers)) {
            this.instance.viewport.removeEventListener(key, value);
        }
        this._documentEventsHandlers = {};
        this._viewportEventsHandlers = {};
    }

    /**
     * Gets the interaction point - only used for visual clues.
     * - In Orbit mode, will always be the same as the target
     * - In other modes, may be where the user clicked or the target
     * @param {THREE.Vector3} target Target object to fill
     */
    getInteractionPoint(target: THREE.Vector3) {
        target.copy(this.interactionPoint);
    }

    /**
     * Sets the interaction point - only used for visual clues.
     * @param {THREE.Vector3} position New position
     */
    setInteractionPoint(position: THREE.Vector3) {
        this.interactionPoint.copy(position);
    }

    /**
     * Gets the target
     * @param {THREE.Vector3} target Target object to fill
     * @returns {THREE.Vector3} Target
     */
    getTarget(target: THREE.Vector3) {
        return this.cameraControls.getTarget(target);
    }

    /**
     * Gets the camera position
     * @param target Target object to fill
     */
    getPosition(target: THREE.Vector3) {
        return this.cameraControls.getPosition(target);
    }

    /**
     * Sets the pivot point, i.e. the point we're orbitting around
     * @param pivot Pivot point
     */
    setPivot(pivot: THREE.Vector3) {
        this.cameraControls.setOrbitPoint(pivot.x, pivot.y, pivot.z);
        this.cameraControls.dispatchEvent({ type: 'update' });
    }

    /**
     * Resets pivot point to the center of the screen
     */
    resetPivot() {
        const view = this.instance.view;
        const rect = this._stateObserver.select(layoutSlice.getRect) ?? new Rect(0, 0, view.width, view.height);
        const center = rect.getCenter();
        const picked = this.getPointAt(new THREE.Vector2(center.x, center.y));

        if (picked) {
            this.setPivot(picked.point);
            if (this.mode === CONTROLS_MODE.ORBIT) this.setInteractionPoint(picked.point);
        }
    }

    /**
     * Wraps an interaction with the control to be sure:
     * - it calls giro3d update,
     * - it's visible in giro3dservice,
     * - transitions are smooth.
     * @param callback Interaction to execute
     */
    protected _exec(callback: () => Promise<unknown>): Promise<unknown> {
        // First make sure the interaction point is visible for giro3dservice
        this.getTarget(tmpvec3);
        this.setInteractionPoint(tmpvec3);

        if (!this.cameraControls.active) {
            // Dispatch controlstart only if we're starting a new interaction
            // That will tell giro3dservice that we're starting an interaction
            this.cameraControls.dispatchEvent({ type: 'controlstart' });
        }
        // Execute the interaction
        const res = callback() ?? Promise.resolve();

        // As mainloop can pause, before_camera_update can be triggered irregularly
        // Make sure to "reset" the clock to enable smooth transitions with camera-controls
        this.clock.getDelta();

        // Dispatch events so giro3d and giro3dservice gets notified
        this.cameraControls.dispatchEvent({ type: 'control' });
        this.cameraControls.dispatchEvent({ type: 'update' });
        res.then(() => {
            // Try to dispatch controlend only when we're really done
            if (this._execEndTimeout) clearTimeout(this._execEndTimeout);
            this._execEndTimeout = setTimeout(() => {
                this.cameraControls.dispatchEvent({ type: 'controlend' });
            }, 0);
        });
        return res;
    }

    /**
     * Wraps an interaction to be sure:
     * - it calls giro3d update,
     * - transitions are smooth.
     * This does not start/end an interaction from the giro3dservice perspective.
     * @param callback Interaction to execute
     */
    _do(callback: () => Promise<void>) {
        const res = callback() ?? Promise.resolve();
        this.clock.getDelta();
        this.cameraControls.dispatchEvent({ type: 'control' });
        this.cameraControls.dispatchEvent({ type: 'update' });
        return res;
    }

    _canTransition() {
        this.cameraControls.getPosition(tmpvec3);
        return !Number.isNaN(tmpvec3.x) && !Number.isNaN(tmpvec3.y) && !Number.isNaN(tmpvec3.z);
    }

    /**
     * Zooms in/out to center of the screen
     * @param factor 1 for zooming in, -1 for zooming out
     */
    zoom(factor: ZoomFactor) {
        this.getTarget(tmpvec3);
        tmpvec3.sub(this.instance.view.camera.position);
        const distance = (factor * tmpvec3.length()) / 10;

        this._exec(() => this.cameraControls.dolly(distance, true));
    }

    /**
     * Saves control position and current target
     */
    save() {
        this.cameraControls.saveState();
    }

    /**
     * Restores saved position
     * @param {boolean} enableTransition Enable transition
     */
    restore(enableTransition = true) {
        this._exec(() => {
            const res = this.cameraControls.reset(enableTransition && this._canTransition());
            this.cameraControls.getTarget(this.interactionPoint);
            return res;
        });
    }

    // eslint-disable-next-line class-methods-use-this
    calculateZenithAzimuthChange(x, y) {
        const point = new THREE.Vector3(1, x / Math.sqrt(3), y / Math.sqrt(3));
        const spherical = new THREE.Spherical().setFromVector3(point);

        return {
            deltaTheta: spherical.theta - Math.PI / 2,
            deltaPhi: spherical.phi - Math.PI / 2,
        };
    }

    /**
     * Moves camera to look at a specific object.
     * If Orbit mode, that position becomes the new pivot point
     * @param lookAt Position to look at
     * @param enableTransition Enables transition
     */
    moveTo(lookAt: THREE.Vector3, enableTransition = false) {
        const cameraToCenter = this.cameraControls
            .getPosition(new THREE.Vector3())
            .sub(this.cameraControls.getTarget(new THREE.Vector3()));

        const horizontalVec = new THREE.Vector3(
            -Math.cos(this.cameraControls.azimuthAngle),
            -Math.sin(this.cameraControls.azimuthAngle),
            0
        ).normalize();
        const verticalVec = cameraToCenter.clone().applyAxisAngle(horizontalVec, DEG90).normalize();

        const view = this.instance.view;

        const rect = this._stateObserver.select(layoutSlice.getRect) ?? new Rect(0, 0, view.width, view.height);
        // Calculate pixel offset between the center of the viewport from the center of the camera
        const viewportOffset = {
            x: rect.x + rect.width / 2 - view.width / 2,
            y: rect.y + rect.height / 2 - view.height / 2,
        };

        // Find the distance
        const distance = cameraToCenter.length();

        // Calculate the meters per pixel based on the vertical
        const metersPerPixel = (distance * 2 * TAN15) / view.height;

        // Set the lookAt and position from the bbox center and offsets
        lookAt.addScaledVector(horizontalVec, viewportOffset.x * metersPerPixel);
        lookAt.addScaledVector(verticalVec, viewportOffset.y * metersPerPixel);

        const position = lookAt.clone().add(cameraToCenter);

        this.lookAt(position, lookAt, enableTransition).then(() => this.resetPivot());
    }

    setCamera(position: THREE.Vector3, lookAt: THREE.Vector3) {
        this.cameraControls.setLookAt(position.x, position.y, position.z, lookAt.x, lookAt.y, lookAt.z, false);
    }

    /**
     * Place camera to look at a specific object
     * @param position
     * @param lookAt
     * @param enableTransition
     */
    lookAt(position: THREE.Vector3, lookAt: THREE.Vector3, enableTransition = false) {
        const bbox = new THREE.Box3();
        bbox.setFromPoints([position, lookAt]);
        this.cameraControls.fitToBox(bbox, enableTransition);
        const promise = this.cameraControls.setLookAt(
            position.x,
            position.y,
            position.z,
            lookAt.x,
            lookAt.y,
            lookAt.z,
            enableTransition && this._canTransition()
        );
        this.clock.getDelta();
        this.cameraControls.dispatchEvent({ type: 'update' });
        return promise;
    }

    lookAtPointFromDirection(
        center: THREE.Vector3,
        cameraVec: THREE.Vector3,
        horizontalVec: THREE.Vector3,
        verticalVec: THREE.Vector3,
        visualSize: THREE.Vector2,
        stepBack: number,
        enableTransition = false
    ) {
        const view = this.instance.view;

        const rect = this._stateObserver.select(layoutSlice.getRect) ?? new Rect(0, 0, view.width, view.height);
        // Calculate pixel offset between the center of the viewport from the center of the camera
        const viewportOffset = {
            x: rect.x + rect.width / 2 - view.width / 2,
            y: rect.y + rect.height / 2 - view.height / 2,
        };

        // Find the distance required to see the whole box
        const distance = this.cameraControls.getDistanceToFitBox(
            (view.width / rect.width) * visualSize.x,
            (view.height / rect.height) * visualSize.y,
            0
        );

        // Calculate the meters per pixel based on which axis of the box is bounded
        const metersPerPixel =
            visualSize.x / visualSize.y < rect.width / rect.height
                ? visualSize.y / rect.height
                : visualSize.x / rect.width;

        // Set the lookAt and position from the bbox center and offsets
        center.addScaledVector(horizontalVec, viewportOffset.x * metersPerPixel);
        center.addScaledVector(verticalVec, viewportOffset.y * metersPerPixel);
        center.addScaledVector(cameraVec, stepBack);
        // 1.2 to give some margin and miss less from perspective loss
        const position = center.clone().addScaledVector(cameraVec, distance * 1.2);

        return this.lookAt(position, center, enableTransition);
    }

    /**
     * Place camera to look at a bounding box.
     *
     * The box is viewed from the south-east, at 45 degrees down
     * This function compensates for the window position of the layout
     * @param bbox Bounding box
     * @param enableTransition
     */
    lookAtBbox(bbox: THREE.Box3, enableTransition = false) {
        this.lookAtBboxFromSide('up', bbox, enableTransition);
    }

    /**
     * Place camera to look at a bounding box from an axis.
     *
     * This function compensates for the window position of the layout
     * @param fromDirection
     * @param bbox Bounding box
     * @param enableTransition
     */
    lookAtBboxFromSide(fromDirection = 'up', bbox: THREE.Box3, enableTransition = false) {
        if (bbox === undefined) {
            console.warn('Trying to look at undefined bounding box');
            return undefined;
        }
        if (bbox.isEmpty()) {
            console.warn('Trying to look at empty bounding box');
            return undefined;
        }

        bbox.getSize(tmpvec3);

        let horizontalOffset;
        let verticalOffset;
        let cameraOffset;
        let stepBack;

        // Orient the bbox to face the camera and set the offset directions for the correction
        switch (fromDirection) {
            case 'up':
                tmpvec2.set(tmpvec3.x, tmpvec3.y);
                horizontalOffset = axisVectors.X_neg;
                verticalOffset = axisVectors.Y_pos;
                cameraOffset = axisVectors.Z_pos;
                stepBack = tmpvec3.z / 2;
                break;
            case 'down':
                tmpvec2.set(tmpvec3.x, tmpvec3.y);
                horizontalOffset = axisVectors.X_pos;
                verticalOffset = axisVectors.Y_pos;
                cameraOffset = axisVectors.Z_neg;
                stepBack = tmpvec3.z / 2;
                break;
            case 'north':
                tmpvec2.set(tmpvec3.x, tmpvec3.z);
                horizontalOffset = axisVectors.X_pos;
                verticalOffset = axisVectors.Z_pos;
                cameraOffset = axisVectors.Y_pos;
                stepBack = tmpvec3.y / 2;
                break;
            case 'south':
                tmpvec2.set(tmpvec3.x, tmpvec3.z);
                horizontalOffset = axisVectors.X_neg;
                verticalOffset = axisVectors.Z_pos;
                cameraOffset = axisVectors.Y_neg;
                stepBack = tmpvec3.y / 2;
                break;
            case 'west':
                tmpvec2.set(tmpvec3.y, tmpvec3.z);
                horizontalOffset = axisVectors.Y_pos;
                verticalOffset = axisVectors.Z_pos;
                cameraOffset = axisVectors.X_neg;
                stepBack = tmpvec3.x / 2;
                break;
            case 'east':
                tmpvec2.set(tmpvec3.y, tmpvec3.z);
                horizontalOffset = axisVectors.Y_neg;
                verticalOffset = axisVectors.Z_pos;
                cameraOffset = axisVectors.X_pos;
                stepBack = tmpvec3.x / 2;
                break;
            default:
                console.warn(`Unsupported direction ${fromDirection}`);
                return undefined;
        }

        return this.lookAtPointFromDirection(
            bbox.getCenter(tmpvec3),
            cameraOffset,
            horizontalOffset,
            verticalOffset,
            tmpvec2,
            stepBack,
            enableTransition
        );
    }

    /**
     * Place camera to look at the whole project from a direction
     * @param fromDirection up, down, north, south, west, east
     * @param enableTransition
     */
    lookFromSide(fromDirection = 'up', enableTransition = false) {
        const bbox = this.getBoundingBox();
        this.lookAtBboxFromSide(fromDirection, bbox, enableTransition);
    }
}

export default Controls;

import EntityInspector from '@giro3d/giro3d/gui/EntityInspector';
import type Instance from '@giro3d/giro3d/core/Instance';
import type GUI from 'lil-gui';
import SeismicPlaneEntity from './SeismicPlaneEntity';
import SeismicPlaneTile from './SeismicPlaneTile';

class SeismicPlaneEntityInspector extends EntityInspector<SeismicPlaneEntity> {
    private readonly seismicPlane: SeismicPlaneEntity;

    visibleTiles: number;
    reachableTiles: number;

    constructor(parentGui: GUI, instance: Instance, entity: SeismicPlaneEntity) {
        super(parentGui, instance, entity, {
            boundingBoxes: true,
            boundingBoxColor: true,
            opacity: true,
            visibility: true,
        });

        if (!(entity instanceof SeismicPlaneEntity)) {
            throw new Error('invalid entity');
        }

        this.seismicPlane = entity;
        this.visibleTiles = 0;
        this.reachableTiles = 0;

        this.addController(this.seismicPlane.requests, 'size').name('Pending requests');
        this.addController(this, 'visibleTiles').name('Visible tiles');
        this.addController(this, 'reachableTiles').name('Reachable tiles');
        this.addController(this.seismicPlane, 'showOutlines');
        this.addController(this.seismicPlane, 'wireframe')
            .name('Wireframe')
            .onChange(() => this.notify(this.seismicPlane));
        this.addController(this.seismicPlane, 'sseScale', 0.01, 10, 0.01)
            .name('SSE scale')
            .onChange(() => this.notify(this.seismicPlane));
        this.addController(this.seismicPlane.source, 'width').name('Trace count');
        this.addController(this.seismicPlane.source, 'height').name('Sample count');
        this.addController(this.seismicPlane.source, 'levels').name('LODs');
    }

    updateValues() {
        super.updateValues();

        let visibleTiles = 0;
        let reachableTiles = 0;
        this.seismicPlane.object3d.traverseVisible((x) => {
            if ((x as SeismicPlaneTile).isSeismicPlaneTile) {
                if ((x as SeismicPlaneTile).material.visible) {
                    visibleTiles++;
                }
                reachableTiles++;
            }
        });

        this.visibleTiles = visibleTiles;
        this.reachableTiles = reachableTiles;
    }
}

export default SeismicPlaneEntityInspector;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { COLLECTION_DELETED } from '../../../redux/actionTypes';
import DosApi from '../../../services/DosApi';

const DeleteCollection = ({ collection, disabled, big }) => {
    const dispatch = useDispatch();
    const [deleteState, setDeleteState] = useState(null);
    const [errorText, setErrorText] = useState('');

    const DELETE = {
        PROMPT: 'prompt',
        DELETING: 'deleting',
        CONFIRMATION: 'confirm',
        ERROR: 'error',
    };

    const cancelDelete = () => {
        setDeleteState(null);
        setErrorText('');
    };

    const finishDelete = () => {
        dispatch({
            type: COLLECTION_DELETED,
            payload: collection,
        });
    };

    const deleteCollection = () => {
        setDeleteState(DELETE.DELETING);
        DosApi.deleteCollection(collection.id)
            .then(async () => {
                setDeleteState(DELETE.CONFIRMATION);
                setTimeout(finishDelete, 2000);
            })
            .catch((err) => {
                switch (err.response.status) {
                    case 409:
                        setErrorText('The collection still contains datasets and cannot be deleted.');
                        break;
                    case 403:
                        setErrorText('You do not have permission to complete this action.');
                        break;
                    default:
                        setErrorText(err.message);
                }
                setDeleteState(DELETE.ERROR);
            });
    };

    const modalContent = () => {
        switch (deleteState) {
            case DELETE.PROMPT:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                            <span className="big-modal-text">Are you sure?</span>
                            <button
                                type="button"
                                className="pane-button large highlight"
                                onClick={() => deleteCollection()}
                            >
                                Yes, Delete this Collection
                            </button>
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" className="pane-button large" onClick={() => cancelDelete()}>
                                Cancel
                            </button>
                        </ModalFooter>
                    </>
                );
            case DELETE.DELETING:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-warn fal fa-timer no-hover" />
                            <span className="big-modal-text">Deleting collection...</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case DELETE.CONFIRMATION:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                            <span className="big-modal-text">Collection deleted</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case DELETE.ERROR:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                            <span className="big-modal-text">An error occured</span>
                            <span className="small-modal-text">{errorText}</span>
                        </ModalBody>
                        <ModalFooter>
                            <button
                                type="button"
                                className="pane-button large highlight"
                                onClick={() => cancelDelete()}
                            >
                                OK
                            </button>
                        </ModalFooter>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div id={`delete-${collection.id}`}>
                {big ? (
                    <button
                        type="button"
                        className="pane-button large"
                        onClick={() => setDeleteState(DELETE.PROMPT)}
                        disabled={disabled || !collection.user_permissions.delete_collection}
                    >
                        Delete Collection
                        <i className="fal fa-trash-can" />
                    </button>
                ) : (
                    <Button
                        className="borderless red underline"
                        onClick={() => setDeleteState(DELETE.PROMPT)}
                        disabled={disabled || !collection.user_permissions.delete_collection}
                    >
                        <i className="fal fa-trash-can" />
                    </Button>
                )}

                {disabled || !collection.user_permissions.delete_collection ? (
                    <UncontrolledTooltip target={`delete-${collection.id}`}>
                        {!collection.user_permissions.delete_collection
                            ? 'You lack the permissions to delete this collection.'
                            : 'You cannot delete a collection that contains datasets.'}
                    </UncontrolledTooltip>
                ) : (
                    <UncontrolledTooltip target={`delete-${collection.id}`}>Delete collection</UncontrolledTooltip>
                )}
            </div>

            <Modal isOpen={deleteState !== null} toggle={() => cancelDelete()} centered className="modal-confirm">
                {modalContent()}
            </Modal>
        </>
    );
};

export default DeleteCollection;

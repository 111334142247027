// React
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import Select from 'components/Select';
import {
    getAllDatasets,
    getOrganizations,
    getCollections,
    getUser,
    getCollectionsStatus,
    getLibraryLoaded,
} from '../../redux/selectors';
import {
    fetchCollections,
    fetchAllDatasets,
    fetchOrganizations,
    deleteDataset,
    pollForDatasets,
    fetchProjections,
    deleteDatasetBatch,
} from '../../redux/actions';

// Components
import CollectionListItem from './CollectionListItem';
import AddCollection from './AddCollection';
import AddDataset from '../giro3d/datasetsMenu/AddDataset';
import ApiErrors from '../../services/ApiErrors';
import ToggleSwitch from '../ToggleSwitch';
import DatasetListItem from './DatasetListItem';
import { LAYER_STATES, LAYER_TYPES } from '../../services/Constants';
import { layerStateLabels, supportedTypesLabels } from '../../services/Labels';
import CollectionMenu from './CollectionMenu';
import DeleteCollection from '../overview/collectionsList/DeleteCollection';
import EditCollection from './EditCollection';
import InspectDatasetModal from './InspectDatasetModal';
import useInterval from '../../services/useInterval';
import UppyService from '../../services/UppyService';

const Library = ({ project, collectionId, onCollectionOpen, onCollectionClose }) => {
    const dispatch = useDispatch();

    const user = useSelector(getUser);

    const collections = useSelector(getCollections);
    const collectionsStatus = useSelector(getCollectionsStatus);
    const datasets = useSelector(getAllDatasets);
    const organizations = useSelector(getOrganizations);
    const loaded = useSelector(getLibraryLoaded);

    const DELETE = {
        PROMPT: 'prompt',
        DELETING: 'deleting',
        CONFIRMATION: 'confirm',
        ERROR: 'error',
    };

    const CATEGORY = {
        ANY: null,
        NAME: 'name',
        TYPE: 'type',
        PROJECITON: 'projection',
        ORGANIZATION: 'organization',
        STATE: 'state',
    };

    const CATEGORY_LABEL = {
        [CATEGORY.ANY]: 'Any Element',
        [CATEGORY.NAME]: 'Name',
        [CATEGORY.TYPE]: 'Type',
        [CATEGORY.PROJECITON]: 'Projection',
        [CATEGORY.ORGANIZATION]: 'Organization',
        [CATEGORY.STATE]: 'State',
    };

    const [datasetsWithOrganizations, setDatasetsWithOrganizations] = useState([]);
    const [collectionOpen, setCollectionOpen] = useState(null);
    const [datasetToDelete, setDatasetToDelete] = useState();
    const [datasetDeleteState, setDatasetDeleteState] = useState(null);
    const [batchDeleteState, setBatchDeleteState] = useState(null);
    const [errorText, setErrorText] = useState();
    const [editingCollection, setEditingCollection] = useState(null);
    const [addingCollection, setAddingCollection] = useState(false);
    const [addingDataset, setAddingDataset] = useState(false);
    const [inspectingDataset, setInspectingDataset] = useState(null);
    const [batchDeleteList, setBatchDeleteList] = useState(undefined);
    const [isGrouped, setIsGrouped] = useState(true);
    const [hideEmpty, setHideEmpty] = useState(false);
    const [groups, setGroups] = useState({});
    const [renderedTableItems, setRenderedTableItems] = useState([]);

    const [search, setSearch] = useState('');
    const [searchCategory, setSearchingCategory] = useState(null);

    const [filter, setFilter] = useState({
        type: [],
        organization: [],
        state: [],
    });

    const [sortingCategory, setSortingCategory] = useState('name');
    const [sortingDescending, setSortingDescending] = useState(false);

    useEffect(() => {
        dispatch(fetchOrganizations());
        dispatch(fetchCollections());
        dispatch(fetchAllDatasets());
        dispatch(fetchProjections());
    }, []);

    const openCollection = (coll) => {
        setCollectionOpen(coll);
        if (onCollectionOpen) onCollectionOpen(coll);
    };

    const closeCollection = () => {
        setCollectionOpen(null);
        if (onCollectionClose) onCollectionClose();
    };

    const addToBatch = (datasetId) => {
        setBatchDeleteList([...batchDeleteList, datasetId]);
    };
    const removeFromBatch = (datasetId) => {
        setBatchDeleteList(batchDeleteList.filter((s) => s !== datasetId));
    };

    useEffect(() => {
        if (collectionsStatus === 'collections/success') {
            const collection = collections.find((c) => c.id === collectionId);
            if (collection) {
                openCollection(collection);
            } else {
                closeCollection();
            }
        }
    }, [collections, collectionId]);

    const openDeletePrompt = (dataset) => {
        setDatasetToDelete(dataset);
        setDatasetDeleteState(DELETE.PROMPT);
    };

    const updateSorting = (category) => {
        if (sortingCategory !== category) {
            setSortingCategory(category);
            setSortingDescending(false);
        } else if (!sortingDescending) setSortingDescending(true);
        else setSortingCategory(null);
    };
    const sortingArrow = (category) => {
        if (sortingCategory === category)
            return sortingDescending ? 'fal fa-angle-up icon-yellow' : 'fal fa-angle-down icon-yellow';
        return '';
    };
    const sortableHeader = (category) => (
        <th>
            <Button className="borderless underline sorting-arrow" onClick={() => updateSorting(category)}>
                {CATEGORY_LABEL[category]}
                <i className={sortingArrow(category)} />
            </Button>
        </th>
    );
    const sortingFunction = () => {
        switch (sortingCategory) {
            case CATEGORY.NAME:
            case CATEGORY.TYPE:
            case CATEGORY.STATE:
            case CATEGORY.ORGANIZATION:
                return (a, b) => {
                    if (a[sortingCategory].toUpperCase() < b[sortingCategory].toUpperCase())
                        return sortingDescending ? 1 : -1;
                    if (a[sortingCategory].toUpperCase() > b[sortingCategory].toUpperCase())
                        return sortingDescending ? -1 : 1;
                    return 0;
                };
            case CATEGORY.PROJECITON:
                return (a, b) => (a.projection - b.projection) * (sortingDescending ? -1 : 1);
            default:
                return () => 0;
        }
    };

    const updateFilter = (field, options) => {
        setFilter((prevFilter) => ({ ...prevFilter, [field]: options.map((option) => option.value) }));
    };

    const filtersFunction = () => (dataset) =>
        (filter.type.length === 0 || filter.type.includes(dataset.type)) &&
        (filter.organization.length === 0 || filter.organization.includes(dataset.organization_id)) &&
        (filter.state.length === 0 || filter.state.includes(dataset.state));

    const searchingFunction = () => {
        switch (searchCategory) {
            case CATEGORY.NAME:
            case CATEGORY.TYPE:
            case CATEGORY.STATE:
            case CATEGORY.ORGANIZATION:
                return (dataset) => dataset[searchCategory].toUpperCase().includes(search.toUpperCase());
            case CATEGORY.PROJECITON:
                return (dataset) => dataset.projection.toString().includes(search.toUpperCase());
            default:
                return (dataset) =>
                    dataset.name.toUpperCase().includes(search.toUpperCase()) ||
                    dataset.type.toUpperCase().includes(search.toUpperCase()) ||
                    dataset.state.toUpperCase().includes(search.toUpperCase()) ||
                    dataset.organization.toUpperCase().includes(search.toUpperCase());
        }
    };

    useEffect(() => {
        const groupedSets = {};
        datasetsWithOrganizations.forEach((dataset) => {
            if (groupedSets[dataset.collection_id]) groupedSets[dataset.collection_id].push(dataset);
            else groupedSets[dataset.collection_id] = [dataset];
        });
        setGroups(groupedSets);
    }, [datasetsWithOrganizations]);

    useEffect(() => {
        setDatasetsWithOrganizations(
            datasets && datasets.length !== 0 && organizations && organizations.length !== 0
                ? datasets.map((dataset) => ({
                      ...dataset,
                      organization: organizations.find((organization) => organization.id === dataset.organization_id)
                          .display_name,
                      collection_id: dataset.collection_id || null,
                  }))
                : []
        );
    }, [datasets, organizations]);

    useEffect(() => {
        setRenderedTableItems(
            isGrouped
                ? [
                      ...collections.map((collection) => (
                          <CollectionListItem
                              key={`collection-${collection.id}`}
                              collection={collection}
                              datasets={groups[collection.id] ? groups[collection.id] : []}
                              setDatasetToDelete={openDeletePrompt}
                              setDatasetToInspect={setInspectingDataset}
                              setCollectionToEdit={setEditingCollection}
                              filtersFunction={filtersFunction()}
                              sortingFunction={sortingFunction()}
                              searchingFunction={searchingFunction()}
                              hideIfEmpty={hideEmpty}
                              viewCollection={() => openCollection(collection)}
                              project={project}
                              batchDeleteList={batchDeleteList}
                              addToBatch={addToBatch}
                              removeFromBatch={removeFromBatch}
                          />
                      )),
                      <CollectionListItem
                          key="collection-null"
                          datasets={groups.null ? groups.null : []}
                          setDatasetToDelete={openDeletePrompt}
                          setDatasetToInspect={setInspectingDataset}
                          filtersFunction={filtersFunction()}
                          sortingFunction={sortingFunction()}
                          searchingFunction={searchingFunction()}
                          hideIfEmpty={hideEmpty}
                          project={project}
                          batchDeleteList={batchDeleteList}
                          addToBatch={addToBatch}
                          removeFromBatch={removeFromBatch}
                      />,
                  ]
                : [...datasetsWithOrganizations]
                      .filter(filtersFunction())
                      .filter(searchingFunction())
                      .sort(sortingFunction())
                      .map((dataset) => (
                          <DatasetListItem
                              dataset={dataset}
                              setDatasetToDelete={openDeletePrompt}
                              setDatasetToInspect={setInspectingDataset}
                              key={dataset.id}
                              project={project}
                              batchDeleting={
                                  batchDeleteList === undefined
                                      ? undefined
                                      : {
                                            list: batchDeleteList,
                                            select: () => addToBatch(dataset.id),
                                            unselect: () => removeFromBatch(dataset.id),
                                        }
                              }
                          />
                      ))
        );
    }, [
        datasetsWithOrganizations,
        collections,
        groups,
        isGrouped,
        sortingCategory,
        sortingDescending,
        search,
        searchCategory,
        filter,
        hideEmpty,
        batchDeleteList,
    ]);

    useInterval(() => {
        const datasetsInProcessing = datasets.filter((d) =>
            [LAYER_STATES.CONVERTING, LAYER_STATES.DELETING, LAYER_STATES.UPLOADING].includes(d.state)
        );
        if (datasetsInProcessing.length !== 0) dispatch(pollForDatasets(datasetsInProcessing));
    }, 10000);

    const searchAndFilters = (includeGrouping = true) => (
        <>
            <div className="library-control-row searchbar">
                <div className="searchbox">
                    <Input
                        placeholder="Search"
                        type="text"
                        value={search}
                        onChange={(value) => setSearch(value.target.value)}
                    />
                    <i className="fa-solid fa-magnifying-glass search-icon" />
                </div>
                Search on:
                <Select
                    options={[
                        { value: CATEGORY.ANY, label: 'Any element' },
                        { value: CATEGORY.NAME, label: 'Name' },
                        { value: CATEGORY.TYPE, label: 'Type' },
                        { value: CATEGORY.PROJECITON, label: 'Projection' },
                        { value: CATEGORY.ORGANIZATION, label: 'Organization' },
                        { value: CATEGORY.STATE, label: 'State' },
                    ]}
                    value={{
                        value: searchCategory,
                        label: CATEGORY_LABEL[searchCategory],
                    }}
                    isSearchable={false}
                    onChange={(e) => setSearchingCategory(e.value)}
                    className="library-filter"
                />
            </div>

            {includeGrouping ? (
                <div className="library-control-row">
                    Group by collection:
                    <ToggleSwitch
                        id="library-grouping"
                        checked={isGrouped}
                        onChange={(e) => setIsGrouped(e.target.checked)}
                    />
                    Hide empty collections:
                    <ToggleSwitch
                        id="library-hide-empty"
                        checked={hideEmpty}
                        onChange={(e) => setHideEmpty(e.target.checked)}
                        disabled={!isGrouped}
                    />
                </div>
            ) : null}

            <div className="library-control-row filters">
                Filters:
                <Select
                    options={Object.values(LAYER_TYPES).map((type) => ({
                        value: type,
                        label: supportedTypesLabels[type],
                    }))}
                    isSearchable={false}
                    onChange={(e) => updateFilter('type', e)}
                    isClearable
                    isMulti
                    placeholder="Type"
                    className="library-filter"
                />
                <Select
                    options={
                        organizations
                            ? organizations.map((organization) => ({
                                  value: organization.id,
                                  label: organization.display_name,
                              }))
                            : []
                    }
                    isSearchable={false}
                    onChange={(e) => updateFilter('organization', e)}
                    isClearable
                    isMulti
                    placeholder="Organization"
                    className="library-filter"
                />
                <Select
                    options={Object.values(LAYER_STATES).map((state) => ({
                        value: state,
                        label: layerStateLabels[state],
                    }))}
                    isSearchable={false}
                    onChange={(e) => updateFilter('state', e)}
                    isClearable
                    isMulti
                    placeholder="State"
                    className="library-filter"
                />
            </div>
        </>
    );

    const datasetDeleteModalContent = () => {
        switch (datasetDeleteState) {
            case DELETE.PROMPT:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                            <span className="big-modal-text">Are you sure?</span>
                            <span className="small-modal-text">
                                {datasetToDelete.name} will be removed from Argeo SCOPE.
                            </span>
                            <button
                                type="button"
                                className="pane-button large highlight"
                                onClick={() => {
                                    setDatasetDeleteState(DELETE.DELETING);
                                    dispatch(deleteDataset(datasetToDelete))
                                        .then(async () => {
                                            setDatasetDeleteState(DELETE.CONFIRMATION);
                                            setTimeout(() => setDatasetDeleteState(null), 2000);
                                        })
                                        .catch((err) => {
                                            setErrorText(ApiErrors.getErrorMessage(err));
                                            setDatasetDeleteState(DELETE.ERROR);
                                        });
                                }}
                            >
                                Yes, Delete this Dataset
                            </button>
                        </ModalBody>
                        <ModalFooter>
                            <button
                                type="button"
                                className="pane-button large"
                                onClick={() => setDatasetDeleteState(null)}
                            >
                                Cancel
                            </button>
                        </ModalFooter>
                    </>
                );
            case DELETE.DELETING:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-warn fal fa-timer no-hover" />
                            <span className="big-modal-text">Deleting dataset...</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case DELETE.CONFIRMATION:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                            <span className="big-modal-text">Dataset deleted</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case DELETE.ERROR:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                            <span className="big-modal-text">An error occured</span>
                            <span className="small-modal-text">{errorText}</span>
                        </ModalBody>
                        <ModalFooter>
                            <button
                                type="button"
                                className="pane-button large highlight"
                                onClick={() => setDatasetDeleteState(null)}
                            >
                                OK
                            </button>
                        </ModalFooter>
                    </>
                );
            default:
                return null;
        }
    };

    const batchDeleteModalContent = () => {
        switch (batchDeleteState) {
            case DELETE.PROMPT:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-xmark no-hover" />
                            <span className="big-modal-text">Are you sure?</span>
                            <span className="small-modal-text">
                                {batchDeleteList.length} datasets will be removed from Argeo SCOPE.
                            </span>
                            <button
                                type="button"
                                className="pane-button large highlight"
                                onClick={() => {
                                    setBatchDeleteState(DELETE.DELETING);
                                    dispatch(deleteDatasetBatch(batchDeleteList))
                                        .then(async () => {
                                            setBatchDeleteState(DELETE.CONFIRMATION);
                                            setBatchDeleteList(undefined);
                                            setTimeout(() => setBatchDeleteState(null), 2000);
                                        })
                                        .catch((err) => {
                                            setErrorText(ApiErrors.getErrorMessage(err));
                                            setBatchDeleteState(DELETE.ERROR);
                                        });
                                }}
                            >
                                Yes, Delete these Datasets
                            </button>
                        </ModalBody>
                        <ModalFooter>
                            <button
                                type="button"
                                className="pane-button large"
                                onClick={() => setBatchDeleteState(null)}
                            >
                                Cancel
                            </button>
                        </ModalFooter>
                    </>
                );
            case DELETE.DELETING:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-warn fal fa-timer no-hover" />
                            <span className="big-modal-text">Deleting datasets...</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case DELETE.CONFIRMATION:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-good fal fa-circle-check no-hover" />
                            <span className="big-modal-text">Datasets deleted</span>
                        </ModalBody>
                        <ModalFooter />
                    </>
                );
            case DELETE.ERROR:
                return (
                    <>
                        <ModalBody>
                            <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                            <span className="big-modal-text">An error occured</span>
                            <span className="small-modal-text">{errorText}</span>
                        </ModalBody>
                        <ModalFooter>
                            <button
                                type="button"
                                className="pane-button large highlight"
                                onClick={() => setBatchDeleteState(null)}
                            >
                                OK
                            </button>
                        </ModalFooter>
                    </>
                );
            default:
                return null;
        }
    };

    const datasetUploadModalContent = () => (
        <>
            <ModalHeader
                toggle={() => {
                    setAddingDataset(false);
                    UppyService.clearFiles();
                }}
            >
                Add dataset{collectionOpen ? ` to ${collectionOpen.name}` : ''}
            </ModalHeader>
            <AddDataset
                key="library-add-dataset"
                project={project}
                collectionOpen={collectionOpen}
                onClose={() => setAddingDataset(false)}
            />
        </>
    );

    const inspectDatasetModalContent = () => {
        if (!inspectingDataset) return null;
        return <InspectDatasetModal datasetId={inspectingDataset.id} onClose={() => setInspectingDataset(null)} />;
    };

    const editCollectionModalContent = () => {
        if (!editingCollection) return null;
        return <EditCollection openCollection={editingCollection} onClose={() => setEditingCollection(null)} />;
    };

    const addCollectionModalContent = () => {
        if (!addingCollection) return null;
        return (
            <ModalBody>
                <AddCollection
                    onClose={() => setAddingCollection(false)}
                    onCreation={(collection) => openCollection(collection)}
                />
            </ModalBody>
        );
    };

    if (!loaded)
        return (
            <div className="library">
                <div className="dot-flashing" />
            </div>
        );

    if (collectionOpen)
        return (
            <div className="library">
                <div className="full-page-title-bar">
                    <div className="full-page-title">
                        <button type="button" className="full-page-back-button" onClick={() => closeCollection()}>
                            <i className="fal fa-arrow-left" />
                        </button>
                        {collectionOpen.name}
                    </div>
                    <div className="map-pane-title-bar-buttons">
                        <DeleteCollection
                            collection={collectionOpen}
                            disabled={groups[collectionOpen.id] !== undefined}
                            big
                        />
                        <div id={`edit-collection-${collectionOpen.id}`}>
                            <button
                                type="button"
                                className="pane-button large highlight"
                                id="edit"
                                onClick={() => setEditingCollection(collectionOpen)}
                                disabled={!collectionOpen.user_permissions.update_collection && !user.is_admin}
                            >
                                Edit Collection
                                <i className="fas fa-pen" />
                            </button>
                        </div>
                        {!collectionOpen.user_permissions.update_collection && !user.is_admin ? (
                            <UncontrolledTooltip target={`edit-collection-${collectionOpen.id}`}>
                                You lack the permissions to edit this collection.
                            </UncontrolledTooltip>
                        ) : (
                            <UncontrolledTooltip target={`edit-collection-${collectionOpen.id}`}>
                                Edit collection
                            </UncontrolledTooltip>
                        )}
                        <div id={`add-${collectionOpen.id}`}>
                            <button
                                type="button"
                                className="pane-button large highlight"
                                onClick={() => setAddingDataset(true)}
                                disabled={!collectionOpen.user_permissions.update_collection && !user.is_admin}
                            >
                                Add Dataset
                                <i className="fas fa-layer-plus" />
                            </button>
                        </div>
                        {!collectionOpen.user_permissions.update_collection && !user.is_admin ? (
                            <UncontrolledTooltip target={`add-${collectionOpen.id}`}>
                                You lack the permissions to edit this collection.
                            </UncontrolledTooltip>
                        ) : (
                            <UncontrolledTooltip target={`add-${collectionOpen.id}`}>Add dataset</UncontrolledTooltip>
                        )}
                    </div>
                </div>

                {searchAndFilters(false)}

                <CollectionMenu
                    collection={collectionOpen}
                    datasets={groups[collectionOpen.id]}
                    openDeletePrompt={openDeletePrompt}
                    setDatasetToInspect={setInspectingDataset}
                    filtersFunction={filtersFunction()}
                    sortingFunction={sortingFunction()}
                    searchingFunction={searchingFunction()}
                    sortableHeader={sortableHeader}
                    project={project}
                />
                <Modal isOpen={datasetDeleteState !== null} centered className="modal-confirm">
                    {datasetDeleteModalContent()}
                </Modal>
                <Modal isOpen={batchDeleteState !== null} centered className="modal-confirm">
                    {batchDeleteModalContent()}
                </Modal>
                <Modal isOpen={addingDataset} centered>
                    {datasetUploadModalContent()}
                </Modal>
                <Modal isOpen={inspectingDataset !== null} centered>
                    {inspectDatasetModalContent()}
                </Modal>
                <Modal isOpen={editingCollection !== null} centered>
                    {editCollectionModalContent()}
                </Modal>
            </div>
        );

    return (
        <div className="library">
            <div className="full-page-title-bar">
                <div className="full-page-title">Library</div>
                <div className="map-pane-title-bar-buttons">
                    {batchDeleteList === undefined ? (
                        <>
                            <div id="batch-delete">
                                <button
                                    type="button"
                                    className="pane-button large"
                                    onClick={() => setBatchDeleteList([])}
                                >
                                    Batch Delete
                                    <i className="fas fa-dumpster" />
                                </button>
                            </div>
                            <div id="add-dataset">
                                <button
                                    type="button"
                                    className="pane-button large highlight"
                                    onClick={() => setAddingDataset(true)}
                                    disabled={
                                        user.memberships.filter(
                                            (membership) => membership.organization.user_permissions.create_datasets
                                        ).length === 0 && !user.is_admin
                                    }
                                >
                                    Add Dataset
                                    <i className="fas fa-layer-plus" />
                                </button>
                            </div>
                            {user.memberships.filter(
                                (membership) => membership.organization.user_permissions.create_datasets
                            ).length === 0 && !user.is_admin ? (
                                <UncontrolledTooltip target="add-dataset">
                                    You lack the permissions to add datasets.
                                </UncontrolledTooltip>
                            ) : (
                                <UncontrolledTooltip target="add-dataset">Add dataset</UncontrolledTooltip>
                            )}
                            <div id="add-collection">
                                <button
                                    type="button"
                                    className="pane-button large highlight"
                                    onClick={() => setAddingCollection(true)}
                                    disabled={
                                        user.memberships.filter(
                                            (membership) => membership.organization.user_permissions.create_collections
                                        ).length === 0 && !user.is_admin
                                    }
                                >
                                    Create Collection
                                    <i className="fas fa-rectangle-history-circle-plus" />
                                </button>
                            </div>
                            {user.memberships.filter(
                                (membership) => membership.organization.user_permissions.create_collections
                            ).length === 0 && !user.is_admin ? (
                                <UncontrolledTooltip target="add-collection">
                                    You lack the permissions to add collections.
                                </UncontrolledTooltip>
                            ) : (
                                <UncontrolledTooltip target="add-collection">Add collection</UncontrolledTooltip>
                            )}
                        </>
                    ) : (
                        <>
                            <div id="batch-delete-cancel">
                                <button
                                    type="button"
                                    className="pane-button large"
                                    onClick={() => setBatchDeleteList(undefined)}
                                >
                                    Cancel Delete
                                </button>
                            </div>
                            <div id="batch-delete-confirm">
                                <button
                                    type="button"
                                    className="pane-button large highlight"
                                    onClick={() => setBatchDeleteState(DELETE.PROMPT)}
                                    disabled={batchDeleteList.length === 0}
                                >
                                    Delete
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {searchAndFilters()}

            <div className="full-page-table">
                <table>
                    <thead>
                        <tr>
                            {project && <th>Included</th>}
                            {sortableHeader(CATEGORY.NAME)}
                            {sortableHeader(CATEGORY.TYPE)}
                            {sortableHeader(CATEGORY.PROJECITON)}
                            {sortableHeader(CATEGORY.ORGANIZATION)}
                            {sortableHeader(CATEGORY.STATE)}
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>{renderedTableItems}</tbody>
                </table>
            </div>

            <Modal isOpen={datasetDeleteState !== null} centered className="modal-confirm">
                {datasetDeleteModalContent()}
            </Modal>
            <Modal isOpen={batchDeleteState !== null} centered className="modal-confirm">
                {batchDeleteModalContent()}
            </Modal>
            <Modal isOpen={addingDataset} centered>
                {datasetUploadModalContent()}
            </Modal>
            <Modal isOpen={inspectingDataset !== null} centered>
                {inspectDatasetModalContent()}
            </Modal>
            <Modal isOpen={editingCollection !== null} centered>
                {editCollectionModalContent()}
            </Modal>
            <Modal isOpen={addingCollection} centered>
                {addCollectionModalContent()}
            </Modal>
        </div>
    );
};

export default Library;

import { useAppDispatch, useAppSelector } from 'store';
import { DRAWN_TOOL, DRAWTOOL_MODE, isElevationType, LAYER_DATA_TYPES, LAYER_STATES } from 'services/Constants';
import * as drawToolSlice from 'redux/drawTool';
import * as annotationsSlice from 'redux/annotations';
import * as datasetsSlice from 'redux/datasets';
import * as layersSlice from 'redux/layers';
import {
    cancelElevationProfile,
    clearMeasureDrawing,
    generateElevationProfile,
    startAnnotationCreation,
    startAnnotationEdit,
    startAnnotationView,
    startMeasureDrawing,
    startProfileLineDrawing,
    stopAnnotationCreation,
    stopAnnotationEdit,
    stopAnnotationView,
} from 'redux/actions';
import { useMountEffect } from 'components/utils';
import { DrawToolConflict, useEventBus } from 'EventBus';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useState } from 'react';
import Dataset from 'types/Dataset';
import { shallowEqual } from 'react-redux';

const DrawToolSwap = () => {
    const dispatch = useAppDispatch();
    const eventBus = useEventBus();
    const tool = useAppSelector(drawToolSlice.getTool);

    const annotationCreate = useAppSelector(annotationsSlice.createState);
    const annotationEdit = useAppSelector(annotationsSlice.editState);

    const datasets: Dataset[] = useAppSelector(datasetsSlice.getRenderableDatasets, shallowEqual);
    const layers = useAppSelector(layersSlice.all);

    const [conflict, setConflict] = useState<DrawToolConflict>();

    const onConflict = (conflictMessage: DrawToolConflict) => setConflict(conflictMessage);

    useMountEffect(
        () => eventBus.subscribe('draw-tool-conflict', onConflict),
        () => eventBus.unsubscribe('draw-tool-conflict', onConflict)
    );

    const cancel = () => setConflict(undefined);
    const proceed = () => {
        switch (tool) {
            case DRAWN_TOOL.ANNOTATION:
                if (annotationCreate) dispatch(stopAnnotationCreation());
                else if (annotationEdit) dispatch(stopAnnotationEdit());
                else dispatch(stopAnnotationView());
                break;
            case DRAWN_TOOL.ELEVATION_PROFILE:
                dispatch(cancelElevationProfile());
                break;
            case DRAWN_TOOL.MEASURE:
                dispatch(clearMeasureDrawing());
                break;
            default:
                break;
        }

        switch (conflict.tool) {
            case DRAWN_TOOL.ANNOTATION:
                switch (conflict.mode) {
                    case DRAWTOOL_MODE.CREATE:
                        dispatch(startAnnotationCreation());
                        break;
                    case DRAWTOOL_MODE.EDIT:
                        dispatch(startAnnotationEdit(conflict.targetAnnotation));
                        break;
                    case DRAWTOOL_MODE.VIEW:
                        dispatch(startAnnotationView(conflict.targetAnnotation));
                        break;
                    default:
                }
                break;
            case DRAWN_TOOL.ELEVATION_PROFILE:
                dispatch(startProfileLineDrawing()).then((geometry) => {
                    if (geometry) {
                        const start = geometry.coordinates[0];
                        const end = geometry.coordinates[1];
                        dispatch(
                            generateElevationProfile(
                                start,
                                end,
                                datasets.filter(
                                    (dataset) =>
                                        isElevationType(dataset.type) &&
                                        dataset.datatype === LAYER_DATA_TYPES.SINGLEBANDCOG &&
                                        dataset.state === LAYER_STATES.LOADED &&
                                        layers.find((l) => l.datasetId === dataset.id)?.visible
                                ),
                                200
                            )
                        );
                    }
                });
                break;
            case DRAWN_TOOL.MEASURE:
                dispatch(startMeasureDrawing());
                break;
            default:
                break;
        }

        setConflict(undefined);
    };

    const toolName = (toolId) => {
        switch (toolId) {
            case DRAWN_TOOL.ANNOTATION:
                return 'annotation tool';
            case DRAWN_TOOL.ELEVATION_PROFILE:
                return 'elevation profile tool';
            case DRAWN_TOOL.MEASURE:
                return 'measurement tool';
            default:
                return '';
        }
    };

    return (
        <Modal isOpen={conflict !== undefined} className="modal-confirm">
            <ModalBody>
                <i className="modal-icon modal-icon-bad fal fa-circle-exclamation no-hover" />
                <span className="big-modal-text">Caution</span>
                <span className="small-modal-text">
                    There is a conflict in tool,
                    <br />
                    selecting <b>{toolName(conflict?.tool)}</b> may remove work done using <b>{toolName(tool)}</b>.
                </span>
            </ModalBody>
            <ModalFooter>
                <button type="button" className="pane-button large" onClick={cancel}>
                    Cancel
                </button>
                <button type="button" className="pane-button large highlight" onClick={proceed}>
                    Continue
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default DrawToolSwap;

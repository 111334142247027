import Annotation from 'types/Annotation';
import GeometryInfo from './GeometryInfo';

export type Props = {
    annotation: Annotation;
    onEndView: () => void;
};

const ViewAnnotation = (props: Props) => {
    const { annotation } = props;

    return (
        <div className="tabContent">
            <div className="inspector-title">
                <span>
                    {annotation.name} ({annotation.geometry.type})
                </span>
            </div>
            <div>
                <i className="fal fa-exclamation-triangle" /> This geometry is not editable.
            </div>
            <hr />
            <GeometryInfo geometry={annotation.geometry} />
            <hr />
            <div className="input-row">
                <button type="button" className="pane-button highlight" id="end-viewing" onClick={props.onEndView}>
                    Close
                </button>
            </div>
        </div>
    );
};

export default ViewAnnotation;

import { IJsonRowNode } from 'flexlayout-react';
import { HostView } from 'giro3d_extensions/layers/Layer';
import BaseGiro3dService from 'services/BaseGiro3dService';
import { DRAWN_TOOL, DRAWTOOL_MODE, PANE } from 'services/Constants';
import { Box3, Box2, EventDispatcher, EventListener } from 'three';
import Annotation, { AnnotationId } from 'types/Annotation';
import HoveredItem from 'types/HoveredItem';
import { LoadingStatus } from 'types/LoadingStatus';
import MouseCoordinates from 'types/MouseCoordinates';
import { DatasetId, SourceFileId, ZoomFactor } from 'types/common';

type NoArgs = Record<string, never>;

export type CreatePane = {
    paneType: PANE;
    tabsetId?: string;
    index?: number;
    showExisting: boolean;
};

export type CreateDataPane = CreatePane & {
    data;
};

export type UpdateDataPane = {
    tabId: string;
    data;
};

export type CreateDatasetConfigPane = CreatePane & {
    data: { datasetId: DatasetId };
};

export type UpdateDatasetConfigPane = {
    tabId: string;
    data: { datasetId: DatasetId };
};

export type CreateDatasetPane = CreatePane & {
    datasetId: DatasetId;
};

export type CreateAnnotationPane = CreatePane & {
    annotationId: AnnotationId;
};

export type DrawToolConflict = { tool: DRAWN_TOOL; mode?: DRAWTOOL_MODE; targetAnnotation?: Annotation };

export type EventMap = {
    'close-context-menu': NoArgs;
    'save-camera-state': NoArgs;
    'restore-camera-state': NoArgs;
    'zoom-camera': { factor: ZoomFactor };
    'notify-change': { source?: unknown };
    'highlight-layer': { layer: DatasetId };
    'highlight-file': { dataset: DatasetId; file: SourceFileId; scroll?: boolean };
    'layer-initialized': { layer: DatasetId };
    'go-to-layer': { layer: DatasetId };
    'go-to-file': { dataset: DatasetId; file: SourceFileId };
    'go-to-bbox': { bbox: Box3 };
    'go-to-extent': { extent: Box2 };
    'mouse-coordinates': { coordinates: MouseCoordinates };
    'hovered-item': { item?: HoveredItem; location?: { x: number; y: number } };
    'dataset-loaded': { id: DatasetId; view: HostView };
    'go-to-follow-progress': { progress: number };
    'start-drawing': NoArgs;
    'end-drawing': NoArgs;
    'rendering-context-lost': { service: BaseGiro3dService };
    'rendering-context-restored': { service: BaseGiro3dService };
    'instance-loading-status-updated': { status: LoadingStatus };
    'dataset-loading-status-updated': { id: DatasetId; loading: boolean };
    'create-pane': CreatePane;
    'create-data-pane': CreateDataPane;
    'update-data-pane': UpdateDataPane;
    'create-dataset-pane': CreateDatasetPane;
    'create-dataset-config-pane': CreateDatasetConfigPane;
    'update-dataset-config-pane': UpdateDatasetConfigPane;
    'create-annotation-pane': CreateAnnotationPane;
    'close-dataset-panes': { datasetId: DatasetId };
    'close-annotation-panes': { annotationId: AnnotationId };
    'draw-tool-conflict': DrawToolConflict;
    'select-annotation-form': { annotationId: AnnotationId; action: string; commentId };
    'uppy-change': { eventType: string };
    'overwrite-model': { jsonModel: IJsonRowNode };
    'update-annotation-point': { id: AnnotationId; pointIndex: number; x: number; y: number; z: number };
    'delete-annotation-point': { id: AnnotationId; pointIndex: number };
};

/**
 * Dispatches events.
 *
 * The EventBus can be used instead of normal redux actions to represent
 * transient events that should not trigger a redux state change.
 * This is useful for optimizing "actions" that happen a lot, such as mouse interactions,
 * which do not need to be persisted into the redux store.
 */
export class EventBus {
    // For convenience, we use the already implemented EventDispatcher from THREE
    private readonly _eventDispatcher = new EventDispatcher<EventMap>();

    /**
     * Dispatches an event.
     * @param type The event type.
     * @param arg The event argument.
     */
    dispatch<T extends keyof EventMap>(type: T, arg?: EventMap[T]): void {
        return this._eventDispatcher.dispatchEvent({ type, ...arg });
    }

    /**
     * Adds a listener to an event type.
     * @param type The type of event to listen to.
     * @param listener The function that gets called when the event is fired.
     */
    subscribe<T extends Extract<keyof EventMap, string>>(
        type: T,
        listener: EventListener<EventMap[T], T, EventDispatcher>
    ): void {
        this._eventDispatcher.addEventListener(type, listener);
    }

    /**
     * Removes a listener from an event type.
     * @param type The type of the listener that gets removed.
     * @param listener The listener function that gets removed.
     */
    unsubscribe<T extends Extract<keyof EventMap, string>>(
        type: T,
        listener: EventListener<EventMap[T], T, EventDispatcher>
    ): void {
        this._eventDispatcher.removeEventListener(type, listener);
    }
}

const GLOBAL_EVENT_BUS = new EventBus();

export function useEventBus(): EventBus {
    return GLOBAL_EVENT_BUS;
}

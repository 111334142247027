import AnnotationLayer from 'giro3d_extensions/layers/AnnotationLayer';
import { Frustum, Matrix4, Object3D } from 'three';
import Annotation, { AnnotationFilter, AnnotationGeometry, AnnotationId, isFiltered } from 'types/Annotation';
import store, { Dispatch, RootState } from 'store';
import { EventBus, EventMap } from 'EventBus';
import * as annotationsSlice from 'redux/annotations';
import * as settingsSlice from 'redux/settings';
import { HostView } from 'giro3d_extensions/layers/Layer';
import LayerManager from 'giro3d_extensions/LayerManager';
import StateObserver from 'giro3d_extensions/layers/StateObserver';
import Disposable from '@giro3d/giro3d/core/Disposable';
import type Instance from '@giro3d/giro3d/core/Instance';
import { ShapeManager } from './ShapeManager';

export type AnnotationObject = {
    annotation: Annotation;
    layer: AnnotationLayer;
    hidden: boolean;
    filtered: boolean;
    inView: boolean;
};

export default interface AnnotationManager {
    getAnnotation(id: AnnotationId): AnnotationObject | null;
    editAnnotation(id: AnnotationId): Promise<AnnotationGeometry>;
    getClickableAnnotations(target: Object3D[]): void;
}

export class AnnotationManagerImpl implements AnnotationManager, Disposable {
    private readonly _annotationById: Map<AnnotationId, AnnotationObject> = new Map();
    private readonly _tmpFrustum = new Frustum();
    private readonly _tmpMatrix4 = new Matrix4();
    private readonly _hostView: HostView;

    private _instance: Instance;
    private _dispatch: Dispatch;

    private _annotationsClickable = true;
    private _onStartDrawing: () => void;
    private _onEndDrawing: () => void;
    private _onUpdateAnnotationPoint: (args: EventMap['update-annotation-point']) => void;
    private _onDeleteAnnotationPoint: (args: EventMap['delete-annotation-point']) => void;
    private _eventBus: EventBus;
    private _shapeManager: ShapeManager;
    private _layerManager: LayerManager;
    private _showAnnotations: boolean;
    private _filter: AnnotationFilter;
    private _filterByVisibility: boolean;
    private _stateObserver: StateObserver<RootState>;

    constructor(hostView: HostView) {
        this._hostView = hostView;
    }

    init(
        instance: Instance,
        dispatch: Dispatch,
        shapeManager: ShapeManager,
        eventBus: EventBus,
        layerManager: LayerManager,
        stateObserver: StateObserver<RootState>
    ) {
        this._instance = instance;
        this._dispatch = dispatch;
        this._shapeManager = shapeManager;
        this._layerManager = layerManager;

        this._eventBus = eventBus;

        this._onStartDrawing = this.onStartDrawing.bind(this);
        this._onEndDrawing = this.onEndDrawing.bind(this);
        this._onUpdateAnnotationPoint = this.onUpdateAnnotationPoint.bind(this);
        this._onDeleteAnnotationPoint = this.onDeleteAnnotationPoint.bind(this);

        this._eventBus.subscribe('start-drawing', this._onStartDrawing);
        this._eventBus.subscribe('end-drawing', this._onEndDrawing);
        this._eventBus.subscribe('update-annotation-point', this._onUpdateAnnotationPoint);
        this._eventBus.subscribe('delete-annotation-point', this._onDeleteAnnotationPoint);

        instance.addEventListener('update-end', this.update.bind(this));

        this._stateObserver = stateObserver;
        this._showAnnotations = stateObserver.select(settingsSlice.getShowAnnotationsInViewport);
        this._filterByVisibility = stateObserver.select(settingsSlice.getFilterAnnotationByVisibility);
        this._filter = stateObserver.select(annotationsSlice.filter);

        stateObserver.subscribe(settingsSlice.getShowAnnotationsInViewport, (v) => {
            this._showAnnotations = v;
            this.updateVisibility();
        });

        stateObserver.subscribe(settingsSlice.getFilterAnnotationByVisibility, (v) => {
            this._filterByVisibility = v;
            if (v) {
                this.updateAnnotations();
            }
        });

        stateObserver.subscribe(annotationsSlice.filter, (v) => {
            this._filter = v;
            this.update();
        });

        stateObserver.subscribe(annotationsSlice.list, (list) => {
            this.setAnnotations(list);
            this.update();
        });
    }

    private update() {
        this.updateAnnotationFilter();
        this.updateVisibility();
        this.updateAnnotations();
    }

    dispose() {
        this._annotationById.forEach((object) => object.layer.dispose());
        this._annotationById.clear();
        this._eventBus.unsubscribe('start-drawing', this._onStartDrawing);
        this._eventBus.unsubscribe('end-drawing', this._onEndDrawing);
    }

    private onEndDrawing() {
        this._annotationsClickable = true;
    }

    private onUpdateAnnotationPoint(event: EventMap['update-annotation-point']) {
        const { id, pointIndex, x, y, z } = event;
        const annotation = this.getAnnotation(id);
        const geometry = annotation.layer.updatePoint(pointIndex, x, y, z);
        this._dispatch(annotationsSlice.updateAnnotationGeometry({ id, geometry }));
    }

    private onDeleteAnnotationPoint(event: EventMap['delete-annotation-point']) {
        const { id, pointIndex } = event;
        const annotation = this.getAnnotation(id);
        const geometry = annotation.layer.deletePoint(pointIndex);
        this._dispatch(annotationsSlice.updateAnnotationGeometry({ id, geometry }));
    }

    private onStartDrawing() {
        this._annotationsClickable = false;
    }

    getAnnotation(id: AnnotationId): AnnotationObject | null {
        return this._annotationById.get(id) ?? null;
    }

    private updateVisibility() {
        if (!this._instance) {
            return;
        }

        const hidden = !this._showAnnotations;

        let mustNotify = false;
        this._annotationById.forEach((annotation) => {
            if (annotation.hidden !== hidden) {
                annotation.hidden = hidden;
                this.updateLayerVisibility(annotation);
                mustNotify = true;
            }
        });

        if (mustNotify) {
            this._instance.notifyChange();
        }
    }

    private updateAnnotationFilter() {
        if (!this._instance) {
            return;
        }

        let mustNotify = false;
        this._annotationById.forEach((tuple) => {
            const { annotation } = tuple;

            const filtered = isFiltered(this._filter, annotation);

            if (filtered !== tuple.filtered) {
                tuple.filtered = filtered;
                this.updateLayerVisibility(tuple);
                this._dispatch(annotationsSlice.setAnnotationFiltered({ annotation, filtered }));
                mustNotify = true;
            }
        });

        if (mustNotify) {
            this._instance.notifyChange(this._instance.threeObjects);
        }
    }

    updateGeometry(layer: AnnotationLayer, newGeometry: AnnotationGeometry) {
        this._dispatch(annotationsSlice.updateAnnotationGeometry({ id: layer.annotationId, geometry: newGeometry }));
    }

    editAnnotation(id: AnnotationId) {
        const { annotation, layer } = this._annotationById.get(id);

        if (!annotation) {
            console.warn(`no annotation with ID ${id}`);
            return null;
        }

        this._annotationsClickable = false;

        this._shapeManager.stopDrawing();

        const onShapeUpdated = (_: AnnotationId, geometry: AnnotationGeometry) => {
            this.updateGeometry(layer, geometry);
        };

        return this._shapeManager
            .editAnnotation(id, onShapeUpdated)
            .then((geometry) => {
                this.updateGeometry(layer, geometry);
                return geometry;
            })
            .finally(() => {
                this._annotationsClickable = true;
            });
    }

    private setAnnotations(list: Annotation[]) {
        // Remove annotations that no longer exist
        for (const id of [...this._annotationById.keys()]) {
            if (!list.some((ann) => ann.id === id)) {
                this.removeAnnotation(id);
            }
        }

        // Create new annotations
        for (const annotation of list) {
            if (this._annotationById.has(annotation.id)) {
                continue;
            }

            const layer = new AnnotationLayer(
                annotation.id,
                this._instance,
                this._dispatch,
                annotation,
                this._layerManager,
                this._shapeManager,
                this._hostView,
                new StateObserver(store.getState, store.subscribe)
            );

            const result = {
                annotation,
                layer,
                hidden: false,
                filtered: false,
                inView: false,
            };

            this._annotationById.set(annotation.id, result);

            layer
                .init()
                .catch((e) => console.error(e))
                .then(() => {
                    this.updateLayerVisibility(result);
                });
        }
    }

    getClickableAnnotations(target: Object3D[]) {
        if (this._annotationsClickable) {
            this._annotationById.forEach((a) => {
                if (a.filtered || a.hidden) return;

                target.push(a.layer.getClickableElement());
            });
        }
    }

    private updateAnnotations() {
        this._tmpMatrix4.multiplyMatrices(
            this._instance.view.camera.projectionMatrix,
            this._instance.view.camera.matrixWorldInverse
        );
        this._tmpFrustum.setFromProjectionMatrix(this._tmpMatrix4);

        this._annotationById.forEach((annotation) => {
            this.updateLayerVisibility(annotation);
            if (this._filterByVisibility) {
                this.updateInView(annotation, this._tmpFrustum);
            }
        });
    }

    private removeAnnotation(id: AnnotationId) {
        const annotation = this._annotationById.get(id);
        annotation.layer.removeFromParent();
        annotation.layer.dispose();
        this._annotationById.delete(id);
        this._instance.notifyChange();
    }

    private updateInView(obj: AnnotationObject, frustum: Frustum) {
        const current = obj.inView;
        const newValue = frustum.intersectsBox(obj.layer.getBoundingBox());

        if (current !== newValue) {
            obj.inView = newValue;
            this._dispatch(annotationsSlice.updateAnnotationVisibility({ id: obj.annotation.id, visible: obj.inView }));
        }
    }

    // eslint-disable-next-line class-methods-use-this
    private updateLayerVisibility(obj: AnnotationObject) {
        const { layer } = obj;

        const state = this._stateObserver.select(annotationsSlice.getState(obj.annotation.id));
        const hidden = obj.filtered === true || obj.hidden === true;
        // We force the visibility of edited shapes otherwise
        // the user cannot even see the shape being edited.
        const visible = !hidden || state === 'edited';
        layer.setThisVisibility(visible);
    }
}

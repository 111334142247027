import { createSelector, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ANNOTATION_CREATESTATE, DEFAULT_ANNOTATION_FILTER, EDITSTATE } from 'services/Constants';
import { RootState } from 'store';
import Annotation, {
    AnnotationComment,
    AnnotationFilter,
    AnnotationGeometry,
    AnnotationId,
    AnnotationInteractionState,
} from 'types/Annotation';
import { DatasetId } from 'types/common';

export type AnnotationWithState = Annotation & {
    inView?: boolean;
    filtered?: boolean;
    previousGeometry?: AnnotationGeometry;
    state: AnnotationInteractionState;
};

export type State = {
    list: AnnotationWithState[];
    active: AnnotationId | null;
    createState: ANNOTATION_CREATESTATE;
    editState: EDITSTATE;
    comments: Record<AnnotationId, Readonly<AnnotationComment[]>>;
    filter: AnnotationFilter;
};

const initialState: State = {
    list: [],
    active: null,
    createState: ANNOTATION_CREATESTATE.NONE,
    editState: EDITSTATE.NONE,
    comments: {},
    filter: DEFAULT_ANNOTATION_FILTER,
};

const self = (store: RootState) => store.annotations;

function getAnnotationById(list: AnnotationWithState[], id: AnnotationId): AnnotationWithState {
    return list.find((a) => a.id === id);
}

// Selectors
export const get = (id: AnnotationId) => createSelector(self, (s) => toAnnotation(getAnnotationById(s.list, id)));
export const getGeometry = (id: AnnotationId) => createSelector(self, (s) => getAnnotationById(s.list, id)?.geometry);
export const getState = (id: AnnotationId) => createSelector(self, (s) => getAnnotationById(s.list, id)?.state);
export const list = createSelector(self, (s) => s.list.map(toAnnotation));
export const filtered = (viewFilter: boolean) =>
    createSelector(self, (s) => s.list.filter((ann) => !ann.filtered && (ann.inView || !viewFilter)).map(toAnnotation));
export const filter = createSelector(self, (s) => s.filter);
export const active = createSelector(self, (s) => toAnnotation(getAnnotationById(s.list, s.active)));
export const comments = (id: AnnotationId) => createSelector(self, (s) => s.comments[id] || []);
export const createState = createSelector(self, (s) => s.createState);
export const editState = createSelector(self, (s) => s.editState);

// Strip an annotation with state from its state part
function toAnnotation(withState: AnnotationWithState): Annotation {
    if (!withState) {
        return null;
    }
    const stripped = { ...withState };

    delete stripped.inView;
    delete stripped.filtered;
    delete stripped.previousGeometry;
    delete stripped.state;

    return stripped;
}

function createAnnotationWithState(annotation: Annotation): AnnotationWithState {
    return {
        ...annotation,
        state: 'normal',
    };
}

const slice = createSlice({
    name: 'annotations',
    initialState,
    reducers: {
        reset: () => initialState,
        setAnnotations: (state, action: PayloadAction<Annotation[]>) => {
            state.list = action.payload.map(createAnnotationWithState);
        },
        setActiveAnnotation: (state, action: PayloadAction<AnnotationId | null>) => {
            const id = action.payload;
            state.active = id ?? null;

            state.list.forEach((an) => {
                an.state = 'normal';
            });

            if (id) {
                const annotation = getAnnotationById(state.list, id);
                if (annotation.state !== 'edited') {
                    annotation.state = 'selected';
                }
            }
        },
        createAnnotationState: (state, action: PayloadAction<ANNOTATION_CREATESTATE>) => {
            state.createState = action.payload;
        },
        appendAnnotation: (state, action: PayloadAction<Annotation>) => {
            state.list.push(createAnnotationWithState(action.payload));
            state.active = action.payload.id;
        },
        removeAnnotation: (state, action: PayloadAction<AnnotationId>) => {
            state.list = state.list.filter((a) => a.id !== action.payload);
        },
        setAnnotationDatasets: (state, action: PayloadAction<{ id: AnnotationId; datasets: DatasetId[] }>) => {
            for (const annotation of state.list) {
                if (annotation.id === action.payload.id) {
                    annotation.datasets = action.payload.datasets;
                }
            }
        },
        restorePreviousGeometry: (state, action: PayloadAction<Annotation>) => {
            const annotation = getAnnotationById(state.list, action.payload.id);
            if (annotation.previousGeometry != null) {
                annotation.geometry = annotation.previousGeometry;
                annotation.previousGeometry = undefined;
            }
        },
        saveCurrentGeometry: (state, action: PayloadAction<Annotation>) => {
            const annotation = getAnnotationById(state.list, action.payload.id);
            annotation.previousGeometry = annotation.geometry;
        },
        editAnnotationState: (state, action: PayloadAction<EDITSTATE>) => {
            state.editState = action.payload;
        },
        updateAnnotation: (state, action: PayloadAction<Annotation>) => {
            const index = state.list.findIndex((ann) => ann.id === action.payload.id);
            if (index !== -1) {
                state.list[index] = createAnnotationWithState(action.payload);
            }
        },
        updateAnnotationGeometry: (
            state,
            action: PayloadAction<{ id: AnnotationId; geometry: AnnotationGeometry }>
        ) => {
            const index = state.list.findIndex((ann) => ann.id === action.payload.id);
            if (index !== -1) {
                state.list[index].geometry = action.payload.geometry;
            }
        },
        updateAnnotationVisibility: (state, action: PayloadAction<{ id: AnnotationId; visible: boolean }>) => {
            const index = state.list.findIndex((ann) => ann.id === action.payload.id);
            if (index !== -1) {
                state.list[index].inView = action.payload.visible;
            }
        },
        resetVisibilities: (state) => {
            state.list.forEach((annotation) => {
                annotation.inView = true;
            });
        },
        setCommentList: (state, action: PayloadAction<{ annotation: AnnotationId; comments: AnnotationComment[] }>) => {
            state.comments[action.payload.annotation] = action.payload.comments;
        },
        appendCommentList: (state, action: PayloadAction<AnnotationComment>) => {
            if (state.comments[action.payload.annotation_id])
                state.comments[action.payload.annotation_id].push(action.payload);
            else state.comments[action.payload.annotation_id] = [action.payload];
            for (const annotation of state.list) {
                if (action.payload.annotation_id === annotation.id) {
                    annotation.comment_count += 1;
                }
            }
        },
        removeCommentFromList: (state, action: PayloadAction<AnnotationComment>) => {
            const index = state.comments[action.payload.annotation_id].findIndex(
                (comm) => comm.id === action.payload.id
            );
            if (index !== -1) {
                state.comments[action.payload.annotation_id].splice(index);
            }
            for (const annotation of state.list) {
                if (action.payload.annotation_id === annotation.id) {
                    annotation.comment_count -= 1;
                }
            }
        },
        updateComment: (state, action: PayloadAction<AnnotationComment>) => {
            const index = state.comments[action.payload.annotation_id].findIndex(
                (comm) => comm.id === action.payload.id
            );
            if (index !== -1) {
                state.comments[action.payload.annotation_id][index] = action.payload;
            }
        },
        setAnnotationFilter: (state, action: PayloadAction<AnnotationFilter>) => {
            state.filter = action.payload;
        },
        setAnnotationFiltered: (state, action: PayloadAction<{ annotation: Annotation; filtered: boolean }>) => {
            const annotation = getAnnotationById(state.list, action.payload.annotation.id);
            annotation.filtered = action.payload.filtered;
        },
        hoverAnnotation: (state, action: PayloadAction<{ annotation: AnnotationId; hovered: boolean }>) => {
            const annotation = getAnnotationById(state.list, action.payload.annotation);
            if (annotation.state === 'selected' || annotation.state === 'edited') {
                // Selected/Edited state takes priority over hover
                return;
            }

            annotation.state = action.payload.hovered ? 'hovered' : 'normal';
        },
        selectAnnotation: (state, action: PayloadAction<{ annotation: AnnotationId; selected: boolean }>) => {
            const annotation = getAnnotationById(state.list, action.payload.annotation);
            if (annotation.state === 'edited') {
                // Selected/Edited state takes priority over hover
                return;
            }

            annotation.state = action.payload.selected ? 'selected' : 'normal';
        },
        editAnnotation: (state, action: PayloadAction<{ annotation: AnnotationId; edited: boolean }>) => {
            const annotation = getAnnotationById(state.list, action.payload.annotation);
            // The edited annotation is necessarily also the selected annotation, so
            // we have to revert to the selected state when edition is finished.
            annotation.state = action.payload.edited ? 'edited' : 'selected';
        },
    },
});

export const reducer = slice.reducer;

// Actions
export const reset = slice.actions.reset;
export const setAnnotations = slice.actions.setAnnotations;
export const setActiveAnnotation = slice.actions.setActiveAnnotation;
export const createAnnotationState = slice.actions.createAnnotationState;
export const appendAnnotation = slice.actions.appendAnnotation;
export const removeAnnotation = slice.actions.removeAnnotation;
export const setAnnotationDatasets = slice.actions.setAnnotationDatasets;
export const editAnnotationState = slice.actions.editAnnotationState;
export const updateAnnotation = slice.actions.updateAnnotation;
export const setCommentList = slice.actions.setCommentList;
export const setAnnotationFilter = slice.actions.setAnnotationFilter;
export const setAnnotationFiltered = slice.actions.setAnnotationFiltered;
export const updateComment = slice.actions.updateComment;
export const appendCommentList = slice.actions.appendCommentList;
export const removeCommentFromList = slice.actions.removeCommentFromList;
export const updateAnnotationVisibility = slice.actions.updateAnnotationVisibility;
export const resetVisibilities = slice.actions.resetVisibilities;
export const updateAnnotationGeometry = slice.actions.updateAnnotationGeometry;
export const restorePreviousGeometry = slice.actions.restorePreviousGeometry;
export const saveCurrentGeometry = slice.actions.saveCurrentGeometry;
export const selectAnnotation = slice.actions.selectAnnotation;
export const hoverAnnotation = slice.actions.hoverAnnotation;
export const editAnnotation = slice.actions.editAnnotation;

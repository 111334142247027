/**
 * Build request, success and error action types to be used in builReducer. To be used in actionTypes.js for instance.
 *
 * Ex usage:
 *
 * const RETICULATE_SPLINE_TYPES = buildActionTypes('reticulateSplines');
 */

export function buildActionTypes(name) {
    return {
        REQUEST: `${name}/request`,
        SUCCESS: `${name}/success`,
        ERROR: `${name}/error`,
        CLEAR: `${name}/clear`,
        NOT_FOUND: `${name}/notfound`,
        UNKNOWN: `${name}/unknown`,
    };
}

// Collections - for overview map
export const COLLECTION_ADDED = 'collection/added'; // Collection ready to be displayed and added to the overview map
export const COLLECTION_LOADING = 'collection/loading'; // Collection being loaded in overview map (typically after creation)
export const COLLECTION_LOADING_FAILED = 'collection/loading_failed';
export const COLLECTION_UPDATED = 'collection/updated'; // Collection got updated (from front-end form)
export const COLLECTION_REMOVED = 'collection/removed'; // Collection being disposed from overview map
export const COLLECTION_DELETED = 'collection/deleted'; // Collection being deleted

// Projects - for overview map
export const PROJECT_LOADED = 'project/loaded'; // Project ready to be displayed and added to the overview map
export const PROJECT_LOADING = 'project/loading'; // Project being loaded in overview map (typically after creation)
export const PROJECT_LOADING_FAILED = 'project/loading_failed';
export const PROJECT_UPDATED = 'project/updated'; // Project got updated (from front-end form)
export const PROJECT_REMOVED = 'project/removed'; // Project being disposed from overview map

export const PROJECT_USERS = 'project/users';

export const USER_TYPES = buildActionTypes('authentication/user');
export const REGISTER_USER_TYPES = buildActionTypes('authentication/register/user');
export const HEALTHCHECK_TYPES = buildActionTypes('healthcheck');
export const ORGANIZATIONS_TYPES = buildActionTypes('organizations');
export const SSDM_TYPES = buildActionTypes('ssdm');
export const PROJECTIONS = buildActionTypes('projections');
export const PROJECTS = buildActionTypes('projects');
export const COLLECTIONS = buildActionTypes('collections');
export const ELIGIBLE_TYPES = buildActionTypes('authentication/user/eligible');

export const ADD_PROJ4 = 'proj4';

export const SET_DATASETS = 'all_datasets/set';
export const ADD_DATASET = 'all_datasets/add';
export const UPDATE_DATASET = 'all_datasets/update';
export const DELETE_DATASET = 'all_datasets/delete';
export const DELETE_DATASETS = 'all_datasets/deleteBatch';

export const HELP_SHOW = 'help/show';
export const HELP_HIDE = 'help/hide';
export const ERROR_SHOW = 'error/show';
export const ERROR_HIDE = 'error/hide';
export const RELEASE_SHOW = 'release/show';
export const RELEASE_HIDE = 'release/hide';
export const EDIT_VECTOR_STATE = 'vector/edit_state';
export const NOTIFICATIONS = 'notifications/list';
export const ACTIVE_NOTIFICATION = 'notifications/active';
export const UPDATE_NOTIFICATION = 'notifications/update';
export const QUEUE_USERNAMES = 'users/queue/multiple';
export const ADD_USERNAMES = 'users/add/multiple';

// OVERVIEW MAP signals
export const OVERVIEW_MAP_LOADED = 'overview_map/loaded';
export const OVERVIEW_MAP_RESOLUTION = 'overview_map/resolution';
export const MEASUREMENT_COORDINATES = 'overview_map/measurement/coordinates';
export const MEASUREMENT_RESULT = 'overview_map/measurement/result';
export const OVERVIEW_MAP_FEATURE_INFO = 'overview_map/feature_info';

export const VERSION_INFO = 'info';

// NAVIGATION
export const NAV_PAGE = 'nav/page';

// ADMIN
export const COLLECTION_CREATED = 'collections/created';
export const PROJECT_CREATED = 'projects/created';
export const PROJECT_DELETED = 'projects/deleted';
export const SET_ADMINISTRATED_MEMBERSHIPS = 'memberships/set';
export const UPDATE_ADMINISTRATED_MEMBERSHIP = 'memberships/update';
export const ADD_ADMINISTRATED_MEMBERSHIP = 'memberships/add';
export const REMOVE_ADMINISTRATED_MEMBERSHIP = 'memberships/remove';
export const SET_ADMINISTRATED_USERS = 'users/set';
export const UPDATE_ADMINISTRATED_USER = 'users/update';
export const SET_MEMBERSHIP_REQUESTS = 'memberships/requests/set';
export const REMOVE_MEMBERSHIP_REQUEST = 'memberships/requests/remove';

// API KEYS
export const SET_API_KEYS = 'api_keys/set';
export const REMOVE_API_KEY = 'api_keys/remove';
export const SET_API_KEYS_ADMIN = 'api_keys/admin/set';

export const UPPY_SHOWN = 'uppy/shown';

import AnnotationManager from 'services/AnnotationManager';
import FeatureManager from 'services/FeatureManager';
import Picker from 'services/Picker';
import { ShapeManager } from 'services/ShapeManager';
import ViewManager from 'services/ViewManager';

export type ServiceMap = {
    'Picker': Picker;
    'FeatureManager': FeatureManager;
    'MainViewManager': ViewManager;
    'SeismicViewManager': ViewManager;
    'AnnotationManager': AnnotationManager;
    'ShapeManager': ShapeManager;
};

export default class ServiceContainer {
    private readonly _map: Partial<ServiceMap> = {};

    /**
     * Gets the service implementation for the specified service key.
     * @param key The service key.
     * @returns The service implementation, or `null` if no service was registered for this key.
     */
    get<K extends keyof ServiceMap>(key: K): ServiceMap[K] | null {
        const service = this._map[key];

        return (service as ServiceMap[K]) ?? null;
    }

    /**
     * Registers a service for the specified key.
     * @param key The service key.
     * @param service The service implementation.
     */
    register<K extends keyof ServiceMap>(key: K, service: ServiceMap[K]) {
        this._map[key] = service;
    }
}

const GLOBAL_SERVICE_CONTAINER = new ServiceContainer();

export function useServiceContainer(): ServiceContainer {
    return GLOBAL_SERVICE_CONTAINER;
}

export function getService<K extends keyof ServiceMap>(key: K): ServiceMap[K] | null {
    return GLOBAL_SERVICE_CONTAINER.get(key);
}

import { createSelector, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DRAWN_TOOL, DRAWTOOL_MODE } from 'services/Constants';
import { RootState } from 'store';
import { AnnotationGeometry, AnnotationGeometryType } from 'types/Annotation';
import { GeometryId } from 'types/common';

export type State = {
    tool: DRAWN_TOOL;
    mode: DRAWTOOL_MODE;
    type: AnnotationGeometryType;
    activeGeometry: GeometryId;
    geometries: Record<GeometryId, AnnotationGeometry>;
};

const initialState: State = {
    tool: null,
    mode: null,
    type: null,
    activeGeometry: null,
    geometries: {},
};

const self = (store: RootState) => store.drawTool;

// Selectors
export const getTool = createSelector(self, (s) => s.tool);
export const getMode = createSelector(self, (s) => s.mode);
export const getType = createSelector(self, (s) => s.type);
export const getGeometry = (id: GeometryId) => createSelector(self, (s) => s.geometries[id]);
export const getActiveGeometry = createSelector(self, (s) => s.geometries[s.activeGeometry]);
export const activeGeometry = createSelector(self, (s) => s.activeGeometry);

const slice = createSlice({
    name: 'drawTool',
    initialState,
    reducers: {
        setTool: (state, action: PayloadAction<DRAWN_TOOL>) => {
            state.tool = action.payload;
        },
        setMode: (state, action: PayloadAction<DRAWTOOL_MODE>) => {
            state.mode = action.payload;
        },
        setType: (state, action: PayloadAction<AnnotationGeometryType>) => {
            state.type = action.payload;
        },
        setActiveGeometry: (state, action: PayloadAction<GeometryId>) => {
            state.activeGeometry = action.payload;
        },
        updateGeometry: (state, action: PayloadAction<{ id: string; geometry: AnnotationGeometry }>) => {
            const { id, geometry } = action.payload;
            state.geometries[id] = geometry;
        },
    },
});

export const reducer = slice.reducer;

// Actions
export const setTool = slice.actions.setTool;
export const setMode = slice.actions.setMode;
export const setType = slice.actions.setType;
export const updateGeometry = slice.actions.updateGeometry;
export const setActiveGeometry = slice.actions.setActiveGeometry;

import * as THREE from 'three';
import MemoryUsage, { GetMemoryUsageContext } from '@giro3d/giro3d/core/MemoryUsage';
import SeismicPlaneRegion from './SeismicPlaneRegion';
import fragmentShader from './SeismicPlane.frag.glsl.js';
import vertexShader from './SeismicPlane.vert.glsl.js';

const DEFAULT_COLOR = new THREE.Color('white');
const DEFAULT_LUT: THREE.Color[] = new Array(256).fill(DEFAULT_COLOR);

function createMaterial(params: {
    region?: SeismicPlaneRegion;
    textureRegion?: SeismicPlaneRegion;
    widthMeters?: number;
    heightMeters?: number;
    sizePixels?: THREE.Vector2;
    opacity: number;
    geometry?: THREE.BufferGeometry<THREE.NormalBufferAttributes>;
    bounds?: {
        customMin: number;
        customMax: number;
        min: number;
        max: number;
    };
    lut?: THREE.Color[];
}) {
    const bounds = params.bounds;
    const lut = params.lut ?? DEFAULT_LUT;
    const material = new THREE.ShaderMaterial({
        uniforms: {
            brightness: new THREE.Uniform(0),
            signalTexture: new THREE.Uniform(null),
            vLut: new THREE.Uniform(lut),
            dataMin: new THREE.Uniform(bounds.min),
            dataMax: new THREE.Uniform(bounds.max),
            customMin: new THREE.Uniform(bounds.customMin),
            customMax: new THREE.Uniform(bounds.customMax),
            customBoundsMode: new THREE.Uniform(false),
            intensityFilter: new THREE.Uniform(0),
            filterTransparency: new THREE.Uniform(false),
            isEnvelope: new THREE.Uniform(false),
            opacity: new THREE.Uniform(params.opacity),
            offsetScale: new THREE.Uniform(new THREE.Vector4(0, 0, 1, 1)),
        },
        defines: {
            'LUT_SIZE': lut.length,
        },
        depthTest: true,
        depthWrite: true,
        transparent: params.opacity < 1,
        clipping: false,
        side: THREE.DoubleSide,
        vertexShader,
        fragmentShader,
        opacity: params.opacity,
    });

    return material;
}

type ConstructorParams = {
    region: SeismicPlaneRegion;
    textureRegion?: SeismicPlaneRegion;
    widthMeters: number;
    heightMeters: number;
    sizePixels: THREE.Vector2;
    opacity: number;
    geometry: THREE.BufferGeometry;
    lut: THREE.Color[];
    bounds: {
        min: number;
        max: number;
        customMin: number;
        customMax: number;
    };
};

/**
 * A tile on a seismic plane that handles a single texture.
 */
export default class SeismicPlaneTile extends THREE.Group implements MemoryUsage {
    readonly isMemoryUsage = true as const;

    readonly diagonalPixels: number;
    readonly resolution: number;

    private _textureIsOwned: boolean;

    mesh: THREE.Mesh<THREE.BufferGeometry<THREE.NormalBufferAttributes>, THREE.ShaderMaterial, THREE.Object3DEventMap>;
    region: SeismicPlaneRegion;
    textureRegion: SeismicPlaneRegion;
    isSeismicPlaneTile: boolean;
    params: ConstructorParams;
    texture: THREE.DataTexture;
    sizePixels: THREE.Vector2;
    sizeMeters: THREE.Vector2;
    geometricError: number;
    isLeaf: boolean;
    material: THREE.ShaderMaterial;
    childrenGroup: THREE.Group<THREE.Object3DEventMap>;
    disposed: boolean;

    /**
     * @param params Options.
     * @param params.region The region of the plane occupied by this tile.
     * @param params.textureRegion The region of the plane occupied by this tile's texture.
     * It's slightly bigger than the geometric region to avoid visual artifacts.
     * @param params.widthMeters The width in meters of this tile.
     * @param params.heightMeters The height in meters of this tile.
     * @param params.geometry The tile geometry.
     * @param params.opacity The tile opacity.
     * @param params.sizePixels The approximate pixel size of this tile.
     * @param params.bounds The colormap bounds.
     * @param params.lut The colormap LUT.
     */
    constructor(params: ConstructorParams) {
        super();
        const geometry = params.geometry;
        const material = createMaterial(params);
        const mesh = new THREE.Mesh(geometry, material);
        this.add(mesh);
        this.mesh = mesh;
        this.mesh.name = 'mesh';
        this.renderOrder = 1;
        this.name = params.region.toString();
        this.region = params.region;
        this.textureRegion = params.textureRegion;
        this.isSeismicPlaneTile = true;
        // @ts-expect-error type is readonly
        this.type = 'SeismicPlaneTile';
        this.params = params;

        this.texture = null;

        // Pre-computes data for the three-mesh-bvh package
        this.mesh.geometry.computeBoundsTree();

        this.sizePixels = params.sizePixels;
        this.sizeMeters = new THREE.Vector2(params.widthMeters, params.heightMeters);

        // NOTE: consider width also
        this.resolution = this.sizeMeters.height / this.sizePixels.height;

        const diagonalPixels = Math.sqrt(this.sizePixels.width ** 2 + this.sizePixels.height ** 2);

        this.diagonalPixels = diagonalPixels;

        this.geometricError = this.mesh.geometry.boundingSphere.radius;

        this.isLeaf = true;

        this._textureIsOwned = false;

        this.material = material;

        // don't display the tile until we have a texture to show
        this.material.visible = false;

        this.childrenGroup = new THREE.Group();
        this.childrenGroup.name = 'children';
        this.add(this.childrenGroup);

        this.updateMatrixWorld();
    }

    getMemoryUsage(context: GetMemoryUsageContext): void {
        if (this._textureIsOwned) {
            const texture = this.texture;
            const { width, height } = texture.image;

            context.objects.set(this.id, { cpuMemory: 0, gpuMemory: width * height });
        }
    }

    onBeforeRender() {
        this._updateMaterial();
    }

    _updateMaterial() {
        this.material.uniforms.opacity.value = this.material.opacity;
        if (this.material.transparent !== this.material.opacity < 1) {
            this.material.transparent = this.material.opacity < 1;
            this.material.needsUpdate = true;
        }
    }

    addChild(child: THREE.Object3D) {
        this.childrenGroup.add(child);
    }

    get localBoundingBox() {
        return this.mesh.geometry.boundingBox;
    }

    get worldSpaceBoundingBox() {
        return this.computeWorldSpaceBoundingBox();
    }

    private computeWorldSpaceBoundingBox() {
        if (!this.parent) {
            throw new Error('cannot compute world space bounding box with no parent');
        }

        this.updateMatrixWorld();

        const result = this.mesh.geometry.boundingBox.clone();
        result.applyMatrix4(this.matrixWorld);

        return result;
    }

    set wireframe(v: boolean) {
        this.material.wireframe = v;
    }

    dispose() {
        this.disposed = true;
        this.disposeOwnedTexture();
        // Dispose three-mesh-bvh data
        this.mesh.geometry.disposeBoundsTree();
        this.mesh.geometry.dispose();
        this.material.dispose();
    }

    isLoaded() {
        return this.texture != null && this._textureIsOwned;
    }

    computeBoundingBox() {
        this.mesh.geometry.computeBoundingSphere();
        this.mesh.geometry.computeBoundingBox();
    }

    showSelf() {
        this.isLeaf = true;
        this.visible = true;
        this.mesh.visible = true;
        this.material.visible = true;
        this.childrenGroup.traverse((c) => {
            if ((c as SeismicPlaneTile).isSeismicPlaneTile) {
                (c as SeismicPlaneTile).dispose();
            }
        });
        this.childrenGroup.clear();
    }

    showChildren() {
        this.isLeaf = false;
        this.visible = true;
        this.mesh.visible = false;
        // NOTE: forgotten?
        this.material.visible = false;
        this.childrenGroup.visible = true;
        this.childrenGroup.children.forEach((c) => {
            c.visible = true;
        });
    }

    hide() {
        this.visible = false;
    }

    /**
     * @param {THREE.Texture} texture The texture to inherit.
     * @param {SeismicPlaneRegion} region The region of the ancestor that owns this texture.
     */
    inheritTextureFromAncestor(texture: THREE.DataTexture, region: SeismicPlaneRegion) {
        this.disposeOwnedTexture();
        this._textureIsOwned = false;
        this.texture = texture;
        this.material.uniforms.signalTexture.value = texture;
        this.material.visible = true;

        SeismicPlaneRegion.getOffsetScale(region, this.region, this.material.uniforms.offsetScale.value);
    }

    /**
     * @param {THREE.Texture} texture The texture.
     * @param {SeismicPlaneRegion} region The region of the texture.
     */
    setOwnTexture(texture: THREE.DataTexture, region: SeismicPlaneRegion) {
        this.disposeOwnedTexture();
        this._textureIsOwned = true;
        this.textureRegion = region;
        this.texture = texture;
        this.material.uniforms.signalTexture.value = texture;
        SeismicPlaneRegion.getOffsetScale(this.textureRegion, this.region, this.material.uniforms.offsetScale.value);
        this.material.visible = true;
    }

    get showOutlines() {
        return this.material.defines.ENABLE_OUTLINES === 1;
    }

    set showOutlines(show: boolean) {
        const currentValue = this.material.defines.ENABLE_OUTLINES;

        if (!show && currentValue != null) {
            delete this.material.defines.ENABLE_OUTLINES;
            this.material.needsUpdate = true;
        } else if (show && currentValue == null) {
            this.material.defines.ENABLE_OUTLINES = 1;
            this.material.needsUpdate = true;
        }
    }

    disposeOwnedTexture() {
        // We may not dispose a texture that we merely inherit from an ancestor.
        if (this._textureIsOwned) {
            this.texture?.dispose();
            this.texture = null;
            this._textureIsOwned = false;
        }
    }
}

export function isSeismicPlaneTile(obj: unknown): obj is SeismicPlaneTile {
    return (obj as SeismicPlaneTile).isSeismicPlaneTile;
}

import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useFormik } from 'formik';
import { Integer } from 'types/common';
import { AnnotationGeometry } from 'types/Annotation';
import {
    getLength,
    getElevationDiff,
    getArea,
    getMinMaxAltitudes,
    extractCoordinates,
} from '../../../giro3d_extensions/Measure';
import SettingInput from '../settingsMenu/inputs/SettingInput';

type ValidateResult = {
    submit?: string;
};

type FormValues = {
    x: string;
    y: string;
    z: string;
    submit?: string;
};

export type Props = {
    /**
     * The geometry to display.
     */
    geometry: AnnotationGeometry;
    /**
     * Optional callback to delete a point. If undefined, points cannot be deleted.
     */
    onPointDeleted?: (pointIndex: number) => void;
    /**
     * Optional callback to update a point's coordinates. If undefined, points cannot be moved.
     */
    onPointUpdated?: (pointIndex: number, x: number, y: number, z: number) => void;
};

const GeometryInfo = (props: Props) => {
    const { geometry, onPointDeleted: onDeletePoint, onPointUpdated: onUpdatePoint } = props;

    if (!geometry) {
        return null;
    }

    const coordinates = extractCoordinates(geometry);
    const geometryType = geometry.type;

    const [editIndex, setEditIndex] = useState<Integer>(null);

    const validate = (values: FormValues): ValidateResult => {
        const errors: ValidateResult = {};
        if (values.x === '' || values.y === '' || values.z === '') errors.submit = 'All inputs must be provided';
        return errors;
    };

    const formik = useFormik<FormValues>({
        initialValues: {
            x: '0',
            y: '0',
            z: '0',
        },
        onSubmit: (values) => {
            onUpdatePoint(editIndex, parseFloat(values.x), parseFloat(values.y), parseFloat(values.z));
            setEditIndex(null);
        },
        validate,
    });

    useEffect(
        () => {
            if (geometry == null || coordinates.length === 0 || editIndex == null) {
                return;
            }

            const coord = coordinates[editIndex];

            formik.setValues({
                x: coord.x.toFixed(5),
                y: coord.y.toFixed(5),
                z: coord.z.toFixed(5),
            });
        },
        coordinates.length > 0 && editIndex != null
            ? [coordinates[editIndex].x, coordinates[editIndex].y, coordinates[editIndex].z]
            : [null, null, null]
    );

    const editPoint = (event) => {
        setEditIndex(parseInt(event.currentTarget.id.split('-')[1], 10));
    };

    const inputForm = () => {
        if (editIndex !== null)
            return (
                <>
                    <hr />
                    <form onSubmit={formik.handleSubmit}>
                        <ul className="list coordinates-input">
                            <SettingInput
                                id="x"
                                name="x"
                                title="X"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.x}
                            />
                            <SettingInput
                                id="y"
                                name="y"
                                title="Y"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.y}
                            />
                            <SettingInput
                                id="z"
                                name="z"
                                title="Z"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.z}
                            />
                        </ul>
                        {formik.errors.submit ? <div className="invalid">{formik.errors.submit}</div> : null}

                        <div className="input-row">
                            <button type="button" className="pane-button" onClick={() => setEditIndex(null)}>
                                Cancel
                            </button>
                            <button type="submit" className="pane-button highlight">
                                Submit
                            </button>
                        </div>
                    </form>
                </>
            );
        return null;
    };

    const deletePoint = (event) => {
        const index = parseInt(event.currentTarget.id.split('-')[1], 10);
        onDeletePoint(index);
    };

    const pointCount = coordinates.length;
    const canDelete = pointCount > 2;

    const distances = getLength(geometry);
    const elevationDiffs = getElevationDiff(geometry);
    const totalDistance = distances?.reduce((acc, value) => acc + value, 0);
    const minMax = getMinMaxAltitudes(geometry);
    const elevationRange = minMax[1] - minMax[0];
    const totalArea = geometryType === 'Polygon' ? getArea(geometry) : null;

    const showDistance = (distance: number) =>
        distance < 10000
            ? `${distance < 100 ? distance.toPrecision(3) : distance.toFixed(0)}m`
            : `${(distance / 1000).toFixed(0)}km`;

    const showElevationDiff = (elevation: number) =>
        `${elevation < 10 ? elevation.toPrecision(3) : elevation.toFixed(0)}m`;

    const showArea = (area: number) =>
        area < 1000000 ? `${area < 10 ? area.toPrecision(3) : area.toFixed(0)}m²` : `${(area / 1000000).toFixed(0)}km²`;

    const coord = (i: number, last: boolean) => (
        <tbody key={`selected-points-table-row-${String.fromCharCode(i + 65)}`}>
            <tr>
                <td>
                    <div className="coordinate-label">{last && geometryType !== 'LineString' ? '1' : i + 1}</div>
                </td>
                <td className="coordinate-box coordinate-box-3d">
                    <span className="coordinate-component x">X</span>: {coordinates[i].x.toFixed(3)}
                    <br />
                    <span className="coordinate-component y">Y</span>: {coordinates[i].y.toFixed(3)}
                    <br />
                    <span className="coordinate-component z">Z</span>: {coordinates[i].z.toFixed(3)}
                </td>
                <td className="coordinate-buttons">
                    {onUpdatePoint != null ? (
                        <Button
                            className="borderless green"
                            id={`edit-${i}`}
                            onClick={editPoint}
                            title="Edit point coordinates"
                            onKeyDown={editPoint}
                        >
                            <i className="fas fa-pen" />
                        </Button>
                    ) : null}
                    {onDeletePoint != null ? (
                        <Button
                            className="borderless red"
                            id={`delete-${i}`}
                            onClick={deletePoint}
                            onKeyDown={deletePoint}
                            title="Delete point"
                            disabled={!canDelete}
                        >
                            <i className="fas fa-trash-can" />
                        </Button>
                    ) : null}
                </td>
            </tr>
            <tr>
                {coordinates[i + 1] ? (
                    <>
                        <td className="coordinates-result-tag">┣</td>
                        <td colSpan={2} className="coordinates-result">
                            <span className="coordinates-result">{showDistance(distances[i])}</span>
                            <span className="coordinates-result-neutral"> / </span>
                            <span className="coordinates-result-secondary">{showElevationDiff(elevationDiffs[i])}</span>
                        </td>
                    </>
                ) : null}
            </tr>
        </tbody>
    );

    const fillCoordinateTable = () => {
        if (!geometry) return [];
        const children = [];
        for (let i = 0; i < pointCount; i += 1) {
            children.push(coord(i, pointCount === i + 1));
        }
        return children;
    };

    return (
        <>
            <div>Defined Points</div>
            <div className="grow shrink">
                <table className="coordinates-table" id="selected-points-table">
                    {fillCoordinateTable()}
                </table>
            </div>

            {inputForm()}

            <hr />
            <table>
                <tbody>
                    <tr>
                        <td>Total Distance</td>
                        <td>Area Size</td>
                        <td>Elevation Range</td>
                    </tr>
                    <tr>
                        <td className="coordinates-result">{distances ? showDistance(totalDistance) : 'N/A'}</td>
                        <td className="coordinates-result">{totalArea ? showArea(totalArea) : 'N/A'}</td>
                        <td className="coordinates-result-secondary">
                            {elevationRange !== -Infinity ? showElevationDiff(elevationRange) : 'N/A'}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export default GeometryInfo;

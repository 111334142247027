import { useAppDispatch, useAppSelector } from 'store';
import { DRAWN_TOOL } from 'services/Constants';
import * as drawToolSlice from 'redux/drawTool';
import { cancelTool } from 'redux/actions';

const DisabledDrawTool = () => {
    const dispatch = useAppDispatch();
    const tool = useAppSelector(drawToolSlice.getTool);

    const toolName = () => {
        switch (tool) {
            case DRAWN_TOOL.ANNOTATION:
            case DRAWN_TOOL.QUICK_OBSERVATION:
                return 'annotation tool';
            case DRAWN_TOOL.ELEVATION_PROFILE:
                return 'elevation profile tool';
            case DRAWN_TOOL.MEASURE:
                return 'measurement tool';
            default:
                throw new Error('No tool is active. Check if this component should exist.');
        }
    };

    return (
        <>
            Tool disabled while the {toolName()} is active.
            <button type="button" className="pane-button highlight" onClick={() => dispatch(cancelTool)}>
                Discard Active Tool
            </button>
        </>
    );
};

export default DisabledDrawTool;

import { DatasetId } from 'types/common';
import TabHeader from 'components/flexLayout/TabHeader';
import { useEffect, useRef, useState } from 'react';
import ReactECharts, { EChartsInstance } from 'echarts-for-react';
import { EChartsOption } from 'echarts';
import giro3dService from 'services/Giro3dService';
import LineLayer from 'giro3d_extensions/layers/lines/LineLayer';
import HeaderButton from 'components/flexLayout/HeaderButton';
import { PopoverBody } from 'reactstrap';
import TriState, { STATE } from 'components/dropdown/TriState';
import { useEventBus } from 'EventBus';
import * as datasetsSlice from 'redux/datasets';
import * as layersSlice from 'redux/layers';
import { useAppSelector } from 'store';
import Input from 'components/dropdown/Input';
import Title from 'components/dropdown/Title';
import Button from 'components/dropdown/Button';
import Toggle from 'components/dropdown/Toggle';
import { LAYER_STATES } from 'services/Constants';
import { doLoadDataset } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { hasAttributes, LayerState } from 'types/LayerState';
import Select from 'components/dropdown/Select';
import { Option } from 'components/Select';
import TextInput from 'components/dropdown/TextInput';
import { useMountEffect } from 'components/utils';

type AxisSettings = {
    min?: number;
    max?: number;
    invert: boolean;
    title?: string;
};

type Props = {
    tabId: string;
    config: {
        properties: string[];
        yAxisAttributes: STATE[];
        datasetId: DatasetId;
        zoom?: { start: number; end: number };
        legend?: boolean;
        axes?: AxisSettings[];
        xAxisAttribute?: number;
        scatter?: boolean;
        showZoom?: boolean;
        darkMode?: boolean;
        title?: string;
    };
};

function getDefaultProperty(layer: LayerState) {
    if (!hasAttributes(layer)) return undefined;
    if (layer.activeAttribute) return layer.activeAttribute;
    return undefined;
}

const ChartMenu = (props: Props) => {
    const { tabId, config } = props;

    const eventBus = useEventBus();
    const dispatch = useDispatch();

    const [data, setData] = useState<number[][]>([]);
    const [attributes, setAttributes] = useState<string[]>();
    const properties: string[] = config.properties ?? [];

    const dataset = useAppSelector(datasetsSlice.get(config.datasetId));
    const layer = useAppSelector(layersSlice.get(config.datasetId));
    const selectedProperty = getDefaultProperty(layer);

    useMountEffect(() => {
        if (dataset.state !== LAYER_STATES.LOADED && dataset.state === LAYER_STATES.ACTIVE)
            doLoadDataset(dispatch, dataset);
    });

    const chartRef = useRef(null);
    const containerRef = useRef(null);

    // Resize handler for forcing charts update
    const handleResize = () => {
        const echartsInstance = chartRef.current?.getEchartsInstance();
        if (echartsInstance) echartsInstance.resize();
    };
    useEffect(() => {
        if (containerRef.current) {
            const resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(containerRef.current);
            return resizeObserver.disconnect;
        }
        return undefined;
    }, []);

    useEffect(handleResize, [config]);

    function ensureUnique(property: string, extantProperties: string[]): string {
        if (extantProperties.includes(property)) return ensureUnique(`${property}_`, extantProperties);
        return property;
    }

    const generateChartValues = async () => {
        const instanceLayer = (await giro3dService.getLayersForDataset(config.datasetId)[0]) as LineLayer;
        const geometry = await instanceLayer.getSource().getGeometries();
        const propertiesMap = await instanceLayer.getSource().getPropertyValues();
        const propertiesArray = propertiesMap ? Array.from(propertiesMap.keys()) : [];

        if ((!properties || Object.keys(properties).length === 0) && propertiesArray.length !== 0) {
            const yAxisAttributes = new Array(propertiesArray.length + 5).fill(STATE.CENTRE);
            if (selectedProperty) yAxisAttributes[propertiesArray.indexOf(selectedProperty) + 5] = STATE.LEFT;
            yAxisAttributes[5] = STATE.LEFT;

            eventBus.dispatch('update-dataset-config-pane', {
                tabId,
                data: {
                    ...config,
                    // @ts-expect-error datasetId exists in config
                    properties: [
                        ensureUnique('Sample', propertiesArray),
                        ensureUnique('Distance', propertiesArray),
                        ensureUnique('X', propertiesArray),
                        ensureUnique('Y', propertiesArray),
                        ensureUnique('Z', propertiesArray),
                    ].concat(propertiesArray),
                    yAxisAttributes,
                },
            });
        }

        const sampleCount = geometry ? geometry[0].points.buffer.length / 3 : 0;

        const chartData = [];
        const allProperties = propertiesMap ? Array.from(propertiesMap.values()).map((a) => a[0]) : [];

        for (let i = 0; i < sampleCount; i++) {
            const x = geometry[0].points.buffer[i * 3];
            const y = geometry[0].points.buffer[i * 3 + 1];
            const z = geometry[0].points.buffer[i * 3 + 2];

            const d = Math.sqrt(
                (x - geometry[0].points.buffer[(i - 1) * 3]) ** 2 +
                    (y - geometry[0].points.buffer[(i - 1) * 3 + 1]) ** 2 +
                    (z - geometry[0].points.buffer[(i - 1) * 3 + 2]) ** 2
            );

            chartData[i] = [
                i,
                i !== 0 ? chartData[i - 1][1] + d : 0,
                geometry[0].origin.x + x,
                geometry[0].origin.y + y,
                geometry[0].origin.z + z,
                ...allProperties.map((row) => row[i]),
            ];
        }

        setData(chartData);
        setAttributes(
            [
                ensureUnique('Sample', propertiesArray),
                ensureUnique('Distance', propertiesArray),
                ensureUnique('X', propertiesArray),
                ensureUnique('Y', propertiesArray),
                ensureUnique('Z', propertiesArray),
            ].concat(propertiesArray)
        );
    };

    useEffect(() => {
        if (dataset.state === LAYER_STATES.LOADED) generateChartValues();
    }, [dataset.state]);

    const setPropertySide = (side: STATE, propertyIndex: number) => {
        const newConfig = { ...props.config };
        newConfig.yAxisAttributes[propertyIndex] = side;

        eventBus.dispatch('update-dataset-config-pane', {
            tabId,
            data: newConfig,
        });
    };

    const setLegend = (open: boolean) => {
        const newProperties = { ...properties };
        const newConfig = { properties: newProperties, ...props.config };
        newConfig.legend = open;

        eventBus.dispatch('update-dataset-config-pane', {
            tabId,
            data: newConfig,
        });
    };

    const setScatter = (scatter: boolean) => {
        const newProperties = { ...properties };
        const newConfig = { properties: newProperties, ...props.config };
        newConfig.scatter = scatter;

        eventBus.dispatch('update-dataset-config-pane', {
            tabId,
            data: newConfig,
        });
    };

    const setShowZoom = (show: boolean) => {
        const newProperties = { ...properties };
        const newConfig = { properties: newProperties, ...props.config };
        newConfig.showZoom = show;

        eventBus.dispatch('update-dataset-config-pane', {
            tabId,
            data: newConfig,
        });
    };

    const setDarkMode = (dark: boolean) => {
        const newProperties = { ...properties };
        const newConfig = { properties: newProperties, ...props.config };
        newConfig.darkMode = dark;

        eventBus.dispatch('update-dataset-config-pane', {
            tabId,
            data: newConfig,
        });
    };

    const dataZoom = [];
    if (config.showZoom === undefined || config.showZoom)
        dataZoom.push({
            type: 'slider', // Slider zooming
            xAxisIndex: 0,
            start: config.zoom?.start ?? 0,
            end: config.zoom?.end ?? 100,
            height: 15,
            filterMode: 'empty',
            minSpan: 0.1,
            bottom: 15,
        });
    dataZoom.push({
        type: 'inside', // Mouse wheel zooming
        xAxisIndex: 0,
        filterMode: 'empty',
        minSpan: 0.1,
    });

    const options: EChartsOption = {
        // darkMode: true,
        animation: false,
        grid: {
            containLabel: true,
            top: config.title ? 0 : 10,
            bottom:
                5 +
                (config.axes && config.axes[2].title ? 10 : 0) +
                (config.showZoom === undefined || config.showZoom ? 30 : 0),
            left: config.axes && config.axes[0].title ? 5 : 10,
            right: config.axes && config.axes[1].title ? 5 : 10,
        },
        tooltip:
            config.darkMode === undefined || config.darkMode === true
                ? {
                      trigger: config.scatter ? 'item' : 'axis',
                      transitionDuration: 0,
                      backgroundColor: '#242a2d', // --background
                      borderColor: '#30383b', // --foreground
                      borderWidth: 2,
                      textStyle: { color: '#eeeeee' }, // --text-high-emphasis
                  }
                : {
                      trigger: config.scatter ? 'item' : 'axis',
                      transitionDuration: 0,
                      backgroundColor: '#f2f2f2', // --light-gray-3
                      borderColor: '#c3c3c3', // --light-gray-0
                      borderWidth: 2,
                      textStyle: { color: '#777' },
                  },
        dataZoom,
        dataset: {
            source: data,
        },
        xAxis: {
            type: 'value',
            max(value) {
                return Math.ceil(value.max);
            },
            min(value) {
                return Math.floor(value.min);
            },
            inverse: config.axes ? config.axes[2].invert : undefined,
            name: config.axes && config.axes[2].title,
            nameLocation: 'middle',
            nameGap: 20,
        },
        yAxis: [
            {
                type: 'value',
                min: config.axes ? config.axes[0].min : undefined,
                max: config.axes ? config.axes[0].max : undefined,
                inverse: config.axes ? config.axes[0].invert : undefined,
            },
            {
                type: 'value',
                splitLine: {
                    show: true,
                    lineStyle: { opacity: 0.5 },
                },
                min: config.axes ? config.axes[1].min : undefined,
                max: config.axes ? config.axes[1].max : undefined,
                inverse: config.axes ? config.axes[1].invert : undefined,
            },
        ],
        series: properties.map((property, index) => {
            return {
                type: config.scatter ? 'scatter' : 'line',
                symbolSize: 2,
                name: property,
                // echarts doesnt like to disable series so we just provide no data to hide
                encode:
                    config.yAxisAttributes[index] === STATE.CENTRE
                        ? { x: -1, y: -1 }
                        : { x: config.xAxisAttribute ?? 0, y: index },
                seriesLayoutBy: 'column',
                large: true,
                showSymbol: false,
                yAxisIndex: config.yAxisAttributes[index] === STATE.RIGHT ? 1 : 0,
            };
        }),
    };

    const showMarker = (params) => {
        const heights = (data[params.dataIndex] as number[]).slice(3);
        const datasetIndex = heights.indexOf(Math.max(...heights.filter((h) => h !== null)));
        if (datasetIndex !== -1) {
            giro3dService.updateCoordinates(
                {
                    point: {
                        x: data[params.dataIndex][2],
                        y: data[params.dataIndex][3],
                        z: data[params.dataIndex][4],
                    },
                    picked: true,
                },
                true
            );
        }
    };

    // Use a timeout to reduce updates during zooming
    const zoomTimeout = useRef(null);
    const updateZoom = (params) => {
        if (zoomTimeout.current) clearTimeout(zoomTimeout.current);

        zoomTimeout.current = setTimeout(() => {
            const newConfig = { ...config };
            newConfig.zoom = { start: params.start || params.batch[0].start, end: params.end || params.batch[0].end };
            eventBus.dispatch('update-dataset-config-pane', { tabId, data: newConfig });
        }, 1000);
    };

    useEffect(() => {
        if (chartRef.current) {
            const chartInstance = chartRef.current.getEchartsInstance();
            chartInstance.on('showTip', showMarker);
            chartInstance.on('dataZoom', updateZoom);
        }
        return () => {
            if (chartRef.current) {
                const chartInstance: EChartsInstance = chartRef.current.getEchartsInstance();
                chartInstance.off('showTip', showMarker);
                chartInstance.off('dataZoom', updateZoom);
            }
        };
    }, [data, config]);

    const Legend = () => {
        const echartsInstance = chartRef.current?.getEchartsInstance() as EChartsInstance;
        const colors = echartsInstance.getOption().color;
        const series = echartsInstance.getOption().series;

        let colorIndex = 0;

        return (
            <ul className="legends">
                {properties.map((property, index) => {
                    let color;
                    const propertySeries = series.find((s) => s.name === property);
                    if (propertySeries.color) color = propertySeries.color;
                    else {
                        color = colors[colorIndex % colors.length];
                        colorIndex += 1;
                    }

                    return (
                        <li
                            key={property}
                            hidden={config.yAxisAttributes[index] === STATE.CENTRE}
                            onMouseEnter={() =>
                                echartsInstance.dispatchAction({ type: 'highlight', seriesName: property })
                            }
                            onFocus={() => echartsInstance.dispatchAction({ type: 'highlight', seriesName: property })}
                            onMouseLeave={() =>
                                echartsInstance.dispatchAction({ type: 'downplay', seriesName: property })
                            }
                            onBlur={() => echartsInstance.dispatchAction({ type: 'downplay', seriesName: property })}
                            style={{ pointerEvents: 'all' }}
                        >
                            {config.yAxisAttributes[index] === STATE.LEFT ? (
                                <div>
                                    <i className="fas fa-circle" style={{ color }} />
                                    {property}
                                </div>
                            ) : (
                                <div className="right">
                                    {property}
                                    <i className="fas fa-circle" style={{ color }} />
                                </div>
                            )}
                        </li>
                    );
                })}
            </ul>
        );
    };

    const setXAxis = (attributeIndex: number) => {
        const newConfig = { ...props.config, xAxisAttribute: attributeIndex || 0 };
        eventBus.dispatch('update-dataset-config-pane', {
            tabId,
            data: newConfig,
        });
    };

    const setAxisMinMax = (axis: number, min: number, max: number) => {
        const newAxes = props.config.axes
            ? [props.config.axes[0], props.config.axes[1], props.config.axes[2]]
            : [{ invert: false }, { invert: false }, { invert: false }];
        const newConfig = { axes: newAxes, ...props.config };
        newConfig.axes[axis].min = min;
        newConfig.axes[axis].max = max;

        eventBus.dispatch('update-dataset-config-pane', {
            tabId,
            data: newConfig,
        });
    };

    const setAxisInvert = (axis: number, invert: boolean) => {
        const newAxes = props.config.axes
            ? [props.config.axes[0], props.config.axes[1], props.config.axes[2]]
            : [{ invert: false }, { invert: false }, { invert: false }];
        const newConfig = { axes: newAxes, ...props.config };
        newConfig.axes[axis].invert = invert;

        eventBus.dispatch('update-dataset-config-pane', {
            tabId,
            data: newConfig,
        });
    };

    const setAxisTitle = (axis: number, title: string) => {
        const newAxes = props.config.axes
            ? [props.config.axes[0], props.config.axes[1], props.config.axes[2]]
            : [{ invert: false }, { invert: false }, { invert: false }];
        const newConfig = { axes: newAxes, ...props.config };
        newConfig.axes[axis].title = title;

        eventBus.dispatch('update-dataset-config-pane', {
            tabId,
            data: newConfig,
        });
    };

    const setTitle = (title: string) => {
        const newProperties = { ...properties };
        const newConfig = { properties: newProperties, ...props.config };
        newConfig.title = title;

        eventBus.dispatch('update-dataset-config-pane', {
            tabId,
            data: newConfig,
        });
    };
    const attributeOptions = attributes?.map((a) => {
        return { label: a, value: a };
    });

    return (
        <>
            <TabHeader
                left={
                    <>
                        <HeaderButton
                            popover={{
                                name: 'Toggle Properties',
                                icon: 'fas fa-chart-line',
                                content: (
                                    <PopoverBody>
                                        <ul>
                                            {properties.map((property, index) => (
                                                <TriState
                                                    key={property}
                                                    title={property}
                                                    state={config.yAxisAttributes[index]}
                                                    onChange={(v) => setPropertySide(v, index)}
                                                    disabled={index === (config.xAxisAttribute ?? 0)}
                                                />
                                            ))}
                                        </ul>
                                    </PopoverBody>
                                ),
                            }}
                        />
                        <HeaderButton
                            popover={{
                                name: 'Axis Settings',
                                icon: 'fas fa-axe',
                                content: (
                                    <PopoverBody>
                                        <ul>
                                            <TextInput
                                                value={config.title ?? ''}
                                                title="Chart Title"
                                                onChange={(title) => setTitle(title)}
                                            />
                                            <hr />
                                            <Title title="X Axis" />
                                            <TextInput
                                                value={config.axes ? config.axes[2].title : ''}
                                                title="Title"
                                                onChange={(title) => setAxisTitle(2, title)}
                                            />
                                            <Select
                                                title="Attribute"
                                                options={attributeOptions}
                                                value={
                                                    attributeOptions
                                                        ? attributeOptions[config.xAxisAttribute ?? 0]
                                                        : null
                                                }
                                                onChange={(value) =>
                                                    setXAxis(attributeOptions.indexOf(value as Option<string>))
                                                }
                                            />
                                            <Toggle
                                                checked={config.axes ? config.axes[2].invert : false}
                                                title="Invert"
                                                onChange={(checked) => setAxisInvert(2, checked)}
                                            />
                                            <Toggle
                                                checked={config.showZoom === undefined ? true : config.showZoom}
                                                title="Show Zoom Bar"
                                                onChange={(checked) => setShowZoom(checked)}
                                            />
                                            <hr />
                                            <Title title="Left Axis" />
                                            <TextInput
                                                value={config.axes ? config.axes[0].title : ''}
                                                title="Title"
                                                onChange={(title) => setAxisTitle(0, title)}
                                            />
                                            <Button
                                                title="Reset"
                                                icon="fas fa-arrow-rotate-left"
                                                onClick={() => {
                                                    setAxisMinMax(0, undefined, undefined);
                                                    setAxisInvert(0, false);
                                                }}
                                            />
                                            <Input
                                                value={config.axes ? config.axes[0].min : undefined}
                                                step={1}
                                                title="Min"
                                                onChange={(v: number) => {
                                                    setAxisMinMax(0, v, config.axes ? config.axes[0].max : undefined);
                                                }}
                                            />
                                            <Input
                                                value={config.axes ? config.axes[0].max : undefined}
                                                step={1}
                                                title="Max"
                                                onChange={(v: number) => {
                                                    setAxisMinMax(0, config.axes ? config.axes[0].min : undefined, v);
                                                }}
                                            />
                                            <Toggle
                                                checked={config.axes ? config.axes[0].invert : false}
                                                title="Invert"
                                                onChange={(checked) => setAxisInvert(0, checked)}
                                            />
                                            <hr />
                                            <Title title="Right Axis" />
                                            <TextInput
                                                value={config.axes ? config.axes[1].title : ''}
                                                title="Title"
                                                onChange={(title) => setAxisTitle(1, title)}
                                            />
                                            <Button
                                                title="Reset"
                                                icon="fas fa-arrow-rotate-left"
                                                onClick={() => {
                                                    setAxisMinMax(1, undefined, undefined);
                                                    setAxisInvert(1, false);
                                                }}
                                            />
                                            <Input
                                                value={config.axes ? config.axes[1].min : undefined}
                                                step={1}
                                                title="Min"
                                                onChange={(v: number) => {
                                                    setAxisMinMax(1, v, config.axes ? config.axes[1].max : undefined);
                                                }}
                                            />
                                            <Input
                                                value={config.axes ? config.axes[1].max : undefined}
                                                step={1}
                                                title="Max"
                                                onChange={(v: number) => {
                                                    setAxisMinMax(1, config.axes ? config.axes[1].min : undefined, v);
                                                }}
                                            />
                                            <Toggle
                                                checked={config.axes ? config.axes[1].invert : false}
                                                title="Invert"
                                                onChange={(checked) => setAxisInvert(1, checked)}
                                            />
                                            <hr />
                                            <Toggle
                                                checked={config.darkMode === undefined ? true : config.darkMode}
                                                title="Dark Mode"
                                                onChange={(checked) => setDarkMode(checked)}
                                            />
                                        </ul>
                                    </PopoverBody>
                                ),
                            }}
                        />
                        <HeaderButton
                            toggle={{
                                name: 'Show Legend',
                                icon: 'fas fa-list-ul',
                                checked: config.legend,
                                onChange: (v) => setLegend(v),
                            }}
                        />
                        <HeaderButton
                            toggle={{
                                name: 'Scatter Mode',
                                icon: 'fas fa-chart-scatter',
                                checked: config.scatter,
                                onChange: (v) => setScatter(v),
                            }}
                        />
                    </>
                }
                center={undefined}
                right={undefined}
            />
            <div
                className={`chartContainer ${config.darkMode === undefined || config.darkMode ? '' : 'light'}`}
                ref={containerRef}
            >
                {config.legend ? <Legend /> : undefined}
                <div>
                    <span>{config.title}</span>
                    <div>
                        <span>{config.axes && config.axes[0].title}</span>
                        <ReactECharts ref={chartRef} option={options} style={{ height: '100%', flexGrow: '1' }} />
                        <span>{config.axes && config.axes[1].title}</span>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ChartMenu;

import type Instance from '@giro3d/giro3d/core/Instance';
import Annotation, { AnnotationGeometry, AnnotationId, AnnotationInteractionState } from 'types/Annotation';
import { DatasetId } from 'types/common';
import { selectAnnotation } from 'redux/annotationActions';
import { Dispatch, RootState } from 'store';
import LayerManager from 'giro3d_extensions/LayerManager';
import HoveredItem from 'types/HoveredItem';
import { ANNOTATION_STYLES } from 'services/Constants';
import Shape from '@giro3d/giro3d/entities/Shape';
import { ShapeManager } from 'services/ShapeManager';
import * as annotationSlice from 'redux/annotations';
import IsClickable from './IsClickable';
import Layer, { HostView } from './Layer';
import IsHoverable from './IsHoverable';
import StateObserver from './StateObserver';

class AnnotationLayer extends Layer implements IsClickable, IsHoverable {
    readonly isClickable = true as const;
    readonly isHoverable = true as const;

    private readonly _observer: StateObserver<RootState>;
    private readonly _shapeManager: ShapeManager;

    private _selected = false;

    private _shape: Shape;

    readonly annotationId: AnnotationId;
    private _currentGeometry: AnnotationGeometry;

    get3dElement() {
        return this._shape.object3d;
    }

    setOpacity(opacity: number): void {
        this._shape.opacity = opacity;
    }

    getOpacity(): number {
        return this._shape.opacity;
    }

    protected doSetVisibility(visible: boolean): Promise<void> {
        this._shape.visible = visible;
        return Promise.resolve();
    }

    constructor(
        datasetId: DatasetId,
        instance: Instance,
        dispatch: Dispatch,
        annotation: Annotation,
        layerManager: LayerManager,
        shapeManager: ShapeManager,
        hostView: HostView,
        observer: StateObserver<RootState>
    ) {
        super({
            instance,
            getFootprint: null,
            datasetId,
            dispatch,
            layerManager,
            hostView,
        });
        this._shapeManager = shapeManager;
        this.settings.sourceFileVisibility = true;
        this.annotationId = annotation.id;

        this._observer = observer;
    }

    // eslint-disable-next-line class-methods-use-this
    protected override subscribeToStateChanges(): void {
        // Nothing to do here
    }

    protected override async initOnce() {
        const geometry = this._observer.select(annotationSlice.getGeometry(this.annotationId));

        this._currentGeometry = geometry;

        const shape = this._shapeManager.createShapeFromGeoJSON(this.annotationId, geometry, {
            datasetId: this.datasetId,
            annotationId: this.annotationId,
        });

        shape.color = ANNOTATION_STYLES.normal.color;
        shape.vertexRadius = ANNOTATION_STYLES.normal.vertexRadius[geometry.type];
        shape.lineWidth = ANNOTATION_STYLES.normal.width;
        shape.borderWidth = ANNOTATION_STYLES.normal.borderWidth;

        this._shape = shape;
        this.initialized = true;
        this.notifyLayerChange();

        this._observer.subscribe(annotationSlice.getGeometry(this.annotationId), (g) => {
            this.updateGeometry(g);
        });
        this._observer.subscribe(annotationSlice.getState(this.annotationId), (state) => {
            this.updateState(state);
        });

        const state = this._observer.select(annotationSlice.getState(this.annotationId));
        this.updateState(state);
    }

    override setThisVisibility(visible: boolean): Promise<void> {
        if (this._shape) {
            if (this._shape.visible !== visible) {
                this._shape.visible = visible;
                this._giro3dInstance.notifyChange();
            }
        }
        return Promise.resolve();
    }

    private updateState(state: AnnotationInteractionState | undefined) {
        state = state ?? 'normal';

        const style = ANNOTATION_STYLES[state];

        this._shape.color = style.color;
        this._shape.vertexRadius = style.vertexRadius[this._currentGeometry.type];
        this._shape.lineWidth = style.width;
        this._shape.borderWidth = style.borderWidth;

        // Make the shape appear on top of the others when it is selected or edited.
        // Especially useful for polygons to ensure their surface is not occluded by other polygons.
        this._shape.renderOrder = state === 'selected' || state === 'edited' ? 999 : 0;

        this._shape.showVertexLabels = this._currentGeometry.type !== 'Point' && state === 'edited';
        this._shape.rebuildLabels();

        this._giro3dInstance.notifyChange(this._shape);
    }

    hover(hovered: boolean): void {
        this._dispatch(annotationSlice.hoverAnnotation({ annotation: this.annotationId, hovered }));
    }

    getHoverInfo(): HoveredItem {
        return {
            name: this._observer.select(annotationSlice.get(this.annotationId)).name,
            itemType: 'Annotation',
        };
    }

    removeFromParent() {
        this._shapeManager.deleteShape(this.annotationId);
    }

    protected override onDispose() {
        this._shape = null;
        this._observer.dispose();
        super.onDispose();
    }

    async clickHandler() {
        this._dispatch(selectAnnotation(this.annotationId));
    }

    isSelected() {
        const activeAnnotation = this._observer.select(annotationSlice.active);
        if (!activeAnnotation) {
            return false;
        }
        return activeAnnotation.id === this.annotationId;
    }

    updateGeometry(geometry?: AnnotationGeometry) {
        if (geometry) {
            this._currentGeometry = geometry;
            this._shapeManager.updateShapeFromGeoJSON(this.annotationId, geometry);
        }
    }

    getClickableElement() {
        return this.get3dElement();
    }

    getBoundingBox() {
        if (!this.initialized) return null;

        return this._shape.getBoundingBox();
    }

    deletePoint(pointIndex: number): AnnotationGeometry {
        this._shapeManager.deletePoint(this.annotationId, pointIndex);
        return this._shapeManager.getGeoJSON(this._shape);
    }

    updatePoint(pointIndex: number, x: number, y: number, z: number): AnnotationGeometry {
        this._shapeManager.updatePoint(this.annotationId, pointIndex, x, y, z);
        return this._shapeManager.getGeoJSON(this._shape);
    }
}

export default AnnotationLayer;

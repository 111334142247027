import Annotation, { AnnotationId } from 'types/Annotation';
import { Dispatch, GetState } from 'store';
import DosApi from 'services/DosApi';
import * as annotationSlice from 'redux/annotations';
import * as giro3dSlice from 'redux/giro3d';
import { useEventBus } from 'EventBus';
import { Box3, Vector3 } from 'three';

// Note: the code of this file was originally in actions.ts,
// but actions.ts imports the Giro3D service, which makes it untestable
// in a unit test environment.

export function selectAnnotationById(id?: AnnotationId) {
    return async (dispatch: Dispatch, getState: GetState) => {
        const annotations: Annotation[] = getState().annotations.list;
        const annotation = annotations.find((ann) => ann.id === id);

        if (annotation) {
            // Only select if not in edit mode
            await dispatch(updateCommentsList(annotation));
        }
        return dispatch(annotationSlice.setActiveAnnotation(annotation?.id));
    };
}

export function updateCommentsList(annotation: Annotation) {
    return async (dispatch: Dispatch) => {
        if (!annotation) return null;
        return DosApi.fetchComments(annotation.project_id, annotation.id).then((data) =>
            dispatch(annotationSlice.setCommentList({ annotation: annotation.id, comments: data }))
        );
    };
}

export function selectAnnotation(annotationId?: AnnotationId) {
    return async (dispatch: Dispatch, getState: GetState) => {
        // Only select if not in edit mode
        await dispatch(updateCommentsList(annotationSlice.get(annotationId)(getState())));
        if (annotationId) dispatch(giro3dSlice.setSelection({ objectId: annotationId, type: 'annotation' }));
        return dispatch(annotationSlice.setActiveAnnotation(annotationId));
    };
}

function generateBbox(coordinates) {
    // Initialize the min and max values to extreme values
    let minLon = Infinity;
    let minLat = Infinity;
    let minAlt = Infinity;
    let maxLon = -Infinity;
    let maxLat = -Infinity;
    let maxAlt = -Infinity;

    const PADDING = 1;

    // Helper function to process each coordinate array recursively
    function processCoordinates(coords) {
        if (typeof coords[0] === 'number' && coords.length === 3) {
            // It's a 3-value array (lon, lat, alt)
            const [lon, lat, alt] = coords;
            if (lon - PADDING < minLon) minLon = lon - PADDING;
            if (lat - PADDING < minLat) minLat = lat - PADDING;
            if (alt - PADDING < minAlt) minAlt = alt - PADDING;
            if (lon + PADDING > maxLon) maxLon = lon + PADDING;
            if (lat + PADDING > maxLat) maxLat = lat + PADDING;
            if (alt + PADDING > maxAlt) maxAlt = alt + PADDING;
        } else if (Array.isArray(coords)) {
            // Recursively process each sub-array
            coords.forEach(processCoordinates);
        }
    }

    // Start processing the coordinates
    processCoordinates(coordinates);

    // Return the 3D bounding box
    return [minLon, minLat, minAlt, maxLon, maxLat, maxAlt];
}

export function gotoAnnotation(annotation: Annotation) {
    return async () => {
        const minMax = generateBbox(annotation.geometry.coordinates);

        useEventBus().dispatch('go-to-bbox', {
            bbox: new Box3(new Vector3(minMax[0], minMax[1], minMax[2]), new Vector3(minMax[3], minMax[4], minMax[5])),
        });
    };
}

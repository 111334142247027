import chroma, { type Scale } from 'chroma-js';
import { Color } from 'three';
import {
    CYCLE_SCALE,
    SEISMIC_SCALE,
    LANDMARK_SCALE,
    KLEIN_SCALE,
    COPPER_SCALE,
    GRAYSCALE_SCALE,
    C_THERMAL_SCALE,
    C_HALINE_SCALE,
    C_SOLAR_SCALE,
    C_ICE_SCALE,
    C_OXY_SCALE,
    C_DENSE_SCALE,
    C_ALGAE_SCALE,
    C_DEEP_SCALE,
    C_MATTER_SCALE,
    C_TURBID_SCALE,
    C_SPEED_SCALE,
    C_AMP_SCALE,
    C_TEMPO_SCALE,
    C_RAIN_SCALE,
    C_PHASE_SCALE,
    C_TOPO_SCALE,
    C_BALANCE_SCALE,
    C_DELTA_SCALE,
    C_CURL_SCALE,
    C_DIFF_SCALE,
    C_TARNN_SCALE,
} from './ColorMapScales';
import { Curve } from './Curve';

// Colormaps starting C_ are from cmocean https://matplotlib.org/cmocean/

export enum COLORMAP_NAMES {
    C_DEEP = 'Deep',
    SPECTRAL = 'Spectral',
    C_PHASE = 'Phase',
    GRAYSCALE = 'Grayscale',

    VIRIDIS = 'Viridis',
    YLORRD = 'YlOrRd',
    RDBU = 'RdBu',
    ACCENT = 'Accent',
    PASTEL1 = 'Pastel1',
    SEISMIC = 'Seismic',
    LANDMARK = 'Landmark',
    CYCLE = 'Aspect',
    COPPER = 'Copper',
    KLEIN = 'Klein',

    C_THERMAL = 'Thermal',
    C_HALINE = 'Haline',
    C_SOLAR = 'Solar',
    C_ICE = 'Ice',
    C_OXY = 'Oxy',
    C_DENSE = 'Dense',
    C_ALGAE = 'Algae',
    C_MATTER = 'Matter',
    C_TURBID = 'Turbid',
    C_SPEED = 'Speed',
    C_AMP = 'Amp',
    C_TEMPO = 'Tempo',
    C_RAIN = 'Rain',
    C_TOPO = 'Topo',
    C_BALANCE = 'Balance',
    C_DELTA = 'Delta',
    C_CURL = 'Curl',
    C_DIFF = 'Diff',
    C_TARN = 'Tarn',
}

export const COLORMAP_GROUPS = {
    'Base': [
        COLORMAP_NAMES.SPECTRAL,
        COLORMAP_NAMES.GRAYSCALE,
        COLORMAP_NAMES.VIRIDIS,
        COLORMAP_NAMES.YLORRD,
        COLORMAP_NAMES.RDBU,
        COLORMAP_NAMES.ACCENT,
        COLORMAP_NAMES.PASTEL1,
        COLORMAP_NAMES.SEISMIC,
        COLORMAP_NAMES.LANDMARK,
        COLORMAP_NAMES.CYCLE,
        COLORMAP_NAMES.COPPER,
        COLORMAP_NAMES.KLEIN,
    ],
    'cmocean': [
        COLORMAP_NAMES.C_DEEP,
        COLORMAP_NAMES.C_PHASE,
        COLORMAP_NAMES.C_THERMAL,
        COLORMAP_NAMES.C_HALINE,
        COLORMAP_NAMES.C_SOLAR,
        COLORMAP_NAMES.C_ICE,
        COLORMAP_NAMES.C_OXY,
        COLORMAP_NAMES.C_DENSE,
        COLORMAP_NAMES.C_ALGAE,
        COLORMAP_NAMES.C_MATTER,
        COLORMAP_NAMES.C_TURBID,
        COLORMAP_NAMES.C_SPEED,
        COLORMAP_NAMES.C_AMP,
        COLORMAP_NAMES.C_TEMPO,
        COLORMAP_NAMES.C_RAIN,
        COLORMAP_NAMES.C_TOPO,
        COLORMAP_NAMES.C_BALANCE,
        COLORMAP_NAMES.C_DELTA,
        COLORMAP_NAMES.C_CURL,
        COLORMAP_NAMES.C_DIFF,
        COLORMAP_NAMES.C_TARN,
    ],
};

export enum COLORMAP_BOUNDSMODE {
    DATA = 'data',
    CUSTOM = 'custom',
}

export type SerializedColorMap = {
    name: COLORMAP_NAMES;
    colors: string[];
    colorsSetps: number[];
    discrete: boolean;
    invert: boolean;
    discreteNumberOfColors: number;
    representation: string;
    dataMin: number;
    dataMax: number;
    customMin: number;
    customMax: number;
    boundsMode: COLORMAP_BOUNDSMODE;
    dataZScale: number;
    dataZOffset: number;
    pinned: boolean;
};

type ColorMap = {
    boundsMode: COLORMAP_BOUNDSMODE;
    customMin: number;
    customMax: number;
    name: COLORMAP_NAMES;
    invert: boolean;
    discrete: boolean;
    opacityCurve?: Curve;
};

export function getLUT(colorMap: ColorMap, options?: { samples?: number }): Color[] {
    let scale: Scale;

    switch (colorMap.name) {
        case COLORMAP_NAMES.SPECTRAL:
        case COLORMAP_NAMES.VIRIDIS:
        case COLORMAP_NAMES.YLORRD:
        case COLORMAP_NAMES.RDBU:
        case COLORMAP_NAMES.ACCENT:
        case COLORMAP_NAMES.PASTEL1:
            scale = chroma.scale(colorMap.name);
            break;
        case COLORMAP_NAMES.SEISMIC:
            scale = SEISMIC_SCALE;
            break;
        case COLORMAP_NAMES.LANDMARK:
            scale = LANDMARK_SCALE;
            break;
        case COLORMAP_NAMES.CYCLE:
            // cyclical scale for aspect mode
            scale = CYCLE_SCALE;
            break;
        case COLORMAP_NAMES.COPPER:
            scale = COPPER_SCALE;
            break;
        case COLORMAP_NAMES.KLEIN:
            scale = KLEIN_SCALE;
            break;
        case COLORMAP_NAMES.C_THERMAL:
            scale = C_THERMAL_SCALE;
            break;
        case COLORMAP_NAMES.C_HALINE:
            scale = C_HALINE_SCALE;
            break;
        case COLORMAP_NAMES.C_SOLAR:
            scale = C_SOLAR_SCALE;
            break;
        case COLORMAP_NAMES.C_ICE:
            scale = C_ICE_SCALE;
            break;
        case COLORMAP_NAMES.C_OXY:
            scale = C_OXY_SCALE;
            break;
        case COLORMAP_NAMES.C_DEEP:
            scale = C_DEEP_SCALE;
            break;
        case COLORMAP_NAMES.C_DENSE:
            scale = C_DENSE_SCALE;
            break;
        case COLORMAP_NAMES.C_ALGAE:
            scale = C_ALGAE_SCALE;
            break;
        case COLORMAP_NAMES.C_MATTER:
            scale = C_MATTER_SCALE;
            break;
        case COLORMAP_NAMES.C_TURBID:
            scale = C_TURBID_SCALE;
            break;
        case COLORMAP_NAMES.C_SPEED:
            scale = C_SPEED_SCALE;
            break;
        case COLORMAP_NAMES.C_AMP:
            scale = C_AMP_SCALE;
            break;
        case COLORMAP_NAMES.C_TEMPO:
            scale = C_TEMPO_SCALE;
            break;
        case COLORMAP_NAMES.C_RAIN:
            scale = C_RAIN_SCALE;
            break;
        case COLORMAP_NAMES.C_PHASE:
            scale = C_PHASE_SCALE;
            break;
        case COLORMAP_NAMES.C_TOPO:
            scale = C_TOPO_SCALE;
            break;
        case COLORMAP_NAMES.C_BALANCE:
            scale = C_BALANCE_SCALE;
            break;
        case COLORMAP_NAMES.C_DELTA:
            scale = C_DELTA_SCALE;
            break;
        case COLORMAP_NAMES.C_CURL:
            scale = C_CURL_SCALE;
            break;
        case COLORMAP_NAMES.C_DIFF:
            scale = C_DIFF_SCALE;
            break;
        case COLORMAP_NAMES.C_TARN:
            scale = C_TARNN_SCALE;
            break;
        case COLORMAP_NAMES.GRAYSCALE:
        default:
            scale = GRAYSCALE_SCALE;
            break;
    }
    const actualSamples = colorMap.discrete ? 8 : options?.samples ?? 256;

    let lut = scale
        .mode('lab')
        .colors(actualSamples)
        .map((c) => {
            const rgb = chroma(c).gl();
            return new Color().setRGB(rgb[0], rgb[1], rgb[2], 'srgb');
        });

    if ((colorMap.name === COLORMAP_NAMES.SPECTRAL) !== colorMap.invert) lut = lut.reverse();

    return lut;
}

export default ColorMap;

import { Unsubscribe } from '@reduxjs/toolkit';

type Selector<T = unknown, S = unknown> = (state: S) => T;

type Listener = {
    selector: Selector;
    callback: (v: unknown) => void;
    equalityFn?: (left: unknown, right: unknown) => boolean;
};

function isObject(object: unknown) {
    return object != null && typeof object === 'object';
}

interface Equals {
    equals(other: this): boolean;
}

function deepObjectEquals(lhs: object, rhs: object): boolean {
    if (!lhs && !rhs) {
        return true;
    }
    if (!lhs || !rhs) {
        return false;
    }

    const keys1 = Object.keys(lhs);
    const keys2 = Object.keys(rhs);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = lhs[key];
        const val2 = rhs[key];
        if (Array.isArray(val1) && Array.isArray(val2)) {
            if (!arrayEqualityFn(val1, val2)) {
                return false;
            }
        } else if (isObject(val1) && isObject(val2)) {
            // Some classes in THREE.js expose an equals() method that we may use
            if ('equals' in val1) {
                if (!(val1 as Equals).equals(val2)) {
                    return false;
                }
            } else if (!deepObjectEquals(val1, val2)) {
                return false;
            }
        }
        // primitive comparison
        else if (val1 !== val2) {
            return false;
        }
    }

    return true;
}

function deepEquals(lhs: unknown, rhs: unknown): boolean {
    if (typeof lhs === 'object' && typeof rhs === 'object') {
        return deepObjectEquals(lhs, rhs);
    }
    return lhs === rhs;
}

function arrayEqualityFn(lhs: unknown[], rhs: unknown[]): boolean {
    for (let i = 0; i < lhs.length; i++) {
        const l = lhs[i];
        const r = rhs[i];
        if (!deepEquals(r, l)) {
            return false;
        }
    }

    return true;
}

function defaultEqualityFn(lhs: unknown, rhs: unknown): boolean {
    if (Array.isArray(lhs) && Array.isArray(rhs)) {
        if (lhs.length === rhs.length) {
            return arrayEqualityFn(lhs, rhs);
        }
        return false;
    }
    return deepEquals(lhs, rhs);
}

/**
 * Observes redux state changes.
 */
export default class StateObserver<S> {
    private readonly _listeners: Listener[] = [];
    private readonly _unsubscribe: Unsubscribe;
    private readonly _getState: () => S;

    private _previousState: S;
    private _disposed = false;

    constructor(getState: () => S, subscribe: (listener: () => void) => Unsubscribe) {
        this._getState = getState;
        this._previousState = getState();
        this._unsubscribe = subscribe(() => this.onStateChanged());
    }

    private onStateChanged() {
        const oldState = this._previousState;
        const newState = this._getState();

        for (const { selector, callback, equalityFn } of this._listeners) {
            const oldValue = selector(oldState);
            const newValue = selector(newState);

            let areEqual: boolean;

            if (equalityFn) {
                areEqual = equalityFn(oldValue, newValue);
            } else {
                areEqual = defaultEqualityFn(oldValue, newValue);
            }

            if (!areEqual) {
                callback(newValue);
            }
        }

        this._previousState = newState;
    }

    /**
     * Triggers all listeners, regardless of the actual change in the state.
     * Useful to get initial values.
     */
    triggerAllListeners() {
        this.throwIfDisposed();
        const state = this._getState();
        for (const { selector, callback } of this._listeners) {
            const value = selector(state);
            callback(value);
        }
    }

    /**
     * Executes the selector on the current state.
     */
    select<T>(selector: Selector<T, S>): T {
        this.throwIfDisposed();
        return selector(this._getState());
    }

    /**
     * Subscribe to a particular layer state change through the specified selector.
     * @param selector The selector function.
     * @param callback The listener callback. Note: triggered only when the selector value does change.
     * @param equalityFn Optional equality function to compare states.
     */
    subscribe<T>(selector: Selector<T, S>, callback: (v: T) => void, equalityFn?: (left: T, right: T) => boolean) {
        this.throwIfDisposed();
        this._listeners.push({ selector, callback, equalityFn });
    }

    /**
     * Unsubscribes to store changes.
     * Note: this makes the observer not usable anymore.
     */
    dispose() {
        if (this._disposed) {
            return;
        }
        this._disposed = true;
        this._listeners.length = 0;
        this._unsubscribe();
    }

    private throwIfDisposed() {
        if (this._disposed) {
            throw new Error('listener is disposed');
        }
    }
}

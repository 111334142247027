import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { getOrganizations, getUser } from '../../redux/selectors';
import InlineDropMenu from '../InlineDropMenu';
import DeleteCollection from '../overview/collectionsList/DeleteCollection';
import DatasetListItem from './DatasetListItem';

export const COLLECTION_ACTION = {
    CREATE: 'create',
    EDIT: 'edit',
    DELETE: 'delete',
    DATASETS: 'datasets',
    DATASET_ADD: 'dataset/add',
    OPEN: 'open',
};

const CollectionListItem = (props) => {
    const {
        collection,
        datasets,
        setDatasetToDelete,
        setDatasetToInspect,
        setCollectionToEdit,
        filtersFunction,
        sortingFunction,
        searchingFunction,
        hideIfEmpty,
        viewCollection,
        project,
        batchDeleteList,
        addToBatch,
        removeFromBatch,
    } = props;

    const organizations = useSelector(getOrganizations);
    const user = useSelector(getUser);

    const [open, setOpen] = useState(false);
    const renderedTableItems = datasets
        ? datasets
              .filter(filtersFunction)
              .filter(searchingFunction)
              .sort(sortingFunction)
              .map((dataset) => (
                  <DatasetListItem
                      dataset={dataset}
                      setDatasetToDelete={setDatasetToDelete}
                      setDatasetToInspect={setDatasetToInspect}
                      key={dataset.id}
                      project={project}
                      batchDeleting={
                          batchDeleteList === undefined
                              ? undefined
                              : {
                                    list: batchDeleteList,
                                    select: () => addToBatch(dataset.id),
                                    unselect: () => removeFromBatch(dataset.id),
                                }
                      }
                  />
              ))
        : [];

    if (hideIfEmpty && renderedTableItems.length === 0) return null;

    const id = collection?.id || 'null';

    return (
        <>
            <tr className="library-list-group" key={`collection-select-row-${id}`}>
                <td colSpan={project ? 4 : 3}>
                    <label htmlFor={`collection-select-row-${id}-dropdown`} className="dropdown-arrow">
                        <input
                            type="checkbox"
                            id={`collection-select-row-${id}-dropdown`}
                            onChange={(e) => setOpen(e.target.checked)}
                        />
                        <div className="arrow" />
                        <span>
                            {collection?.name || 'No Collection'}
                            <span className="faded-text">({renderedTableItems ? renderedTableItems.length : 0})</span>
                        </span>
                    </label>
                </td>
                <td colSpan={2}>
                    {collection && organizations
                        ? organizations.find((e) => e.id === collection.organization_id)?.display_name
                        : ''}
                </td>
                <td className="library-list-item-buttons">
                    {collection ? (
                        <InlineDropMenu>
                            <div id={`edit-${collection.id}`}>
                                <Button
                                    className="borderless green"
                                    id="add"
                                    title="Edit Collection"
                                    onClick={() => setCollectionToEdit(collection)}
                                    disabled={!collection.user_permissions.update_collection && !user.is_admin}
                                >
                                    <i className="fal fa-pen" />
                                </Button>
                            </div>
                            {!collection.user_permissions.update_collection && !user.is_admin ? (
                                <UncontrolledTooltip target={`edit-${collection.id}`}>
                                    You do not have the permissions to edit this collection.
                                </UncontrolledTooltip>
                            ) : null}
                            <DeleteCollection collection={collection} disabled={datasets.length !== 0} />
                            <Button
                                className="borderless green"
                                id="add"
                                title="Open Collection"
                                onClick={viewCollection}
                            >
                                <i className="fal fa-eye" />
                            </Button>
                        </InlineDropMenu>
                    ) : null}
                </td>
            </tr>
            {open ? renderedTableItems : null}
        </>
    );
};

export default CollectionListItem;

import { Box3, Color, Vector4, Plane } from 'three';

import Tiles3D from '@giro3d/giro3d/entities/Tiles3D';

import PointCloudMaterial, { MODE } from '@giro3d/giro3d/renderer/PointCloudMaterial';
import {
    LayerState,
    Attribute,
    ColoringMode,
    HasAttributes,
    WellKnownAttributeNames,
    IsPointCloud,
    HasOpacity,
    HasOverlayColor,
    HasColoringMode,
    HasProgress,
} from 'types/LayerState';
import ColorMap, { COLORMAP_BOUNDSMODE, getLUT } from 'types/ColorMap';
import * as layersSlice from 'redux/layers';
import { Dispatch } from 'store';
import { evaluateCurve } from 'types/Curve';
import HoveredItem from 'types/HoveredItem';
import { isElevationType, LAYER_TYPES, ZSCALE_BEHAVIOR } from '../../../services/Constants';
import Layer, { Settings as BaseSettings, ConstructorParams as BaseConstructorParams, LayerEventMap } from '../Layer';
import LayerStateObserver from '../LayerStateObserver';
import IsHoverable from '../IsHoverable';

export interface Settings extends BaseSettings {
    opacity: number;
    overlayColor: Color;
}

type PointCloudLayerState = LayerState &
    HasProgress &
    HasOpacity &
    IsPointCloud &
    HasOverlayColor &
    HasColoringMode &
    HasAttributes;

export interface ConstructorParams extends BaseConstructorParams {
    defaultAttribute: string;
    /**
     * Giro3d Tiles3D entity
     */
    entity: Tiles3D<PointCloudMaterial>;

    datasetType: LAYER_TYPES;

    readableName: string;

    dispatch: Dispatch;
}

class PointCloudLayer extends Layer<Settings, LayerEventMap, PointCloudLayerState> implements IsHoverable {
    readonly isHoverable = true as const;

    private readonly _entity: Tiles3D<PointCloudMaterial>;
    private readonly _datasetType: LAYER_TYPES;
    private readonly _readableName: string;
    private _attribute: string;

    private _externalMin: number;
    private _externalMax: number;

    constructor(params: ConstructorParams) {
        super(params);
        this._attribute = params.defaultAttribute;
        this._entity = params.entity;
        this._entity.visible = this.getVisibility();
        this.settings.opacity = 1;
        this._entity.material.opacity = this.settings.opacity;
        this.settings.zscaleEffect = ZSCALE_BEHAVIOR.SCALE;
        this._datasetType = params.datasetType;
        this._readableName = params.readableName;

        this.assignInitialValues();
    }

    protected override subscribeToStateChanges(observer: LayerStateObserver<PointCloudLayerState>): void {
        super.subscribeToStateChanges(observer);

        observer.subscribe(layersSlice.getPointCloudSize(this._layerState), (v) => this.setPointSize(v));
        observer.subscribe(layersSlice.getSseThreshold(this._layerState), (v) => this.setSseThreshold(v));
        observer.subscribe(layersSlice.getOpacity(this._layerState), (v) => this.setOpacity(v));
        observer.subscribe(layersSlice.getOverlayColor(this._layerState), (v) => this.setOverlayColor(v));
        observer.subscribe(layersSlice.getCurrentColoringMode(this._layerState), (v) => this.setColoringMode(v));
        observer.subscribe(layersSlice.getActiveAttributeAndColorMap(this._layerState), (v) =>
            this.setColormapProperties(v)
        );
    }

    setZScale(scale: number) {
        this.settings.zScale = scale;
        const obj = this.get3dElement().object3d;
        obj.scale.set(1, 1, scale);
        obj.updateMatrix();
        obj.updateMatrixWorld(true);
        this.notifyLayerChange();

        if (isElevationType(this._datasetType))
            this.updateMaterialColorMap({
                min: this._externalMin * scale,
                max: this._externalMax * scale,
            });
    }

    get3dElement(): Tiles3D {
        return this._entity;
    }

    getBoundingBox(): Box3 {
        if (!this.initialized) return null;

        const entity = this._entity;

        if (entity === undefined || entity.root === undefined) {
            return new Box3();
        }

        return entity.root.boundingVolume.box.clone().applyMatrix4(entity.root.matrixWorld);
    }

    protected override async initOnce() {
        return this._giro3dInstance.add(this._entity).then(() => this.postInitialization());
    }

    protected override onDispose() {
        if (this.initialized) {
            this._giro3dInstance.remove(this._entity);
        }
    }

    getLoading(): boolean {
        return this._entity.loading;
    }

    // eslint-disable-next-line class-methods-use-this
    hover(): void {
        // Do nothing
    }

    getHoverInfo(): HoveredItem {
        return {
            itemType: this._datasetType,
            name: this._readableName,
        };
    }

    private postInitialization() {
        const obj = this._entity;
        const bbox = obj.root.boundingVolume.box;
        const zOffset = obj.root.position.z;
        const zScale = obj.root.scale.z;

        const min = bbox.min.z * zScale + zOffset;
        const max = bbox.max.z * zScale + zOffset;

        // This attribute can only be known when the 3D tiles has been loaded
        this._dispatch(
            layersSlice.updateAttribute({
                layer: this._layerState,
                attributeId: WellKnownAttributeNames.Elevation,
                value: { min, max },
            })
        );
        if (this._entity.material.colorMap.min === 0 && this._entity.material.colorMap.max === 0) {
            this._dispatch(
                layersSlice.setAttributeColorMapCustomMaxBound({
                    layer: this._layerState,
                    attributeId: WellKnownAttributeNames.Elevation,
                    value: max,
                })
            );
            this._dispatch(
                layersSlice.setAttributeColorMapCustomMinBound({
                    layer: this._layerState,
                    attributeId: WellKnownAttributeNames.Elevation,
                    value: min,
                })
            );
            this.assignInitialValues();
            this.updateMaterialColorMap({ min, max });
        }

        this.notifyLayerChange();
    }

    protected setClippingPlane(plane: Plane) {
        this._entity.clippingPlanes = plane ? [plane] : null;
        this.notifyLayerChange();
    }

    async doSetVisibility(value: boolean) {
        if (this.initialized && value !== undefined) {
            this._entity.visible = value;
            this.notifyLayerChange();
        }
    }

    private setColoringMode(mode: ColoringMode): void {
        switch (mode) {
            case ColoringMode.Colormap:
                this.setColorMapRenderingMode();
                break;
            default:
                this.setRenderingMode(MODE.COLOR);
                break;
        }
    }

    setColorMapRenderingMode() {
        const attribute = this._attribute;

        if (attribute === WellKnownAttributeNames.Elevation) {
            this.setRenderingMode(MODE.ELEVATION);
        } else if (attribute === WellKnownAttributeNames.Intensity) {
            this.setRenderingMode(MODE.INTENSITY);
        }
    }

    private setColormapProperties(arg: { attribute: Attribute; colorMap: ColorMap }): void {
        const { attribute, colorMap } = arg;

        this._attribute = attribute.id;

        this.setColorMapRenderingMode();

        let min: number;
        let max: number;

        switch (colorMap.boundsMode) {
            case COLORMAP_BOUNDSMODE.CUSTOM:
                min = colorMap.customMin;
                max = colorMap.customMax;
                break;
            default:
                min = attribute.min;
                max = attribute.max;
                break;
        }

        const lut = getLUT(colorMap, { samples: 256 });
        const range = attribute.max - attribute.min;
        const opacity = evaluateCurve(colorMap.opacityCurve, {
            samples: lut.length,
            start: min / range,
            end: max / range,
        });

        this._externalMin = min;
        this._externalMax = max;

        const isElevation = isElevationType(this._datasetType);

        this.updateMaterialColorMap({
            min: this._externalMin * (isElevation ? this.settings.zScale : 1),
            max: this._externalMax * (isElevation ? this.settings.zScale : 1),
            lut,
            opacity,
        });

        this.notifyLayerChange();
    }

    private updateMaterialColorMap(props: { min: number; max: number; lut?: Color[]; opacity?: number[] }) {
        const material = this._entity.material;

        material.colorMap.min = props.min;
        material.colorMap.max = props.max;

        if (props.lut) {
            material.colorMap.colors = props.lut;
        }
        if (props.opacity) {
            material.colorMap.opacity = props.opacity;
        }
    }

    private setOverlayColor(value: Color) {
        this.settings.overlayColor = value;
        if (this._entity) {
            this._entity.material.overlayColor = new Vector4(...value.toArray(), 1);
            this._entity.material.updateUniforms();
            this.notifyLayerChange();
        }
    }

    getOpacity(): number {
        return this.settings.opacity;
    }

    setOpacity(opacity: number) {
        this.settings.opacity = opacity;

        this._entity.material.opacity = opacity;
        this.notifyLayerChange();
    }

    private setRenderingMode(value: number) {
        this._entity.material.mode = value;
        this.notifyLayerChange();
    }

    private setPointSize(value: number) {
        this._entity.material.size = value;
        this.notifyLayerChange();
    }

    private setSseThreshold(value: number) {
        this._entity.sseThreshold = value;
        this.notifyLayerChange();
    }

    protected setBrightness(brightness: number): void {
        this._entity.material.brightness = brightness;
        this.notifyLayerChange();
    }
}

export default PointCloudLayer;

import Disposable from '@giro3d/giro3d/core/Disposable';
import DrawTool, {
    afterRemovePointOfRing,
    CreationOptions,
    inhibitHook,
    limitRemovePointHook,
    PickCallback,
} from '@giro3d/giro3d/interactions/DrawTool';
import Instance from '@giro3d/giro3d/core/Instance';
import type { LineString, Polygon } from 'geojson';
import Shape, {
    isShapePickResult,
    SegmentLabelFormatter,
    ShapeConstructorOptions,
    SurfaceLabelFormatter,
    VertexLabelFormatter,
} from '@giro3d/giro3d/entities/Shape';
import { GeometryId, GeometryWithCRS } from 'types/common';
import { EventDispatcher, MathUtils, Matrix4, Triangle, Vector2, Vector3 } from 'three';
import View from '@giro3d/giro3d/renderer/View';
import { AnnotationGeometry, AnnotationGeometryType } from 'types/Annotation';
import { extractCoordinates } from 'giro3d_extensions/Measure';
import PickResult from '@giro3d/giro3d/core/picking/PickResult';
import earcut from 'earcut';
import * as geojsonUtils from '../geojsonUtils';
import { ANNOTATION_STYLES, DEFAULT_SHAPE_SETTINGS } from './Constants';

export type MeasureGeometryWithoutCrs = LineString | Polygon;
export type MeasureGeometry = GeometryWithCRS<MeasureGeometryWithoutCrs>;

export interface ShapeManager extends Disposable {
    get isDrawingOrEditing(): boolean;
    get currentGeometryCoordinateCount(): number;

    stopDrawing(): void;

    getGeoJSON(shape: Shape): AnnotationGeometry;

    createShapeFromGeoJSON(id: GeometryId, geometry: AnnotationGeometry, userData: Record<string, string>): Shape;
    updateShapeFromGeoJSON(id: GeometryId, geometry: AnnotationGeometry): void;

    createShapeFromPoint(point: Vector3): { id: GeometryId; geometry: AnnotationGeometry };

    deleteShape(id: GeometryId): void;
    deleteCurrentMeasure(): void;

    deletePoint(id: GeometryId, pointIndex: number): void;
    updatePoint(id: GeometryId, pointIndex: number, x: number, y: number, z: number): void;

    drawAnnotation(
        type: AnnotationGeometryType,
        onDrawingChanged: (id: GeometryId, geometry: AnnotationGeometry) => void
    ): Promise<{ id: GeometryId; geometry: AnnotationGeometry }>;
    editAnnotation(
        id: GeometryId,
        onShapeUpdated: (id: GeometryId, geometry: AnnotationGeometry) => void
    ): Promise<AnnotationGeometry>;
    endEdition(): void;
    abortEdition(): void;

    drawSegment(): Promise<GeometryWithCRS<LineString>>;
    drawMeasure(onDrawingChanged: (id: GeometryId, geometry: MeasureGeometry) => void): Promise<void>;
}

const kilometerPrecision = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 2,
    unitDisplay: 'short',
    style: 'unit',
    unit: 'kilometer',
});
const meterPrecision = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 0,
    unitDisplay: 'short',
    style: 'unit',
    unit: 'meter',
});

const centimeterPrecision = new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 2,
    unitDisplay: 'short',
    style: 'unit',
    unit: 'meter',
});

const pointIndexformatter: VertexLabelFormatter = (values) => {
    const { shape, index } = values;

    if (index === shape.points.length - 1 && shape.isClosed) {
        // shape is a ring / polygon. Dont' display the last
        // point as it will be exactly the same as the first point.
        return null;
    }

    // Giro3D Shapes indices are 0-indexed, but the vertex
    // indices displayed in the GUI are 1-indexed.
    return (index + 1).toString();
};

const tmpNdcStart = new Vector3();
const tmpNdcEnd = new Vector3();

const tmpViewportStart = new Vector2();
const tmpViewportEnd = new Vector2();

/**
 * Returns the distance, in pixels, of the two points projected on the screen.
 */
function getDistanceOnScreen(start: Vector3, end: Vector3, view: View): number {
    const camera = view.camera;

    // Compute Normalized device coordinates (NDC)
    const ndcStart = tmpNdcStart.copy(start).project(camera);
    const ndcEnd = tmpNdcEnd.copy(end).project(camera);

    // Convert to viewport coordinates (in pixels)
    const viewportStart = tmpViewportStart.set(ndcStart.x * view.width, ndcStart.y * view.height);
    const viewportEnd = tmpViewportEnd.set(ndcEnd.x * view.width, ndcEnd.y * view.height);

    const distanceOnScreen = viewportStart.distanceTo(viewportEnd);

    return distanceOnScreen;
}

const segmentLengthFormatter: (view: View, scaleMatrix: Matrix4) => SegmentLabelFormatter = (view, scaleMatrix) => {
    return (values) => {
        const { start, end } = values;

        const distanceOnScreen = getDistanceOnScreen(start, end, view);

        const sizeThreshold = 100; // Pixels

        // Don't display the label if the segment is too short on the screen
        if (distanceOnScreen < sizeThreshold) {
            return null;
        }

        // We have to convert the scaled coordinates to unscaled coordinates to perform
        // correct length computations.
        const matrix = scaleMatrix.clone().invert();
        const crsStart = start.clone().applyMatrix4(matrix);
        const crsEnd = end.clone().applyMatrix4(matrix);

        const length = crsStart.distanceTo(crsEnd);

        if (length > 0) {
            if (length < 10) {
                return centimeterPrecision.format(length);
            }

            if (length < 10_000) {
                return meterPrecision.format(length);
            }

            return kilometerPrecision.format(length / 1000);
        }

        return null;
    };
};

// This function is a copy-paste from the one in Giro3d (but which is not exported)
function toNumberArray(vectors: Vector3[], origin: Vector3): ArrayLike<number> {
    const result = new Float32Array(vectors.length * 3);
    for (let i = 0; i < vectors.length; i++) {
        const v = vectors[i];
        result[i * 3 + 0] = v.x - origin.x;
        result[i * 3 + 1] = v.y - origin.y;
        result[i * 3 + 2] = v.z - origin.z;
    }

    return result;
}

// This function is a copy-paste from the one in Giro3d (but which is not exported)
function computeArea(shape: Shape, matrix: Matrix4): number | null {
    if (shape.points.length < 2) {
        return null;
    }

    const crsPoints = shape.points.map((p) => p.clone().applyMatrix4(matrix.clone().invert()));
    // Let's have relative point to avoid jittering
    const origin = crsPoints[0];

    const indices = earcut(toNumberArray(crsPoints, origin), undefined, 3);

    const triangleCount = indices.length / 3;

    let area = 0;

    for (let i = 0; i < triangleCount; i++) {
        const a = crsPoints[indices[i * 3 + 0]];
        const b = crsPoints[indices[i * 3 + 1]];
        const c = crsPoints[indices[i * 3 + 2]];

        if (a == null || b == null || c == null) {
            continue;
        }

        const triangle = new Triangle(a, b, c);
        area += triangle.getArea();
    }

    return area;
}

const surfaceLabelFormatter: (view: View, scaleMatrix: Matrix4) => SurfaceLabelFormatter = (view, scaleMatrix) => {
    return (values) => {
        // We have to replace the default area computation of the shape because of the z-scale
        // messes with the coordinates and gives incorrect measurements.
        const area = computeArea(values.shape, scaleMatrix);

        return values.defaultFormatter({ ...values, area });
    };
};

function updateShapeAttributeFromType(shape: Shape, type: AnnotationGeometryType) {
    shape.vertexRadius = ANNOTATION_STYLES.normal.vertexRadius[type];
    shape.showVertices = true;

    switch (type) {
        case 'Polygon':
            shape.showSurface = true;
            break;
        default:
    }
}

/**
 * Returns true if the first and last point and close
 * enough to visually consider the shape a closed polygon.
 */
function isApproximatelyClosed(shape: Shape, view: View): boolean {
    if (shape.points.length > 3) {
        const first = shape.points[0];
        const last = shape.points[shape.points.length - 1];

        const apparentDistance = getDistanceOnScreen(first, last, view);

        return apparentDistance < 10;
    }

    return false;
}

function updateMeasureShape(shape: Shape, view: View): void {
    // Measures can be either LineStrings or Polygons,
    // depending on wether the last point is very close to the first.
    // The displays changes to reflect this dynamic nature.
    const isPolygon = isApproximatelyClosed(shape, view);

    shape.showSurface = isPolygon;
    shape.showSurfaceLabel = isPolygon;
    shape.showSegmentLabels = !isPolygon;
}

function finalizeMeasureShape(shape: Shape, view: View): void {
    if (isApproximatelyClosed(shape, view)) {
        shape.updatePoint(shape.points.length - 1, shape.points[0].clone());
    } else if (shape.showSurface) {
        shape.makeClosed();
    }
}

interface ShapeManagerEvents {
    'shape-updated': { id: string; geometry: AnnotationGeometry };
    'vertex-dragged': { drag: boolean };
}

// Note: this is a patched version of the original callback in Giro3D that contained a bug.
// When https://gitlab.com/giro3d/giro3d/-/merge_requests/773 is merged and the next Giro3D release
// is integrated, we can safely remove the patched version and use the original one.
export const afterUpdatePointOfRingPatched = (options: { shape: Shape; index: number; newPosition: Vector3 }) => {
    const { index, shape } = options;

    const newPosition = options.newPosition.clone();

    if (index === 0) {
        // Also remove last point
        shape.updatePoint(shape.points.length - 1, newPosition);
    } else if (index === shape.points.length - 1) {
        // Also remove first point
        shape.updatePoint(0, newPosition);
    }
};

/**
 * Updates the .userData property of all objects in the shape hierarchy.
 */
function updateUserData(shape: Shape, userData: Record<string, string> | null) {
    if (!userData) {
        return;
    }

    // We have to reassign the userdata to objects because the shape recreates
    // its internal hierarchy of objects when updating points.
    shape.traverse((obj) => {
        obj.userData = { ...obj.userData, ...userData };
    });
}

function setPoints(shape: Shape, points: Vector3[], userData: Record<string, string>) {
    shape.setPoints(points);

    updateUserData(shape, userData);
}

function removePoint(shape: Shape, index: number, userData: Record<string, string>) {
    shape.removePoint(index);

    updateUserData(shape, userData);
}

function updatePoint(shape: Shape, index: number, position: Vector3, userData: Record<string, string>) {
    shape.updatePoint(index, position);

    updateUserData(shape, userData);
}

export class ShapeManagerImpl extends EventDispatcher<ShapeManagerEvents> implements ShapeManager {
    private readonly _shapes: Map<string, { shape: Shape; userData: Record<string, string> }> = new Map();
    private readonly _updateLabels: () => void;
    private readonly _scaleMatrix = new Matrix4();

    private _drawTool?: DrawTool;
    private _instance?: Instance;
    private _lastCameraPosition?: Vector3;
    private _currentShape?: Shape;
    private _pick: PickCallback;

    private _isDrawing = false;
    private _isEditing = false;

    private _currentMeasure?: string;
    private _drawingCancellationController?: AbortController;
    private _abortEditionController?: AbortController;
    private _zScale = 1;

    get isDrawingOrEditing() {
        return this._isDrawing || this._isEditing;
    }

    get currentGeometryCoordinateCount(): number {
        if (this._currentShape == null) {
            return 0;
        }

        const coordCount = this._currentShape.points.length;

        if (this._isEditing) {
            return coordCount;
        }

        // If we are in creation mode, there is always one "virtual point"
        // being currently drawn that should not be taken into account.
        return coordCount - 1;
    }

    constructor(options: { pick: PickCallback }) {
        super();

        this._pick = options.pick;

        this._updateLabels = this.updateLabels.bind(this);
    }

    private pickNonShapes(event: MouseEvent): PickResult[] {
        // We don't want to pick other shapes while drawing or editing shapes,
        // because that would create a snapping effect where the mouse will be attracted
        // to the nearest shape.
        const nonShapeResults = this._pick(event).filter((r) => !isShapePickResult(r));

        return nonShapeResults;
    }

    private updateLabels() {
        const view = this._instance.view;
        if (this._lastCameraPosition == null) {
            this._lastCameraPosition = view.camera.getWorldPosition(new Vector3());
        }

        const currentCameraPosition = view.camera.getWorldPosition(new Vector3());

        const cameraHasMoved = !currentCameraPosition.equals(this._lastCameraPosition);

        this._lastCameraPosition.copy(currentCameraPosition);

        this._shapes.forEach(({ shape }) => {
            // Since the segment labels depend on the camera position relative
            // to the shape, we have to force udpating the labels each frame.
            // console.log(shape);
            if (cameraHasMoved && shape.showSegmentLabels) {
                shape.rebuildLabels();
            }
        });
    }

    setZScale(scale: number) {
        // To scale shapes, we don't scale the actual 3D objects, but we retransform each
        // individual points. This way, their 3D objects stay on a default scale, which
        // makes it much easier to interact (picking, edition...).
        // The drawback is that after applying a non-default scale, point coordinates are no
        // longer meaningful metric values, so lengths and area computation become incorrect.
        // This is fixed by counteracting the z-scale once more for those specific computations.
        // Since three.js is using double precision number for matrix computation, the loss
        // of precision induced by successive matrix multiplications should be fairly limited,
        // as long as we don't keep modifying the z-scale thousands of times per session.
        // Besides, since shapes are hand drawn, their coordinate precision is fairly low anyway.

        if (this._zScale === scale) {
            return;
        }

        this._zScale = scale;

        // Create a matrix that will return all points to the default scale (1, 1, 1).
        const oldMatrix = this._scaleMatrix.clone().invert();
        this._scaleMatrix.makeScale(1, 1, scale);

        for (const { shape, userData } of this._shapes.values()) {
            const points = [...shape.points.map((p) => p.clone())];

            // Reset the current z-scale transformation,
            // then apply the new one.
            points.forEach((p) => p.applyMatrix4(oldMatrix).applyMatrix4(this._scaleMatrix));

            setPoints(shape, points, userData);
        }
    }

    init(instance: Instance): void {
        this._instance = instance;
        this._drawTool = new DrawTool({ instance });
        instance.addEventListener('after-camera-update', this._updateLabels);

        // We want to prevent moving the camera while dragging a point
        this._drawTool.addEventListener('start-drag', () => {
            this.dispatchEvent({ type: 'vertex-dragged', drag: true });
        });
        this._drawTool.addEventListener('end-drag', () => {
            this.dispatchEvent({ type: 'vertex-dragged', drag: false });
        });
    }

    dispose() {
        this.stopDrawing();
        this._drawTool?.exitEditMode();
        this._instance.removeEventListener('after-camera-update', this._updateLabels);
        this._drawTool?.dispose();

        this._instance = undefined;
        this._drawTool = undefined;
    }

    stopDrawing(abortCurrentDrawing = true): void {
        if (this._drawTool == null) {
            return;
        }

        // Cancel creation mode
        if (abortCurrentDrawing) {
            if (this._currentShape) {
                this._instance?.remove(this._currentShape);
                this._shapes.delete(this._currentShape.id);
            }
            this._drawingCancellationController?.abort();
        }
        this._drawingCancellationController = null;
        this._abortEditionController = null;

        // Cancel edition mode
        this._drawTool.exitEditMode();

        this._isDrawing = false;
        this._isEditing = false;
        this._currentShape = undefined;
    }

    private getMeasureGeoJSON(shape: Shape): GeometryWithCRS {
        // Ensure that if the shape is almost closed, then
        // the generated GeoJSON should be a polygon (so that the
        // rest of the application handles it like a polygon).
        // Otherwise, it should be a LineString.
        if (isApproximatelyClosed(shape, this._instance.view)) {
            // temporarily close the shape so that it can export a GeoJSON Polygon
            const lastPoint = shape.points[shape.points.length - 1].clone();

            shape.makeClosed();

            const result = this.getGeoJSON(shape);

            // Restore the original shape
            shape.updatePoint(shape.points.length - 1, lastPoint);

            return result;
        }

        return this.getGeoJSON(shape);
    }

    /**
     * Gets the GeoJSON representation of the shape, in the CRS of the instance.
     */
    getGeoJSON(shape: Shape): AnnotationGeometry {
        if (this._zScale !== 1) {
            const resetZScale = this._scaleMatrix.clone().invert();

            // Since the shape points are not in CRS coordinates (due to z-scale messing things up),
            // we have to temporarily convert them back to default-scale so that they match the actual CRS.
            shape.points.forEach((p) => p.applyMatrix4(resetZScale));
        }

        const wgs84 = shape.toGeoJSON().geometry;

        if (this._zScale !== 1) {
            // Then scale the points back to the non-default scale, if any.
            shape.points.forEach((p) => p.applyMatrix4(this._scaleMatrix));
        }

        const transformed = geojsonUtils.transform(wgs84, 'EPSG:4326', this._instance.referenceCrs);

        return geojsonUtils.toGeometryWithCRS(transformed, this._instance.referenceCrs) as AnnotationGeometry;
    }

    private beforeDrawing() {
        if (this._drawTool == null) {
            throw new Error('not initialized');
        }

        this.stopDrawing();

        this._drawingCancellationController = new AbortController();
        this._isDrawing = true;
    }

    async drawSegment(): Promise<GeometryWithCRS<LineString>> {
        this.beforeDrawing();

        const shape = await this._drawTool.createSegment({
            color: DEFAULT_SHAPE_SETTINGS.COLOR,
            signal: this._drawingCancellationController.signal,
            pick: this.pickNonShapes.bind(this),
            onTemporaryPointMoved: (tmpShape) => {
                this._currentShape = tmpShape;
            },
        });

        if (shape == null) {
            return null;
        }

        const geojson = this.getGeoJSON(shape) as GeometryWithCRS<LineString>;

        // Delete temporary shape entity
        this._instance.remove(shape);

        this.onDrawingFinished();

        return geojson;
    }

    deleteCurrentMeasure() {
        if (this._currentMeasure) {
            const { shape } = this.getShapeSafe(this._currentMeasure);
            if (shape) {
                this._instance?.remove(shape);
                this._shapes.delete(this._currentMeasure);
            }
            this._currentMeasure = undefined;
        }
    }

    private notifyUpdate(id: GeometryId, shape: Shape) {
        this.dispatchEvent({ type: 'shape-updated', id, geometry: this.getGeoJSON(shape) });
    }

    private getShapeSafe(shapeId: GeometryId, pointIndex?: number): { shape: Shape; userData: Record<string, string> } {
        const result = this._shapes.get(shapeId);
        if (!result) {
            console.warn(`no shape with id ${shapeId}`);
            return { shape: null, userData: null };
        }

        const { shape, userData } = result;

        if (pointIndex != null && shape.points.length - 1 < pointIndex) {
            console.warn(
                `shape ${shapeId} has only ${shape.points.length}, but was asked to update point ${pointIndex}`
            );
            return { shape: null, userData: null };
        }

        return { shape, userData };
    }

    deletePoint(shapeId: GeometryId, pointIndex: number): void {
        const { shape, userData } = this.getShapeSafe(shapeId, pointIndex);
        if (!shape) {
            return;
        }

        removePoint(shape, pointIndex, userData);

        this.notifyUpdate(shapeId, shape);
    }

    updatePoint(shapeId: GeometryId, pointIndex: number, x: number, y: number, z: number): void {
        const { shape, userData } = this.getShapeSafe(shapeId, pointIndex);
        if (!shape) {
            return;
        }

        updatePoint(shape, pointIndex, new Vector3(x, y, z), userData);

        this.notifyUpdate(shapeId, shape);
    }

    async drawMeasure(onDrawingChanged: (id: GeometryId, geometry: MeasureGeometry) => void): Promise<void> {
        this.beforeDrawing();

        this.deleteCurrentMeasure();

        const id = MathUtils.generateUUID();
        this._currentMeasure = id;

        const shape = await this._drawTool.createLineString({
            color: DEFAULT_SHAPE_SETTINGS.COLOR,
            showSegmentLabels: true,
            showSurface: false,
            segmentLabelFormatter: segmentLengthFormatter(this._instance.view, this._scaleMatrix),
            surfaceLabelFormatter: surfaceLabelFormatter(this._instance.view, this._scaleMatrix),
            signal: this._drawingCancellationController.signal,
            pick: this.pickNonShapes.bind(this),
            onTemporaryPointMoved: (tempShape) => {
                this._currentShape = tempShape;
                this._shapes.set(id, { shape: tempShape, userData: null });
                updateMeasureShape(tempShape, this._instance.view);
                this.notifyUpdate(id, tempShape);
                onDrawingChanged(id, this.getMeasureGeoJSON(tempShape) as MeasureGeometry);
            },
        });

        finalizeMeasureShape(shape, this._instance.view);

        const geometry = this.getMeasureGeoJSON(shape) as MeasureGeometry;

        onDrawingChanged(id, geometry);

        this.notifyUpdate(id, shape);

        this.onDrawingFinished();
    }

    async drawAnnotation(
        type: AnnotationGeometryType,
        onDrawingChanged: (id: GeometryId, geometry: AnnotationGeometry) => void
    ): Promise<{ id: GeometryId; geometry: AnnotationGeometry }> {
        this.beforeDrawing();

        // Generate an ID for the temporary annotation shape
        const id = MathUtils.generateUUID();

        let shape: Shape;

        const baseOptions: Partial<CreationOptions> = {
            color: ANNOTATION_STYLES.edited.color,
            vertexRadius: ANNOTATION_STYLES.edited.vertexRadius[type],
            lineWidth: ANNOTATION_STYLES.edited.width,
            borderWidth: ANNOTATION_STYLES.edited.borderWidth,
            signal: this._drawingCancellationController.signal,
            pick: this.pickNonShapes.bind(this),
            onTemporaryPointMoved: (tmpShape) => {
                this._currentShape = tmpShape;
                onDrawingChanged(id, this.getGeoJSON(tmpShape));
            },
        };

        switch (type) {
            case 'Point':
                shape = await this._drawTool.createPoint({
                    ...baseOptions,
                });
                break;
            case 'LineString':
                shape = await this._drawTool.createLineString({
                    ...baseOptions,
                });
                break;
            case 'Polygon':
                shape = await this._drawTool.createPolygon({
                    ...baseOptions,
                });
                break;
            default:
                throw new Error('invalid state');
        }

        const geometry = this.getGeoJSON(shape);

        this._shapes.set(id, { shape, userData: null });

        this.onDrawingFinished();

        return { id, geometry };
    }

    private onDrawingFinished() {
        this.stopDrawing(false);
    }

    updateShapeFromGeoJSON(id: GeometryId, geometry: AnnotationGeometry): void {
        const { shape, userData } = this.getShapeSafe(id);

        // We have to transform those points to account for the the z-scale.
        const coordinates = extractCoordinates(geometry).map((p) => p.applyMatrix4(this._scaleMatrix));
        const currentCoordinates = shape.points;

        let hasChanged = false;
        if (coordinates.length === currentCoordinates.length) {
            const epsilon = 0.00001;

            for (let i = 0; i < coordinates.length; i++) {
                const a = coordinates[i];
                const b = currentCoordinates[i];

                if (Math.abs(a.x - b.x) > epsilon || Math.abs(a.y - b.y) > epsilon || Math.abs(a.z - b.z) > epsilon) {
                    hasChanged = true;
                }
            }
        } else {
            hasChanged = true;
        }

        if (hasChanged) {
            setPoints(shape, coordinates, userData);
            updateShapeAttributeFromType(shape, geometry.type);
        }
    }

    createShapeFromGeoJSON(id: GeometryId, geometry: AnnotationGeometry, userData: Record<string, string>): Shape {
        const options: Partial<ShapeConstructorOptions> = {};

        switch (geometry.type) {
            case 'Point':
                // Prevent deleting the unique point
                options.beforeRemovePoint = inhibitHook;
                break;
            case 'LineString':
                // Prevent going under 2 points.
                options.beforeRemovePoint = limitRemovePointHook(2);
                options.vertexLabelFormatter = pointIndexformatter;
                break;
            case 'Polygon':
                // Ensure that the polygon remains properly closed when editing vertices
                options.afterUpdatePoint = afterUpdatePointOfRingPatched;
                options.afterRemovePoint = afterRemovePointOfRing;
                // Prevent going under 4 points (that takes into acount the duplicate first/last
                // point, so 3 points visually distinct).
                options.beforeRemovePoint = limitRemovePointHook(4);
                options.vertexLabelFormatter = pointIndexformatter;
                break;
            default:
                break;
        }

        const shape = new Shape(options);

        shape.name = `${geometry.type} (${id})`;

        // Don't forget to transform those points to apply Z-scale.
        const coordinates = extractCoordinates(geometry).map((p) => p.applyMatrix4(this._scaleMatrix));

        this._instance.add(shape);

        setPoints(shape, coordinates, userData);

        updateShapeAttributeFromType(shape, geometry.type);

        this._shapes.set(id, { shape, userData });

        updateUserData(shape, userData);

        this.onDrawingFinished();

        return shape;
    }

    createShapeFromPoint(point: Vector3): { id: GeometryId; geometry: AnnotationGeometry } {
        const options: Partial<ShapeConstructorOptions> = {};

        const id = MathUtils.generateUUID();

        // Prevent deleting the unique point
        options.beforeRemovePoint = inhibitHook;

        const shape = new Shape(options);

        // Don't forget to transform those points to apply Z-scale.
        const coordinates = [point].map((p) => p.applyMatrix4(this._scaleMatrix));

        this._instance.add(shape);

        setPoints(shape, coordinates, {});

        updateShapeAttributeFromType(shape, 'Point');

        this._shapes.set(id, { shape, userData: {} });

        updateUserData(shape, {});

        this.onDrawingFinished();

        return { id, geometry: this.getGeoJSON(shape) };
    }

    deleteShape(id: GeometryId): void {
        const { shape } = this.getShapeSafe(id);
        if (shape) {
            this._instance.remove(shape);
            this._shapes.delete(id);
        }
    }

    endEdition() {
        this._drawingCancellationController?.abort();
        this._drawingCancellationController = null;
    }

    abortEdition(): void {
        this._abortEditionController?.abort();
        this._abortEditionController = null;
    }

    editAnnotation(
        id: GeometryId,
        onShapeUpdated: (id: GeometryId, geometry: AnnotationGeometry) => void
    ): Promise<AnnotationGeometry> {
        const { shape, userData } = this.getShapeSafe(id);

        if (!shape) {
            return Promise.resolve(null);
        }

        this.endEdition();

        this._currentShape = shape;
        this._isEditing = true;

        const updateShape = () => {
            updateUserData(shape, userData);
            onShapeUpdated(id, this.getGeoJSON(shape));
        };

        this._drawingCancellationController = new AbortController();
        this._abortEditionController = new AbortController();

        return new Promise<AnnotationGeometry>((resolve, reject) => {
            this._drawTool.enterEditMode({
                shapesToEdit: [shape],
                pick: this._pick,
                onPointInserted: updateShape,
                onPointRemoved: updateShape,
                onPointUpdated: updateShape,
            });
            this._isDrawing = true;

            let stopEdition: () => void;
            let abortEdition: () => void;

            const unregisterListeners = () => {
                this._drawingCancellationController.signal.removeEventListener('abort', stopEdition);
                this._abortEditionController.signal.removeEventListener('abort', abortEdition);
            };

            abortEdition = () => {
                unregisterListeners();
                this.onDrawingFinished();
                reject(new Error('aborted'));
            };

            stopEdition = () => {
                unregisterListeners();
                this.onDrawingFinished();
                updateUserData(shape, userData);
                resolve(this.getGeoJSON(shape));
            };

            this._drawingCancellationController.signal.addEventListener('abort', stopEdition);
            this._abortEditionController.signal.addEventListener('abort', abortEdition);
        });
    }
}

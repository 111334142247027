import chroma from 'chroma-js';

export const CYCLE_SCALE = chroma
    .scale(['#89d51b', '#2daa46', '#2b66be', '#6b03a2', '#c3199c', '#f65d6a', '#f8ae4e', '#f1fb27', '#89d51b'])
    .domain([0, 1 / 8, 2 / 8, 3 / 8, 4 / 8, 5 / 8, 6 / 8, 7 / 8, 1]);

export const GRAYSCALE_SCALE = chroma.scale(['black', 'white']);
export const COPPER_SCALE = chroma.scale(['black', '#ff9f65', '#ffc77e']).domain([0, 51 / 64, 1]);

// domain specifies the position of each color. This colormap interpolates between:
// 1. white and dark orange from 0 -> 0.1
// 2. dark orange and black from 0.1 -> 0.99
export const SEISMIC_SCALE = chroma.scale(['white', '#cc7000', 'black']).domain([0, 0.1, 0.99]);

export const LANDMARK_SCALE = chroma.scale(['red', 'white', 'black']).domain([0, 0.5, 1]);

export const KLEIN_SCALE = chroma.scale([
    chroma(0, 0, 0),
    chroma(1, 1, 0),
    chroma(3, 2, 0),
    chroma(5, 3, 0),
    chroma(7, 5, 0),
    chroma(9, 6, 0),
    chroma(11, 7, 0),
    chroma(13, 9, 0),
    chroma(15, 10, 0),
    chroma(17, 11, 0),
    chroma(19, 13, 0),
    chroma(21, 14, 0),
    chroma(23, 15, 0),
    chroma(25, 17, 0),
    chroma(27, 18, 0),
    chroma(29, 19, 0),
    chroma(31, 21, 0),
    chroma(33, 22, 0),
    chroma(35, 23, 0),
    chroma(37, 25, 0),
    chroma(39, 26, 0),
    chroma(41, 27, 0),
    chroma(43, 29, 0),
    chroma(45, 30, 0),
    chroma(47, 31, 0),
    chroma(49, 33, 0),
    chroma(51, 34, 0),
    chroma(53, 35, 0),
    chroma(55, 37, 0),
    chroma(57, 38, 0),
    chroma(59, 39, 0),
    chroma(61, 41, 0),
    chroma(63, 42, 0),
    chroma(65, 43, 0),
    chroma(67, 45, 0),
    chroma(69, 46, 0),
    chroma(71, 47, 0),
    chroma(73, 49, 0),
    chroma(75, 50, 0),
    chroma(77, 51, 0),
    chroma(79, 53, 0),
    chroma(81, 54, 0),
    chroma(83, 55, 0),
    chroma(85, 57, 0),
    chroma(87, 58, 0),
    chroma(89, 59, 0),
    chroma(91, 61, 0),
    chroma(93, 62, 0),
    chroma(95, 63, 0),
    chroma(97, 65, 0),
    chroma(99, 66, 0),
    chroma(101, 67, 0),
    chroma(103, 69, 0),
    chroma(105, 70, 0),
    chroma(107, 71, 0),
    chroma(109, 73, 0),
    chroma(111, 74, 0),
    chroma(113, 75, 0),
    chroma(115, 77, 0),
    chroma(117, 78, 0),
    chroma(119, 79, 0),
    chroma(121, 81, 0),
    chroma(123, 82, 0),
    chroma(125, 83, 0),
    chroma(127, 85, 0),
    chroma(129, 86, 0),
    chroma(131, 87, 0),
    chroma(133, 89, 0),
    chroma(135, 90, 0),
    chroma(137, 91, 0),
    chroma(139, 93, 0),
    chroma(141, 94, 0),
    chroma(143, 95, 0),
    chroma(145, 97, 0),
    chroma(147, 98, 0),
    chroma(149, 99, 0),
    chroma(151, 101, 0),
    chroma(153, 102, 0),
    chroma(155, 103, 0),
    chroma(157, 105, 0),
    chroma(159, 106, 0),
    chroma(161, 107, 0),
    chroma(163, 109, 0),
    chroma(165, 110, 0),
    chroma(167, 111, 0),
    chroma(169, 113, 0),
    chroma(171, 114, 0),
    chroma(173, 115, 0),
    chroma(175, 117, 0),
    chroma(177, 118, 0),
    chroma(179, 119, 0),
    chroma(181, 121, 0),
    chroma(183, 122, 0),
    chroma(185, 123, 0),
    chroma(187, 125, 0),
    chroma(189, 126, 0),
    chroma(191, 127, 0),
    chroma(193, 129, 0),
    chroma(195, 130, 0),
    chroma(197, 131, 0),
    chroma(199, 133, 0),
    chroma(201, 134, 0),
    chroma(203, 135, 0),
    chroma(205, 137, 0),
    chroma(207, 138, 0),
    chroma(209, 139, 0),
    chroma(211, 141, 0),
    chroma(213, 142, 0),
    chroma(215, 143, 0),
    chroma(217, 145, 0),
    chroma(219, 146, 0),
    chroma(221, 147, 0),
    chroma(223, 149, 0),
    chroma(225, 150, 0),
    chroma(227, 151, 0),
    chroma(229, 153, 0),
    chroma(231, 154, 0),
    chroma(233, 155, 0),
    chroma(235, 157, 0),
    chroma(237, 158, 0),
    chroma(239, 159, 0),
    chroma(241, 161, 0),
    chroma(243, 162, 0),
    chroma(245, 163, 0),
    chroma(247, 165, 0),
    chroma(249, 166, 0),
    chroma(251, 167, 0),
    chroma(253, 169, 0),
    chroma(255, 170, 0),
    chroma(255, 170, 2),
    chroma(255, 171, 4),
    chroma(255, 172, 6),
    chroma(255, 172, 8),
    chroma(255, 173, 10),
    chroma(255, 174, 12),
    chroma(255, 174, 14),
    chroma(255, 175, 16),
    chroma(255, 176, 18),
    chroma(255, 176, 20),
    chroma(255, 177, 22),
    chroma(255, 178, 24),
    chroma(255, 178, 26),
    chroma(255, 179, 28),
    chroma(255, 180, 30),
    chroma(255, 180, 32),
    chroma(255, 181, 34),
    chroma(255, 182, 36),
    chroma(255, 182, 38),
    chroma(255, 183, 40),
    chroma(255, 184, 42),
    chroma(255, 184, 44),
    chroma(255, 185, 46),
    chroma(255, 186, 48),
    chroma(255, 186, 50),
    chroma(255, 187, 52),
    chroma(255, 188, 54),
    chroma(255, 188, 56),
    chroma(255, 189, 58),
    chroma(255, 190, 60),
    chroma(255, 190, 62),
    chroma(255, 191, 64),
    chroma(255, 192, 66),
    chroma(255, 192, 68),
    chroma(255, 193, 70),
    chroma(255, 194, 72),
    chroma(255, 194, 74),
    chroma(255, 195, 76),
    chroma(255, 196, 78),
    chroma(255, 196, 80),
    chroma(255, 197, 82),
    chroma(255, 198, 84),
    chroma(255, 198, 86),
    chroma(255, 199, 88),
    chroma(255, 200, 90),
    chroma(255, 200, 92),
    chroma(255, 201, 94),
    chroma(255, 202, 96),
    chroma(255, 202, 98),
    chroma(255, 203, 100),
    chroma(255, 204, 102),
    chroma(255, 204, 104),
    chroma(255, 205, 106),
    chroma(255, 206, 108),
    chroma(255, 206, 110),
    chroma(255, 207, 112),
    chroma(255, 208, 114),
    chroma(255, 208, 116),
    chroma(255, 209, 118),
    chroma(255, 210, 120),
    chroma(255, 210, 122),
    chroma(255, 211, 124),
    chroma(255, 212, 126),
    chroma(255, 212, 128),
    chroma(255, 213, 130),
    chroma(255, 214, 132),
    chroma(255, 214, 134),
    chroma(255, 215, 136),
    chroma(255, 216, 138),
    chroma(255, 216, 140),
    chroma(255, 217, 142),
    chroma(255, 218, 144),
    chroma(255, 218, 146),
    chroma(255, 219, 148),
    chroma(255, 220, 150),
    chroma(255, 220, 152),
    chroma(255, 221, 154),
    chroma(255, 222, 156),
    chroma(255, 222, 158),
    chroma(255, 223, 160),
    chroma(255, 224, 162),
    chroma(255, 224, 164),
    chroma(255, 225, 166),
    chroma(255, 226, 168),
    chroma(255, 226, 170),
    chroma(255, 227, 172),
    chroma(255, 228, 174),
    chroma(255, 228, 176),
    chroma(255, 229, 178),
    chroma(255, 230, 180),
    chroma(255, 230, 182),
    chroma(255, 231, 184),
    chroma(255, 232, 186),
    chroma(255, 232, 188),
    chroma(255, 233, 190),
    chroma(255, 234, 192),
    chroma(255, 234, 194),
    chroma(255, 235, 196),
    chroma(255, 236, 198),
    chroma(255, 236, 200),
    chroma(255, 237, 202),
    chroma(255, 238, 204),
    chroma(255, 238, 206),
    chroma(255, 239, 208),
    chroma(255, 240, 210),
    chroma(255, 240, 212),
    chroma(255, 241, 214),
    chroma(255, 242, 216),
    chroma(255, 242, 218),
    chroma(255, 243, 220),
    chroma(255, 244, 222),
    chroma(255, 244, 224),
    chroma(255, 245, 226),
    chroma(255, 246, 228),
    chroma(255, 246, 230),
    chroma(255, 247, 232),
    chroma(255, 248, 234),
    chroma(255, 248, 236),
    chroma(255, 249, 238),
    chroma(255, 250, 240),
    chroma(255, 250, 242),
    chroma(255, 251, 244),
    chroma(255, 252, 246),
    chroma(255, 252, 248),
    chroma(255, 253, 250),
    chroma(255, 254, 252),
    chroma(255, 254, 254),
]);

export const C_THERMAL_SCALE = chroma.scale([
    '#032333',
    '#042335',
    '#042437',
    '#042539',
    '#04263a',
    '#04263c',
    '#05273e',
    '#052840',
    '#052842',
    '#052944',
    '#052a46',
    '#062a49',
    '#062b4b',
    '#062b4d',
    '#072c4f',
    '#072c51',
    '#082d54',
    '#082e56',
    '#092e58',
    '#0a2f5a',
    '#0a2f5d',
    '#0b305f',
    '#0c3062',
    '#0d3064',
    '#0e3167',
    '#0f3169',
    '#10326c',
    '#11326e',
    '#123271',
    '#143274',
    '#153376',
    '#163379',
    '#18337c',
    '#1a337e',
    '#1b3381',
    '#1d3384',
    '#1f3386',
    '#213389',
    '#23338c',
    '#25338e',
    '#273391',
    '#293393',
    '#2b3395',
    '#2e3397',
    '#303399',
    '#32329a',
    '#35329b',
    '#37339d',
    '#39339d',
    '#3b339e',
    '#3d339f',
    '#3f339f',
    '#41349f',
    '#43349f',
    '#45359f',
    '#47359f',
    '#49369f',
    '#4a369f',
    '#4c379e',
    '#4e379e',
    '#4f389d',
    '#51399d',
    '#53399c',
    '#543a9c',
    '#563b9c',
    '#573b9b',
    '#593c9b',
    '#5a3d9a',
    '#5c3d9a',
    '#5d3e99',
    '#5f3f99',
    '#603f98',
    '#614098',
    '#634197',
    '#644297',
    '#664296',
    '#674396',
    '#684395',
    '#6a4495',
    '#6b4594',
    '#6c4594',
    '#6e4693',
    '#6f4793',
    '#704793',
    '#724892',
    '#734992',
    '#754992',
    '#764a91',
    '#774a91',
    '#794b90',
    '#7a4c90',
    '#7b4c90',
    '#7d4d8f',
    '#7e4d8f',
    '#7f4e8f',
    '#814e8e',
    '#824f8e',
    '#83508e',
    '#85508e',
    '#86518d',
    '#87518d',
    '#89528d',
    '#8a528c',
    '#8c538c',
    '#8d538c',
    '#8e548b',
    '#90548b',
    '#91558b',
    '#93558a',
    '#94568a',
    '#96568a',
    '#975789',
    '#985789',
    '#9a5888',
    '#9b5888',
    '#9d5988',
    '#9e5987',
    '#a05a87',
    '#a15a86',
    '#a35b86',
    '#a45b85',
    '#a65c85',
    '#a75c85',
    '#a95d84',
    '#aa5d83',
    '#ac5d83',
    '#ad5e82',
    '#af5e82',
    '#b05f81',
    '#b25f81',
    '#b36080',
    '#b5607f',
    '#b6617f',
    '#b8617e',
    '#b9617d',
    '#bb627c',
    '#bc627c',
    '#be637b',
    '#bf637a',
    '#c16479',
    '#c26478',
    '#c46578',
    '#c56577',
    '#c76676',
    '#c86675',
    '#ca6774',
    '#cb6773',
    '#cd6872',
    '#ce6871',
    '#cf6970',
    '#d1696f',
    '#d26a6e',
    '#d46b6d',
    '#d56b6c',
    '#d66c6b',
    '#d86c69',
    '#d96d68',
    '#db6e67',
    '#dc6e66',
    '#dd6f65',
    '#de7064',
    '#e07062',
    '#e17161',
    '#e27260',
    '#e3735f',
    '#e5745d',
    '#e6745c',
    '#e7755b',
    '#e8765a',
    '#e97758',
    '#ea7857',
    '#eb7956',
    '#ec7a55',
    '#ed7b53',
    '#ee7c52',
    '#ef7d51',
    '#f07e50',
    '#f17f4f',
    '#f1804e',
    '#f2824c',
    '#f3834b',
    '#f3844a',
    '#f48549',
    '#f58748',
    '#f58847',
    '#f68946',
    '#f68b45',
    '#f78c45',
    '#f78d44',
    '#f88f43',
    '#f89042',
    '#f99241',
    '#f99341',
    '#f99440',
    '#fa963f',
    '#fa973f',
    '#fa993e',
    '#fa9a3e',
    '#fa9c3e',
    '#fb9d3d',
    '#fb9f3d',
    '#fba13d',
    '#fba23c',
    '#fba43c',
    '#fba53c',
    '#fba73c',
    '#fba83c',
    '#fbaa3c',
    '#fbac3c',
    '#fbad3c',
    '#fbaf3c',
    '#fbb03c',
    '#fbb23c',
    '#fbb43c',
    '#fbb53d',
    '#fbb73d',
    '#fab83d',
    '#faba3e',
    '#fabc3e',
    '#fabd3e',
    '#fabf3f',
    '#f9c13f',
    '#f9c240',
    '#f9c440',
    '#f9c641',
    '#f8c741',
    '#f8c942',
    '#f8cb43',
    '#f7cc43',
    '#f7ce44',
    '#f6d045',
    '#f6d145',
    '#f6d346',
    '#f5d547',
    '#f5d648',
    '#f4d848',
    '#f4da49',
    '#f3db4a',
    '#f3dd4b',
    '#f2df4c',
    '#f2e04d',
    '#f1e24d',
    '#f1e44e',
    '#f0e54f',
    '#efe750',
    '#efe951',
    '#eeea52',
    '#eeec53',
    '#edee54',
    '#ecf055',
    '#ebf156',
    '#ebf356',
    '#eaf557',
    '#e9f658',
    '#e8f859',
    '#e7fa5a',
]);
export const C_HALINE_SCALE = chroma.scale([
    '#29186b',
    '#2a186e',
    '#2a1870',
    '#2a1972',
    '#2b1975',
    '#2b1977',
    '#2c197a',
    '#2c1a7c',
    '#2c1a7f',
    '#2d1a81',
    '#2d1a84',
    '#2d1b86',
    '#2d1b89',
    '#2e1b8b',
    '#2e1c8e',
    '#2e1c90',
    '#2e1d93',
    '#2e1d95',
    '#2d1e97',
    '#2d1f99',
    '#2d209b',
    '#2c219d',
    '#2b229f',
    '#2a23a0',
    '#2925a1',
    '#2827a2',
    '#2728a2',
    '#262aa2',
    '#252ca2',
    '#232ea2',
    '#2230a2',
    '#2131a2',
    '#1f33a1',
    '#1e35a1',
    '#1d37a0',
    '#1b38a0',
    '#1a3a9f',
    '#193b9f',
    '#183d9e',
    '#163e9d',
    '#15409d',
    '#14419c',
    '#13429b',
    '#12449b',
    '#11459a',
    '#104699',
    '#0f4799',
    '#0f4998',
    '#0e4a98',
    '#0d4b97',
    '#0d4c96',
    '#0c4d96',
    '#0c4e95',
    '#0c4f95',
    '#0c5194',
    '#0c5294',
    '#0c5393',
    '#0c5493',
    '#0c5592',
    '#0c5692',
    '#0d5791',
    '#0d5891',
    '#0e5990',
    '#0e5a90',
    '#0f5b90',
    '#0f5c8f',
    '#105d8f',
    '#115d8f',
    '#125e8e',
    '#125f8e',
    '#13608e',
    '#14618d',
    '#15628d',
    '#15638d',
    '#16648c',
    '#17658c',
    '#18668c',
    '#19678c',
    '#1a678c',
    '#1a688b',
    '#1b698b',
    '#1c6a8b',
    '#1d6b8b',
    '#1e6c8b',
    '#1f6d8a',
    '#1f6d8a',
    '#206e8a',
    '#216f8a',
    '#22708a',
    '#23718a',
    '#237289',
    '#247389',
    '#257389',
    '#267489',
    '#277589',
    '#277689',
    '#287789',
    '#297889',
    '#2a7989',
    '#2a7989',
    '#2b7a89',
    '#2c7b88',
    '#2c7c88',
    '#2d7d88',
    '#2e7e88',
    '#2e7f88',
    '#2f7f88',
    '#308088',
    '#308188',
    '#318288',
    '#318388',
    '#328488',
    '#338588',
    '#338588',
    '#348688',
    '#348788',
    '#358888',
    '#368988',
    '#368a87',
    '#378b87',
    '#378c87',
    '#388c87',
    '#388d87',
    '#398e87',
    '#398f87',
    '#3a9087',
    '#3a9187',
    '#3b9287',
    '#3b9387',
    '#3c9486',
    '#3c9486',
    '#3d9586',
    '#3d9686',
    '#3e9786',
    '#3e9886',
    '#3f9985',
    '#409a85',
    '#409b85',
    '#419c85',
    '#419d85',
    '#429e84',
    '#429e84',
    '#439f84',
    '#43a084',
    '#44a183',
    '#45a283',
    '#45a383',
    '#46a483',
    '#46a582',
    '#47a682',
    '#48a782',
    '#48a881',
    '#49a981',
    '#4aa980',
    '#4aaa80',
    '#4bab80',
    '#4cac7f',
    '#4dad7f',
    '#4eae7e',
    '#4eaf7e',
    '#4fb07d',
    '#50b17d',
    '#51b27c',
    '#52b37c',
    '#53b37b',
    '#54b47b',
    '#55b57a',
    '#56b679',
    '#57b779',
    '#58b878',
    '#59b978',
    '#5aba77',
    '#5bbb76',
    '#5cbb76',
    '#5ebc75',
    '#5fbd74',
    '#60be73',
    '#62bf73',
    '#63c072',
    '#64c171',
    '#66c170',
    '#67c270',
    '#69c36f',
    '#6ac46e',
    '#6cc56d',
    '#6dc66c',
    '#6fc66b',
    '#71c76b',
    '#72c86a',
    '#74c969',
    '#76c968',
    '#78ca67',
    '#7acb66',
    '#7ccc65',
    '#7ecc64',
    '#80cd64',
    '#82ce63',
    '#84ce62',
    '#86cf61',
    '#88d060',
    '#8ad05f',
    '#8dd15f',
    '#8fd25e',
    '#91d25d',
    '#94d35d',
    '#96d35c',
    '#98d45c',
    '#9bd45c',
    '#9dd55b',
    '#a0d65b',
    '#a2d65b',
    '#a5d75b',
    '#a7d75b',
    '#aad75c',
    '#acd85c',
    '#afd85c',
    '#b1d95d',
    '#b3d95e',
    '#b6da5e',
    '#b8da5f',
    '#badb60',
    '#bddb61',
    '#bfdc62',
    '#c1dc63',
    '#c3dd64',
    '#c5dd66',
    '#c7de67',
    '#cade68',
    '#ccdf6a',
    '#cedf6b',
    '#d0e06d',
    '#d2e06e',
    '#d4e170',
    '#d6e171',
    '#d8e273',
    '#d9e275',
    '#dbe376',
    '#dde478',
    '#dfe47a',
    '#e1e57b',
    '#e3e57d',
    '#e5e67f',
    '#e6e681',
    '#e8e783',
    '#eae884',
    '#ece886',
    '#eee988',
    '#efe98a',
    '#f1ea8c',
    '#f3eb8e',
    '#f5eb90',
    '#f6ec91',
    '#f8ec93',
    '#faed95',
    '#fbee97',
    '#fdee99',
]);
export const C_SOLAR_SCALE = chroma.scale([
    '#331317',
    '#341418',
    '#351418',
    '#371519',
    '#381519',
    '#39151a',
    '#3a161a',
    '#3b161b',
    '#3d171b',
    '#3e171c',
    '#3f171c',
    '#40181c',
    '#42181d',
    '#43191d',
    '#44191e',
    '#45191e',
    '#471a1e',
    '#481a1f',
    '#491b1f',
    '#4a1b1f',
    '#4c1b20',
    '#4d1c20',
    '#4e1c20',
    '#4f1c21',
    '#511d21',
    '#521d21',
    '#531d22',
    '#541e22',
    '#561e22',
    '#571e22',
    '#581f23',
    '#591f23',
    '#5b1f23',
    '#5c2023',
    '#5d2023',
    '#5e2023',
    '#602124',
    '#612124',
    '#622124',
    '#632224',
    '#652224',
    '#662224',
    '#672324',
    '#682324',
    '#6a2324',
    '#6b2424',
    '#6c2424',
    '#6d2524',
    '#6f2524',
    '#702524',
    '#712624',
    '#722624',
    '#742624',
    '#752724',
    '#762724',
    '#772824',
    '#782823',
    '#7a2923',
    '#7b2923',
    '#7c2a23',
    '#7d2a23',
    '#7e2b22',
    '#7f2b22',
    '#812c22',
    '#822c22',
    '#832d21',
    '#842d21',
    '#852e21',
    '#862e21',
    '#872f20',
    '#883020',
    '#893020',
    '#8a311f',
    '#8b321f',
    '#8c321f',
    '#8d331f',
    '#8e341e',
    '#8f341e',
    '#90351e',
    '#91361d',
    '#92371d',
    '#93381d',
    '#94381c',
    '#95391c',
    '#963a1c',
    '#973b1b',
    '#973c1b',
    '#983c1b',
    '#993d1b',
    '#9a3e1a',
    '#9b3f1a',
    '#9c401a',
    '#9c4119',
    '#9d4219',
    '#9e4319',
    '#9f4319',
    '#a04418',
    '#a04518',
    '#a14618',
    '#a24718',
    '#a34817',
    '#a44917',
    '#a44a17',
    '#a54b17',
    '#a64c16',
    '#a64d16',
    '#a74e16',
    '#a84f16',
    '#a95015',
    '#a95115',
    '#aa5215',
    '#ab5315',
    '#ab5415',
    '#ac5514',
    '#ad5614',
    '#ad5714',
    '#ae5814',
    '#af5914',
    '#af5a14',
    '#b05b14',
    '#b15c13',
    '#b15d13',
    '#b25e13',
    '#b35f13',
    '#b36013',
    '#b46113',
    '#b46213',
    '#b56313',
    '#b66413',
    '#b66513',
    '#b76613',
    '#b76712',
    '#b86812',
    '#b96912',
    '#b96a12',
    '#ba6b12',
    '#ba6c12',
    '#bb6d12',
    '#bb6e12',
    '#bc6f13',
    '#bc7113',
    '#bd7213',
    '#be7313',
    '#be7413',
    '#bf7513',
    '#bf7613',
    '#c07713',
    '#c07813',
    '#c17913',
    '#c17a14',
    '#c27b14',
    '#c27c14',
    '#c37e14',
    '#c37f14',
    '#c48014',
    '#c48115',
    '#c58215',
    '#c58315',
    '#c68415',
    '#c68516',
    '#c78616',
    '#c78716',
    '#c78916',
    '#c88a17',
    '#c88b17',
    '#c98c17',
    '#c98d18',
    '#ca8e18',
    '#ca8f18',
    '#cb9019',
    '#cb9219',
    '#cb9319',
    '#cc941a',
    '#cc951a',
    '#cd961b',
    '#cd971b',
    '#cd991b',
    '#ce9a1c',
    '#ce9b1c',
    '#cf9c1d',
    '#cf9d1d',
    '#cf9e1e',
    '#d09f1e',
    '#d0a11f',
    '#d1a21f',
    '#d1a320',
    '#d1a420',
    '#d2a520',
    '#d2a721',
    '#d2a821',
    '#d3a922',
    '#d3aa22',
    '#d3ab23',
    '#d4ad24',
    '#d4ae24',
    '#d4af25',
    '#d5b025',
    '#d5b126',
    '#d5b326',
    '#d6b427',
    '#d6b527',
    '#d6b628',
    '#d7b728',
    '#d7b929',
    '#d7ba2a',
    '#d8bb2a',
    '#d8bc2b',
    '#d8be2b',
    '#d8bf2c',
    '#d9c02c',
    '#d9c12d',
    '#d9c32e',
    '#d9c42e',
    '#dac52f',
    '#dac62f',
    '#dac830',
    '#dac931',
    '#dbca31',
    '#dbcb32',
    '#dbcd33',
    '#dbce33',
    '#dccf34',
    '#dcd034',
    '#dcd235',
    '#dcd336',
    '#dcd436',
    '#ddd637',
    '#ddd738',
    '#ddd838',
    '#ddda39',
    '#dddb3a',
    '#dedc3a',
    '#dede3b',
    '#dedf3c',
    '#dee03c',
    '#dee23d',
    '#dee33d',
    '#dfe43e',
    '#dfe63f',
    '#dfe73f',
    '#dfe840',
    '#dfea41',
    '#dfeb41',
    '#dfec42',
    '#dfee43',
    '#dfef44',
    '#e0f044',
    '#e0f245',
    '#e0f346',
    '#e0f546',
    '#e0f647',
    '#e0f748',
    '#e0f948',
    '#e0fa49',
    '#e0fc4a',
    '#e0fd4a',
]);
export const C_ICE_SCALE = chroma.scale([
    '#030512',
    '#040613',
    '#050715',
    '#060816',
    '#060818',
    '#070919',
    '#080a1b',
    '#090b1c',
    '#0a0c1e',
    '#0b0d1f',
    '#0c0e20',
    '#0d0f22',
    '#0e1023',
    '#0f1025',
    '#101126',
    '#111228',
    '#121329',
    '#13142a',
    '#14152c',
    '#15152d',
    '#16162f',
    '#171730',
    '#181832',
    '#191933',
    '#1a1935',
    '#1b1a36',
    '#1b1b38',
    '#1c1c39',
    '#1d1d3b',
    '#1e1d3c',
    '#1f1e3e',
    '#201f3f',
    '#212041',
    '#222042',
    '#222144',
    '#232245',
    '#242347',
    '#252348',
    '#26244a',
    '#27254b',
    '#27264d',
    '#28264f',
    '#292750',
    '#2a2852',
    '#2a2953',
    '#2b2955',
    '#2c2a57',
    '#2d2b58',
    '#2d2c5a',
    '#2e2d5b',
    '#2f2d5d',
    '#302e5f',
    '#302f60',
    '#313062',
    '#323064',
    '#323165',
    '#333267',
    '#343369',
    '#34336a',
    '#35346c',
    '#35356e',
    '#36366f',
    '#373771',
    '#373773',
    '#383874',
    '#383976',
    '#393a78',
    '#393b79',
    '#3a3b7b',
    '#3a3c7d',
    '#3a3d7e',
    '#3b3e80',
    '#3b3f82',
    '#3c4083',
    '#3c4185',
    '#3c4186',
    '#3c4288',
    '#3d438a',
    '#3d448b',
    '#3d458d',
    '#3d468e',
    '#3e4790',
    '#3e4891',
    '#3e4993',
    '#3e4a94',
    '#3e4b96',
    '#3e4c97',
    '#3e4d98',
    '#3e4e9a',
    '#3e4f9b',
    '#3e509c',
    '#3e519d',
    '#3e529f',
    '#3e53a0',
    '#3e54a1',
    '#3e55a2',
    '#3e57a3',
    '#3e58a4',
    '#3e59a5',
    '#3e5aa6',
    '#3e5ba7',
    '#3e5ca8',
    '#3e5da8',
    '#3e5ea9',
    '#3e60aa',
    '#3e61ab',
    '#3e62ac',
    '#3e63ac',
    '#3e64ad',
    '#3e65ae',
    '#3e66ae',
    '#3e67af',
    '#3e69af',
    '#3e6ab0',
    '#3e6bb1',
    '#3e6cb1',
    '#3e6db2',
    '#3e6eb2',
    '#3f6fb3',
    '#3f70b3',
    '#3f72b4',
    '#3f73b4',
    '#4074b4',
    '#4075b5',
    '#4076b5',
    '#4177b6',
    '#4178b6',
    '#4179b7',
    '#427ab7',
    '#427bb7',
    '#437db8',
    '#437eb8',
    '#447fb9',
    '#4480b9',
    '#4581b9',
    '#4582ba',
    '#4683ba',
    '#4684ba',
    '#4785bb',
    '#4886bb',
    '#4887bc',
    '#4988bc',
    '#4a89bc',
    '#4a8bbd',
    '#4b8cbd',
    '#4c8dbd',
    '#4c8ebe',
    '#4d8fbe',
    '#4e90be',
    '#4f91bf',
    '#4f92bf',
    '#5093c0',
    '#5194c0',
    '#5295c0',
    '#5296c1',
    '#5397c1',
    '#5498c1',
    '#5599c2',
    '#569ac2',
    '#579cc3',
    '#589dc3',
    '#589ec3',
    '#599fc4',
    '#5aa0c4',
    '#5ba1c5',
    '#5ca2c5',
    '#5da3c5',
    '#5ea4c6',
    '#5fa5c6',
    '#60a6c7',
    '#61a7c7',
    '#62a8c7',
    '#63a9c8',
    '#64aac8',
    '#65abc9',
    '#66acc9',
    '#67aec9',
    '#68afca',
    '#69b0ca',
    '#6ab1cb',
    '#6bb2cb',
    '#6db3cb',
    '#6eb4cc',
    '#6fb5cc',
    '#70b6cd',
    '#71b7cd',
    '#72b8cd',
    '#74b9ce',
    '#75bace',
    '#76bbcf',
    '#77bccf',
    '#79bdd0',
    '#7abed0',
    '#7bbfd0',
    '#7dc0d1',
    '#7ec1d1',
    '#80c2d2',
    '#81c3d2',
    '#83c4d3',
    '#84c6d3',
    '#86c7d3',
    '#87c8d4',
    '#89c9d4',
    '#8acad5',
    '#8ccbd5',
    '#8ecbd6',
    '#8fccd6',
    '#91cdd7',
    '#93ced7',
    '#95cfd8',
    '#96d0d8',
    '#98d1d9',
    '#9ad2d9',
    '#9cd3da',
    '#9ed4db',
    '#a0d5db',
    '#a1d6dc',
    '#a3d7dc',
    '#a5d8dd',
    '#a7d9de',
    '#a9dade',
    '#abdbdf',
    '#addce0',
    '#afdde1',
    '#b0dde1',
    '#b2dee2',
    '#b4dfe3',
    '#b6e0e4',
    '#b8e1e4',
    '#bae2e5',
    '#bce3e6',
    '#bee4e7',
    '#c0e5e8',
    '#c1e6e9',
    '#c3e7ea',
    '#c5e8ea',
    '#c7e9eb',
    '#c9eaec',
    '#cbebed',
    '#cdecee',
    '#cfedef',
    '#d0eef0',
    '#d2eff1',
    '#d4f0f2',
    '#d6f1f3',
    '#d8f2f4',
    '#daf3f5',
    '#dbf4f5',
    '#ddf5f6',
    '#dff6f7',
    '#e1f7f8',
    '#e3f8f9',
    '#e4f9fa',
    '#e6fafb',
    '#e8fbfc',
    '#eafcfd',
]);
export const C_OXY_SCALE = chroma.scale([
    '#3f0505',
    '#410505',
    '#420505',
    '#430505',
    '#460606',
    '#470606',
    '#490607',
    '#4a0607',
    '#4d0608',
    '#4e0608',
    '#4f0609',
    '#510609',
    '#53070a',
    '#55070a',
    '#56070a',
    '#58070b',
    '#5a070b',
    '#5c070c',
    '#5d070c',
    '#5e070c',
    '#61070d',
    '#62060d',
    '#64060d',
    '#65060d',
    '#68060e',
    '#69060e',
    '#6b060e',
    '#6c060e',
    '#6f050f',
    '#70050f',
    '#72050f',
    '#73050f',
    '#76050f',
    '#77050e',
    '#78040e',
    '#7a040e',
    '#7c050e',
    '#7e050d',
    '#7f050d',
    '#80060d',
    '#83070c',
    '#84080b',
    '#850a0b',
    '#860b0a',
    '#880e09',
    '#890f09',
    '#8a1109',
    '#8b1208',
    '#8c1508',
    '#8d1607',
    '#8e1807',
    '#4f4f4e',
    '#504f4f',
    '#515050',
    '#515151',
    '#535252',
    '#545353',
    '#545453',
    '#555554',
    '#575656',
    '#575756',
    '#585857',
    '#595858',
    '#5a5a59',
    '#5b5b5a',
    '#5c5b5b',
    '#5d5c5c',
    '#5e5e5d',
    '#5f5e5e',
    '#605f5f',
    '#60605f',
    '#626161',
    '#636262',
    '#636362',
    '#646463',
    '#666565',
    '#666665',
    '#676766',
    '#686767',
    '#696968',
    '#6a6a69',
    '#6b6a6a',
    '#6c6b6b',
    '#6d6d6c',
    '#6e6e6d',
    '#6f6e6e',
    '#706f6f',
    '#717170',
    '#727171',
    '#737272',
    '#737372',
    '#757574',
    '#767575',
    '#777676',
    '#777776',
    '#797878',
    '#7a7979',
    '#7b7a79',
    '#7b7b7a',
    '#7d7c7c',
    '#7e7d7d',
    '#7e7e7d',
    '#80807f',
    '#818080',
    '#828181',
    '#828281',
    '#848483',
    '#858484',
    '#868585',
    '#868685',
    '#888887',
    '#898888',
    '#8a8989',
    '#8b8a89',
    '#8c8c8b',
    '#8d8d8c',
    '#8e8d8d',
    '#8f8e8e',
    '#90908f',
    '#919190',
    '#929291',
    '#939292',
    '#949493',
    '#959594',
    '#969695',
    '#979796',
    '#999897',
    '#999998',
    '#9a9a99',
    '#9b9b9a',
    '#9d9c9c',
    '#9e9d9d',
    '#9f9e9d',
    '#9f9f9e',
    '#a1a1a0',
    '#a2a2a1',
    '#a3a3a2',
    '#a4a3a3',
    '#a5a5a4',
    '#a6a6a5',
    '#a7a7a6',
    '#a8a8a7',
    '#aaaaa9',
    '#abaaaa',
    '#acabaa',
    '#adacab',
    '#aeaead',
    '#afafae',
    '#b0b0af',
    '#b1b1b0',
    '#b3b2b2',
    '#b4b3b2',
    '#b5b4b3',
    '#b6b6b5',
    '#b7b7b6',
    '#b8b8b7',
    '#b9b9b8',
    '#bbbbba',
    '#bcbcbb',
    '#bdbdbc',
    '#bebdbc',
    '#c0bfbe',
    '#c1c0bf',
    '#c2c1c0',
    '#c2c2c1',
    '#c4c4c3',
    '#c5c5c4',
    '#c6c6c5',
    '#c7c7c6',
    '#c9c9c8',
    '#cacac9',
    '#cbcbca',
    '#cccccb',
    '#cececd',
    '#cfcfce',
    '#d0d0ce',
    '#d1d1cf',
    '#d3d3d1',
    '#d4d4d2',
    '#d5d5d3',
    '#d6d6d4',
    '#d8d8d6',
    '#d9d9d7',
    '#dadad8',
    '#dbdbd9',
    '#dddddb',
    '#dededc',
    '#dfdfdd',
    '#e0e0de',
    '#e2e2e0',
    '#e3e3e2',
    '#e4e4e3',
    '#e5e5e4',
    '#e7e7e6',
    '#e8e8e7',
    '#e9e9e8',
    '#eaeae9',
    '#ececeb',
    '#ededec',
    '#efeeed',
    '#f0efee',
    '#f2f2f0',
    '#f3f3f1',
    '#f4f4f2',
    '#f7fe68',
    '#f6fd66',
    '#f4fc64',
    '#f3fb61',
    '#f1f95c',
    '#eff85a',
    '#eef757',
    '#edf654',
    '#ebf44e',
    '#ebf34b',
    '#eaf248',
    '#eaf045',
    '#eaee40',
    '#e9ec3e',
    '#e9eb3c',
    '#e9e93a',
    '#e9e738',
    '#e9e536',
    '#e9e435',
    '#e9e234',
    '#e8e032',
    '#e8de31',
    '#e8dd30',
    '#e8dc2f',
    '#e7d92d',
    '#e7d72c',
    '#e7d62c',
    '#e6d52b',
    '#e6d229',
    '#e6d129',
    '#e5cf28',
    '#e5ce27',
    '#e4cc26',
    '#e4ca25',
    '#e4c925',
    '#e3c824',
    '#e3c523',
    '#e2c422',
    '#e2c222',
    '#e2c121',
    '#e1bf20',
    '#e1bd1f',
    '#e0bc1f',
    '#e0bb1e',
    '#dfb81d',
    '#dfb71d',
    '#deb61c',
    '#deb51c',
    '#ddb21a',
    '#ddb11a',
    '#dcb019',
    '#dcae19',
]);
export const C_DEEP_SCALE = chroma.scale(
    [
        '#fdfdcc',
        '#fbfcca',
        '#f9fcc9',
        '#f7fbc8',
        '#f5fac7',
        '#f3f9c6',
        '#f1f8c4',
        '#eff8c3',
        '#edf7c2',
        '#ebf6c1',
        '#e8f5c0',
        '#e6f5bf',
        '#e4f4be',
        '#e2f3bd',
        '#e0f2bc',
        '#def2bb',
        '#dcf1ba',
        '#daf0b9',
        '#d8f0b8',
        '#d6efb7',
        '#d4eeb6',
        '#d2edb5',
        '#d0edb4',
        '#ceecb3',
        '#ccebb2',
        '#c9ebb1',
        '#c7eab0',
        '#c5e9b0',
        '#c3e8af',
        '#c1e8ae',
        '#bfe7ad',
        '#bde6ad',
        '#bbe6ac',
        '#b8e5ab',
        '#b6e4aa',
        '#b4e3aa',
        '#b2e3a9',
        '#b0e2a9',
        '#aee1a8',
        '#abe1a8',
        '#a9e0a7',
        '#a7dfa7',
        '#a5dea6',
        '#a3dea6',
        '#a0dda5',
        '#9edca5',
        '#9cdba5',
        '#9adba4',
        '#98daa4',
        '#95d9a4',
        '#93d8a3',
        '#91d8a3',
        '#8fd7a3',
        '#8dd6a3',
        '#8bd5a3',
        '#89d5a3',
        '#87d4a3',
        '#85d3a3',
        '#83d2a2',
        '#81d1a2',
        '#7fd1a2',
        '#7dd0a2',
        '#7bcfa2',
        '#79cea2',
        '#77cda2',
        '#76cca3',
        '#74cba3',
        '#72caa3',
        '#71caa3',
        '#6fc9a3',
        '#6ec8a3',
        '#6cc7a3',
        '#6bc6a3',
        '#69c5a3',
        '#68c4a3',
        '#67c3a3',
        '#66c2a3',
        '#64c1a3',
        '#63c0a3',
        '#62bfa3',
        '#61bea3',
        '#60bda3',
        '#5fbca3',
        '#5ebba3',
        '#5dbaa3',
        '#5cb9a3',
        '#5cb8a3',
        '#5bb7a3',
        '#5ab6a3',
        '#59b5a3',
        '#58b4a3',
        '#58b3a3',
        '#57b2a3',
        '#56b1a3',
        '#56b0a3',
        '#55afa3',
        '#55aea3',
        '#54ada3',
        '#53aca3',
        '#53aba3',
        '#52aaa3',
        '#52a9a2',
        '#51a8a2',
        '#51a7a2',
        '#51a6a2',
        '#50a5a2',
        '#50a4a2',
        '#4fa3a2',
        '#4fa2a1',
        '#4ea1a1',
        '#4ea0a1',
        '#4e9fa1',
        '#4d9ea1',
        '#4d9ca0',
        '#4c9ba0',
        '#4c9aa0',
        '#4c99a0',
        '#4b98a0',
        '#4b979f',
        '#4b969f',
        '#4a959f',
        '#4a949f',
        '#4a939f',
        '#49929e',
        '#49919e',
        '#49909e',
        '#488f9e',
        '#488e9d',
        '#488d9d',
        '#478c9d',
        '#478b9d',
        '#478a9d',
        '#46899c',
        '#46889c',
        '#46879c',
        '#45869c',
        '#45859b',
        '#45849b',
        '#44839b',
        '#44829b',
        '#44819a',
        '#43819a',
        '#43809a',
        '#437f9a',
        '#427e9a',
        '#427d99',
        '#427c99',
        '#427b99',
        '#417a99',
        '#417999',
        '#417898',
        '#407798',
        '#407698',
        '#407598',
        '#407497',
        '#3f7397',
        '#3f7297',
        '#3f7197',
        '#3f7097',
        '#3f6f96',
        '#3e6e96',
        '#3e6d96',
        '#3e6c96',
        '#3e6b96',
        '#3e6a95',
        '#3e6995',
        '#3e6795',
        '#3d6695',
        '#3d6595',
        '#3d6494',
        '#3d6394',
        '#3d6294',
        '#3d6194',
        '#3d6093',
        '#3d5f93',
        '#3d5e93',
        '#3d5d93',
        '#3d5c92',
        '#3d5b92',
        '#3d5a92',
        '#3d5991',
        '#3e5891',
        '#3e5791',
        '#3e5590',
        '#3e5490',
        '#3e538f',
        '#3e528f',
        '#3f518e',
        '#3f508e',
        '#3f4f8d',
        '#3f4e8c',
        '#404d8b',
        '#404c8a',
        '#404b89',
        '#404a88',
        '#404887',
        '#414786',
        '#414685',
        '#414584',
        '#414582',
        '#414481',
        '#41437f',
        '#41427e',
        '#41417c',
        '#41407b',
        '#413f79',
        '#413e78',
        '#403d76',
        '#403d74',
        '#403c73',
        '#403b71',
        '#3f3a6f',
        '#3f396e',
        '#3f396c',
        '#3e386a',
        '#3e3769',
        '#3e3667',
        '#3d3665',
        '#3d3563',
        '#3d3462',
        '#3c3360',
        '#3c335f',
        '#3b325d',
        '#3b315b',
        '#3a305a',
        '#3a3058',
        '#392f56',
        '#392e55',
        '#382e53',
        '#382d52',
        '#372c50',
        '#362b4e',
        '#362b4d',
        '#352a4b',
        '#35294a',
        '#342948',
        '#342847',
        '#332745',
        '#322644',
        '#322642',
        '#312541',
        '#31243f',
        '#30233e',
        '#2f233c',
        '#2f223b',
        '#2e2139',
        '#2d2038',
        '#2d2037',
        '#2c1f35',
        '#2b1e34',
        '#2b1d32',
        '#2a1d31',
        '#291c30',
        '#291b2e',
        '#281a2d',
        '#271a2c',
    ].reverse()
);
export const C_DENSE_SCALE = chroma.scale([
    '#e6f0f0',
    '#e4efef',
    '#e2efef',
    '#e0eeee',
    '#dfedee',
    '#ddeced',
    '#dbebec',
    '#daebec',
    '#d8eaeb',
    '#d6e9eb',
    '#d4e8ea',
    '#d3e7ea',
    '#d1e6e9',
    '#cfe6e9',
    '#cee5e8',
    '#cce4e8',
    '#cae3e7',
    '#c9e2e7',
    '#c7e1e7',
    '#c5e1e6',
    '#c4e0e6',
    '#c2dfe6',
    '#c0dee5',
    '#bfdde5',
    '#bddce5',
    '#bbdce4',
    '#badbe4',
    '#b8dae4',
    '#b7d9e4',
    '#b5d8e4',
    '#b3d7e3',
    '#b2d7e3',
    '#b0d6e3',
    '#afd5e3',
    '#add4e3',
    '#acd3e3',
    '#aad2e2',
    '#a9d1e2',
    '#a7d1e2',
    '#a6d0e2',
    '#a4cfe2',
    '#a3cee2',
    '#a1cde2',
    '#a0cce2',
    '#9fcbe2',
    '#9dcae2',
    '#9cc9e2',
    '#9ac9e2',
    '#99c8e2',
    '#98c7e2',
    '#96c6e2',
    '#95c5e2',
    '#94c4e2',
    '#92c3e2',
    '#91c2e2',
    '#90c1e2',
    '#8fc0e2',
    '#8dbfe2',
    '#8cbee2',
    '#8bbee2',
    '#8abde2',
    '#89bce2',
    '#88bbe2',
    '#87bae2',
    '#86b9e2',
    '#85b8e2',
    '#84b7e3',
    '#83b6e3',
    '#82b5e3',
    '#81b4e3',
    '#80b3e3',
    '#7fb2e3',
    '#7eb1e3',
    '#7db0e3',
    '#7cafe3',
    '#7caee3',
    '#7bade3',
    '#7aace4',
    '#7aabe4',
    '#79aae4',
    '#78a9e4',
    '#78a7e4',
    '#77a6e4',
    '#77a5e4',
    '#76a4e4',
    '#76a3e4',
    '#75a2e4',
    '#75a1e4',
    '#75a0e4',
    '#749fe4',
    '#749ee4',
    '#749ce4',
    '#749be4',
    '#739ae4',
    '#7399e4',
    '#7398e4',
    '#7397e4',
    '#7396e3',
    '#7394e3',
    '#7393e3',
    '#7392e3',
    '#7391e3',
    '#7390e3',
    '#738fe2',
    '#738de2',
    '#738ce2',
    '#738be1',
    '#738ae1',
    '#7389e1',
    '#7487e0',
    '#7486e0',
    '#7485df',
    '#7484df',
    '#7483df',
    '#7481de',
    '#7580dd',
    '#757fdd',
    '#757edc',
    '#757ddc',
    '#757bdb',
    '#767ada',
    '#7679da',
    '#7678d9',
    '#7677d8',
    '#7675d8',
    '#7674d7',
    '#7773d6',
    '#7772d5',
    '#7771d5',
    '#776fd4',
    '#776ed3',
    '#776dd2',
    '#786cd1',
    '#786bd0',
    '#7869cf',
    '#7868ce',
    '#7867cd',
    '#7866cc',
    '#7865cb',
    '#7864ca',
    '#7863c9',
    '#7961c8',
    '#7960c7',
    '#795fc6',
    '#795ec5',
    '#795dc4',
    '#795cc3',
    '#795bc2',
    '#7959c0',
    '#7958bf',
    '#7957be',
    '#7956bd',
    '#7955bc',
    '#7854ba',
    '#7853b9',
    '#7852b8',
    '#7851b7',
    '#7850b5',
    '#784fb4',
    '#784db3',
    '#784cb2',
    '#784bb0',
    '#774aaf',
    '#7749ae',
    '#7748ac',
    '#7747ab',
    '#7746aa',
    '#7745a8',
    '#7644a7',
    '#7643a5',
    '#7642a4',
    '#7641a3',
    '#7540a1',
    '#753fa0',
    '#753e9e',
    '#743d9d',
    '#743c9b',
    '#743b9a',
    '#743a99',
    '#733997',
    '#733896',
    '#733794',
    '#723693',
    '#723591',
    '#713490',
    '#71338e',
    '#71328d',
    '#70318b',
    '#70318a',
    '#6f3088',
    '#6f2f86',
    '#6e2e85',
    '#6e2d83',
    '#6d2c82',
    '#6d2b80',
    '#6c2a7f',
    '#6c2a7d',
    '#6b297b',
    '#6b287a',
    '#6a2778',
    '#6a2677',
    '#692575',
    '#692573',
    '#682472',
    '#672370',
    '#67226e',
    '#66226d',
    '#65216b',
    '#65206a',
    '#641f68',
    '#631f66',
    '#631e65',
    '#621d63',
    '#611d61',
    '#601c60',
    '#601c5e',
    '#5f1b5c',
    '#5e1a5b',
    '#5d1a59',
    '#5c1957',
    '#5c1956',
    '#5b1854',
    '#5a1852',
    '#591751',
    '#58174f',
    '#57164d',
    '#56164c',
    '#55164a',
    '#541549',
    '#531547',
    '#521445',
    '#511444',
    '#501442',
    '#4f1341',
    '#4e133f',
    '#4d133e',
    '#4c133c',
    '#4b123b',
    '#4a1239',
    '#491238',
    '#481237',
    '#471135',
    '#451134',
    '#441132',
    '#431131',
    '#421030',
    '#41102f',
    '#40102d',
    '#3e102c',
    '#3d0f2b',
    '#3c0f2a',
    '#3b0f28',
    '#390f27',
    '#380e26',
    '#370e25',
    '#360e24',
]);
export const C_ALGAE_SCALE = chroma.scale([
    '#d6f9cf',
    '#d5f8ce',
    '#d4f7cc',
    '#d3f6cb',
    '#d1f5c9',
    '#d0f4c8',
    '#cff3c6',
    '#cef2c5',
    '#ccf1c3',
    '#cbf0c2',
    '#caefc1',
    '#c9efbf',
    '#c7eebe',
    '#c6edbc',
    '#c5ecbb',
    '#c4ebb9',
    '#c2eab8',
    '#c1e9b7',
    '#c0e8b5',
    '#bfe7b4',
    '#bde7b2',
    '#bce6b1',
    '#bbe5b0',
    '#bae4ae',
    '#b8e3ad',
    '#b7e2ab',
    '#b6e1aa',
    '#b5e1a9',
    '#b3e0a7',
    '#b2dfa6',
    '#b1dea5',
    '#afdda3',
    '#aedca2',
    '#addba0',
    '#acdb9f',
    '#aada9e',
    '#a9d99c',
    '#a8d89b',
    '#a6d79a',
    '#a5d798',
    '#a4d697',
    '#a3d596',
    '#a1d495',
    '#a0d393',
    '#9fd392',
    '#9dd291',
    '#9cd18f',
    '#9bd08e',
    '#99cf8d',
    '#98cf8c',
    '#97ce8a',
    '#95cd89',
    '#94cc88',
    '#93cb87',
    '#91cb85',
    '#90ca84',
    '#8fc983',
    '#8dc882',
    '#8cc880',
    '#8bc77f',
    '#89c67e',
    '#88c57d',
    '#86c57c',
    '#85c47a',
    '#84c379',
    '#82c278',
    '#81c277',
    '#7fc176',
    '#7ec075',
    '#7cbf73',
    '#7bbf72',
    '#79be71',
    '#78bd70',
    '#76bc6f',
    '#75bc6e',
    '#73bb6d',
    '#72ba6c',
    '#70b96b',
    '#6fb969',
    '#6db868',
    '#6cb767',
    '#6ab766',
    '#68b665',
    '#67b564',
    '#65b563',
    '#64b462',
    '#62b361',
    '#60b260',
    '#5eb260',
    '#5db15f',
    '#5bb05e',
    '#59b05d',
    '#57af5c',
    '#55ae5b',
    '#53ae5a',
    '#52ad5a',
    '#50ac59',
    '#4eab58',
    '#4cab57',
    '#4aaa57',
    '#47a956',
    '#45a956',
    '#43a855',
    '#41a754',
    '#3fa754',
    '#3da654',
    '#3aa553',
    '#38a453',
    '#36a452',
    '#34a352',
    '#32a252',
    '#2fa152',
    '#2da151',
    '#2ba051',
    '#299f51',
    '#279e51',
    '#259d51',
    '#239d51',
    '#219c51',
    '#1f9b50',
    '#1e9a50',
    '#1c9950',
    '#1a9850',
    '#199850',
    '#179750',
    '#159650',
    '#149550',
    '#139450',
    '#11934f',
    '#10924f',
    '#0f924f',
    '#0d914f',
    '#0c904f',
    '#0b8f4f',
    '#0a8e4f',
    '#0a8d4e',
    '#098c4e',
    '#088b4e',
    '#088a4e',
    '#078a4e',
    '#07894d',
    '#07884d',
    '#06874d',
    '#06864d',
    '#06854d',
    '#06844c',
    '#06834c',
    '#07824c',
    '#07814c',
    '#07814b',
    '#07804b',
    '#087f4b',
    '#087e4a',
    '#097d4a',
    '#097c4a',
    '#0a7b4a',
    '#0a7a49',
    '#0b7949',
    '#0b7849',
    '#0c7748',
    '#0c7748',
    '#0d7648',
    '#0d7547',
    '#0e7447',
    '#0e7346',
    '#0f7246',
    '#0f7146',
    '#107045',
    '#106f45',
    '#116e45',
    '#116e44',
    '#126d44',
    '#126c43',
    '#126b43',
    '#136a43',
    '#136942',
    '#146842',
    '#146741',
    '#146641',
    '#156640',
    '#156540',
    '#15643f',
    '#15633f',
    '#16623f',
    '#16613e',
    '#16603e',
    '#175f3d',
    '#175e3d',
    '#175e3c',
    '#175d3c',
    '#175c3b',
    '#185b3b',
    '#185a3a',
    '#18593a',
    '#185839',
    '#185739',
    '#195638',
    '#195638',
    '#195537',
    '#195437',
    '#195336',
    '#195236',
    '#195135',
    '#195034',
    '#195034',
    '#194f33',
    '#194e33',
    '#194d32',
    '#194c32',
    '#194b31',
    '#194a31',
    '#194930',
    '#19492f',
    '#19482f',
    '#19472e',
    '#19462e',
    '#19452d',
    '#19442c',
    '#19432c',
    '#19432b',
    '#19422b',
    '#19412a',
    '#194029',
    '#193f29',
    '#193e28',
    '#183d28',
    '#183d27',
    '#183c26',
    '#183b26',
    '#183a25',
    '#183924',
    '#173824',
    '#173723',
    '#173723',
    '#173622',
    '#173521',
    '#163421',
    '#163320',
    '#16321f',
    '#16311f',
    '#16311e',
    '#15301d',
    '#152f1d',
    '#152e1c',
    '#152d1b',
    '#142c1b',
    '#142b1a',
    '#142b19',
    '#132a19',
    '#132918',
    '#132817',
    '#122716',
    '#122616',
    '#122515',
    '#112514',
    '#112414',
]);
export const C_MATTER_SCALE = chroma.scale([
    '#fdedb0',
    '#fdebae',
    '#fdeaad',
    '#fde9ac',
    '#fde7aa',
    '#fce6a9',
    '#fce4a7',
    '#fce3a6',
    '#fce2a5',
    '#fce0a3',
    '#fcdfa2',
    '#fcdda1',
    '#fcdc9f',
    '#fbdb9e',
    '#fbd99d',
    '#fbd89c',
    '#fbd69a',
    '#fbd599',
    '#fbd498',
    '#fbd296',
    '#fad195',
    '#facf94',
    '#face93',
    '#facd91',
    '#facb90',
    '#faca8f',
    '#fac88e',
    '#f9c78d',
    '#f9c68b',
    '#f9c48a',
    '#f9c389',
    '#f9c288',
    '#f9c087',
    '#f8bf86',
    '#f8bd84',
    '#f8bc83',
    '#f8bb82',
    '#f8b981',
    '#f7b880',
    '#f7b77f',
    '#f7b57e',
    '#f7b47d',
    '#f7b27c',
    '#f7b17a',
    '#f6b079',
    '#f6ae78',
    '#f6ad77',
    '#f6ac76',
    '#f5aa75',
    '#f5a974',
    '#f5a873',
    '#f5a672',
    '#f5a571',
    '#f4a370',
    '#f4a26f',
    '#f4a16e',
    '#f49f6d',
    '#f39e6c',
    '#f39d6c',
    '#f39b6b',
    '#f39a6a',
    '#f29969',
    '#f29768',
    '#f29667',
    '#f19466',
    '#f19365',
    '#f19265',
    '#f19064',
    '#f08f63',
    '#f08e62',
    '#f08c61',
    '#ef8b61',
    '#ef8a60',
    '#ef885f',
    '#ee875e',
    '#ee855e',
    '#ee845d',
    '#ed835c',
    '#ed815c',
    '#ed805b',
    '#ec7f5b',
    '#ec7d5a',
    '#eb7c59',
    '#eb7b59',
    '#eb7958',
    '#ea7858',
    '#ea7657',
    '#e97557',
    '#e97456',
    '#e87256',
    '#e87155',
    '#e87055',
    '#e76e55',
    '#e76d54',
    '#e66c54',
    '#e66a54',
    '#e56953',
    '#e56853',
    '#e46653',
    '#e46553',
    '#e36453',
    '#e26252',
    '#e26152',
    '#e16052',
    '#e15f52',
    '#e05d52',
    '#df5c52',
    '#df5b52',
    '#de5952',
    '#dd5852',
    '#dd5752',
    '#dc5652',
    '#db5552',
    '#da5352',
    '#da5253',
    '#d95153',
    '#d85053',
    '#d74f53',
    '#d74d53',
    '#d64c53',
    '#d54b54',
    '#d44a54',
    '#d34954',
    '#d24854',
    '#d14755',
    '#d04655',
    '#d04555',
    '#cf4456',
    '#ce4356',
    '#cd4256',
    '#cc4157',
    '#cb4057',
    '#ca3f57',
    '#c93e58',
    '#c83d58',
    '#c73c58',
    '#c63b59',
    '#c53a59',
    '#c43959',
    '#c3385a',
    '#c1385a',
    '#c0375a',
    '#bf365b',
    '#be355b',
    '#bd345b',
    '#bc345c',
    '#bb335c',
    '#ba325c',
    '#b9315d',
    '#b8305d',
    '#b6305d',
    '#b52f5d',
    '#b42e5e',
    '#b32e5e',
    '#b22d5e',
    '#b12c5f',
    '#af2c5f',
    '#ae2b5f',
    '#ad2a5f',
    '#ac2a60',
    '#ab2960',
    '#a92860',
    '#a82860',
    '#a72761',
    '#a62761',
    '#a52661',
    '#a32561',
    '#a22561',
    '#a12462',
    '#a02462',
    '#9e2362',
    '#9d2362',
    '#9c2262',
    '#9b2262',
    '#992162',
    '#982163',
    '#972163',
    '#952063',
    '#942063',
    '#931f63',
    '#921f63',
    '#901f63',
    '#8f1e63',
    '#8e1e63',
    '#8c1e63',
    '#8b1d63',
    '#8a1d63',
    '#881d63',
    '#871c63',
    '#861c63',
    '#851c63',
    '#831c62',
    '#821b62',
    '#811b62',
    '#7f1b62',
    '#7e1b62',
    '#7d1a62',
    '#7b1a61',
    '#7a1a61',
    '#781a61',
    '#771a61',
    '#761a60',
    '#741960',
    '#731960',
    '#72195f',
    '#70195f',
    '#6f195f',
    '#6e195e',
    '#6c195e',
    '#6b185d',
    '#6a185d',
    '#68185d',
    '#67185c',
    '#66185c',
    '#64185b',
    '#63185b',
    '#62175a',
    '#60175a',
    '#5f1759',
    '#5e1759',
    '#5c1758',
    '#5b1757',
    '#591757',
    '#581756',
    '#571656',
    '#551655',
    '#541654',
    '#531654',
    '#521653',
    '#501652',
    '#4f1552',
    '#4e1551',
    '#4c1550',
    '#4b154f',
    '#4a154f',
    '#48144e',
    '#47144d',
    '#46144c',
    '#44144c',
    '#43134b',
    '#42134a',
    '#411349',
    '#3f1349',
    '#3e1248',
    '#3d1247',
    '#3b1246',
    '#3a1245',
    '#391144',
    '#381144',
    '#361143',
    '#351042',
    '#341041',
    '#321040',
    '#310f3f',
    '#300f3e',
    '#2f0f3d',
]);
export const C_TURBID_SCALE = chroma.scale([
    '#e8f5ab',
    '#e8f4a9',
    '#e7f3a8',
    '#e7f2a6',
    '#e6f1a5',
    '#e5efa3',
    '#e5eea2',
    '#e4eda0',
    '#e4ec9f',
    '#e3eb9d',
    '#e3ea9c',
    '#e2e89b',
    '#e2e799',
    '#e1e698',
    '#e1e596',
    '#e0e495',
    '#dfe393',
    '#dfe292',
    '#dee090',
    '#dedf8f',
    '#ddde8e',
    '#dddd8c',
    '#dcdc8b',
    '#dcdb89',
    '#dbda88',
    '#dbd887',
    '#dad785',
    '#dad684',
    '#d9d582',
    '#d9d481',
    '#d9d380',
    '#d8d27e',
    '#d8d07d',
    '#d7cf7c',
    '#d7ce7a',
    '#d6cd79',
    '#d6cc78',
    '#d5cb77',
    '#d5ca75',
    '#d4c974',
    '#d4c773',
    '#d3c671',
    '#d3c570',
    '#d2c46f',
    '#d2c36e',
    '#d2c26c',
    '#d1c16b',
    '#d1c06a',
    '#d0bf69',
    '#d0bd68',
    '#cfbc66',
    '#cfbb65',
    '#ceba64',
    '#ceb963',
    '#ceb862',
    '#cdb761',
    '#cdb660',
    '#ccb55f',
    '#ccb45e',
    '#cbb25c',
    '#cbb15b',
    '#cab05a',
    '#caaf59',
    '#caae58',
    '#c9ad57',
    '#c9ac56',
    '#c8ab55',
    '#c8aa54',
    '#c7a954',
    '#c7a853',
    '#c6a752',
    '#c6a551',
    '#c5a450',
    '#c5a34f',
    '#c4a24e',
    '#c4a14e',
    '#c3a04d',
    '#c39f4c',
    '#c29e4b',
    '#c29d4b',
    '#c19c4a',
    '#c19b49',
    '#c09a48',
    '#c09948',
    '#bf9847',
    '#bf9747',
    '#be9646',
    '#be9545',
    '#bd9445',
    '#bc9344',
    '#bc9244',
    '#bb9143',
    '#bb9043',
    '#ba8f42',
    '#b98e42',
    '#b98d42',
    '#b88c41',
    '#b88b41',
    '#b78a40',
    '#b68940',
    '#b68840',
    '#b5873f',
    '#b4863f',
    '#b4853f',
    '#b3843e',
    '#b2833e',
    '#b2823e',
    '#b1813e',
    '#b0813d',
    '#af803d',
    '#af7f3d',
    '#ae7e3d',
    '#ad7d3d',
    '#ad7c3c',
    '#ac7b3c',
    '#ab7a3c',
    '#aa793c',
    '#a9793c',
    '#a9783c',
    '#a8773c',
    '#a7763b',
    '#a6753b',
    '#a6743b',
    '#a5743b',
    '#a4733b',
    '#a3723b',
    '#a2713b',
    '#a1703b',
    '#a16f3b',
    '#a06f3b',
    '#9f6e3b',
    '#9e6d3a',
    '#9d6c3a',
    '#9c6c3a',
    '#9b6b3a',
    '#9a6a3a',
    '#9a693a',
    '#99693a',
    '#98683a',
    '#97673a',
    '#96663a',
    '#95663a',
    '#94653a',
    '#93643a',
    '#92633a',
    '#91633a',
    '#90623a',
    '#8f613a',
    '#8e613a',
    '#8d6039',
    '#8d5f39',
    '#8c5e39',
    '#8b5e39',
    '#8a5d39',
    '#895c39',
    '#885c39',
    '#875b39',
    '#865a39',
    '#855a39',
    '#845939',
    '#835839',
    '#825839',
    '#815738',
    '#805638',
    '#7f5638',
    '#7e5538',
    '#7d5538',
    '#7c5438',
    '#7b5338',
    '#7a5338',
    '#795237',
    '#785137',
    '#775137',
    '#755037',
    '#745037',
    '#734f37',
    '#724e37',
    '#714e36',
    '#704d36',
    '#6f4c36',
    '#6e4c36',
    '#6d4b36',
    '#6c4b35',
    '#6b4a35',
    '#6a4935',
    '#694935',
    '#684835',
    '#674834',
    '#664734',
    '#654734',
    '#644634',
    '#634533',
    '#624533',
    '#614433',
    '#604433',
    '#5f4332',
    '#5e4232',
    '#5d4232',
    '#5c4131',
    '#5a4131',
    '#594031',
    '#583f31',
    '#573f30',
    '#563e30',
    '#553e30',
    '#543d2f',
    '#533c2f',
    '#523c2f',
    '#513b2e',
    '#503b2e',
    '#4f3a2e',
    '#4e3a2d',
    '#4d392d',
    '#4c382d',
    '#4b382c',
    '#4a372c',
    '#49372c',
    '#48362b',
    '#47352b',
    '#46352a',
    '#45342a',
    '#44342a',
    '#433329',
    '#423229',
    '#413229',
    '#403128',
    '#3f3128',
    '#3e3027',
    '#3d2f27',
    '#3c2f27',
    '#3b2e26',
    '#3a2d26',
    '#392d25',
    '#382c25',
    '#372c24',
    '#362b24',
    '#352a24',
    '#342a23',
    '#332923',
    '#322822',
    '#312822',
    '#302721',
    '#2f2721',
    '#2e2620',
    '#2d2520',
    '#2c251f',
    '#2b241f',
    '#2a231f',
    '#29231e',
    '#28221e',
    '#27211d',
    '#26211d',
    '#25201c',
    '#241f1c',
    '#231f1b',
    '#221e1b',
]);
export const C_SPEED_SCALE = chroma.scale([
    '#fefccd',
    '#fefbca',
    '#fdfac8',
    '#fcf9c6',
    '#fcf7c4',
    '#fbf6c2',
    '#faf5c0',
    '#faf4be',
    '#f9f3bc',
    '#f8f1b9',
    '#f8f0b7',
    '#f7efb5',
    '#f6eeb3',
    '#f6edb1',
    '#f5ecaf',
    '#f4eaad',
    '#f4e9ab',
    '#f3e8a8',
    '#f2e7a6',
    '#f2e6a4',
    '#f1e5a2',
    '#f0e3a0',
    '#f0e29e',
    '#efe19c',
    '#eee099',
    '#eedf97',
    '#edde95',
    '#ecdd93',
    '#ebdc91',
    '#ebdb8f',
    '#ead98c',
    '#e9d88a',
    '#e8d788',
    '#e8d686',
    '#e7d584',
    '#e6d481',
    '#e5d37f',
    '#e5d27d',
    '#e4d17b',
    '#e3d079',
    '#e2cf77',
    '#e1ce74',
    '#e0cd72',
    '#dfcc70',
    '#dfcb6e',
    '#deca6c',
    '#ddc96a',
    '#dcc867',
    '#dbc765',
    '#dac663',
    '#d9c561',
    '#d8c45f',
    '#d7c45d',
    '#d6c35b',
    '#d5c258',
    '#d4c156',
    '#d3c054',
    '#d1bf52',
    '#d0be50',
    '#cfbe4e',
    '#cebd4c',
    '#cdbc4a',
    '#cbbb48',
    '#caba46',
    '#c9ba44',
    '#c8b942',
    '#c6b840',
    '#c5b73e',
    '#c3b73c',
    '#c2b63b',
    '#c1b539',
    '#bfb537',
    '#beb435',
    '#bcb333',
    '#bbb332',
    '#b9b230',
    '#b8b12e',
    '#b6b12c',
    '#b5b02b',
    '#b3af29',
    '#b2af28',
    '#b0ae26',
    '#afad24',
    '#adad23',
    '#abac21',
    '#aaab20',
    '#a8ab1e',
    '#a7aa1d',
    '#a5aa1c',
    '#a3a91a',
    '#a2a819',
    '#a0a817',
    '#9ea716',
    '#9da715',
    '#9ba614',
    '#99a512',
    '#97a511',
    '#96a410',
    '#94a40f',
    '#92a30e',
    '#91a20d',
    '#8fa20c',
    '#8da10b',
    '#8ba10a',
    '#8aa009',
    '#889f08',
    '#869f07',
    '#849e07',
    '#839e06',
    '#819d06',
    '#7f9c06',
    '#7d9c05',
    '#7c9b05',
    '#7a9b05',
    '#789a05',
    '#769905',
    '#749905',
    '#739805',
    '#719706',
    '#6f9706',
    '#6d9607',
    '#6b9607',
    '#6a9508',
    '#689408',
    '#669409',
    '#649309',
    '#62920a',
    '#61920b',
    '#5f910c',
    '#5d910c',
    '#5b900d',
    '#598f0e',
    '#578f0f',
    '#568e10',
    '#548d10',
    '#528d11',
    '#508c12',
    '#4e8b13',
    '#4c8b14',
    '#4b8a14',
    '#498915',
    '#478916',
    '#458817',
    '#438717',
    '#428618',
    '#408619',
    '#3e851a',
    '#3c841a',
    '#3b831b',
    '#39831c',
    '#37821c',
    '#35811d',
    '#34811e',
    '#32801e',
    '#307f1f',
    '#2e7e20',
    '#2d7d20',
    '#2b7d21',
    '#2a7c21',
    '#287b22',
    '#267a23',
    '#257923',
    '#237924',
    '#227824',
    '#207725',
    '#1f7625',
    '#1d7526',
    '#1c7526',
    '#1a7427',
    '#197327',
    '#187227',
    '#167128',
    '#157028',
    '#146f28',
    '#136f29',
    '#126e29',
    '#116d29',
    '#106c2a',
    '#0f6b2a',
    '#0e6a2a',
    '#0d692b',
    '#0d682b',
    '#0c672b',
    '#0c672b',
    '#0b662b',
    '#0b652c',
    '#0b642c',
    '#0a632c',
    '#0a622c',
    '#0a612c',
    '#0a602c',
    '#0b5f2c',
    '#0b5e2c',
    '#0b5d2c',
    '#0b5c2c',
    '#0c5c2c',
    '#0c5b2c',
    '#0c5a2c',
    '#0d592c',
    '#0d582c',
    '#0e572c',
    '#0e562c',
    '#0f552c',
    '#0f542c',
    '#0f532c',
    '#10522b',
    '#10512b',
    '#11502b',
    '#114f2b',
    '#124e2b',
    '#124d2a',
    '#134c2a',
    '#134b2a',
    '#144a2a',
    '#144a29',
    '#144929',
    '#154829',
    '#154728',
    '#154628',
    '#164528',
    '#164427',
    '#164327',
    '#174227',
    '#174126',
    '#174026',
    '#173f25',
    '#183e25',
    '#183d24',
    '#183c24',
    '#183b23',
    '#183a23',
    '#183922',
    '#193822',
    '#193721',
    '#193621',
    '#193520',
    '#19341f',
    '#19341f',
    '#19331e',
    '#19321e',
    '#19311d',
    '#19301c',
    '#192f1c',
    '#192e1b',
    '#192d1a',
    '#182c1a',
    '#182b19',
    '#182a18',
    '#182918',
    '#182817',
    '#182716',
    '#172615',
    '#172515',
    '#172414',
    '#172313',
    '#172312',
]);
export const C_AMP_SCALE = chroma.scale([
    '#f1ecec',
    '#f0ebea',
    '#f0eae9',
    '#efe9e8',
    '#eee8e6',
    '#eee7e5',
    '#ede5e3',
    '#ede4e2',
    '#ece3e1',
    '#ece2df',
    '#ebe1de',
    '#ebdfdc',
    '#eadedb',
    '#eaddda',
    '#e9dcd8',
    '#e9dbd7',
    '#e9d9d5',
    '#e8d8d4',
    '#e8d7d3',
    '#e7d6d1',
    '#e7d5d0',
    '#e6d4ce',
    '#e6d2cd',
    '#e6d1cb',
    '#e5d0ca',
    '#e5cfc8',
    '#e4cec7',
    '#e4cdc6',
    '#e4cbc4',
    '#e3cac3',
    '#e3c9c1',
    '#e2c8c0',
    '#e2c7be',
    '#e2c6bd',
    '#e1c4bc',
    '#e1c3ba',
    '#e0c2b9',
    '#e0c1b7',
    '#e0c0b6',
    '#dfbfb4',
    '#dfbdb3',
    '#dfbcb1',
    '#debbb0',
    '#debaae',
    '#deb9ad',
    '#ddb8ac',
    '#ddb6aa',
    '#dcb5a9',
    '#dcb4a7',
    '#dcb3a6',
    '#dbb2a4',
    '#dbb1a3',
    '#dbb0a1',
    '#daaea0',
    '#daad9f',
    '#daac9d',
    '#d9ab9c',
    '#d9aa9a',
    '#d9a999',
    '#d8a897',
    '#d8a696',
    '#d8a594',
    '#d7a493',
    '#d7a392',
    '#d7a290',
    '#d6a18f',
    '#d6a08d',
    '#d69e8c',
    '#d59d8a',
    '#d59c89',
    '#d59b88',
    '#d49a86',
    '#d49985',
    '#d49883',
    '#d39782',
    '#d39580',
    '#d3947f',
    '#d2937e',
    '#d2927c',
    '#d1917b',
    '#d19079',
    '#d18f78',
    '#d08d77',
    '#d08c75',
    '#d08b74',
    '#cf8a72',
    '#cf8971',
    '#cf8870',
    '#ce876e',
    '#ce856d',
    '#ce846b',
    '#cd836a',
    '#cd8269',
    '#cd8167',
    '#cc8066',
    '#cc7f64',
    '#cc7d63',
    '#cb7c62',
    '#cb7b60',
    '#ca7a5f',
    '#ca795e',
    '#ca785c',
    '#c9775b',
    '#c9755a',
    '#c97458',
    '#c87357',
    '#c87256',
    '#c87154',
    '#c77053',
    '#c76e52',
    '#c66d50',
    '#c66c4f',
    '#c66b4e',
    '#c56a4d',
    '#c5694b',
    '#c4674a',
    '#c46649',
    '#c46547',
    '#c36446',
    '#c36345',
    '#c26144',
    '#c26043',
    '#c25f41',
    '#c15e40',
    '#c15d3f',
    '#c05b3e',
    '#c05a3d',
    '#c0593b',
    '#bf583a',
    '#bf5639',
    '#be5538',
    '#be5437',
    '#bd5336',
    '#bd5135',
    '#bc5034',
    '#bc4f33',
    '#bc4e32',
    '#bb4c31',
    '#bb4b30',
    '#ba4a2f',
    '#ba492e',
    '#b9472d',
    '#b9462c',
    '#b8452c',
    '#b8432b',
    '#b7422a',
    '#b74129',
    '#b63f29',
    '#b53e28',
    '#b53d28',
    '#b43b27',
    '#b43a26',
    '#b33926',
    '#b33726',
    '#b23625',
    '#b13525',
    '#b13325',
    '#b03224',
    '#af3124',
    '#af2f24',
    '#ae2e24',
    '#ad2d24',
    '#ac2c24',
    '#ac2a24',
    '#ab2924',
    '#aa2824',
    '#a92724',
    '#a82524',
    '#a72424',
    '#a72324',
    '#a62224',
    '#a52124',
    '#a42024',
    '#a31f25',
    '#a21e25',
    '#a11c25',
    '#a01b25',
    '#9f1a25',
    '#9e1926',
    '#9d1826',
    '#9c1826',
    '#9b1726',
    '#9a1626',
    '#981527',
    '#971427',
    '#961327',
    '#951327',
    '#941228',
    '#931128',
    '#911128',
    '#901028',
    '#8f1028',
    '#8e1028',
    '#8d0f28',
    '#8b0f29',
    '#8a0e29',
    '#890e29',
    '#870e29',
    '#860e29',
    '#850e29',
    '#840e29',
    '#820e29',
    '#810e29',
    '#800d29',
    '#7e0d29',
    '#7d0d29',
    '#7c0e28',
    '#7a0e28',
    '#790e28',
    '#780e28',
    '#760e28',
    '#750e27',
    '#740e27',
    '#720e27',
    '#710e27',
    '#700e26',
    '#6e0e26',
    '#6d0e26',
    '#6c0e25',
    '#6a0e25',
    '#690e25',
    '#680e24',
    '#660e24',
    '#650e23',
    '#640e23',
    '#620e22',
    '#610e22',
    '#600e21',
    '#5e0e21',
    '#5d0e21',
    '#5c0e20',
    '#5a0e20',
    '#590d1f',
    '#580d1e',
    '#560d1e',
    '#550d1d',
    '#540d1d',
    '#530d1c',
    '#510d1c',
    '#500c1b',
    '#4f0c1b',
    '#4d0c1a',
    '#4c0c19',
    '#4b0c19',
    '#4a0b18',
    '#480b18',
    '#470b17',
    '#460b16',
    '#450a16',
    '#430a15',
    '#420a14',
    '#410a14',
    '#3f0913',
    '#3e0913',
    '#3d0912',
    '#3c0911',
]);
export const C_TEMPO_SCALE = chroma.scale([
    '#fef5f4',
    '#fdf4f2',
    '#fcf3f1',
    '#faf2ef',
    '#f9f1ee',
    '#f7f0ec',
    '#f6efeb',
    '#f5eee9',
    '#f3eee8',
    '#f2ede6',
    '#f1ece5',
    '#efebe3',
    '#eeeae2',
    '#ece9e0',
    '#ebe8df',
    '#eae7dd',
    '#e8e6dc',
    '#e7e5db',
    '#e5e5d9',
    '#e4e4d8',
    '#e3e3d6',
    '#e1e2d5',
    '#e0e1d4',
    '#dee0d2',
    '#dddfd1',
    '#dcdfcf',
    '#dadece',
    '#d9ddcd',
    '#d7dccb',
    '#d6dbca',
    '#d4dac9',
    '#d3dac7',
    '#d2d9c6',
    '#d0d8c5',
    '#cfd7c3',
    '#cdd6c2',
    '#ccd6c1',
    '#cad5c0',
    '#c9d4be',
    '#c7d3bd',
    '#c6d2bc',
    '#c4d2bb',
    '#c3d1b9',
    '#c1d0b8',
    '#c0cfb7',
    '#becfb6',
    '#bdceb5',
    '#bbcdb3',
    '#baccb2',
    '#b8ccb1',
    '#b7cbb0',
    '#b5caaf',
    '#b4c9ae',
    '#b2c9ac',
    '#b1c8ab',
    '#afc7aa',
    '#aec6a9',
    '#acc6a8',
    '#aac5a7',
    '#a9c4a6',
    '#a7c3a5',
    '#a6c3a4',
    '#a4c2a3',
    '#a2c1a2',
    '#a1c1a1',
    '#9fc0a0',
    '#9dbf9f',
    '#9cbf9e',
    '#9abe9d',
    '#99bd9c',
    '#97bc9b',
    '#95bc9a',
    '#94bb99',
    '#92ba99',
    '#90ba98',
    '#8eb997',
    '#8db896',
    '#8bb895',
    '#89b794',
    '#88b694',
    '#86b693',
    '#84b592',
    '#82b491',
    '#80b491',
    '#7fb390',
    '#7db28f',
    '#7bb28f',
    '#79b18e',
    '#77b08d',
    '#76b08d',
    '#74af8c',
    '#72ae8b',
    '#70ae8b',
    '#6ead8a',
    '#6cac8a',
    '#6aab89',
    '#69ab89',
    '#67aa88',
    '#65a988',
    '#63a987',
    '#61a887',
    '#5fa786',
    '#5da786',
    '#5ba685',
    '#59a585',
    '#57a585',
    '#55a484',
    '#53a384',
    '#51a283',
    '#4fa283',
    '#4da183',
    '#4ba083',
    '#49a082',
    '#479f82',
    '#459e82',
    '#439d81',
    '#419d81',
    '#3f9c81',
    '#3d9b81',
    '#3b9a81',
    '#399980',
    '#379980',
    '#359880',
    '#339780',
    '#319680',
    '#2f957f',
    '#2e957f',
    '#2c947f',
    '#2a937f',
    '#28927f',
    '#26917e',
    '#25907e',
    '#23907e',
    '#228f7e',
    '#208e7e',
    '#1f8d7e',
    '#1d8c7d',
    '#1c8b7d',
    '#1a8a7d',
    '#19897d',
    '#18897c',
    '#17887c',
    '#16877c',
    '#15867c',
    '#14857b',
    '#13847b',
    '#13837b',
    '#12827b',
    '#12817a',
    '#11807a',
    '#117f7a',
    '#117e79',
    '#107d79',
    '#107d79',
    '#107c78',
    '#107b78',
    '#107a78',
    '#107977',
    '#117877',
    '#117777',
    '#117676',
    '#117576',
    '#127475',
    '#127375',
    '#127275',
    '#137174',
    '#137074',
    '#136f73',
    '#146e73',
    '#146d72',
    '#146d72',
    '#156c72',
    '#156b71',
    '#156a71',
    '#166970',
    '#166870',
    '#16676f',
    '#17666f',
    '#17656e',
    '#17646e',
    '#18636d',
    '#18626d',
    '#18616c',
    '#19606c',
    '#195f6b',
    '#195e6b',
    '#195e6a',
    '#1a5d6a',
    '#1a5c69',
    '#1a5b69',
    '#1a5a68',
    '#1a5967',
    '#1a5867',
    '#1b5766',
    '#1b5666',
    '#1b5565',
    '#1b5465',
    '#1b5364',
    '#1b5264',
    '#1b5163',
    '#1b5162',
    '#1b5062',
    '#1c4f61',
    '#1c4e61',
    '#1c4d60',
    '#1c4c60',
    '#1c4b5f',
    '#1c4a5e',
    '#1c495e',
    '#1c485d',
    '#1c475d',
    '#1c465c',
    '#1c465b',
    '#1b455b',
    '#1b445a',
    '#1b435a',
    '#1b4259',
    '#1b4159',
    '#1b4058',
    '#1b3f57',
    '#1b3e57',
    '#1b3d56',
    '#1b3c56',
    '#1b3c55',
    '#1a3b54',
    '#1a3a54',
    '#1a3953',
    '#1a3853',
    '#1a3752',
    '#1a3652',
    '#1a3551',
    '#193450',
    '#193350',
    '#19324f',
    '#19314f',
    '#19304e',
    '#192f4e',
    '#182f4d',
    '#182e4c',
    '#182d4c',
    '#182c4b',
    '#182b4b',
    '#172a4a',
    '#17294a',
    '#172849',
    '#172748',
    '#162648',
    '#162547',
    '#162447',
    '#162346',
    '#162246',
    '#152145',
    '#152045',
    '#151f44',
    '#151e44',
    '#141d43',
]);
export const C_RAIN_SCALE = chroma.scale([
    '#eeedf2',
    '#edecf1',
    '#ecebef',
    '#eceaee',
    '#ebe9ec',
    '#eae7ea',
    '#e9e6e9',
    '#e9e5e7',
    '#e8e4e6',
    '#e7e3e4',
    '#e6e2e3',
    '#e5e1e1',
    '#e5e0e0',
    '#e4dfde',
    '#e3dedd',
    '#e3dddb',
    '#e2dcd9',
    '#e2dbd8',
    '#e1dad6',
    '#e1d8d4',
    '#e0d7d2',
    '#dfd6d0',
    '#dfd5ce',
    '#ded4cc',
    '#ded3cb',
    '#ddd2c9',
    '#ddd1c7',
    '#dcd0c5',
    '#dccfc3',
    '#dbcec1',
    '#dbcdbf',
    '#dbccbd',
    '#dacbbb',
    '#dac9b9',
    '#d9c8b7',
    '#d9c7b5',
    '#d8c6b3',
    '#d7c5b1',
    '#d7c4af',
    '#d6c3ad',
    '#d6c2ab',
    '#d5c2a9',
    '#d4c1a7',
    '#d3c0a5',
    '#d2bfa3',
    '#d1bea1',
    '#d0bd9f',
    '#cfbd9e',
    '#cebc9c',
    '#cdbb9a',
    '#ccba99',
    '#caba97',
    '#c9b996',
    '#c7b994',
    '#c6b893',
    '#c4b792',
    '#c2b791',
    '#c1b690',
    '#bfb68f',
    '#bdb58e',
    '#bcb58d',
    '#bab48c',
    '#b8b48b',
    '#b7b38a',
    '#b5b28a',
    '#b3b289',
    '#b1b188',
    '#b0b188',
    '#aeb087',
    '#acb086',
    '#abaf86',
    '#a9af85',
    '#a7ae84',
    '#a5ad84',
    '#a4ad83',
    '#a2ac83',
    '#a0ac82',
    '#9fab81',
    '#9dab81',
    '#9baa80',
    '#99a980',
    '#98a97f',
    '#96a87e',
    '#94a87e',
    '#93a77d',
    '#91a77d',
    '#8fa67c',
    '#8da57c',
    '#8ca57b',
    '#8aa47b',
    '#88a47a',
    '#86a37a',
    '#85a379',
    '#83a278',
    '#81a178',
    '#7fa177',
    '#7da077',
    '#7ca076',
    '#7a9f76',
    '#789f75',
    '#769e75',
    '#749e75',
    '#729d74',
    '#719c74',
    '#6f9c73',
    '#6d9b73',
    '#6b9b72',
    '#699a72',
    '#679a71',
    '#659971',
    '#639871',
    '#619870',
    '#5f9770',
    '#5d9770',
    '#5b966f',
    '#59966f',
    '#57956f',
    '#55946f',
    '#52946e',
    '#50936e',
    '#4e936e',
    '#4c926e',
    '#4a916e',
    '#47916d',
    '#45906d',
    '#43906d',
    '#418f6d',
    '#3e8e6d',
    '#3c8e6d',
    '#3a8d6d',
    '#388c6d',
    '#358c6d',
    '#338b6d',
    '#318a6d',
    '#2f8a6d',
    '#2c896d',
    '#2a886d',
    '#28876d',
    '#26876d',
    '#24866d',
    '#22856d',
    '#20846d',
    '#1e836e',
    '#1c836e',
    '#1a826e',
    '#18816e',
    '#16806e',
    '#157f6e',
    '#137e6e',
    '#127e6e',
    '#107d6e',
    '#0f7c6e',
    '#0d7b6e',
    '#0c7a6e',
    '#0b796e',
    '#09786e',
    '#08786e',
    '#08776e',
    '#07766e',
    '#06756e',
    '#05746d',
    '#05736d',
    '#04726d',
    '#04716d',
    '#03706d',
    '#036f6d',
    '#036f6d',
    '#036e6d',
    '#036d6d',
    '#036c6c',
    '#036b6c',
    '#036a6c',
    '#04696c',
    '#04686c',
    '#04676c',
    '#05666b',
    '#05656b',
    '#06656b',
    '#07646b',
    '#08636a',
    '#09626a',
    '#09616a',
    '#0a606a',
    '#0b5f69',
    '#0c5e69',
    '#0d5d69',
    '#0e5c68',
    '#0f5b68',
    '#105a67',
    '#115967',
    '#125867',
    '#135766',
    '#145666',
    '#155565',
    '#165465',
    '#175464',
    '#185364',
    '#185263',
    '#195163',
    '#1a5062',
    '#1b4f61',
    '#1b4e61',
    '#1c4d60',
    '#1d4c60',
    '#1d4b5f',
    '#1e4a5e',
    '#1e495e',
    '#1f485d',
    '#20475c',
    '#20465b',
    '#20455b',
    '#21445a',
    '#214359',
    '#224258',
    '#224158',
    '#224057',
    '#233f56',
    '#233e55',
    '#233e54',
    '#243d53',
    '#243c53',
    '#243b52',
    '#243a51',
    '#243950',
    '#24384f',
    '#25374e',
    '#25364e',
    '#25354d',
    '#25344c',
    '#25334b',
    '#25324a',
    '#25314a',
    '#253049',
    '#252f48',
    '#252f47',
    '#252e46',
    '#242d46',
    '#242c45',
    '#242b44',
    '#242a43',
    '#242943',
    '#242842',
    '#242741',
    '#242640',
    '#232540',
    '#23243f',
    '#23233e',
    '#23223d',
    '#23213d',
    '#22203c',
    '#221f3b',
    '#221e3b',
    '#221d3a',
    '#221c39',
    '#211b39',
    '#211a38',
]);
export const C_PHASE_SCALE = chroma.scale([
    '#a7770c',
    '#a9760e',
    '#aa7510',
    '#ac7412',
    '#ad7414',
    '#af7316',
    '#b07217',
    '#b27119',
    '#b3701b',
    '#b46f1c',
    '#b66e1e',
    '#b76d1f',
    '#b86c21',
    '#ba6a23',
    '#bb6924',
    '#bc6826',
    '#bd6727',
    '#be6629',
    '#c0652b',
    '#c1642c',
    '#c2632e',
    '#c3622f',
    '#c46131',
    '#c56033',
    '#c65f34',
    '#c75d36',
    '#c85c38',
    '#c95b3a',
    '#ca5a3b',
    '#cb593d',
    '#cc583f',
    '#cd5641',
    '#ce5543',
    '#cf5444',
    '#d05346',
    '#d15148',
    '#d2504a',
    '#d34f4c',
    '#d34e4e',
    '#d44c50',
    '#d54b53',
    '#d64a55',
    '#d64857',
    '#d74759',
    '#d8455b',
    '#d8445e',
    '#d94360',
    '#da4163',
    '#da4065',
    '#db3e68',
    '#db3d6a',
    '#dc3b6d',
    '#dc3a70',
    '#dc3972',
    '#dd3775',
    '#dd3678',
    '#dd347b',
    '#de337e',
    '#de3181',
    '#de3084',
    '#de2f87',
    '#de2d8a',
    '#de2c8d',
    '#de2b90',
    '#de2a93',
    '#de2996',
    '#de2899',
    '#dd279c',
    '#dd26a0',
    '#dd26a3',
    '#dc25a6',
    '#dc25a9',
    '#db25ac',
    '#db25af',
    '#da25b2',
    '#d925b5',
    '#d826b8',
    '#d826bb',
    '#d727be',
    '#d628c1',
    '#d529c3',
    '#d42ac6',
    '#d32bc9',
    '#d22ccb',
    '#d12dce',
    '#cf2fd0',
    '#ce30d2',
    '#cd32d4',
    '#cc33d7',
    '#ca35d9',
    '#c936db',
    '#c738dc',
    '#c63ade',
    '#c43be0',
    '#c33de2',
    '#c13fe3',
    '#c041e5',
    '#be42e6',
    '#bc44e8',
    '#bb46e9',
    '#b947ea',
    '#b749eb',
    '#b54bec',
    '#b44ded',
    '#b24eee',
    '#b050ef',
    '#ae52f0',
    '#ac53f0',
    '#aa55f1',
    '#a856f2',
    '#a658f2',
    '#a45af2',
    '#a25bf3',
    '#a05df3',
    '#9e5ef3',
    '#9c60f3',
    '#9961f4',
    '#9763f4',
    '#9564f4',
    '#9366f3',
    '#9067f3',
    '#8e69f3',
    '#8c6af3',
    '#896bf2',
    '#876df2',
    '#846ef2',
    '#8270f1',
    '#7f71f0',
    '#7d72f0',
    '#7a73ef',
    '#7875ee',
    '#7576ed',
    '#7377ec',
    '#7078eb',
    '#6d7aea',
    '#6a7be9',
    '#687ce8',
    '#657de6',
    '#627ee5',
    '#5f7fe4',
    '#5d80e2',
    '#5a81e1',
    '#5782df',
    '#5483dd',
    '#5284dc',
    '#4f85da',
    '#4c86d8',
    '#4987d6',
    '#4788d4',
    '#4489d2',
    '#4289d0',
    '#3f8ace',
    '#3d8bcc',
    '#3a8bca',
    '#388cc8',
    '#358dc6',
    '#338dc4',
    '#318ec2',
    '#2f8ec0',
    '#2d8fbe',
    '#2b8fbc',
    '#2a90b9',
    '#2890b7',
    '#2690b5',
    '#2591b3',
    '#2391b1',
    '#2292af',
    '#2192ad',
    '#2092ab',
    '#1f93a9',
    '#1e93a7',
    '#1d93a5',
    '#1c93a3',
    '#1b94a1',
    '#1a949f',
    '#19949d',
    '#18949c',
    '#17959a',
    '#169598',
    '#169596',
    '#159594',
    '#149692',
    '#139690',
    '#12968e',
    '#11968c',
    '#10978a',
    '#0f9788',
    '#0f9786',
    '#0e9784',
    '#0d9782',
    '#0c9880',
    '#0c987d',
    '#0b987b',
    '#0b9879',
    '#0b9877',
    '#0b9974',
    '#0b9972',
    '#0c9970',
    '#0d996d',
    '#0f996b',
    '#109968',
    '#129966',
    '#149963',
    '#169960',
    '#199a5d',
    '#1b9a5b',
    '#1e9a58',
    '#219a55',
    '#249952',
    '#27994f',
    '#2a994c',
    '#2d9949',
    '#319946',
    '#349942',
    '#38983f',
    '#3b983c',
    '#3f9838',
    '#439735',
    '#479732',
    '#4a962e',
    '#4e962b',
    '#529528',
    '#569525',
    '#5a9422',
    '#5d9320',
    '#61921d',
    '#64921b',
    '#689119',
    '#6b9017',
    '#6e8f15',
    '#718e13',
    '#748d12',
    '#778d11',
    '#798c10',
    '#7c8b0f',
    '#7f8a0f',
    '#81890e',
    '#83880e',
    '#86870d',
    '#88870d',
    '#8a860d',
    '#8c850d',
    '#8e840d',
    '#91830d',
    '#93820d',
    '#95810d',
    '#97800d',
    '#987f0d',
    '#9a7e0d',
    '#9c7d0d',
    '#9e7c0d',
    '#a07b0d',
    '#a27a0d',
    '#a4790d',
    '#a6780c',
    '#a7770c',
]);
export const C_TOPO_SCALE = chroma.scale([
    '#271a2c',
    '#291b2e',
    '#2a1d31',
    '#2b1e34',
    '#2d2037',
    '#2e2139',
    '#2f233c',
    '#31243f',
    '#322642',
    '#332745',
    '#342948',
    '#352a4b',
    '#362b4e',
    '#382d52',
    '#392e55',
    '#3a3058',
    '#3b315b',
    '#3c335f',
    '#3d3462',
    '#3d3665',
    '#3e3769',
    '#3f396c',
    '#3f3a6f',
    '#403c73',
    '#403d76',
    '#413f79',
    '#41417c',
    '#41437f',
    '#414582',
    '#414685',
    '#404887',
    '#404b89',
    '#404d8b',
    '#3f4f8d',
    '#3f518e',
    '#3e538f',
    '#3e5590',
    '#3e5891',
    '#3d5a92',
    '#3d5c92',
    '#3d5e93',
    '#3d6093',
    '#3d6294',
    '#3d6494',
    '#3d6695',
    '#3e6995',
    '#3e6b96',
    '#3e6d96',
    '#3f6f96',
    '#3f7197',
    '#3f7397',
    '#407598',
    '#407798',
    '#417999',
    '#427b99',
    '#427d99',
    '#437f9a',
    '#43819a',
    '#44829b',
    '#45849b',
    '#45869c',
    '#46889c',
    '#478a9d',
    '#478c9d',
    '#488e9d',
    '#49909e',
    '#49929e',
    '#4a949f',
    '#4b969f',
    '#4b98a0',
    '#4c9aa0',
    '#4d9ca0',
    '#4e9fa1',
    '#4ea1a1',
    '#4fa3a2',
    '#50a5a2',
    '#51a7a2',
    '#52a9a2',
    '#53aba3',
    '#54ada3',
    '#55afa3',
    '#56b1a3',
    '#58b3a3',
    '#59b5a3',
    '#5bb7a3',
    '#5cb9a3',
    '#5ebba3',
    '#60bda3',
    '#62bfa3',
    '#64c1a3',
    '#67c3a3',
    '#69c5a3',
    '#6cc7a3',
    '#6fc9a3',
    '#72caa3',
    '#76cca3',
    '#79cea2',
    '#7dd0a2',
    '#81d1a2',
    '#85d3a3',
    '#89d5a3',
    '#8dd6a3',
    '#91d8a3',
    '#95d9a4',
    '#9adba4',
    '#9edca5',
    '#a3dea6',
    '#a7dfa7',
    '#abe1a8',
    '#b0e2a9',
    '#b4e3aa',
    '#b8e5ab',
    '#bde6ad',
    '#c1e8ae',
    '#c5e9b0',
    '#c9ebb1',
    '#ceecb3',
    '#d2edb5',
    '#d6efb7',
    '#daf0b9',
    '#def2bb',
    '#e2f3bd',
    '#e6f5bf',
    '#ebf6c1',
    '#eff8c3',
    '#f3f9c6',
    '#f7fbc8',
    '#fbfcca',
    '#0d2513',
    '#0e2614',
    '#0f2815',
    '#102a15',
    '#112c16',
    '#122e17',
    '#132f17',
    '#133118',
    '#143319',
    '#153519',
    '#16361a',
    '#17381a',
    '#183a1b',
    '#193c1b',
    '#193d1b',
    '#1a3f1c',
    '#1b411c',
    '#1c431d',
    '#1d441d',
    '#1d461d',
    '#1e481d',
    '#204a1e',
    '#214b1e',
    '#224d1e',
    '#244f1e',
    '#27501e',
    '#2a511e',
    '#2d531f',
    '#305420',
    '#335521',
    '#365723',
    '#395824',
    '#3b5926',
    '#3e5a28',
    '#415c29',
    '#435d2b',
    '#465e2d',
    '#495f2e',
    '#4b612f',
    '#4e6231',
    '#516332',
    '#536433',
    '#566534',
    '#596736',
    '#5b6837',
    '#5e6937',
    '#606a38',
    '#636c39',
    '#666d3a',
    '#686e3b',
    '#6b6f3b',
    '#6e713c',
    '#70723c',
    '#73733d',
    '#76743d',
    '#78763e',
    '#7b773e',
    '#7e783e',
    '#80793f',
    '#837b3f',
    '#867c3f',
    '#887d3f',
    '#8b7e3f',
    '#8e803f',
    '#90813f',
    '#93823f',
    '#96833f',
    '#99853f',
    '#9b863f',
    '#9e873f',
    '#a1883f',
    '#a48a3f',
    '#a78b3f',
    '#a98c3f',
    '#ac8e3e',
    '#af8f3e',
    '#b2903e',
    '#b5913e',
    '#b8923e',
    '#bb943e',
    '#be953e',
    '#c19640',
    '#c29844',
    '#c39a48',
    '#c59c4c',
    '#c69e4f',
    '#c7a053',
    '#c8a256',
    '#c9a45a',
    '#caa75d',
    '#cba961',
    '#ccab64',
    '#cdad68',
    '#ceaf6b',
    '#cfb16f',
    '#d0b372',
    '#d2b676',
    '#d3b879',
    '#d4ba7d',
    '#d5bc80',
    '#d6be84',
    '#d7c187',
    '#d8c38b',
    '#d9c58e',
    '#dac792',
    '#dbca95',
    '#dccc99',
    '#dece9c',
    '#dfd0a0',
    '#e0d3a3',
    '#e1d5a7',
    '#e2d7aa',
    '#e3daae',
    '#e5dcb1',
    '#e6deb5',
    '#e7e0b9',
    '#e8e3bc',
    '#eae5c0',
    '#ebe7c3',
    '#eceac7',
    '#eeecca',
    '#efefce',
    '#f1f1d2',
    '#f2f3d5',
    '#f3f6d9',
    '#f5f8dc',
    '#f7fae0',
    '#f8fde4',
]);
export const C_BALANCE_SCALE = chroma.scale([
    '#171c42',
    '#181d45',
    '#191f48',
    '#1a204b',
    '#1b214e',
    '#1c2351',
    '#1d2455',
    '#1e2658',
    '#1f275b',
    '#20285e',
    '#212961',
    '#222b65',
    '#232c68',
    '#232d6b',
    '#242f6f',
    '#253072',
    '#263176',
    '#263379',
    '#27347d',
    '#273580',
    '#283784',
    '#283888',
    '#28398b',
    '#293a8f',
    '#293c92',
    '#293d96',
    '#293f9a',
    '#28409e',
    '#2841a1',
    '#2743a5',
    '#2744a8',
    '#2646ac',
    '#2448af',
    '#234ab2',
    '#214bb5',
    '#1e4db8',
    '#1c50ba',
    '#1852bb',
    '#1554bc',
    '#1256bd',
    '#0f59bd',
    '#0d5bbe',
    '#0b5dbe',
    '#0a5fbd',
    '#0962bd',
    '#0a64bd',
    '#0b66bd',
    '#0c68bc',
    '#0e6abc',
    '#106cbc',
    '#136ebb',
    '#1570bb',
    '#1872bb',
    '#1b74bb',
    '#1d75ba',
    '#2077ba',
    '#2379ba',
    '#257bba',
    '#287dba',
    '#2b7fba',
    '#2d80b9',
    '#3082b9',
    '#3384b9',
    '#3586b9',
    '#3887b9',
    '#3b89b9',
    '#3d8bb9',
    '#408cb9',
    '#428eb9',
    '#4590b9',
    '#4891b9',
    '#4b93ba',
    '#4d95ba',
    '#5096ba',
    '#5398ba',
    '#569aba',
    '#599bba',
    '#5b9dba',
    '#5e9ebb',
    '#61a0bb',
    '#64a2bb',
    '#68a3bc',
    '#6ba5bc',
    '#6ea6bc',
    '#71a8bd',
    '#74a9bd',
    '#77abbe',
    '#7bacbe',
    '#7eadbf',
    '#81afbf',
    '#84b0c0',
    '#87b2c1',
    '#8bb3c1',
    '#8eb5c2',
    '#91b6c3',
    '#94b8c4',
    '#97b9c5',
    '#9abac5',
    '#9dbcc6',
    '#a0bdc7',
    '#a3bfc8',
    '#a6c0c9',
    '#a9c2ca',
    '#acc3cb',
    '#afc5cd',
    '#b2c6ce',
    '#b5c8cf',
    '#b8cad0',
    '#bbcbd1',
    '#becdd2',
    '#c1ced4',
    '#c4d0d5',
    '#c7d2d6',
    '#cad3d7',
    '#ccd5d9',
    '#cfd6da',
    '#d2d8db',
    '#d5dadd',
    '#d8dcde',
    '#dbdddf',
    '#dddfe1',
    '#e0e1e2',
    '#e3e3e4',
    '#e6e4e5',
    '#e8e6e7',
    '#ebe8e8',
    '#eeeaea',
    '#f0eceb',
    '#f0eceb',
    '#efe9e8',
    '#eee7e6',
    '#ede5e3',
    '#ece2e0',
    '#ebe0dd',
    '#eadeda',
    '#e9dbd7',
    '#e8d9d5',
    '#e7d6d2',
    '#e7d4cf',
    '#e6d2cc',
    '#e5cfc9',
    '#e4cdc6',
    '#e3cbc3',
    '#e3c8c0',
    '#e2c6be',
    '#e1c4bb',
    '#e0c1b8',
    '#dfbfb5',
    '#dfbdb2',
    '#debaaf',
    '#ddb8ac',
    '#ddb6a9',
    '#dcb3a6',
    '#dbb1a3',
    '#daafa1',
    '#daad9e',
    '#d9aa9b',
    '#d8a898',
    '#d8a695',
    '#d7a392',
    '#d6a18f',
    '#d69f8c',
    '#d59d89',
    '#d49a87',
    '#d49884',
    '#d39681',
    '#d2947e',
    '#d2917b',
    '#d18f78',
    '#d08d76',
    '#d08a73',
    '#cf8870',
    '#ce866d',
    '#cd846a',
    '#cd8168',
    '#cc7f65',
    '#cb7d62',
    '#cb7a5f',
    '#ca785d',
    '#c9765a',
    '#c87357',
    '#c87155',
    '#c76f52',
    '#c66c4f',
    '#c56a4d',
    '#c5684a',
    '#c46548',
    '#c36345',
    '#c26043',
    '#c15e40',
    '#c05c3e',
    '#c0593c',
    '#bf573a',
    '#be5437',
    '#bd5235',
    '#bc4f33',
    '#bb4d31',
    '#ba4a2f',
    '#b9482d',
    '#b8452c',
    '#b7422a',
    '#b64029',
    '#b53d28',
    '#b43a27',
    '#b33826',
    '#b13525',
    '#b03224',
    '#af3024',
    '#ad2d24',
    '#ac2b24',
    '#aa2824',
    '#a82624',
    '#a72324',
    '#a52124',
    '#a31f25',
    '#a11d25',
    '#9f1b25',
    '#9d1926',
    '#9b1726',
    '#991527',
    '#961427',
    '#941227',
    '#921128',
    '#8f1028',
    '#8d0f28',
    '#8a0f29',
    '#880e29',
    '#850e29',
    '#820e29',
    '#800d29',
    '#7d0d29',
    '#7a0e28',
    '#780e28',
    '#750e27',
    '#720e27',
    '#700e26',
    '#6d0e26',
    '#6a0e25',
    '#680e24',
    '#650e23',
    '#620e22',
    '#600e21',
    '#5d0e21',
    '#5a0e20',
    '#580d1e',
    '#550d1d',
    '#530d1c',
    '#500c1b',
    '#4d0c1a',
    '#4b0c19',
    '#480b18',
    '#460b16',
    '#430a15',
    '#410a14',
    '#3e0913',
    '#3c0911',
]);
export const C_DELTA_SCALE = chroma.scale([
    '#101f3f',
    '#122042',
    '#132245',
    '#142349',
    '#16254c',
    '#17264f',
    '#182752',
    '#192956',
    '#1b2a59',
    '#1c2b5c',
    '#1d2d60',
    '#1e2e63',
    '#1f2f67',
    '#20306b',
    '#21326e',
    '#223372',
    '#233476',
    '#24357a',
    '#25377d',
    '#253881',
    '#263985',
    '#263b89',
    '#263c8d',
    '#263e90',
    '#254094',
    '#234296',
    '#224498',
    '#214699',
    '#1f489a',
    '#1e4b9b',
    '#1d4d9b',
    '#1d4f9c',
    '#1c519c',
    '#1b539d',
    '#1b569d',
    '#1a589d',
    '#1a5a9d',
    '#1a5c9e',
    '#1a5e9e',
    '#1a609e',
    '#1b629f',
    '#1b649f',
    '#1b669f',
    '#1c68a0',
    '#1c6aa0',
    '#1d6ca0',
    '#1e6ea1',
    '#1f70a1',
    '#1f72a1',
    '#2074a2',
    '#2176a2',
    '#2278a3',
    '#2379a3',
    '#247ba4',
    '#267da4',
    '#277fa5',
    '#2881a5',
    '#2983a5',
    '#2a85a6',
    '#2c87a6',
    '#2d89a7',
    '#2f8ba7',
    '#308da8',
    '#318ea8',
    '#3390a9',
    '#3592a9',
    '#3694aa',
    '#3896aa',
    '#3a98ab',
    '#3c9aab',
    '#3e9cac',
    '#409dac',
    '#429fad',
    '#44a1ad',
    '#47a3ae',
    '#49a5ae',
    '#4ca7ae',
    '#4fa8af',
    '#52aaaf',
    '#56acb0',
    '#59adb0',
    '#5dafb0',
    '#60b1b1',
    '#64b2b1',
    '#68b4b2',
    '#6cb5b3',
    '#70b7b3',
    '#74b8b4',
    '#78b9b5',
    '#7cbbb6',
    '#80bcb7',
    '#84beb8',
    '#88bfb9',
    '#8cc1ba',
    '#8fc2bb',
    '#93c4bc',
    '#97c5be',
    '#9ac6bf',
    '#9ec8c0',
    '#a2c9c1',
    '#a5cbc3',
    '#a9ccc4',
    '#accec5',
    '#afd0c7',
    '#b3d1c8',
    '#b6d3ca',
    '#b9d4cb',
    '#bdd6cc',
    '#c0d7ce',
    '#c3d9cf',
    '#c7dbd1',
    '#cadcd2',
    '#cdded4',
    '#d0e0d5',
    '#d3e2d7',
    '#d6e3d8',
    '#d9e5da',
    '#dde7db',
    '#e0e9dc',
    '#e3ebde',
    '#e6eddf',
    '#e9eee0',
    '#ecf0e1',
    '#eff2e2',
    '#f3f4e3',
    '#f6f6e4',
    '#f9f8e5',
    '#fdfae5',
    '#fefccb',
    '#fdf9c7',
    '#fbf7c3',
    '#faf4bf',
    '#f9f2bb',
    '#f7f0b6',
    '#f6edb2',
    '#f5ebae',
    '#f3e9a9',
    '#f2e6a5',
    '#f1e4a1',
    '#efe29c',
    '#eedf98',
    '#ecdd94',
    '#ebdb8f',
    '#ead98b',
    '#e8d787',
    '#e6d582',
    '#e5d27e',
    '#e3d07a',
    '#e2ce75',
    '#e0cc71',
    '#deca6d',
    '#dcc968',
    '#dac764',
    '#d8c560',
    '#d6c35b',
    '#d4c157',
    '#d2c053',
    '#cfbe4f',
    '#cdbc4b',
    '#cbbb47',
    '#c8b943',
    '#c5b83f',
    '#c3b63b',
    '#c0b538',
    '#bdb334',
    '#bab230',
    '#b7b12d',
    '#b4af2a',
    '#b1ae27',
    '#aead23',
    '#aaac20',
    '#a7aa1d',
    '#a4a91b',
    '#a0a818',
    '#9da715',
    '#9aa613',
    '#96a410',
    '#93a30e',
    '#8fa20c',
    '#8ca10a',
    '#88a008',
    '#859e07',
    '#819d06',
    '#7e9c05',
    '#7a9b05',
    '#779905',
    '#739805',
    '#709706',
    '#6c9607',
    '#689508',
    '#659309',
    '#61920b',
    '#5d910c',
    '#5a8f0e',
    '#568e0f',
    '#528d11',
    '#4f8b13',
    '#4b8a14',
    '#478916',
    '#448717',
    '#408619',
    '#3d841a',
    '#39831c',
    '#36811d',
    '#32801e',
    '#2f7e20',
    '#2b7d21',
    '#287b22',
    '#257a23',
    '#227824',
    '#1f7625',
    '#1c7526',
    '#197327',
    '#177128',
    '#147028',
    '#126e29',
    '#106c2a',
    '#0e6a2a',
    '#0d692b',
    '#0c672b',
    '#0b652c',
    '#0a632c',
    '#0a612c',
    '#0b5f2c',
    '#0b5e2c',
    '#0c5c2c',
    '#0c5a2c',
    '#0d582c',
    '#0e562c',
    '#0f542c',
    '#10522b',
    '#11502b',
    '#124e2b',
    '#134c2a',
    '#144b2a',
    '#144929',
    '#154728',
    '#164528',
    '#164327',
    '#174126',
    '#173f25',
    '#183d24',
    '#183b23',
    '#183922',
    '#193721',
    '#193520',
    '#19341f',
    '#19321e',
    '#19301c',
    '#192e1b',
    '#182c1a',
    '#182a18',
    '#182817',
    '#172615',
    '#172414',
    '#172312',
]);
export const C_CURL_SCALE = chroma.scale([
    '#141d43',
    '#151f44',
    '#152145',
    '#162346',
    '#162547',
    '#172748',
    '#17294a',
    '#182b4b',
    '#182d4c',
    '#182f4d',
    '#19304e',
    '#19324f',
    '#193450',
    '#1a3652',
    '#1a3853',
    '#1a3a54',
    '#1b3c55',
    '#1b3d56',
    '#1b3f57',
    '#1b4159',
    '#1b435a',
    '#1b455b',
    '#1c475c',
    '#1c485d',
    '#1c4a5e',
    '#1c4c60',
    '#1c4e61',
    '#1b5062',
    '#1b5263',
    '#1b5364',
    '#1b5565',
    '#1b5766',
    '#1a5967',
    '#1a5b69',
    '#1a5d6a',
    '#195f6b',
    '#19606c',
    '#18626d',
    '#17646e',
    '#17666f',
    '#166870',
    '#156a71',
    '#156c72',
    '#146e73',
    '#137073',
    '#127174',
    '#127375',
    '#117576',
    '#117777',
    '#107977',
    '#107b78',
    '#107d79',
    '#117f7a',
    '#11807a',
    '#12827b',
    '#14847b',
    '#15867c',
    '#17887c',
    '#1a8a7d',
    '#1c8b7d',
    '#1f8d7e',
    '#228f7e',
    '#25917e',
    '#29927f',
    '#2c947f',
    '#30967f',
    '#349780',
    '#389980',
    '#3c9a81',
    '#3f9c81',
    '#449d82',
    '#489f82',
    '#4ca083',
    '#50a283',
    '#54a384',
    '#58a585',
    '#5ca685',
    '#5fa886',
    '#63a987',
    '#67aa88',
    '#6bac89',
    '#6fad8a',
    '#73ae8c',
    '#76b08d',
    '#7ab18e',
    '#7eb28f',
    '#81b491',
    '#85b592',
    '#88b794',
    '#8cb896',
    '#8fb997',
    '#92bb99',
    '#96bc9b',
    '#99bd9c',
    '#9cbf9e',
    '#a0c0a0',
    '#a3c2a2',
    '#a6c3a4',
    '#a9c4a6',
    '#adc6a9',
    '#b0c7ab',
    '#b3c9ad',
    '#b6caaf',
    '#b9ccb1',
    '#bccdb4',
    '#bfcfb6',
    '#c2d0b9',
    '#c5d2bb',
    '#c8d4be',
    '#cbd5c0',
    '#ced7c3',
    '#d1d8c5',
    '#d4dac8',
    '#d6dccb',
    '#d9ddcd',
    '#dcdfd0',
    '#dfe1d3',
    '#e2e2d6',
    '#e5e4d8',
    '#e7e6db',
    '#eae8de',
    '#ede9e1',
    '#f0ebe4',
    '#f3ede7',
    '#f5efea',
    '#f8f1ed',
    '#fbf3f0',
    '#fdf5f3',
    '#fdf5f3',
    '#fbf2f0',
    '#faf0ed',
    '#f9ede9',
    '#f8ebe6',
    '#f7e9e3',
    '#f6e6df',
    '#f5e4dc',
    '#f4e1d8',
    '#f3dfd5',
    '#f2dcd2',
    '#f1dace',
    '#f0d7cb',
    '#efd5c8',
    '#eed2c4',
    '#edd0c1',
    '#eccdbe',
    '#eccbbb',
    '#ebc8b7',
    '#eac6b4',
    '#e9c3b1',
    '#e9c1ae',
    '#e8bfab',
    '#e7bca8',
    '#e7baa5',
    '#e6b7a2',
    '#e5b59f',
    '#e5b29c',
    '#e4b099',
    '#e3ad96',
    '#e3ab93',
    '#e2a891',
    '#e1a68e',
    '#e1a38b',
    '#e0a089',
    '#df9e86',
    '#df9b84',
    '#de9982',
    '#dd967f',
    '#dd947d',
    '#dc917b',
    '#db8f79',
    '#db8c77',
    '#da8a75',
    '#d98773',
    '#d88571',
    '#d78270',
    '#d7806e',
    '#d67d6d',
    '#d57b6b',
    '#d4786a',
    '#d37669',
    '#d27368',
    '#d17167',
    '#d06f66',
    '#ce6c65',
    '#cd6a64',
    '#cc6864',
    '#cb6563',
    '#c96362',
    '#c86162',
    '#c75e61',
    '#c55c61',
    '#c45a61',
    '#c25860',
    '#c15660',
    '#bf5360',
    '#bd5160',
    '#bc4f60',
    '#ba4d60',
    '#b84b60',
    '#b7495f',
    '#b5475f',
    '#b3455f',
    '#b1435f',
    '#b0415f',
    '#ae3f5f',
    '#ac3d60',
    '#aa3c60',
    '#a83a60',
    '#a63860',
    '#a43660',
    '#a23460',
    '#a03360',
    '#9e3160',
    '#9c2f60',
    '#9a2d60',
    '#972c60',
    '#952a60',
    '#932960',
    '#912760',
    '#8f2660',
    '#8c2460',
    '#8a2360',
    '#882160',
    '#85205f',
    '#831f5f',
    '#811e5f',
    '#7e1c5f',
    '#7c1b5e',
    '#791a5e',
    '#77195d',
    '#74195d',
    '#72185c',
    '#6f175b',
    '#6d165a',
    '#6a1659',
    '#671558',
    '#651557',
    '#621456',
    '#5f1455',
    '#5d1453',
    '#5a1352',
    '#571350',
    '#55134f',
    '#52124d',
    '#4f124b',
    '#4d124a',
    '#4a1148',
    '#481146',
    '#451044',
    '#421042',
    '#401040',
    '#3d0f3d',
    '#3b0e3b',
    '#380e39',
    '#360d37',
    '#330d35',
]);
export const C_DIFF_SCALE = chroma.scale([
    '#07223f',
    '#082441',
    '#092643',
    '#0a2845',
    '#0b2947',
    '#0c2b49',
    '#0d2d4b',
    '#0e2e4d',
    '#0e304e',
    '#0f3250',
    '#103452',
    '#113554',
    '#123756',
    '#123958',
    '#133a5a',
    '#143c5b',
    '#153e5d',
    '#163f5f',
    '#164161',
    '#174362',
    '#184564',
    '#194665',
    '#1b4866',
    '#1d4a67',
    '#1f4b68',
    '#214d6a',
    '#234e6b',
    '#25506c',
    '#28516d',
    '#2a536e',
    '#2c546f',
    '#2f5670',
    '#315771',
    '#335972',
    '#355a73',
    '#375c74',
    '#3a5d75',
    '#3c5f76',
    '#3e6078',
    '#406279',
    '#42637a',
    '#44657b',
    '#46667c',
    '#48687d',
    '#4b697e',
    '#4d6b80',
    '#4f6c81',
    '#516e82',
    '#536f83',
    '#557184',
    '#577285',
    '#597487',
    '#5b7688',
    '#5d7789',
    '#5f798a',
    '#617a8b',
    '#637c8d',
    '#657d8e',
    '#677f8f',
    '#6a8090',
    '#6c8292',
    '#6e8393',
    '#708594',
    '#728796',
    '#748897',
    '#768a98',
    '#788b99',
    '#7a8d9b',
    '#7c8f9c',
    '#7e909d',
    '#80929f',
    '#8293a0',
    '#8495a1',
    '#8797a3',
    '#8998a4',
    '#8b9aa6',
    '#8d9ca7',
    '#8f9da8',
    '#919faa',
    '#93a1ab',
    '#95a2ad',
    '#97a4ae',
    '#9aa6af',
    '#9ca7b1',
    '#9ea9b2',
    '#a0abb4',
    '#a2adb5',
    '#a4aeb7',
    '#a6b0b8',
    '#a9b2ba',
    '#abb4bb',
    '#adb5bd',
    '#afb7be',
    '#b1b9c0',
    '#b4bbc2',
    '#b6bdc3',
    '#b8bfc5',
    '#bac0c6',
    '#bdc2c8',
    '#bfc4ca',
    '#c1c6cb',
    '#c3c8cd',
    '#c6cace',
    '#c8ccd0',
    '#caced2',
    '#cdd0d3',
    '#cfd1d5',
    '#d1d3d7',
    '#d4d5d9',
    '#d6d7da',
    '#d8d9dc',
    '#dbdbde',
    '#dddde0',
    '#dfdfe1',
    '#e1e1e3',
    '#e4e3e5',
    '#e6e5e6',
    '#e8e7e8',
    '#eae8e9',
    '#eceaeb',
    '#eeebec',
    '#efeded',
    '#f1eeee',
    '#f2efef',
    '#f3f0ef',
    '#f4f0f0',
    '#f5f1f0',
    '#f5f1f0',
    '#f5f1ef',
    '#f5f0ef',
    '#f4f0ee',
    '#f4efec',
    '#f3eeeb',
    '#f1ece9',
    '#f0ebe7',
    '#efe9e5',
    '#ede8e3',
    '#ebe6e0',
    '#eae4de',
    '#e8e2dc',
    '#e6e0d9',
    '#e4ded7',
    '#e2dcd4',
    '#e0dad1',
    '#ded8cf',
    '#ddd6cc',
    '#dbd4ca',
    '#d9d2c7',
    '#d7d0c4',
    '#d5cec2',
    '#d3ccbf',
    '#d1cabd',
    '#cfc8ba',
    '#cdc6b8',
    '#ccc4b5',
    '#cac2b2',
    '#c8c0b0',
    '#c6bead',
    '#c4bcab',
    '#c3baa8',
    '#c1b9a6',
    '#bfb7a3',
    '#bdb5a1',
    '#bbb39e',
    '#bab19c',
    '#b8af9a',
    '#b6ad97',
    '#b5ab95',
    '#b3aa92',
    '#b1a890',
    '#b0a68e',
    '#aea48b',
    '#aca289',
    '#aba186',
    '#a99f84',
    '#a79d82',
    '#a69b7f',
    '#a4997d',
    '#a2987b',
    '#a19678',
    '#9f9476',
    '#9e9274',
    '#9c9171',
    '#9a8f6f',
    '#998d6d',
    '#978c6b',
    '#968a68',
    '#948866',
    '#928664',
    '#918562',
    '#8f835f',
    '#8e815d',
    '#8c805b',
    '#8b7e59',
    '#897c56',
    '#887b54',
    '#867952',
    '#847850',
    '#83764e',
    '#81744b',
    '#807349',
    '#7e7147',
    '#7d6f45',
    '#7b6e43',
    '#7a6c41',
    '#786b3f',
    '#77693c',
    '#75673a',
    '#736638',
    '#726436',
    '#706334',
    '#6f6132',
    '#6d6030',
    '#6b5e2e',
    '#6a5d2c',
    '#685b2a',
    '#665a28',
    '#645827',
    '#625725',
    '#605624',
    '#5e5423',
    '#5c5322',
    '#5a5221',
    '#585021',
    '#564f20',
    '#544d1f',
    '#524c1e',
    '#504b1e',
    '#4e491d',
    '#4c481c',
    '#4b461b',
    '#49451b',
    '#47441a',
    '#454219',
    '#434118',
    '#413f18',
    '#3f3e17',
    '#3d3c16',
    '#3b3b15',
    '#3a3a14',
    '#383814',
    '#363713',
    '#343512',
    '#323411',
    '#303210',
    '#2e310f',
    '#2c2f0e',
    '#2b2e0e',
    '#292c0d',
    '#272b0c',
    '#25290b',
    '#23280a',
    '#212609',
    '#1f2508',
    '#1e2307',
    '#1c2206',
]);
export const C_TARNN_SCALE = chroma.scale([
    '#16230d',
    '#18250d',
    '#19260e',
    '#1a280e',
    '#1c2a0f',
    '#1d2c0f',
    '#1e2d0f',
    '#1f2f0f',
    '#21310f',
    '#22330f',
    '#233410',
    '#243610',
    '#253810',
    '#27390f',
    '#283b0f',
    '#293d0f',
    '#2a3f0f',
    '#2c400f',
    '#2d420e',
    '#2e440e',
    '#30450d',
    '#32470c',
    '#33480c',
    '#364a0b',
    '#394b0c',
    '#3c4c0d',
    '#3e4d0e',
    '#414f0f',
    '#445010',
    '#475111',
    '#495211',
    '#4c5312',
    '#4f5413',
    '#525614',
    '#545715',
    '#575816',
    '#5a5917',
    '#5c5a18',
    '#5f5b19',
    '#625c1a',
    '#655d1b',
    '#675f1c',
    '#6a601d',
    '#6d611d',
    '#70621e',
    '#72631f',
    '#756420',
    '#786521',
    '#7b6622',
    '#7d6723',
    '#806824',
    '#836924',
    '#866a25',
    '#896b26',
    '#8c6c27',
    '#8f6d28',
    '#916e29',
    '#946f2a',
    '#97702a',
    '#9a712b',
    '#9d722c',
    '#a0732d',
    '#a3742e',
    '#a6752f',
    '#a97631',
    '#ac7732',
    '#af7833',
    '#b27934',
    '#b57a35',
    '#b87b37',
    '#bb7c38',
    '#be7d3a',
    '#c17e3c',
    '#c37f3e',
    '#c68041',
    '#c88244',
    '#ca8347',
    '#cb854b',
    '#cc874f',
    '#cd8a53',
    '#ce8c56',
    '#cf8e59',
    '#d0905d',
    '#d19360',
    '#d29564',
    '#d39767',
    '#d49a6b',
    '#d59c6e',
    '#d69e72',
    '#d6a175',
    '#d7a378',
    '#d8a57c',
    '#d9a77f',
    '#daaa83',
    '#dbac86',
    '#dcae8a',
    '#ddb18d',
    '#deb391',
    '#dfb594',
    '#e0b898',
    '#e1ba9b',
    '#e2bc9f',
    '#e2bfa2',
    '#e3c1a6',
    '#e4c3a9',
    '#e5c6ad',
    '#e6c8b0',
    '#e8cab4',
    '#e9cdb7',
    '#eacfbb',
    '#ebd1be',
    '#ecd4c2',
    '#edd6c5',
    '#eed9c9',
    '#efdbcd',
    '#f0ddd0',
    '#f1e0d4',
    '#f3e2d7',
    '#f4e5db',
    '#f5e7de',
    '#f6eae2',
    '#f7ece5',
    '#f9eee8',
    '#faf0ec',
    '#fbf2ef',
    '#fbf4f1',
    '#fcf6f3',
    '#fcf7f5',
    '#fcf7f5',
    '#fcf7f4',
    '#fbf6f2',
    '#faf5f0',
    '#f9f3ed',
    '#f7f2ea',
    '#f6f0e6',
    '#f4eee3',
    '#f2ecdf',
    '#f0e9dc',
    '#eee7d8',
    '#ece5d4',
    '#eae3d0',
    '#e8e1cd',
    '#e7dfc9',
    '#e5ddc5',
    '#e3dbc2',
    '#e1d9be',
    '#dfd7bb',
    '#dcd5b7',
    '#dad3b4',
    '#d7d2b1',
    '#d4d0af',
    '#d1cfac',
    '#cdcdab',
    '#cacca9',
    '#c7caa8',
    '#c4c9a7',
    '#c0c7a5',
    '#bdc5a4',
    '#bac4a3',
    '#b7c2a2',
    '#b4c1a1',
    '#b1bfa0',
    '#aebe9f',
    '#abbc9e',
    '#a8ba9d',
    '#a5b99d',
    '#a2b79c',
    '#9fb69b',
    '#9cb49a',
    '#99b399',
    '#96b198',
    '#93b097',
    '#90ae96',
    '#8dac95',
    '#8aab94',
    '#87a993',
    '#85a892',
    '#82a691',
    '#7fa590',
    '#7ca38f',
    '#79a28e',
    '#76a08d',
    '#739f8c',
    '#709d8b',
    '#6d9c8b',
    '#6a9a8a',
    '#679989',
    '#639888',
    '#609687',
    '#5d9586',
    '#5a9386',
    '#579285',
    '#549084',
    '#508f84',
    '#4d8d83',
    '#4a8c82',
    '#478a82',
    '#438981',
    '#408781',
    '#3d8680',
    '#3a847f',
    '#37827f',
    '#34817e',
    '#317f7e',
    '#2e7e7d',
    '#2b7c7d',
    '#297a7c',
    '#27787c',
    '#25777b',
    '#23757b',
    '#22737a',
    '#217179',
    '#206f78',
    '#1f6e78',
    '#1e6c77',
    '#1e6a76',
    '#1e6875',
    '#1d6674',
    '#1d6473',
    '#1c6272',
    '#1c6172',
    '#1b5f71',
    '#1b5d70',
    '#1a5b6f',
    '#19596e',
    '#19576d',
    '#18566c',
    '#17546c',
    '#16526b',
    '#15506a',
    '#144e69',
    '#144d69',
    '#134b68',
    '#124967',
    '#114766',
    '#104566',
    '#0f4365',
    '#0e4264',
    '#0d4064',
    '#0c3e63',
    '#0b3c62',
    '#0a3a62',
    '#0a3861',
    '#093661',
    '#083460',
    '#08325f',
    '#09305e',
    '#092e5d',
    '#0a2c5c',
    '#0b2a5b',
    '#0c2859',
    '#0d2657',
    '#0e2455',
    '#0e2253',
    '#0f2051',
    '#0f1e4f',
]);

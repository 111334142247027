// Giro3d
import * as serializer from 'services/serializer';
import { Notification } from 'types/Notification';
import Annotation, {
    AnnotationComment,
    AnnotationCommentReply,
    AnnotationGeometryType,
    AnnotationId,
    AnnotationStatus,
    BaseComment,
    CommentId,
} from 'types/Annotation';
import { Dispatch, GetState, RootState } from 'store';
import { EmailMembershipRequest, Membership } from 'types/Membership';
import {
    DatasetId,
    ProjectId,
    QueryParameters,
    SerializableVector3,
    SourceFileId,
    toVector3,
    UUID,
    ViewId,
} from 'types/common';
import Dataset from 'types/Dataset';
import { User } from 'types/User';
import Project from 'types/Project';
import * as geojsonUtils from 'geojsonUtils';
import { getService } from 'ServiceContainer';
import { hasSeismicPlane } from 'types/LayerState';
import { SourceFile } from 'types/SourceFile';
import { CONTROLS_MODE } from 'services/Controls';
import * as THREE from 'three';
import { useEventBus } from 'EventBus';
import type { LineString } from 'geojson';
import giro3dService from '../services/Giro3dService';

// OverviewMap
// eslint-disable-next-line import/no-cycle
import overviewMapService from '../services/OverviewMapService';

// API
import DosApi from '../services/DosApi';
import UppyService from '../services/UppyService';

// Redux
// eslint-disable-next-line import/no-cycle
import buildActionGeneratorFromFn from './buildActionGeneratorFromFn';
import {
    USER_TYPES,
    REGISTER_USER_TYPES,
    COLLECTION_REMOVED,
    COLLECTION_UPDATED,
    COLLECTIONS,
    PROJECT_LOADED,
    PROJECT_LOADING,
    PROJECT_LOADING_FAILED,
    PROJECT_REMOVED,
    PROJECT_UPDATED,
    PROJECT_USERS,
    PROJECTS,
    HEALTHCHECK_TYPES,
    ERROR_SHOW,
    ORGANIZATIONS_TYPES,
    SSDM_TYPES,
    PROJECTIONS,
    VERSION_INFO,
    ACTIVE_NOTIFICATION,
    NOTIFICATIONS,
    ADD_USERNAMES,
    UPDATE_NOTIFICATION,
    NAV_PAGE,
    SET_ADMINISTRATED_MEMBERSHIPS,
    SET_ADMINISTRATED_USERS,
    UPDATE_ADMINISTRATED_MEMBERSHIP,
    UPDATE_ADMINISTRATED_USER,
    ELIGIBLE_TYPES,
    ADD_ADMINISTRATED_MEMBERSHIP,
    REMOVE_ADMINISTRATED_MEMBERSHIP,
    SET_MEMBERSHIP_REQUESTS,
    DELETE_DATASET,
    SET_DATASETS,
    COLLECTION_CREATED,
    UPDATE_DATASET,
    ADD_DATASET,
    QUEUE_USERNAMES,
    SET_API_KEYS,
    REMOVE_API_KEY,
    SET_API_KEYS_ADMIN,
    ADD_PROJ4,
    DELETE_DATASETS,
} from './actionTypes';
import * as giro3dSlice from './giro3d';
import {
    LAYER_STATES,
    ANNOTATION_CREATESTATE,
    DATASETS_STATES,
    EDITSTATE,
    ROLES,
    DRAWN_TOOL,
    PANE,
    PAGE,
    DRAWTOOL_MODE,
} from '../services/Constants';
import MinimapService from '../services/MinimapService';
import { initproj4 } from '../services/BaseGiro3dService';
import SeismicService from '../services/SeismicService';
import * as layersSlice from './layers';
import * as gridSlice from './grid';
import * as seismicGridSlice from './seismicGrid';
import * as crossSectionsSlice from './crossSections';
import * as datasetsSlice from './datasets';
import * as drawToolSlice from './drawTool';
import * as annotationsSlice from './annotations';
import * as layoutSlice from './layout';
import { selectAnnotation } from './annotationActions';

const annotationManager = getService('AnnotationManager');
const shapeManager = getService('ShapeManager');
const eventBus = useEventBus();

// Note: most functions defined here are higher-order functions
// meant to be used in combination with the Redux thunk middleware.
// In other words, if a function looks like f => (dispatch) or f => (dispatch, getState),
// it is a thunk action.

export const fetchVersion = buildActionGeneratorFromFn(VERSION_INFO, DosApi.fetchVersion);

export const fetchUser = buildActionGeneratorFromFn(USER_TYPES, DosApi.fetchUser);

export const registerUser = buildActionGeneratorFromFn(REGISTER_USER_TYPES, DosApi.registerUser);

export const fetchHealthCheck = buildActionGeneratorFromFn(HEALTHCHECK_TYPES, DosApi.fetchHealthCheck);

export const userEligible = () => (dispatch: Dispatch) => {
    DosApi.userEligible()
        .then(() => {
            dispatch({
                type: ELIGIBLE_TYPES.SUCCESS,
            });
        })
        .catch((error) => {
            if (error.response && (error.response.status === 403 || error.response.status === 401)) {
                dispatch({
                    type: ELIGIBLE_TYPES.ERROR,
                });
            } else {
                dispatch({
                    type: ELIGIBLE_TYPES.UNKNOWN,
                });
            }
        });
};

export const fetchVersionInfo = (dispatch: Dispatch) => {
    DosApi.fetchVersion().then((data) => {
        dispatch({
            type: VERSION_INFO,
            payload: {
                branch: data.branch,
                hash: data.hash,
                version: data.version,
            },
        });
    });
};

export const showError = (dispatch: Dispatch, err: string) => {
    dispatch({
        type: ERROR_SHOW,
        payload: err,
    });
};

export const createMembershipFromEmail = (emailMembership: EmailMembershipRequest) => (dispatch: Dispatch) =>
    DosApi.createMembershipFromEmail(emailMembership)
        .then((data) => dispatch({ type: ADD_ADMINISTRATED_MEMBERSHIP, payload: data }))
        .catch((error) =>
            dispatch({
                type: ERROR_SHOW,
                payload: error,
            })
        );

export const removeMembership = (membership: Membership) => (dispatch: Dispatch) =>
    DosApi.deleteMembership(membership.id)
        .then(() => dispatch({ type: REMOVE_ADMINISTRATED_MEMBERSHIP, payload: membership }))
        .catch((error) =>
            dispatch({
                type: ERROR_SHOW,
                payload: error,
            })
        );

export const fetchRequests = () => (dispatch: Dispatch) =>
    DosApi.fetchMembershipRequests()
        .then((data) => dispatch({ type: SET_MEMBERSHIP_REQUESTS, payload: data }))
        .catch((error) =>
            dispatch({
                type: ERROR_SHOW,
                payload: error,
            })
        );

const doHealthCheck = () => (dispatch: Dispatch) => {
    dispatch({ type: HEALTHCHECK_TYPES.REQUEST });
    DosApi.fetchHealthCheck()
        .then((data) => {
            if (data.backend_available === true) {
                dispatch({
                    type: HEALTHCHECK_TYPES.SUCCESS,
                    payload: {
                        backend_available: data.backend_available,
                        msg: data.msg,
                    },
                });
                fetchVersionInfo(dispatch);
            } else {
                setTimeout(() => dispatch(doHealthCheck()), 15000);
                dispatch({
                    type: HEALTHCHECK_TYPES.ERROR,
                    payload: {
                        backend_available: data.backend_available,
                        msg: data.msg,
                    },
                });
            }
        })
        .catch((error) => {
            setTimeout(() => dispatch(doHealthCheck()), 15000);
            dispatch({
                type: HEALTHCHECK_TYPES.ERROR,
                payload: {
                    backend_available: false,
                    msg: error.message,
                },
            });
        });
};

export const startHealthCheck = () => {
    console.log('start healthcheck');
    return (dispatch: Dispatch, getState: GetState) => {
        // Only start one health check if several api calls fail.
        if (getState().apiHealthCheck.healthcheck_ongoing === false) {
            dispatch(doHealthCheck());
        }
    };
};

export const selectPage = (page: PAGE) => (dispatch: Dispatch) => {
    dispatch({
        type: NAV_PAGE,
        payload: page,
    });
};

const fetchOrganizationsApi = buildActionGeneratorFromFn(ORGANIZATIONS_TYPES, DosApi.fetchOrganizations);
const fetchSSDMTypesApi = buildActionGeneratorFromFn(SSDM_TYPES, DosApi.fetchSsdmTypes);
const fetchProjectionsApi = buildActionGeneratorFromFn(PROJECTIONS, DosApi.fetchAllProjections);
const fetchProjectsApi = buildActionGeneratorFromFn(PROJECTS, DosApi.fetchProjects);
const fetchCollectionsApi = buildActionGeneratorFromFn(COLLECTIONS, DosApi.fetchCollections);

export const fetchOrganizations = () => async (dispatch: Dispatch, getState: GetState) => {
    // Control if we should trigger a load or not.
    // FIXME untyped redux slice
    // @ts-expect-error organization.status is untyped
    if (getState().organizations.status === ORGANIZATIONS_TYPES.UNKNOWN) {
        dispatch(fetchOrganizationsApi());
    }
};

export const fetchSSDMTypes = () => async (dispatch: Dispatch, getState: GetState) => {
    // Control if we should trigger a load or not.
    // @ts-expect-error organization.status is untyped
    if (getState().ssdm.status === SSDM_TYPES.UNKNOWN) {
        dispatch(fetchSSDMTypesApi());
    }
};

export const fetchProjections = () => async (dispatch: Dispatch, getState: GetState) => {
    // Control if we should trigger a load or not.
    if (getState().projections.status === PROJECTIONS.UNKNOWN) {
        dispatch(fetchProjectionsApi());
    }
};

export const fetchProjects = () => async (dispatch: Dispatch, getState: GetState) => {
    // Control if we should trigger a load or not.
    if (getState().projects.status === PROJECTS.UNKNOWN) {
        dispatch(fetchProjectsApi());
    }
};

export const fetchCollections = () => async (dispatch: Dispatch, getState: GetState) => {
    // Control if we should trigger a load or not.
    if (getState().collections.status === COLLECTIONS.UNKNOWN) {
        dispatch(fetchCollectionsApi());
    }
};

export const fetchAllDatasets = () => async (dispatch: Dispatch, getState: GetState) => {
    // Control if we should trigger a load or not.
    if (getState().allDatasets.status === 'unknown') {
        DosApi.fetchAllDatasets().then((data) => {
            dispatch({ type: SET_DATASETS, payload: data });
        });
    }
};

export const fetchProject =
    (projectId: UUID, force = false, navigate: (route: string) => void, skipReset = false) =>
    async (dispatch: Dispatch, getState: GetState) => {
        const currentProject = getState().datasets.currentProject;
        if (!currentProject || currentProject.id !== projectId || force) {
            DosApi.fetchProject(projectId)
                .then((project: Project) => {
                    if (!skipReset) dispatch(datasetsSlice.reset());
                    dispatch(datasetsSlice.setCurrentProject(project));
                })
                .catch((error) => {
                    dispatch({
                        type: ERROR_SHOW,
                        payload: error,
                    });
                    if (navigate) navigate('/');
                });
        }
    };

// export const loadCollection = (dispatch: Dispatch, collection) => {
//     dispatch({
//         type: COLLECTION_LOADING,
//         payload: collection,
//     });
//     try {
//         const s = overviewMapService.loadCollection(collection);
//         dispatch({
//             type: COLLECTION_ADDED,
//             payload: s,
//         });
//         return s;
//     } catch (e) {
//         dispatch({
//             type: COLLECTION_LOADING_FAILED,
//             payload: { collection, reason: e.message },
//         });
//         throw e;
//     }
// };

// export const loadCollections = (collections) => async (dispatch: Dispatch) => {
//     for (const collection of collections) {
//         try {
//             loadCollection(dispatch, collection);
//         } catch (error) {
//             console.error(error);
//         }
//     }
// };

export const updateCollection = (collectionId, values) => async (dispatch: Dispatch) =>
    DosApi.updateCollection(collectionId, values).then((collection) =>
        dispatch({ type: COLLECTION_UPDATED, payload: collection })
    );

export const createCollection = (values) => async (dispatch: Dispatch) =>
    DosApi.createCollection(values).then((collection) => {
        dispatch({ type: COLLECTION_CREATED, payload: collection });
        return collection;
    });

export const updateDataset = (datasetId: DatasetId, formData) => async (dispatch: Dispatch) => {
    DosApi.updateDataset(datasetId, formData).then((dataset) => {
        dispatch({
            type: UPDATE_DATASET,
            payload: dataset,
        });
        dispatch(datasetsSlice.updateDataset(dataset));
    });
};

const getProj4s = (projections: number[]) => async (dispatch: Dispatch, getState: GetState) => {
    const proj4List = getState().proj4.list;

    const proj4s = [];
    const missingProj4s = [];

    for (const srid of projections) {
        const sridWithPrefix = `EPSG:${srid}`;
        const projection = proj4List.find((p) => p[0] === sridWithPrefix);
        if (projection) proj4s.push(projection);
        else missingProj4s.push(srid);
    }

    if (missingProj4s.length === 0) return proj4s;

    const newProjections = await DosApi.fetchProjections(missingProj4s);
    dispatch({ type: ADD_PROJ4, payload: newProjections });

    return [...proj4s, ...newProjections];
};

export const loadDataset = async (dispatch: Dispatch, dataset: Dataset, initalLoad = false) => {
    dispatch(datasetsSlice.addDataset(dataset));
    dispatch(layersSlice.createLayer(dataset));
    // initproj4 is handled by loadGiro3d with all used projections
    if (!initalLoad) await dispatch(getProj4s([dataset.projection])).then((projections) => initproj4(projections));
    return null;
};

export const fetchAndLoadDataset =
    (datasetId: DatasetId, uploading = false) =>
    async (dispatch: Dispatch) => {
        const dataset = await DosApi.fetchDataset(datasetId);
        dataset.doRender = true;
        await loadDataset(dispatch, { ...dataset, state: uploading ? LAYER_STATES.UPLOADING : dataset.state });
    };

export const unloadDataset = (dispatch: Dispatch, dataset: Dataset) => {
    dispatch(datasetsSlice.removeDataset(dataset));
    dispatch(layersSlice.deleteLayer(dataset.id));
};

export const unrelateProjectDataset =
    (project: Project, dataset: Dataset) => async (dispatch: Dispatch, getState: GetState) => {
        const response = await DosApi.unrelateProjectDataset(project.id, dataset.id);
        eventBus.dispatch('close-dataset-panes', { datasetId: dataset.id });
        if (getState().giro3d.selection.objectId === dataset.id) dispatch(giro3dSlice.setSelection(undefined));
        unloadDataset(dispatch, dataset);
        return response;
    };
export const relateProjectDataset =
    (project: Project, dataset: Dataset, uploading = false) =>
    async (dispatch: Dispatch) => {
        const response = await DosApi.relateProjectDataset(project.id, dataset.id);
        if (project.geometry) {
            await dispatch(fetchAndLoadDataset(dataset.id, uploading));
        } else {
            // First addition of a dataset will require setting
            // project geometry for the first time and we need to
            // force reload the project and trigger giro3d reload.
            // Skipping reset to prevent the render without a project breaking things.
            await dispatch(fetchProject(project.id, true, null, true));
        }
        return response;
    };

export const uploadToDataset = (dataset: Dataset) => async (dispatch: Dispatch) => {
    dataset = { ...dataset, state: LAYER_STATES.UPLOADING };
    dispatch({ type: UPDATE_DATASET, payload: dataset });
    dispatch(datasetsSlice.setDatasetState({ dataset, state: LAYER_STATES.UPLOADING }));

    dispatch(
        datasetsSlice.setUploading({
            datasetId: dataset.id,
            sourceFiles: UppyService.getInstance()
                .getFiles()
                .map((f) => f.name),
        })
    );
    UppyService.upload(dataset.id).then((result) => {
        DosApi.fetchDatasetSourcefiles(dataset.id).then((sourceFiles) => {
            dispatch(
                datasetsSlice.setUploading({
                    datasetId: dataset.id,
                    sourceFiles: [],
                })
            );
            dispatch(
                datasetsSlice.setDatasetSourceFiles({
                    datasetId: dataset.id,
                    sourceFiles: sourceFiles.map((f) => {
                        return {
                            source:
                                f.source ??
                                Object.values(result.successful).find((r) => r.uploadURL.split('/').pop() === f.id)
                                    .name,
                            ...f,
                        };
                    }),
                })
            );
        });
    });
};

export const createDataset = (values) => async (dispatch) =>
    DosApi.createDataset(values).then((dataset) => {
        dispatch({ type: ADD_DATASET, payload: { ...dataset, state: LAYER_STATES.UPLOADING } });
        UppyService.upload(dataset.id);
        return dataset;
    });

export const createDatasetInProject = (values, project: Project) => async (dispatch) =>
    DosApi.createDataset(values)
        .then((dataset) => {
            dispatch({ type: ADD_DATASET, payload: { ...dataset, state: LAYER_STATES.UPLOADING } });
            dispatch(relateProjectDataset(project, dataset, true));
            dispatch(datasetsSlice.addDataset(dataset));
            dispatch(
                datasetsSlice.setUploading({
                    datasetId: dataset.id,
                    sourceFiles: UppyService.getInstance()
                        .getFiles()
                        .map((f) => f.name),
                })
            );
            UppyService.upload(dataset.id)
                .then((result) => {
                    DosApi.fetchDatasetSourcefiles(dataset.id)
                        .then((sourceFiles) => {
                            dispatch(
                                datasetsSlice.setUploading({
                                    datasetId: dataset.id,
                                    sourceFiles: [],
                                })
                            );
                            dispatch(
                                datasetsSlice.setDatasetSourceFiles({
                                    datasetId: dataset.id,
                                    sourceFiles: sourceFiles.map((f) => {
                                        return {
                                            source:
                                                f.source ??
                                                Object.values(result.successful).find(
                                                    (r) => r.uploadURL.split('/').pop() === f.id
                                                ).name,
                                            ...f,
                                        };
                                    }),
                                })
                            );
                        })
                        .catch((e) => console.error(e));
                })
                .catch((e) => console.error(e));
            return dataset;
        })
        .catch((e) => console.error(e));

export const loadProject = (dispatch: Dispatch, project: Project) => {
    dispatch({
        type: PROJECT_LOADING,
        payload: project,
    });
    try {
        const p = overviewMapService.loadProject(project);
        dispatch({
            type: PROJECT_LOADED,
            payload: p,
        });
        return p;
    } catch (e) {
        dispatch({
            type: PROJECT_LOADING_FAILED,
            payload: { project, reason: e.message },
        });
        throw e;
    }
};

export const loadProjects = (projects: Project[]) => async (dispatch: Dispatch) => {
    for (const project of projects) {
        try {
            loadProject(dispatch, project);
        } catch (error) {
            console.error(error);
        }
    }
};

export const updateProject = (dispatch: Dispatch, project: Project) => {
    dispatch({
        type: PROJECT_UPDATED,
        payload: project,
    });
    dispatch(datasetsSlice.updateCurrentProject(project));
};

// export const loadDatasetInOverview = (dispatch: Dispatch, dataset: Dataset) => {
//     if (overviewMapService.getItem(dataset.id)) return null;

//     dispatch(datasetsSlice.setDatasetState({ dataset, state: LAYER_STATES.LOADING }));
//     try {
//         const d = overviewMapService.loadDataset(dataset);
//         dispatch(datasetsSlice.addDataset(d));
//         return d;
//     } catch (e) {
//         dispatch(datasetsSlice.setDatasetState({ dataset, state: LAYER_STATES.LOADING_FAILED, message: e.message }));
//         throw e;
//     }
// };

export const loadOverviewMap = (domElem: HTMLDivElement, navigate) => async (dispatch: Dispatch) => {
    try {
        // Init the map
        overviewMapService.init(domElem, navigate, dispatch);
    } catch (err) {
        showError(dispatch, err);
    }
};

export const unloadOverviewMap = () => (dispatch: Dispatch, getState: GetState) => {
    const allCollections = getState().collections.collections;
    for (const collection of allCollections) {
        dispatch({
            type: COLLECTION_REMOVED,
            payload: collection,
        });
    }
    const allProjects = getState().projects.projects;
    for (const project of allProjects) {
        dispatch({
            type: PROJECT_REMOVED,
            payload: project,
        });
    }
    overviewMapService.deinit();
};

export const doLoadDataset = async (dispatch: Dispatch, dataset: Dataset) => {
    dispatch(datasetsSlice.setDatasetState({ dataset, state: LAYER_STATES.LOADING }));

    const sourceFiles = await DosApi.fetchDatasetSourcefiles(dataset.id);

    dispatch(datasetsSlice.setDatasetSourceFiles({ datasetId: dataset.id, sourceFiles }));
    dispatch(layersSlice.createSourcefiles({ dataset, sourceFiles }));

    return giro3dService
        .loadDataset(dataset, sourceFiles)
        .then(() => {
            dispatch(datasetsSlice.setDatasetState({ dataset, state: LAYER_STATES.LOADED }));
            return dataset;
        })
        .catch((reason) => {
            dispatch(
                datasetsSlice.setDatasetState({ dataset, state: LAYER_STATES.LOADING_FAILED, message: reason.message })
            );
            throw reason;
        });
};

export const doLoadDatasetMinimap = async (dispatch: Dispatch, dataset: Dataset) => {
    const sourceFiles = await DosApi.fetchDatasetSourcefiles(dataset.id);

    return giro3dService
        .getMinimapService()
        .loadDataset(dataset, sourceFiles)
        .catch((reason) => {
            throw reason;
        });
};

export const postComment =
    (annotation: Annotation, comment: Partial<AnnotationComment>) => async (dispatch: Dispatch) => {
        const response = await DosApi.postComment(annotation.project_id, annotation.id, comment);
        response.replies = [];
        dispatch(annotationsSlice.appendCommentList(response));
        return response;
    };

export const updateComment =
    (annotation: Readonly<Annotation>, commentId: CommentId, comment: Partial<AnnotationComment>) =>
    async (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const response = await DosApi.updateComment(annotation.project_id, annotation.id, commentId, comment);
        const original = annotationsSlice
            .comments(annotation.id)(state)
            .find((x: BaseComment) => x.id === commentId);

        response.replies = original.replies;
        response.user_permissions = original.user_permissions;

        dispatch(annotationsSlice.updateComment(response));
        // Update annotation if needed (web only)
        if (
            [
                ...annotationsSlice
                    .comments(annotation.id)(state)
                    .filter((x) => x.id !== commentId),
                response,
            ].filter((x) => x.resolved === false).length >
                0 !==
            annotation.unresolved_comments
        ) {
            const copy = { ...annotation };
            copy.unresolved_comments = !annotation.unresolved_comments;
            dispatch(annotationsSlice.updateAnnotation(copy));
        }
        return response;
    };

export const deleteComment = (annotation: Annotation, comment: AnnotationComment) => (dispatch: Dispatch) =>
    DosApi.deleteComment(annotation.project_id, annotation.id, comment.id).then(() => {
        dispatch(annotationsSlice.removeCommentFromList(comment));
    });

export function postReply(annotation: Annotation, parentId: UUID, reply: Partial<AnnotationCommentReply>) {
    return async (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const comments = annotationsSlice.comments(annotation.id)(state);
        const comment = comments.filter((x) => x.id === parentId)[0];
        const response = await DosApi.postReply(annotation.project_id, annotation.id, parentId, reply);
        const copy: AnnotationComment = { ...comment, replies: [...comment.replies] };
        copy.replies.push(response);
        dispatch(annotationsSlice.updateComment(copy));
        return response;
    };
}

export const updateReply =
    (annotation: Annotation, commentId: CommentId, replyId: CommentId, reply: Partial<AnnotationCommentReply>) =>
    async (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const comments = annotationsSlice.comments(annotation.id)(state);
        const comment = comments.find((x) => x.id === commentId);
        const original = comment.replies.find((x) => x.id === replyId);
        const response = await DosApi.updateReply(annotation.project_id, annotation.id, commentId, replyId, reply);
        response.user_permissions = original.user_permissions;

        const copy: AnnotationComment = {
            ...comment,
            replies: [...comment.replies.filter((x) => x.id !== replyId), response],
        };
        dispatch(annotationsSlice.updateComment(copy));
        return response;
    };

export const deleteReply =
    (annotation: Annotation, reply: AnnotationCommentReply) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const comments = annotationsSlice.comments(annotation.id)(state);
        const comment = comments.filter((x) => x.id === reply.comment_id)[0];
        const copy: AnnotationComment = { ...comment, replies: [...comment.replies] };
        copy.replies = copy.replies.filter((x) => x.id !== reply.id);
        return DosApi.deleteReply(annotation.project_id, annotation.id, reply.comment_id, reply.id).then(() => {
            dispatch(annotationsSlice.updateComment(copy));
        });
    };

export const fetchAnnotations = async (dispatch: Dispatch, projectId: UUID) =>
    DosApi.fetchAnnotations(projectId).then((data) => {
        dispatch(annotationsSlice.setAnnotations(data));
        return data;
    });

export const stopAnnotationCreation = () => (dispatch: Dispatch, getState: GetState) => {
    if (drawToolSlice.getTool(getState()) === DRAWN_TOOL.ANNOTATION) dispatch(drawToolSlice.setTool(null));
    shapeManager.stopDrawing();
    dispatch(drawToolSlice.setActiveGeometry(null));
    return dispatch(annotationsSlice.createAnnotationState(ANNOTATION_CREATESTATE.NONE));
};

export const stopAnnotationView = () => (dispatch: Dispatch) => {
    return dispatch(annotationsSlice.editAnnotationState(EDITSTATE.NONE));
};

export const stopAnnotationEdit = () => (dispatch: Dispatch, getState: GetState) => {
    shapeManager.stopDrawing();
    if (drawToolSlice.getTool(getState()) === DRAWN_TOOL.ANNOTATION) dispatch(drawToolSlice.setTool(null));
    return dispatch(annotationsSlice.editAnnotationState(EDITSTATE.NONE));
};

export const cancelAnnotationEdit = (annotation: Annotation) => (dispatch: Dispatch, getState: GetState) => {
    shapeManager.abortEdition();
    dispatch(annotationsSlice.editAnnotation({ annotation: annotation.id, edited: false }));
    if (drawToolSlice.getTool(getState()) === DRAWN_TOOL.ANNOTATION) dispatch(drawToolSlice.setTool(null));
    dispatch(annotationsSlice.editAnnotationState(EDITSTATE.NONE));
    dispatch(annotationsSlice.restorePreviousGeometry(annotation));
};

export const clearMeasureDrawing = () => async (dispatch: Dispatch, getState: GetState) => {
    shapeManager.stopDrawing();
    shapeManager.deleteCurrentMeasure();
    if (drawToolSlice.getTool(getState()) === DRAWN_TOOL.MEASURE) dispatch(drawToolSlice.setTool(null));
    dispatch(drawToolSlice.setActiveGeometry(null));
};

export const shouldDisplayToolConflictPopup = (state: RootState, dispatch: Dispatch) => {
    const tool = drawToolSlice.getTool(state);

    // The user is currently drawing a geometry: we should display the popup
    // as proceeding with the tool change can be a destructive action.
    if (shapeManager.isDrawingOrEditing && shapeManager.currentGeometryCoordinateCount > 0) {
        return true;
    }

    // No need to display the conflict popup, but close the current tool safely
    if (tool === DRAWN_TOOL.MEASURE) {
        dispatch(clearMeasureDrawing());
    } else if (annotationsSlice.createState(state) !== ANNOTATION_CREATESTATE.NONE) {
        dispatch(stopAnnotationCreation());
    } else if (tool === DRAWN_TOOL.ANNOTATION) {
        if (annotationsSlice.editState(state) !== EDITSTATE.NONE) {
            dispatch(stopAnnotationEdit());
        } else {
            dispatch(stopAnnotationView());
        }
    }

    return false;
};

export const startAnnotationCreation = () => (dispatch: Dispatch, getState: GetState) => {
    if (shouldDisplayToolConflictPopup(getState(), dispatch))
        useEventBus().dispatch('draw-tool-conflict', {
            tool: DRAWN_TOOL.ANNOTATION,
            mode: DRAWTOOL_MODE.CREATE,
        });
    else {
        dispatch(drawToolSlice.setTool(null));
        // Unselect any annotation
        dispatch(selectAnnotation());
        dispatch(annotationsSlice.createAnnotationState(ANNOTATION_CREATESTATE.SELECT));
        useEventBus().dispatch('create-pane', { paneType: PANE.ANNOTATION_TOOL, showExisting: true });
    }
};

export const startAnnotationDrawing = (geometryType: AnnotationGeometryType) => (dispatch: Dispatch) => {
    dispatch(annotationsSlice.createAnnotationState(ANNOTATION_CREATESTATE.DRAWING));

    dispatch(drawToolSlice.setTool(DRAWN_TOOL.ANNOTATION));
    dispatch(drawToolSlice.setType(geometryType));
    dispatch(drawToolSlice.setActiveGeometry(null));

    return shapeManager
        .drawAnnotation(geometryType, (id, geometry) => {
            dispatch(drawToolSlice.updateGeometry({ id, geometry }));
            dispatch(drawToolSlice.setActiveGeometry(id));
        })
        .then(({ id, geometry }) => {
            dispatch(annotationsSlice.createAnnotationState(ANNOTATION_CREATESTATE.DETAILS));
            dispatch(drawToolSlice.updateGeometry({ id, geometry }));
            dispatch(drawToolSlice.setActiveGeometry(id));
        })
        .catch((e) => {
            if (!(e instanceof Error) || e.message !== 'aborted') {
                console.warn(e);
            }
        });
};

export const cancelElevationProfile = () => async (dispatch: Dispatch, getState: GetState) => {
    shapeManager.stopDrawing();
    if (drawToolSlice.getTool(getState()) === DRAWN_TOOL.ELEVATION_PROFILE) dispatch(drawToolSlice.setTool(null));
};

export const cancelTool = (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    switch (state.drawTool.tool) {
        case DRAWN_TOOL.ANNOTATION:
            if (state.annotations.createState) dispatch(stopAnnotationCreation());
            else if (state.annotations.editState) dispatch(stopAnnotationEdit());
            else dispatch(stopAnnotationView());
            break;
        case DRAWN_TOOL.ELEVATION_PROFILE:
            dispatch(cancelElevationProfile());
            break;
        case DRAWN_TOOL.MEASURE:
            dispatch(clearMeasureDrawing());
            break;
        default:
            break;
    }
};

export const startObservationDetails = (point: SerializableVector3) => (dispatch: Dispatch) => {
    dispatch(cancelTool);

    const { id, geometry } = shapeManager.createShapeFromPoint(toVector3(point));

    dispatch(drawToolSlice.setTool(DRAWN_TOOL.QUICK_OBSERVATION));
    dispatch(drawToolSlice.setType('Point'));
    dispatch(annotationsSlice.createAnnotationState(ANNOTATION_CREATESTATE.DETAILS));
    dispatch(drawToolSlice.updateGeometry({ id, geometry }));
    dispatch(drawToolSlice.setActiveGeometry(id));
    eventBus.dispatch('create-pane', { paneType: PANE.ANNOTATION_TOOL, showExisting: true });
};

export const createAnnotation = (annotation: Partial<Annotation>) => async (dispatch: Dispatch, getState: GetState) => {
    dispatch(annotationsSlice.createAnnotationState(ANNOTATION_CREATESTATE.SUMBIT));
    const state = getState();
    const geometry = drawToolSlice.getActiveGeometry(state);
    const projectId = annotation.project_id;
    const createdAnnotation = await DosApi.createAnnotation(projectId, annotation, geometry);
    shapeManager.deleteShape(drawToolSlice.activeGeometry(state));
    dispatch(annotationsSlice.createAnnotationState(ANNOTATION_CREATESTATE.COMPLETE));
    return dispatch(annotationsSlice.appendAnnotation(createdAnnotation));
};
export const selectAnnotationForEdit = (annotation: Annotation) => (dispatch: Dispatch) => {
    dispatch(selectAnnotation(annotation.id)).then(() => {
        dispatch(annotationsSlice.editAnnotationState(EDITSTATE.DETAILS));
        eventBus.dispatch('create-annotation-pane', {
            paneType: PANE.ANNOTATION,
            annotationId: annotation.id,
            showExisting: false,
        });
        eventBus.dispatch('create-pane', { paneType: PANE.ANNOTATION_TOOL, showExisting: true });
    });
};

export const deleteAnnotation = (annotation: Annotation) => async (dispatch: Dispatch, getState: GetState) => {
    if (getState().giro3d.selection.objectId === annotation.id) dispatch(giro3dSlice.setSelection(undefined));
    if (getState().annotations.active === annotation.id) dispatch(annotationsSlice.setActiveAnnotation(null));
    eventBus.dispatch('close-annotation-panes', { annotationId: annotation.id });

    return DosApi.deleteAnnotation(annotation.project_id, annotation.id).then(() => {
        dispatch(annotationsSlice.removeAnnotation(annotation.id));
    });
};

export const startAnnotationEdit = (annotation: Annotation) => async (dispatch: Dispatch, getState: GetState) => {
    if (shouldDisplayToolConflictPopup(getState(), dispatch))
        return eventBus.dispatch('draw-tool-conflict', {
            tool: DRAWN_TOOL.ANNOTATION,
            mode: DRAWTOOL_MODE.EDIT,
            targetAnnotation: annotation,
        });
    dispatch(drawToolSlice.setTool(DRAWN_TOOL.ANNOTATION));
    // To be able to restore it later if the user cancels the edition.
    dispatch(annotationsSlice.saveCurrentGeometry(annotation));

    await dispatch(selectAnnotation(annotation.id)).then(() => {
        dispatch(annotationsSlice.editAnnotation({ annotation: annotation.id, edited: true }));
        dispatch(annotationsSlice.editAnnotationState(EDITSTATE.DRAWING));
        dispatch(drawToolSlice.updateGeometry({ id: annotation.id, geometry: annotation.geometry }));
        dispatch(drawToolSlice.setActiveGeometry(annotation.id));
        eventBus.dispatch('create-annotation-pane', {
            paneType: PANE.ANNOTATION,
            annotationId: annotation.id,
            showExisting: false,
        });
        eventBus.dispatch('create-pane', { paneType: PANE.ANNOTATION_TOOL, showExisting: true });
    });

    return annotationManager
        .editAnnotation(annotation.id)
        .then(async (newGeometry) => {
            const editedAnnotation = {
                ...(await DosApi.editAnnotation(annotation.project_id, annotation.id, {}, newGeometry)),
                user_permissions: annotation.user_permissions,
                datasets: annotation.datasets,
                comment_count: annotation.comment_count,
            };

            dispatch(annotationsSlice.updateAnnotation(editedAnnotation));
            dispatch(drawToolSlice.setTool(null));
            return dispatch(annotationsSlice.editAnnotationState(EDITSTATE.NONE));
        })
        .catch((e) => {
            if (!(e instanceof Error) || e.message !== 'aborted') console.warn(e);
        });
};

export const startAnnotationView = (annotation: Annotation) => (dispatch: Dispatch, getState: GetState) => {
    if (shouldDisplayToolConflictPopup(getState(), dispatch))
        return eventBus.dispatch('draw-tool-conflict', {
            tool: DRAWN_TOOL.ANNOTATION,
            mode: DRAWTOOL_MODE.VIEW,
            targetAnnotation: annotation,
        });
    dispatch(drawToolSlice.setTool(DRAWN_TOOL.ANNOTATION));
    dispatch(drawToolSlice.updateGeometry({ id: annotation.id, geometry: annotation.geometry }));
    dispatch(drawToolSlice.setActiveGeometry(annotation.id));

    eventBus.dispatch('create-annotation-pane', {
        paneType: PANE.ANNOTATION,
        annotationId: annotation.id,
        showExisting: false,
    });
    eventBus.dispatch('create-pane', { paneType: PANE.ANNOTATION_TOOL, showExisting: true });

    return dispatch(selectAnnotation(annotation.id)).then(() =>
        dispatch(annotationsSlice.editAnnotationState(EDITSTATE.VIEW))
    );
};

export const applyAnnotationEdit = (annotation: Annotation) => async (dispatch: Dispatch, getState: GetState) => {
    shapeManager.endEdition();

    const newGeometry = annotationsSlice.get(annotation.id)(getState()).geometry;

    const editedAnnotation = {
        ...(await DosApi.editAnnotation(annotation.project_id, annotation.id, {}, newGeometry)),
        user_permissions: annotation.user_permissions,
        datasets: annotation.datasets,
        comment_count: annotation.comment_count,
    };

    dispatch(annotationsSlice.updateAnnotation(editedAnnotation));
    dispatch(annotationsSlice.editAnnotation({ annotation: annotation.id, edited: false }));
    dispatch(annotationsSlice.selectAnnotation({ annotation: annotation.id, selected: true }));
    dispatch(drawToolSlice.setTool(null));
    return dispatch(annotationsSlice.editAnnotationState(EDITSTATE.NONE));
};

export const editAnnotation = (annotation: Partial<Annotation>) => async (dispatch: Dispatch, getState: GetState) => {
    dispatch(annotationsSlice.editAnnotationState(EDITSTATE.SUMBIT));
    dispatch(annotationsSlice.editAnnotation({ annotation: annotation.id, edited: true }));

    const state = getState();
    const geometry = annotationsSlice.getGeometry(annotation.id)(state);
    const original = annotationsSlice.active(state);

    let editedAnnotation: Annotation;
    if (geometry !== null) {
        editedAnnotation = await DosApi.editAnnotation(annotation.project_id, annotation.id, annotation, geometry);
    } else {
        editedAnnotation = await DosApi.editAnnotation(annotation.project_id, annotation.id, annotation);
    }
    editedAnnotation.comment_count = original.comment_count;
    editedAnnotation.datasets = original.datasets;
    editedAnnotation.user_permissions = original.user_permissions;

    dispatch(annotationsSlice.updateAnnotation(editedAnnotation));
    dispatch(annotationsSlice.editAnnotationState(EDITSTATE.COMPLETE));

    eventBus.dispatch('create-annotation-pane', {
        paneType: PANE.ANNOTATION,
        showExisting: true,
        annotationId: annotation.id,
    });
};

export const editAnnotationState = (annotation: Annotation, status: AnnotationStatus) => async (dispatch: Dispatch) => {
    const editedAnnotation = await DosApi.editAnnotation(annotation.project_id, annotation.id, { status });
    editedAnnotation.comment_count = annotation.comment_count;
    editedAnnotation.datasets = annotation.datasets;
    editedAnnotation.user_permissions = annotation.user_permissions;
    dispatch(annotationsSlice.updateAnnotation(editedAnnotation));
};

export const relateAnnotationDataset = (annotation: Annotation, datasetId: DatasetId) =>
    DosApi.relateAnnotationDataset(annotation.project_id, annotation.id, datasetId);

export const relateAnnotationDatasets = (annotation: Annotation, datasetIds: DatasetId[]) => {
    datasetIds.forEach((datasetId) => {
        relateAnnotationDataset(annotation, datasetId);
    });
};

export const unrelateAnnotationDataset = (annotation: Annotation, datasetId: DatasetId) =>
    DosApi.unrelateAnnotationDataset(annotation.project_id, annotation.id, datasetId);

export const unrelateAnnotationDatasets = (annotation: Annotation, datasetIds: DatasetId[]) => {
    datasetIds.forEach((datasetId) => {
        unrelateAnnotationDataset(annotation, datasetId);
    });
};

export const setAnnotationDatasets =
    (annotationId: AnnotationId, datasetIds: DatasetId[]) => async (dispatch: Dispatch, getState: GetState) => {
        dispatch(annotationsSlice.setAnnotationDatasets({ id: annotationId, datasets: datasetIds }));
        if (annotationsSlice.active(getState())?.id === annotationId)
            dispatch(annotationsSlice.setActiveAnnotation(annotationId));
    };

// The two methods below will fetch and load datasets into the overview for projects/collections that have not been loaded in yet.
// This means that most datasets can be fetched multiple times as they are duplicated across multiple 'collections' of datasets.
// The datasets will only be loaded to the map once, however.
// export const loadProjectDatasetsToOverview = (project: ProjectId) => (dispatch: Dispatch) => {
//     if (!overviewMapService.checkLoadedCollection(project))
//         DosApi.fetchProjectDatasets(project).then((datasets: Dataset[]) => {
//             const datasetsWithGeometry = datasets.filter((d) => !!d.geometry);
//             const srids = Array.from(new Set(datasetsWithGeometry.map((d) => d.projection)));
//             overviewMapService.addLoadedCollection(project);
//             return dispatch(getProj4s(srids)).then((projections) => {
//                 overviewMapService.initproj4(projections);
//                 for (const dataset of datasetsWithGeometry) {
//                     loadDatasetInOverview(dispatch, dataset);
//                 }
//             });
//         });
// };

// export const loadCollectionDatasetsToOverview = (collection) => (dispatch: Dispatch) => {
//     if (!overviewMapService.checkLoadedCollection(collection))
//         DosApi.fetchDatasets(collection).then((datasets: Dataset[]) => {
//             const datasetsWithGeometry = datasets.filter((d) => !!d.geometry);
//             const srids = Array.from(new Set(datasetsWithGeometry.map((d) => d.projection)));
//             overviewMapService.addLoadedCollection(collection);
//             return dispatch(getProj4s(srids)).then((projections) => {
//                 overviewMapService.initproj4(projections);
//                 for (const dataset of datasetsWithGeometry) {
//                     loadDatasetInOverview(dispatch, dataset);
//                 }
//             });
//         });
// };

export const fetchNotifications = () => (dispatch: Dispatch, getState: GetState) => {
    if (getState().notifications.list === undefined) {
        DosApi.fetchNotifications().then((data) =>
            dispatch({
                type: NOTIFICATIONS,
                payload: data,
            })
        );
    }
};

export const selectNotification = (notification: Notification) => (dispatch: Dispatch) =>
    dispatch({
        type: ACTIVE_NOTIFICATION,
        payload: notification,
    });

export const updateNotificationState = (notification: Notification, status) => async (dispatch: Dispatch) => {
    const response = await DosApi.updateNotificationState(notification.id, status);
    dispatch({ type: UPDATE_NOTIFICATION, payload: response });
};

export const checkForUsernames = (userIds: UUID[]) => (dispatch: Dispatch, getState: GetState) => {
    const existingUsernames = getState().users.usernames;
    const requestedUsernames = getState().users.requestedUsernames;

    const newIds = userIds.filter(
        (id) => !existingUsernames.map((user) => user.id).includes(id) && !requestedUsernames.includes(id)
    );

    if (newIds.length !== 0) {
        dispatch({
            type: QUEUE_USERNAMES,
            payload: newIds,
        });
        DosApi.fetchUsernames(newIds).then((users) => {
            const fetchedIds = new Set(users.map((u) => u.id));
            const missingIds = newIds.filter((id) => !fetchedIds.has(id));
            missingIds.forEach((missingId) =>
                users.push({ id: missingId, username: '', given_name: 'Unknown User', family_name: '' })
            );
            dispatch({
                type: ADD_USERNAMES,
                payload: users,
            });
        });
    }
};

export const fetchMembershipsToAdmin = (organizationId: UUID) => (dispatch: Dispatch) =>
    DosApi.fetchMembershipsFor(organizationId).then((data) =>
        dispatch({
            type: SET_ADMINISTRATED_MEMBERSHIPS,
            payload: data,
        })
    );

export const updateMembership = (membership: Membership, role: ROLES) => (dispatch: Dispatch) => {
    membership.role = role;
    DosApi.updateMembership(membership.id, membership).then((data) =>
        dispatch({ type: UPDATE_ADMINISTRATED_MEMBERSHIP, payload: data })
    );
};

export const updateMembershipUser = (membership: Membership, user: User) => (dispatch: Dispatch) => {
    DosApi.updateUser(user.id, user).then((data) => {
        membership.user = data;
        dispatch({ type: UPDATE_ADMINISTRATED_MEMBERSHIP, payload: membership });
    });
};

export const fetchUsersToAdmin = () => (dispatch: Dispatch) =>
    DosApi.fetchUsers().then((data) => {
        dispatch({
            type: SET_ADMINISTRATED_USERS,
            payload: data,
        });
    });

export const updateUser = (user: User) => (dispatch: Dispatch) =>
    DosApi.updateUser(user.id, user).then((data) => dispatch({ type: UPDATE_ADMINISTRATED_USER, payload: data }));

export const deleteDataset = (dataset: Dataset) => (dispatch: Dispatch) =>
    DosApi.deleteDataset(dataset.id).then(() => {
        dispatch({ type: DELETE_DATASET, payload: dataset });
    });

export const deleteDatasetBatch = (datasets: DatasetId[]) => (dispatch: Dispatch) =>
    DosApi.deleteDatasets(datasets).then(() => {
        dispatch({ type: DELETE_DATASETS, payload: datasets });
    });

export const unloadGiro3d = () => (dispatch: Dispatch, getState: GetState) => {
    const allDatasets = getState().datasets.datasets;
    dispatch(annotationsSlice.setAnnotations([]));
    for (const ds of allDatasets) {
        dispatch(datasetsSlice.removeDataset(ds));
    }
    dispatch(gridSlice.reset());
    dispatch(seismicGridSlice.reset());
    dispatch(crossSectionsSlice.reset());
    dispatch(giro3dSlice.setContextMenu({ open: false }));
    dispatch(giro3dSlice.resetFollowCamera());
    dispatch(giro3dSlice.setControlsMode(CONTROLS_MODE.PAN));
    dispatch(giro3dSlice.setCoordinatesShown(false));
    dispatch(giro3dSlice.setZScale(1));
    dispatch(layoutSlice.reset());
    giro3dService.deinit();
};

export const loadGiro3d =
    (
        domElem: HTMLDivElement,
        inspectorDomElem: HTMLDivElement,
        controlsDomElem: HTMLDivElement,
        project: Project,
        queryParams: QueryParameters
    ) =>
    async (dispatch: Dispatch) => {
        try {
            dispatch(datasetsSlice.setState(DATASETS_STATES.LOADING));

            dispatch(layersSlice.reset());

            dispatch(unloadGiro3d());

            initproj4([[`EPSG:${project.projection}`, project.projection_proj4]]);

            const newExtent = geojsonUtils.computeExtent(project.geometry, 'EPSG:4326', `EPSG:${project.projection}`);
            giro3dService.init(domElem, inspectorDomElem, newExtent, dispatch, controlsDomElem);

            await fetchAnnotations(dispatch, project.id);

            if (queryParams.annotation) {
                dispatch(selectAnnotation(queryParams.annotation));
            }

            DosApi.fetchProjectUsers(project.id).then((data) => dispatch({ type: PROJECT_USERS, payload: data }));

            DosApi.fetchProjectDatasetsList(project.id).then(async (datasets: Dataset[]) => {
                const overviewIds = datasets.filter((d) => !!d.overview_id).map((d) => d.overview_id);

                const projections = [...new Set(datasets.map((dataset) => dataset.projection))];
                await dispatch(getProj4s(projections)).then((proj4s) => initproj4(proj4s));

                const allPromises = [];
                for (const dataset of datasets) {
                    dataset.doRender = true;
                    if (overviewIds.includes(dataset.id)) {
                        dataset.doRender = false;
                    }
                    const promise = loadDataset(dispatch, dataset, true);
                    if (promise) {
                        allPromises.push(promise);
                    }
                }

                await Promise.allSettled(allPromises);
                await giro3dService.reorderDatasets(datasets.map((d) => d.id));

                dispatch(datasetsSlice.setState(DATASETS_STATES.LOADED));

                await giro3dService.postInit();

                if (queryParams?.view) {
                    DosApi.fetchView(project.id, queryParams.view.split('/')[0])
                        .then((json) => {
                            serializer.loadView(json.view, queryParams.view.split('/')[0], dispatch);
                        })
                        .catch((err) => {
                            showError(dispatch, err);
                        });
                } else if (project.default_view_id) {
                    DosApi.fetchView(project.id, project.default_view_id)
                        .then((json) => {
                            serializer.loadView(json.view, project.default_view_id, dispatch);
                        })
                        .catch((err) => {
                            showError(dispatch, err);
                        });
                }
            });
        } catch (err) {
            showError(dispatch, err);
        }
    };

export const unloadMinimap = () => async () => {
    giro3dService.getMinimapService().deinit();
    giro3dService.setMinimapService(undefined);
};

export const loadMinimap =
    (minimapDomElem: HTMLDivElement, inspectorDomElem: HTMLDivElement, project: Project) =>
    async (dispatch: Dispatch) => {
        const newExtent = geojsonUtils.computeExtent(project.geometry, 'EPSG:4326', `EPSG:${project.projection}`);

        const minimapService = new MinimapService();
        minimapService.init(minimapDomElem, inspectorDomElem, newExtent, dispatch);
        giro3dService.setMinimapService(minimapService);
        giro3dService.loadLayersToMinimap();
        minimapService.postInit();
    };

export const loadSeismicView =
    (seismicDomElem: HTMLDivElement, inspectorDomElem: HTMLDivElement, project: Project) =>
    async (dispatch: Dispatch) => {
        const newExtent = geojsonUtils.computeExtent(project.geometry, 'EPSG:4326', `EPSG:${project.projection}`);

        const seismicService = new SeismicService();
        giro3dService.setSeismicService(seismicService);
        seismicService.init(seismicDomElem, inspectorDomElem, newExtent, dispatch);
    };

export const unloadSeismicView = () => async () => {
    giro3dService.getSeismicService()?.deinit();
    giro3dService.setSeismicService(undefined);
};

export const setSeismicDataset =
    (dataset: Dataset, sourceFile: SourceFile) => async (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const layer = layersSlice.get(dataset.id)(state);
        if (hasSeismicPlane(layer)) dispatch(layersSlice.setActiveFile({ layer, value: sourceFile.id }));

        giro3dService.loadDatasetToSeismicView(dataset, datasetsSlice.getSourceFiles(dataset.id)(state));
    };

export const reorderDatasets = (order: DatasetId[]) => async (dispatch: Dispatch) => {
    dispatch(datasetsSlice.reorderDatasets(order));
};

export const loadView = (projectId: ProjectId, viewId: ViewId, dispatch: Dispatch) => () =>
    DosApi.fetchView(projectId, viewId).then((json) => serializer.loadView(json.view, viewId, dispatch));

export const createView = (view) => (_dispatch: Dispatch, getState: GetState) =>
    DosApi.createView(getState().datasets.currentProject, view);

export const updateView = (view) => () => DosApi.updateView(view);

export const deleteView = (view) => () => DosApi.deleteView(view);

export const startProfileLineDrawing = () => async (dispatch: Dispatch, getState: GetState) => {
    if (shouldDisplayToolConflictPopup(getState(), dispatch))
        return eventBus.dispatch('draw-tool-conflict', { tool: DRAWN_TOOL.ELEVATION_PROFILE });
    dispatch(drawToolSlice.setTool(DRAWN_TOOL.ELEVATION_PROFILE));
    return shapeManager
        .drawSegment()
        .then((geometry) => geometry)
        .catch((e) => {
            if (!(e instanceof Error) || e.message !== 'aborted') {
                console.warn(e);
            }

            return null as LineString;
        });
};

export const generateElevationProfile = (start, end, datasets, samples) => async (dispatch: Dispatch) => {
    dispatch(drawToolSlice.setTool(null));
    dispatch(drawToolSlice.setActiveGeometry(null));
    dispatch(drawToolSlice.setTool(undefined));

    if (!start || !end || datasets.length === 0 || samples <= 1 || samples > 1000) {
        return;
    }
    const lineCurve = new THREE.LineCurve(new THREE.Vector2(start[0], start[1]), new THREE.Vector2(end[0], end[1]));
    const points = lineCurve.getSpacedPoints(samples - 1);
    const elevations = giro3dService.getElevationProfile(points, datasets);

    eventBus.dispatch('create-data-pane', {
        paneType: PANE.ELEVATION_PROFILE_CHART,
        data: {
            points,
            elevations,
        },
        showExisting: false,
    });
};

export const updateElevationProfile = (start, end, datasets, samples, tabId) => async () => {
    if (!start || !end || datasets.length === 0 || samples <= 1 || samples > 1000) {
        return;
    }
    const lineCurve = new THREE.LineCurve(new THREE.Vector2(start[0], start[1]), new THREE.Vector2(end[0], end[1]));
    const points = lineCurve.getSpacedPoints(samples - 1);
    const elevations = giro3dService.getElevationProfile(points, datasets);

    eventBus.dispatch('update-data-pane', {
        data: {
            points,
            elevations,
        },
        tabId,
    });
};

export const startMeasureDrawing = () => async (dispatch, getState) => {
    if (shouldDisplayToolConflictPopup(getState(), dispatch)) {
        eventBus.dispatch('draw-tool-conflict', { tool: DRAWN_TOOL.MEASURE });
    }

    dispatch(drawToolSlice.setTool(DRAWN_TOOL.MEASURE));

    eventBus.dispatch('create-pane', { paneType: PANE.MEASURE, showExisting: true });

    shapeManager
        .drawMeasure((id) => dispatch(drawToolSlice.setActiveGeometry(id)))
        .catch((e) => {
            if (!(e instanceof Error) || e.message !== 'aborted') console.warn(e);
        });
};

export const setClickedDataset =
    (datasetId: DatasetId, sourceFileId: SourceFileId = undefined) =>
    async (dispatch: Dispatch, getState: GetState) => {
        if (
            getState()
                .datasets.datasets.map((d) => d.id)
                .includes(datasetId)
        ) {
            dispatch(datasetsSlice.setClickedDataset({ dataset: datasetId, sourceFile: sourceFileId }));
            dispatch(giro3dSlice.setSelection({ objectId: datasetId, type: 'dataset' }));
        }
    };

export const generateAPIKey = () => async () => DosApi.createApiKey();

export const fetchApiKeys = () => async (dispatch: Dispatch) => {
    DosApi.fetchApiKeys().then((keys) => dispatch({ type: SET_API_KEYS, payload: keys }));
};

export const fetchAllApiKeys = () => async (dispatch: Dispatch) => {
    DosApi.fetchAllApiKeys().then((keys) => dispatch({ type: SET_API_KEYS_ADMIN, payload: keys }));
};

export const deleteApiKey = (keyId) => async (dispatch: Dispatch) => {
    DosApi.deleteApiKey(keyId).then(() => dispatch({ type: REMOVE_API_KEY, payload: keyId }));
};

export const pollForDatasets = (datasets: Dataset[]) => async (dispatch: Dispatch, getState: GetState) => {
    const projectDatasets = getState().datasets.datasets;
    DosApi.pollDatasets(datasets.map((d) => d.id)).then((response) => {
        response.forEach((freshDataset) => {
            if (freshDataset.state !== datasets.find((d) => d.id === freshDataset.id).state) {
                dispatch({ type: UPDATE_DATASET, payload: freshDataset });
                if (
                    freshDataset.state === LAYER_STATES.ACTIVE &&
                    projectDatasets.map((d) => d.id).includes(freshDataset.id)
                ) {
                    dispatch(layersSlice.rebuildLayer(freshDataset));
                    const allLayers = giro3dService.getLayers();
                    const isFirstLayer = allLayers.size === 0;
                    const promise = doLoadDataset(dispatch, { ...freshDataset, doRender: true });
                    // TODO: show a message saying the dataset is ready, and let the user zoom into it if we wishes
                    if (promise) {
                        if (isFirstLayer) {
                            // Zoom into the new layer only if it's the first we add or if we just uploaded
                            // If there are other layers, we don't want to disrupt the user navigation
                            promise.then(() => {
                                giro3dService.goToLayer(freshDataset.id);
                            });
                        }
                    }
                }
            }
        });
    });
};

export const pollForSourcefiles =
    (datasetId: DatasetId, sourcefiles: SourceFile[]) => async (dispatch: Dispatch, getState: GetState) => {
        const datasetSourceFiles = getState().datasets.sourceFiles[datasetId];
        DosApi.pollDatasetSourcefiles(
            datasetId,
            sourcefiles.map((s) => s.id)
        ).then((response) => {
            response.forEach((freshSourcefile) => {
                if (freshSourcefile.state !== datasetSourceFiles.find((s) => s.id === freshSourcefile.id).state) {
                    dispatch(datasetsSlice.updateDatasetSourceFile({ datasetId, sourceFile: freshSourcefile }));
                }
            });
        });
    };

import { Vector3 } from 'three';
import Shape, { isShape } from '@giro3d/giro3d/entities/Shape';
import type { Position } from 'geojson';
import { AnnotationGeometry } from 'types/Annotation';

export type Input = Vector3[] | AnnotationGeometry | Shape;

function positionToVector3(p: Position): Vector3 {
    return new Vector3(p[0], p[1], p[2] ?? 0);
}

export function extractCoordinates(input: Input): Vector3[] | null {
    if (input == null) {
        return null;
    }

    if (Array.isArray(input)) {
        return input;
    }

    if (isShape(input)) {
        return [...input.points];
    }

    // GeoJSON geometry
    if ('type' in input && 'coordinates' in input) {
        switch (input.type) {
            case 'Point':
                return [positionToVector3(input.coordinates)];
            case 'LineString':
                return input.coordinates.map(positionToVector3);
            case 'Polygon':
                // Take the outer ring, as the inner rings are not supported as valid measures
                return input.coordinates[0].map(positionToVector3);
            default:
                throw new Error('unsupported geometry type');
        }
    }

    throw new Error('invalid object.');
}

/**
 * Gets length of each segments.
 * Assumes coordinates are in geocentric CRS.
 */
export function getLength(input: Input): number[] | null {
    if (!input) return null;

    const coordinates = extractCoordinates(input);

    if (coordinates.length < 2) {
        return null;
    }

    const lengths = new Array<number>(coordinates.length - 1);

    for (let i = 0; i < coordinates.length - 1; i += 1) {
        lengths[i] = coordinates[i].distanceTo(coordinates[i + 1]);
    }

    return lengths;
}

/**
 * Gets elevation difference for each segments.
 * Assumes coordinates are in geocentric CRS.
 */
export function getElevationDiff(input: Input): number[] {
    if (!input) return null;

    const coordinates = extractCoordinates(input);

    if (coordinates.length < 2) {
        return null;
    }

    const elevationDiff: number[] = new Array(coordinates.length - 1);
    for (let i = 0; i < coordinates.length - 1; i += 1) {
        elevationDiff[i] = coordinates[i].z - coordinates[i + 1].z;
    }

    return elevationDiff;
}

/**
 * Gets min and max altitudes of a geometry.
 * Assumes coordinates are in geocentric CRS.
 * @returns Min and max
 */
export function getMinMaxAltitudes(input: Input): [number, number] {
    let min = +Infinity;
    let max = -Infinity;

    if (!input) {
        return [min, max];
    }

    const coordinates = extractCoordinates(input);

    for (let i = 0; i < coordinates.length; i += 1) {
        min = Math.min(min, coordinates[i].z);
        max = Math.max(max, coordinates[i].z);
    }

    return [min, max];
}

/**
 * Gets area of a polygon.
 * Assumes coordinates are in geocentric CRS.
 */
export function getArea(input: Input): number {
    if (!input) return null;

    const coordinates = extractCoordinates(input);

    // Create a temporary Giro3D shape to compute the area,
    // since it supports areas of arbitrary polygons,
    // and not just flat polygons on the horizontal plane.
    const shape = new Shape();
    shape.setPoints(coordinates);

    return shape.getArea();
}
